import React, { Component } from 'react';
import { Xulaservice } from '../service/Xulaservice';
import { InputText } from 'primereact/inputtext';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dialog } from 'primereact/dialog';
import { Growl } from 'primereact/growl';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import './styles.css';
import Cleave from 'cleave.js/react';
import { Panel } from 'primereact/panel';
import { ProgressSpinner } from 'primereact/progressspinner';
import 'moment';
import 'moment/locale/fr';
import moment from 'moment';
import Numeral from 'numeral';

export class FormPrime extends Component {
    constructor() {
        super();
        this.state = {
            liste_prime: [],
            liste_accorder: [],
            liste_personnel: [],
            selectedPrime: null,
            selectedBeneficiaire: null,
            actPrime: {label: 'Oui', value: 'Oui' },
            idPrime: '0',
            libPrime: '',
            mtPrime: 0,

            matrPers: null,
            mtAccorder: 0,
            idAccorder: '0',
            dateAccorder: new Date(),
            actAccorder: {label: 'Oui', value: 'Oui' },
            msg: '',
            modif: false,
            modif_accorder: false,
            visible: false,
            visible_accorder: false,
            liste_actif: [
                {label: 'Oui', value: 'Oui' },
                {label: 'Non ', value: 'Non' },
            ],
            userconnected: JSON.parse(localStorage.getItem('userconnected')),
        };
        this.edit_prime = this.edit_prime.bind(this);
        this.edit_beneficiaire = this.edit_beneficiaire.bind(this);
        this.conf_delete_prime = this.conf_delete_prime.bind(this);
        this.conf_delete_beneficiaire = this.conf_delete_beneficiaire.bind(this);
        this.xulaservice = new Xulaservice();
    }

    componentDidMount() {
        this.get_liste_prime();
        this.get_liste_personnel();
    }

    formaterMontant(montant) {
        return Numeral(parseFloat(montant)).format('0,0');
    }

    get_liste_personnel() {
        this.xulaservice.get_liste_personnel().then(data => this.setState({ liste_personnel: data }));
    }

    personnelItemTemplate(option) {
        if (!option.value) {
            return option.label;
        }
        else {
            return (
                <div className="p-clearfix">
                    <div className="p-grid">
                        <div style={{ fontWeight: 'bold' }} className="p-col-12 p-md-6">
                            <label>{option.label}</label>
                        </div>
                        <div className="p-col-12 p-md-2">
                            <label>{option.value}</label>
                        </div>
                        <div className="p-col-12 p-md-4">
                            <label>{option.nomBoutiq}</label>
                        </div>
                    </div>
                </div>
            );
        }
    }

    get_liste_prime() {
        this.xulaservice.get_liste_prime().then(data => this.setState({ liste_prime: data }));
    }

    getListe_accorder(idPrime) {
        this.xulaservice.get_liste_accorder(idPrime).then(data => {
            this.setState({ liste_accorder: data });
        });
    }

    resultat(code, contenu) {
        if (code == '200') {
            this.growl.show({ severity: 'success', summary: 'Success', detail: contenu });
            this.annule_prime();
            this.get_liste_prime();
        } else {
            this.growl.show({ severity: 'error', summary: 'Error', detail: contenu });
        }
    }

    resultat_accorder(code, contenu) {
        if (code == '200') {
            this.growl.show({ severity: 'success', summary: 'Success', detail: contenu });
            this.annule_beneficiaire();
            this.getListe_accorder(this.state.selectedPrime.idPrime);
        } else {
            this.growl.show({ severity: 'error', summary: 'Error', detail: contenu });
        }
    }

    edit_prime(data) {
        if (data != null && data != undefined) {
            this.setState({ idPrime: data.idPrime });
            this.setState({ libPrime: data.libPrime });
            this.setState({ mtPrime: data.mtPrime });
            this.setState({ actPrime: data.actPrime});
            this.setState({ modif: true });
            this.setState({ descPrime: data.descPrime });
            this.setState({ selectedPrime: data });
        }
    }

    edit_beneficiaire(data) {
        if (data != null && data != undefined) {
            this.setState({ idAccorder: data.idAccorder });
            this.setState({ matrPers: { value: data.matrPers.matrPers, label: data.matrPers.nomPers, nomBoutiq: data.matrPers.idBoutiq.nomBoutiq } });
            this.setState({ mtAccorder: data.mtAccorder });
            this.setState({ actAccorder: data.actAccorder});
            this.setState({ dateAccorder: data.dateAccorder });
            this.setState({ modif_accorder: true });
            this.setState({ selectedBeneficiaire: data });
        }
    }

    annule_prime() {
        this.setState({ libPrime: '' });
        this.setState({ descPrime: '' });
        this.setState({ mtPrime: 0 });
        this.setState({ idPrime: '0' });
        this.setState({ modif: false });
        this.forceUpdate();
    }

    annule_beneficiaire() {
        //this.setState({ matrPers: '' });
        //this.setState({ mtAccorder: 0 });
       // this.setState({ idAccorder: '0' });
        //this.setState({ dateAccorder: '' });
        this.setState({ modif_accorder: false });
        this.forceUpdate();
    }

    enreg_prime() {
        if (this.state.libPrime === "" || this.state.mtPrime === "" || this.state.mtPrime === "0" || this.state.libPrime === undefined) {
            this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez saisir tous les champs obligatoires' });
        } else {
            if (this.state.modif == false) {
                let data = { idPrime: '0', libPrime: this.state.libPrime, mtPrime: this.state.mtPrime, descPrime: this.state.descPrime, actPrime: this.state.actPrime };
                this.xulaservice.enreg_prime(data).then(data => this.resultat(data.code, data.contenu));
            } else {
                let data = this.state.selectedPrime;
                data.mtPrime = this.state.mtPrime;
                data.actPrime = this.state.actPrime;
                data.descPrime = this.state.descPrime;
                this.xulaservice.enreg_prime(data).then(data => this.resultat(data.code, data.contenu));
            }
        }
    }

    enreg_beneficiaire() {
        if (this.state.selectedPrime != null && this.state.selectedPrime != undefined) {
            if (this.state.matrPers === "" || this.state.mtAccorder === 0 || this.state.dateAccorder === '' || this.state.dateAccorder === null || this.state.matrPers === undefined) {
                this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez saisir tous les champs obligatoires' });
            } else {
                if (this.state.modif_accorder === false) {
                    let date = moment(this.state.dateAccorder, 'DD/MM/YYYY').format('DD/MM/YYYY');
                    let data = { idAccorder: '0', idPrime: { idPrime: this.state.selectedPrime.idPrime }, matrPers: { matrPers: this.state.matrPers.value }, mtAccorder: this.state.mtAccorder, actAccorder: this.state.actAccorder, dateAccorder: date };
                    this.xulaservice.enreg_accorder(data).then(data => this.resultat_accorder(data.code, data.contenu));
                } else {
                    let date = moment(this.state.dateAccorder, 'DD/MM/YYYY').format('DD/MM/YYYY');
                    let data = this.state.selectedBeneficiaire;
                    data.mtAccorder = this.state.mtAccorder;
                    data.dateAccorder = date;
                    data.actAccorder = this.state.actAccorder;
                    data.matrPers = { matrPers: this.state.matrPers.value,nomPers: this.state.matrPers.label,idBoutiq: {idBoutiq: this.state.matrPers.idBoutiq,nomBoutiq: this.state.matrPers.nomBoutiq} };
                    this.xulaservice.enreg_accorder(data).then(data => this.resultat_accorder(data.code, data.contenu));
                }
            }
        } else {
            this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez selectionner une prime' });
        }

    }

    actionTemplate(rowData, column) {
        return <div className="p-grid" style={{ width: 90 }}>
            <div className="p-col"><Button onClick={(e) => this.edit_prime(rowData)} className="p-button-info" style={{ width: 25, height: 25 }} icon="pi pi-pencil" /></div>
            <div className="p-col"><Button onClick={(e) => this.conf_delete_prime(rowData)} className="p-button-danger" style={{ width: 25, height: 25 }} icon="pi pi-times" /></div>
        </div>
    }
    actionTemplate_accorder(rowData, column) {
        return <div className="p-grid" style={{ width: 90 }}>
            <div className="p-col"><Button onClick={(e) => this.edit_beneficiaire(rowData)} className="p-button-info" style={{ width: 25, height: 25 }} icon="pi pi-pencil" /></div>
            <div className="p-col"><Button onClick={(e) => this.conf_delete_beneficiaire(rowData)} className="p-button-danger" style={{ width: 25, height: 25 }} icon="pi pi-times" /></div>
        </div>
    }


    delete_prime() {
        if (this.state.selectedPrime == null || this.state.selectedPrime == undefined) {
            this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez sélectionner la ligne à supprimer' });
        } else {
            this.xulaservice.delete_prime(this.state.selectedPrime).then(data => this.resultat(data.code, data.contenu));
        }
    }

    delete_beneficiaire() {
        if (this.state.selectedBeneficiaire == null || this.state.selectedBeneficiaire == undefined) {
            this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez sélectionner la ligne à supprimer' });
        } else {
            this.xulaservice.delete_accorder(this.state.selectedBeneficiaire).then(data => this.resultat_accorder(data.code, data.contenu));
        }
    }


    conf_delete_prime(data) {
        if (data != null && data != undefined) {
            this.setState({ selectedPrime: data });
            this.setState({ msg: data.libPrime });
            this.setState({ visible: true });
        }
    }

    conf_delete_beneficiaire(data) {
        if (data != null && data != undefined) {
            this.setState({ selectedBeneficiaire: data });
            this.setState({ msg: data.matrPers.nomPers+' / '+data.idPrime.libPrime });
            this.setState({ visible_accorder: true });
        }
    }



    render() {

        let lpersonnel = null;
        if (this.state.liste_personnel != null && this.state.liste_personnel != undefined) {
            lpersonnel = this.state.liste_personnel.map(x => {
                return { value: x.matrPers, label: x.nomPers, nomBoutiq: x.idBoutiq.nomBoutiq }
            });
        }

        const dialogFooter = (
            <div>
                <Button icon="pi pi-check" onClick={() => {
                    this.setState({ visible: false });
                    this.delete_prime();
                }} label="Oui" />
                <Button icon="pi pi-times" onClick={() => this.setState({ visible: false })} label="Annuler" className="p-button-secondary" />
                }
            </div>
        );

        const dialogFooter_accorder = (

            <div>
                <Button icon="pi pi-check" onClick={() => {
                    this.setState({ visible_accorder: false });
                    this.delete_beneficiaire();
                }} label="Oui" />
                <Button icon="pi pi-times" onClick={() => this.setState({ visible_accorder: false })} label="Annuler" className="p-button-secondary" />
                }


            </div>
        );
        return (
            <div className="p-fluid ">
                <Growl ref={(el) => this.growl = el} />
                <Dialog header="Confirmation" visible={this.state.visible} style={{ width: '250px' }} modal={true} footer={dialogFooter} onHide={(e) => this.setState({ visible: false })}>
                    <div>
                        <i className="fa fa-warning" style={{ color: '#FFD133', fontSize: 25 }}></i>
                        Voulez vous vraiment supprimer <b>{this.state.msg} </b>?
                    </div>
                </Dialog>

                <Dialog header="Confirmation" visible={this.state.visible_accorder} style={{ width: '250px' }} modal={true} footer={dialogFooter_accorder} onHide={(e) => this.setState({ visible_accorder: false })}>
                    <div>
                        <i className="fa fa-warning" style={{ color: '#FFD133', fontSize: 25 }}></i>
                        Voulez vous vraiment supprimer <b>{this.state.msg} </b>?
                    </div>
                </Dialog>

                <div className="p-grid">
                    <div className="p-col-12 p-lg-6">
                        <div className="p-grid">
                            <div className="p-col-12">
                                <div className="card card-w-title" style={{ height: 700 }}>

                                    <h2><i className="fa fa-fw fa-sitemap"></i>Gestion des Primes</h2>
                                    <hr />
                                    <Panel style={{ minHeight: '250px', marginBottom: 8 }}>
                                        <DataTable value={this.state.liste_prime}
                                            responsive={true} scrollable={true} scrollHeight="200px" selectionMode="single"
                                            onRowClick={e => this.setState({ selectedPrime: e.data }, () => {
                                                this.setState({ mtAccorder: this.state.selectedPrime.mtPrime });
                                                this.getListe_accorder(this.state.selectedPrime.idPrime);
                                            })}
                                            responsive={true} selection={this.state.selectedPrime}
                                        >
                                            <Column header="Action" body={this.actionTemplate.bind(this)} style={{ width: '15%' }} />
                                            <Column field="libPrime" header="Libellé" sortable={true} style={{ width: '50%' }} />
                                            <Column field="mtPrime" body={(rowData, column) => {
                                                return <div>
                                                    <span style={{ color: '#000000', fontWeight: 'bold' }}>{this.formaterMontant(parseFloat(rowData.mtPrime))}</span>
                                                </div>
                                            }} header="Montant" sortable={true} style={{ width: '20%' }} />
                                            <Column field="actPrime" header="Actif" sortable={true} style={{ width: '15%' }} />

                                        </DataTable>
                                    </Panel>


                                    <div className="p-grid">
                                        <div className="p-col-12 p-md-3">
                                            <label htmlFor="libPrime">Libellé *</label>
                                        </div>
                                        <div className="p-col-12 p-md-9">
                                            <InputText id="libPrime" value={this.state.libPrime} onChange={(e) => this.setState({ libPrime: e.target.value, descPrime: e.target.value })} />
                                        </div>
                                        <div className="p-col-12 p-md-3">
                                            <label htmlFor="descPrime">Description</label>
                                        </div>
                                        <div className="p-col-12 p-md-9">
                                            <InputText id="descPrime" value={this.state.descPrime} onChange={(e) => this.setState({ descPrime: e.target.value })} />
                                        </div>
                                        <div className="p-col-12 p-md-3">
                                            <label htmlFor="mtPrime">Montant *</label>
                                        </div>
                                        <div className="p-col-12 p-md-9">
                                            <Cleave value={this.state.mtPrime} options={{ numeral: true, delimiter: ' ' }} onChange={(e) => this.setState({ mtPrime: e.target.rawValue })} className="p-inputtext" />
                                        </div>

                                        <div className="p-col-12 p-md-3">
                                            <label htmlFor="actPrime">Actif *</label>
                                        </div>
                                        <div className="p-col-12 p-md-9">
                                            <Dropdown id="actPrime" options={this.state.liste_actif} autoWidth={false} value={this.state.actPrime}  placeholder="Sélectionner"  onChange={(e) => this.setState({ actPrime: e.value })} />
                                        </div>

                                    </div>
                                    <center>
                                        <div className="p-grid" style={{ width: 300 }}>
                                            <div className="p-col"><Button label="Annuler" icon="pi pi-ban" onClick={(e) => this.annule_prime()} /></div>
                                            <div className="p-col"><Button label="Valider" icon="pi pi-check" onClick={(e) => this.enreg_prime()} /></div>
                                        </div>
                                        {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : null}
                                    </center>

                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="p-col-12 p-lg-6">
                        <div className="p-grid">
                            <div className="p-col-12">
                                <div className="card card-w-title" style={{ height: 700 }}>

                                    <h2><i className="fa fa-fw fa-users"></i>Bénéficiaires</h2>
                                    <hr />
                                    <Panel style={{ minHeight: '250px', marginBottom: 8 }}>
                                        <DataTable value={this.state.liste_accorder}
                                            responsive={true} scrollable={true} scrollHeight="200px" selectionMode="single"
                                            onRowClick={e => this.setState({ selectedBeneficiaire: e.data })}
                                            responsive={true} selection={this.state.selectedBeneficiaire}
                                        >
                                            <Column header="Action" body={this.actionTemplate_accorder.bind(this)} style={{ width: '15%' }} />
                                            <Column body={(rowData, column) => {
                                                return <div>
                                                    <span style={{ color: '#000000', fontWeight: 'bold' }}>{rowData.matrPers.nomPers}</span><br />
                                                    <span style={{ color: '#000000' }}>{rowData.matrPers.idBoutiq.nomBoutiq}</span>
                                                </div>
                                            }} header="Personnel" style={{ width: '35%' }} />
                                            <Column field="mtAccorder" body={(rowData, column) => {
                                                return <div>
                                                    <span style={{ color: '#000000', fontWeight: 'bold' }}>{this.formaterMontant(parseFloat(rowData.mtAccorder))}</span>
                                                </div>
                                            }} header="Montant" style={{ width: '20%' }} />
                                            <Column field="dateAccorder" header="Date" style={{ width: '15%' }} />
                                            <Column field="actAccorder" header="Actif" style={{ width: '15%' }} />
                                        </DataTable>
                                    </Panel>
                                    <div className="p-grid">
                                        <div className="p-col-12 p-md-3">
                                            <label htmlFor="matrPers">Personnel *</label>
                                        </div>
                                        <div className="p-col-12 p-md-9">
                                            <Dropdown value={this.state.matrPers} options={lpersonnel} optionLabel='label' panelStyle={{ width: '500px' }} filter={true} filterBy='label' onChange={(e) => this.setState({ matrPers: e.value })} autoWidth={false} itemTemplate={this.personnelItemTemplate} />
                                        </div>
                                        <div className="p-col-12 p-md-3">
                                            <label htmlFor="mtAccorder">Montant *</label>
                                        </div>
                                        <div className="p-col-12 p-md-9">
                                            <Cleave value={this.state.mtAccorder} options={{ numeral: true, delimiter: ' ' }} onChange={(e) => this.setState({ mtAccorder: e.target.rawValue })} className="p-inputtext" />
                                        </div>
                                        <div className="p-col-12 p-md-3">
                                            <label htmlFor="descPrime">Date *</label>
                                        </div>
                                        <div className="p-col-12 p-md-9">
                                            <Calendar id="dateAccorder" placeholder="Date" dateFormat="dd/mm/yy" value={this.state.dateAccorder} onChange={(e) => this.setState({ dateAccorder: e.target.value })} />
                                        </div>
                                        <div className="p-col-12 p-md-3">
                                            <label htmlFor="actAccorder">Actif *</label>
                                        </div>
                                        <div className="p-col-12 p-md-9">
                                            <Dropdown id="actAccorder" placeholder="Sélectionner" options={this.state.liste_actif} autoWidth={false} value={this.state.actAccorder} onChange={(e) => this.setState({ actAccorder: e.value })} />
                                        </div>
                                    </div>

                                    <center>
                                        <div className="p-grid" style={{ width: 300 }}>
                                            <div className="p-col"><Button label="Annuler" icon="pi pi-ban" onClick={(e) => this.annule_beneficiaire()} /></div>
                                            <div className="p-col"><Button label="Valider" icon="pi pi-check" onClick={(e) => this.enreg_beneficiaire()} /></div>
                                        </div>
                                        {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : null}
                                    </center>

                                </div>
                            </div>
                        </div>
                    </div>

                </div>



            </div>
        );
    }
}