import React, { Component } from 'react';
import { Xulaservice } from '../service/Xulaservice';
import { InputText } from 'primereact/inputtext';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { ProgressSpinner } from 'primereact/progressspinner';
import Numeral from 'numeral'; import 'moment';
import 'moment/locale/fr';
import moment from 'moment';
import './styles.css';
import { SplitButton } from 'primereact/splitbutton';


export class FormEdition_AlertePeremption extends Component {
    constructor() {
        super();
        this.state = {
            pd1: new Date(),
            pd2: new Date(),
            ridBoutiq: null,
            liste_data: [],
            liste_boutique: [],
            montant_total: '0',
            items: [
                {
                    label: 'Format PDF',
                    icon: 'pi pi-print',
                    command: (e) => {
                        this.conf_print_etat('PDF');
                    }
                },
                {
                    label: 'Format XLS',
                    icon: 'pi pi-print',
                    command: (e) => {
                        this.conf_print_etat('XLS');
                    }
                },
            ],
            userconnected: JSON.parse(localStorage.getItem('userconnected')),
        };
        this.state.pd1.setDate(this.state.pd1.getDate() - 7);
        this.conf_print_etat = this.conf_print_etat.bind(this);
        this.xulaservice = new Xulaservice();
    }

   


    get_liste_boutique() {
        let lboutique = null;
        let lboutique_rech = [{ value: 'Tous', label: 'Boutique' }];

        this.xulaservice.getListe_boutique().then(data => this.setState({ liste_boutique: data }, () => {
            if (this.state.liste_boutique != null && this.state.liste_boutique != undefined) {
                lboutique = this.state.liste_boutique.map(x => {
                    return { value: x.idBoutiq, label: x.nomBoutiq }
                });
            }
             
            if (this.state.userconnected.profilUser === 'PDG' || this.state.userconnected.profilUser === 'Coordinateur') {
                this.setState({ lboutique: lboutique });
                lboutique_rech = lboutique_rech.concat(lboutique);

            } else {
                let id = this.state.userconnected.matrPers.idBoutiq.idBoutiq;
                if (lboutique.length > 0) {
                    let liste = lboutique.filter(function (el) {
                        return el.value === id;
                    });
                    this.setState({ lboutique: liste });
                    lboutique_rech = lboutique_rech.concat(liste);
                }
            }
            this.setState({ lboutique_rech: lboutique_rech },()=>{
                this.setState({ridBoutiq: { value: this.state.userconnected.matrPers.idBoutiq.idBoutiq, label: this.state.userconnected.matrPers.idBoutiq.nomBoutiq } });
            })
        }));
    }

   
    componentDidMount() {
        this.get_liste_data();
        this.get_liste_boutique();
    }

    formaterMontant(montant) {
        return Numeral(parseFloat(montant)).format('0,0');
    }

    get_liste_data() {
        this.setState({ showindicator: true });
        let rid_boutique = 'Tous';
        let spd1 = moment(this.state.pd1, 'DD/MM/YYYY').format('DD/MM/YYYY');
        let spd2 = moment(this.state.pd2, 'DD/MM/YYYY').format('DD/MM/YYYY');
       
        if (this.state.ridBoutiq != undefined && this.state.ridBoutiq != null) {
            rid_boutique = this.state.ridBoutiq.value;
        }

        this.xulaservice.get_liste_alerte_peremption(spd1, spd2,rid_boutique).then(data => this.setState({ liste_data: data, showindicator: false }, () => {
            if (data != null && data != undefined) {
                let initialValue = 0;
                let tt = data.reduce(function (accumulator, currentValue) {
                    return accumulator + currentValue.montant
                }, initialValue);
                this.setState({ montant_total: this.formaterMontant(tt) });
            }
        }));
    }

   

    conf_print_etat(format) {
        let rid_boutique = 'Tous';
        let spd1 = moment(this.state.pd1, 'DD/MM/YYYY').format('DD/MM/YYYY');
        let spd2 = moment(this.state.pd2, 'DD/MM/YYYY').format('DD/MM/YYYY');
        if (this.state.ridBoutiq != undefined && this.state.ridBoutiq != null) {
            rid_boutique = this.state.ridBoutiq.value;
        }
        this.xulaservice.generer_liste_alerte_peremption(spd1, spd2, rid_boutique, format);
    }

 

    render() {
        let ptotal = <label style={{ fontSize: 20, fontWeight: 'bold', color: 'red', marginRight: 10 }}> {this.state.montant_total} </label>
       
        return (
            <div className="p-fluid">

                <div className="p-grid">
                    <div className="p-col-12">
                        <div className="card card-w-title" style={{ minHeight: '900px' }}>
                            <div className="p-grid">
                                <div className="p-col-12">
                                    <div className="p-grid">
                                        <div className="p-col-12 p-md-2">
                                            <Calendar value={this.state.pd1} dateFormat="dd/mm/yy" onChange={(e) => this.setState({ pd1: e.value })} />
                                        </div>
                                        <div className="p-col-12 p-md-2">
                                            <Calendar value={this.state.pd2} dateFormat="dd/mm/yy" onChange={(e) => this.setState({ pd2: e.value })} />
                                        </div>
                                        <div className="p-col-12 p-md-3">
                                            <Dropdown options={this.state.lboutique_rech} placeholder="Boutique" value={this.state.ridBoutiq} optionLabel='label' filter={true} filterBy='label' filterMatchMode='contains' onChange={(e) => this.setState({ ridBoutiq: e.value },()=>{
                                                this.get_liste_data();
                                            })} autoWidth={false}  panelStyle={{ width: '400px' }}/>
                                        </div>
                                        <div className="p-col-12 p-md-2">
                                            <div className="p-inputgroup">
                                                <div className="p-inputgroup">
                                                    <SplitButton label="Rechercher" style={{ width: '140px' }} onClick={(e) => this.get_liste_data()} icon="pi pi-search" model={this.state.items}></SplitButton>
                                                    {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : null}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <DataTable value={this.state.liste_data} 
                                responsive={true} paginatorPosition="top" paginator={true} rows={15} header={ptotal}>
                                <Column field="libArt" header="Désignation" style={{ width: '25%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                <Column field="date_exp_article" header="Date perem." style={{ width: '10%' }} filter={true} filterMatchMode='contains' sortable={true} />                                
                                <Column field="prix_ref_vente_format" header="Prix vente" style={{ width: '8%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                <Column field="qte_stock" header="Stock" style={{ width: '6%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                <Column field="montant" body={(rowData, column) => {
                                    return <div>
                                        <span style={{ color: 'red', fontWeight: 'bold' }}>{this.formaterMontant(rowData.montant)}</span>
                                    </div>
                                }} header="Montant" style={{ width: '10%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                <Column field="datecmde" header="Date BC" style={{ width: '8%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                <Column field="qte_lcmde" header="Qté cmde" style={{ width: '7%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                <Column field="num_bc" header="Date BC" style={{ width: '8%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                <Column field="nom_four" header="Fournisseur" style={{ width: '18%' }} filter={true} filterMatchMode='contains' sortable={true} />
                             </DataTable>

                        </div>
                    </div>
                </div>
            </div>
        );
    }
}