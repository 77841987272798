import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { InputText } from 'primereact/inputtext';
import { InputSwitch } from 'primereact/inputswitch';
import { Xulaservice } from '../src/service/Xulaservice';
export class AppTopbar extends Component {


    constructor() {
        super();

        this.state = {
            nom_user: '',
            nomBoutiq: '',
            domaineEtabl: '',
            offline: false,
        };
        this.myservice = new Xulaservice();
    }
 
    onTopbarItemClick(event, item) {
        if (this.props.onTopbarItemClick) {
            this.props.onTopbarItemClick({
                originalEvent: event,
                item: item
            });
        }
    }

    componentDidMount() {
      

        let user=JSON.parse(localStorage.getItem('userconnected'));
        let val_offline = localStorage.getItem('offline');
        if (val_offline == undefined) {
            val_offline = false;
        } else if (val_offline == 'true') {
            val_offline = true;
        } else {
            val_offline = false;
        } 
        this.setState({ offline: val_offline });
        localStorage.setItem('offline', val_offline);

        if(user!==undefined && user!=null){
            console.log(user)
            this.setState({
                nom_user: user.matrPers.nomPers,
                nomBoutiq: user.matrPers.idBoutiq.nomBoutiq,
                domaineEtabl: user.idEtabl.domaineEtabl,
            })
        }
       


    }


    render() {
        let topbarItemsClassName = classNames('topbar-menu fadeInDown', { 'topbar-menu-visible': this.props.topbarMenuActive });

        return <div className="topbar clearfix">

            <img className="logo" alt="apollo-layout" src="assets/layout/images/apollo_logo.png" />

            <button className="p-link" id="menu-button" onClick={this.props.onMenuButtonClick}>
                <i className="fa fa-align-left"></i>
            </button>

            <button className="p-link profile" onClick={this.props.onTopbarMenuButtonClick}>
                <span className="username">{this.state.nom_user}</span>
                <span className="username" style={{ color: this.state.domaineEtabl === 'Station' ? '#ffff00' : '#F85420' }}>{this.state.nomBoutiq}</span>
                <img src="assets/layout/images/avatar/avatar.png" alt="apollo-layout" />
                <i className="fa fa-angle-down"></i>
            </button>

            {this.myservice.get_serveur() == 'Local' ?
                <span className="topbar-themeswitcher">
                    <InputSwitch checked={this.state.offline} onChange={(e) => {
                        this.setState({ offline: e.value }, () => {
                            localStorage.removeItem('srv_url');
                            localStorage.setItem('offline', e.value);
                            if (this.state.offline == true) {
                                localStorage.setItem('srv_url', this.myservice.get_wsurl('Local'));
                                //localStorage.setItem('srv_url', 'http://localhost:9077/xula');
                                //localStorage.setItem('srv_url', 'http://192.168.1.137:9077/ws_xula/xula');
                                //localStorage.setItem('srv_url', 'http://192.168.100.10:9077/ws_xula/xula');
                            } else {
                                localStorage.setItem('srv_url', this.myservice.get_wsurl('Remote'));
                            }
                        });
                        window.location.reload();
                    }}></InputSwitch>
                </span>
                : null}



            <ul className={topbarItemsClassName}>
                <li className={classNames({'menuitem-active': this.props.activeTopbarItem === 'profile' })}
                    onClick={(e) => this.onTopbarItemClick(e, 'profile')}>
                    <button className="p-link" onClick={(e) => {
                        window.location = "#/changer_mot_de_pass";
                    }}>
                        <i className="topbar-icon fa fa-fw fa-user"></i>
                        <span className="topbar-item-name">Changer mon mot de passe</span>
                    </button>
                </li>

                <li className={classNames({ 'menuitem-active': this.props.activeTopbarItem === 'settings' })}
                    onClick={(e) => this.onTopbarItemClick(e, 'settings')}>
                    <button className="p-link" onClick={(e) => {
                        localStorage.removeItem('message');
                        localStorage.removeItem('userconnected');
                        localStorage.removeItem('token');
                        localStorage.setItem('offline', 'false');
                        localStorage.setItem('srv_url', 'https://new.xulanet.com/ws_xula/xula');
                        //localStorage.setItem('srv_url', 'http://192.168.100.10:9077/ws_xula/xula');
                        window.location = "#/login";
                    }}>
                        <i className="topbar-icon fa fa-fw fa-sign-out"></i>
                        <span className="topbar-item-name">Déconnexion</span>
                    </button>
                </li>

            </ul>


        </div>;
    }
}