import React, { Component } from 'react';
import { Xulaservice } from '../service/Xulaservice';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { SplitButton } from 'primereact/splitbutton';
import './styles.css';
import { ProgressSpinner } from 'primereact/progressspinner';
import Numeral from 'numeral';
import 'moment';
import 'moment/locale/fr';
import moment from 'moment';
import { Calendar } from 'primereact/calendar';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Growl } from 'primereact/growl';
import { Panel } from 'primereact/panel';

export class FormEdition_Journal_sortie_stock extends Component {
    constructor() {
        super();
        this.state = {
            pd1: new Date(),
            pd2: new Date(),
            dateBl: new Date(),
            receveurBl: '',
            liste_article: [],
            lboutique_rech: [],
            liste_livraison_lsortie: [],
            selectedData: null,
            visible_dlg_livraison: false,
            selectedLivraisonLsortie: null,
            rcb_article: '',
            rlib_article: '',
            rtypeSortie: { label: 'Type', value: 'Tous' },
            rConformite: { label: 'Conformité marge', value: 'Tous' },
            liste_statut_livraison: [
                { label: 'Livraison', value: 'Tous' },
                { label: 'Livré', value: 'LIVRE' },
                { label: 'Non livré', value: 'NON LIVRE' }
            ],
            rstatutLivraison: null,
            total_facture: '0',
            total_non_livre: '0',
            total_gain: '0',
            ridBoutiq: { value: 'Tous', label: 'Boutique' },
            items: [
                {
                    label: 'Format CSV',
                    icon: 'pi pi-print',
                    command: (e) => {
                        this.dt.exportCSV();
                    }
                }
            ],
            userconnected: JSON.parse(localStorage.getItem('userconnected')),
        };
        this.articleLivraisonBodyTemplate = this.articleLivraisonBodyTemplate.bind(this);
        this.show_dlg_livraison = this.show_dlg_livraison.bind(this);
        this.qteLivrLsEditor = this.qteLivrLsEditor.bind(this);
        this.state.pd1.setDate(this.state.pd1.getDate() - 2);
        this.xulaservice = new Xulaservice();
    }


    articleLivraisonBodyTemplate(rowData, column) {
        let color = 'black';
        if (rowData.qteLivrLs > rowData.qteLivrLs_restant) {
            color = 'red';
        }
        return <div>
            <span style={{ color: color }}>{rowData.idLsortie.idArticle.libArticle}</span><br />
        </div>
    }

    get_liste_livraison_lsortie_by_sortie(idSortie) {
        this.setState({ showindicator: true });
        this.xulaservice.get_liste_livraison_lsortie_by_sortie(idSortie).then(data => {
            this.setState({ liste_livraison_lsortie: data, showindicator: false });
        });
    }

    show_dlg_livraison(data) {
        if (data != null && data != undefined) {
            this.setState({ visible_dlg_livraison: true }, () => {
                this.setState({ receveurBl: data.nomCli });
                this.get_liste_livraison_lsortie_by_sortie(data.idSortie);
            })
        }
    }

    onEditorValueChange_livraison(props, value) {
        let updateds = [...props.value];
        updateds[props.rowIndex][props.field] = value;
        this.setState({ liste_livraison_lsortie: updateds });
    }

    inputTextEditor_livraison(props, field) {
        return <InputText keyfilter="pint" type="text" value={props.rowData[field]} onChange={(e) => this.onEditorValueChange_livraison(props, e.target.value)} />;
    }

    qteLivrLsEditor(props) {
        return this.inputTextEditor_livraison(props, 'qteLivrLs');
    }

    enreg_bl() {
        if (this.state.selectedData === null || this.state.selectedData === undefined || this.state.dateBl === '' || this.state.dateBl === undefined || this.state.dateBl === null || this.state.receveurBl === undefined || this.state.receveurBl === null || this.state.receveurBl === '') {
            this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez saisir tous les champs obligatoires et sélectionner une commande' });
        } else {
            let date_bl = moment(this.state.dateBl, 'DD/MM/YYYY').format('DD/MM/YYYY');
            let data = { numBl: '0', dateBl: date_bl, receveurBl: this.state.receveurBl, idSortie: { idSortie: this.state.selectedData.idSortie } };

            if (this.state.liste_livraison_lsortie == null || this.state.liste_livraison_lsortie == undefined) {
                this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez sélectionner les articles à livrer' });
                return;
            } else if (this.state.liste_livraison_lsortie.length <= 0) {
                this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez sélectionner les articles à livrer' });
                return;
            }
            for (var i = this.state.liste_livraison_lsortie.length; i--;) {
                this.state.liste_livraison_lsortie[i].numBlLs = data;
                this.state.liste_livraison_lsortie[i].iduser = this.state.userconnected.idUser;
                if (this.state.liste_livraison_lsortie[i].qteLivrLs > this.state.liste_livraison_lsortie[i].qteLivrLs_restant) {
                    this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez vérifier les quantités à livrer' });
                    return;
                }
            }
            for (var i = this.state.liste_livraison_lsortie.length; i--;) {
                if (this.state.liste_livraison_lsortie[i].qteLivrLs === 0) {
                    this.state.liste_livraison_lsortie.splice(i, 1);
                }
            }
            this.setState({ showindicator: true });
            this.xulaservice.enreg_livraison_lsortie_batch(this.state.liste_livraison_lsortie).then(reponse => {
                if (reponse != null && reponse != undefined) {
                    this.resultat_livraison(reponse.code, reponse.contenu);
                }
            });

        }
    }

    resultat_livraison(code, contenu) {
        if (code == '200') {
            this.growl.show({ severity: 'success', summary: 'Success', detail: contenu });
            this.setState({ receveurBl: '' });
            this.setState({ liste_livraison_lsortie: [] }, () => {
                this.get_liste_data();
            });
            this.setState({ visible_dlg_livraison: false });
        } else {
            this.growl.show({ severity: 'error', summary: 'Error', detail: contenu });
        }
        this.setState({ showindicator: false });
    }


    formaterMontant(montant) {
        return Numeral(parseFloat(montant)).format('0,0');
    }


    componentDidMount() {
        this.get_liste_data();
        this.get_liste_boutique();
    }

    annule_livraison() {
        this.setState({ visible_dlg_livraison: false }, () => {
            this.setState({ receveurBl: '' });
        });
    }

    get_liste_data() {
        let rid_boutique = 'Tous';
        let rstatut_livraison = 'Tous';
        let rtype = 'Tous';
        let rconforme = 'Tous';
        if (this.state.ridBoutiq != undefined && this.state.ridBoutiq != null) {
            rid_boutique = this.state.ridBoutiq.value;
        }
        if (this.state.rstatutLivraison != undefined || this.state.rstatutLivraison != null) {
            rstatut_livraison = this.state.rstatutLivraison.value;
        }
        if (this.state.rtypeSortie != undefined || this.state.rtypeSortie != null) {
            rtype = this.state.rtypeSortie.value;
        }
        if (this.state.rConformite != undefined || this.state.rConformite != null) {
            rconforme = this.state.rConformite.value;
        }
        let spd1 = moment(this.state.pd1).format('DD-MM-YYYY');
        let spd2 = moment(this.state.pd2).format('DD-MM-YYYY');
        this.setState({ showindicator: true });
        this.xulaservice.get_journal_sortie_stock(spd1, spd2, rid_boutique, this.state.rlib_article, 'Tous', rstatut_livraison, rtype, rconforme).then(data => this.setState({ liste_article: data, showindicator: false }, () => {
            if (data != null && data != undefined) {
                let initialValue = 0
                let total_facture = data.reduce(function (accumulator, currentValue) {
                    return accumulator + currentValue.montant
                }, initialValue);

                let tt_marge = data.reduce(function (accumulator, currentValue) {
                    return accumulator + currentValue.marge_article
                }, initialValue);

                let tt_nl = data.reduce(function (accumulator, currentValue) {
                    return accumulator + currentValue.valeur_qte_a_livrer
                }, initialValue);

                this.setState({ total_facture: this.formaterMontant(total_facture), total_non_livre: this.formaterMontant(tt_nl) });
                if (this.state.userconnected.profilUser === 'PDG') {
                    this.setState({ total_gain: this.formaterMontant(tt_marge) });
                } else {
                    this.setState({ total_gain: '******' });
                }
            }
        }));
    }


    get_liste_boutique() {
        let lboutique_rech = [{ value: 'Tous', label: 'Boutique' }];
        let lboutique = null;
        this.xulaservice.getListe_boutique().then(data => this.setState({ liste_boutique: data }, () => {
            if (this.state.liste_boutique != null && this.state.liste_boutique != undefined) {
                lboutique = this.state.liste_boutique.map(x => {
                    return { value: x.idBoutiq, label: x.nomBoutiq }
                });
            }

            if (this.state.userconnected.profilUser === 'PDG' || this.state.userconnected.profilUser === 'Coordinateur') {
                this.setState({ lboutique: lboutique });
                lboutique_rech = lboutique_rech.concat(lboutique);
            } else {
                let id = this.state.userconnected.matrPers.idBoutiq.idBoutiq;
                if (lboutique.length > 0) {
                    let liste = lboutique.filter(function (el) {
                        return el.value === id;
                    });
                    this.setState({ lboutique: liste });
                    lboutique_rech = liste;
                }
            }
            this.setState({ lboutique_rech: lboutique_rech })
        }));
    }

    actionTemplate(rowData, column) {
        let disable = true;
        if (parseFloat(rowData.qte_a_livrer) > 0) {
            disable = false;
        }
        return <div className="p-grid" style={{ width: 90 }}>
            <div className="p-col"><Button disabled={disable} onClick={(e) => this.show_dlg_livraison(rowData)} className="p-button-info" style={{ width: 25, height: 25 }} icon="pi pi-list" /></div>
        </div>
    }

    render() {
        return (
            <div className="p-fluid">
                <Growl ref={(el) => this.growl = el} />
                <Dialog header="Livraisons" visible={this.state.visible_dlg_livraison} style={{ width: '70%' }} modal={true} onHide={(e) => this.setState({ visible_dlg_livraison: false })} >
                    <div className="p-grid">
                        <div className="p-col-12">
                            <div className="card card-w-title" style={{ height: 400 }}>
                                <div className="p-grid">
                                    <div className="p-col-12 p-md-2">
                                        <label htmlFor="libCodif">Date *</label>
                                    </div>
                                    <div className="p-col-12 p-md-4">
                                        <Calendar value={this.state.dateBl} dateFormat="dd/mm/yy" onChange={(e) => this.setState({ dateBl: e.value })} />
                                    </div>
                                    <div className="p-col-12 p-md-2">
                                        <label htmlFor="descParamCodif">Receveur *</label>
                                    </div>
                                    <div className="p-col-12 p-md-4">
                                        <InputText value={this.state.receveurBl} onChange={(e) => this.setState({ receveurBl: e.target.value })} />
                                    </div>
                                </div>
                                <DataTable value={this.state.liste_livraison_lsortie} selectionMode="single" scrollable={true} scrollHeight="250px"
                                    onRowClick={e => this.setState({ selectedLivraisonLsortie: e.data })} onRowSelect={e => this.setState({ selectedLivraisonLsortie: e.data })}
                                    responsive={true} selection={this.state.selectedLivraisonLsortie} style={{ marginBottom: 12 }} editable={true}>
                                    <Column field="idLsortie.idArticle.libArticle" body={this.articleLivraisonBodyTemplate} header="Désignation" style={{ width: '55%' }} />
                                    <Column field="idLsortie.qteLsortie" header="Achetée" style={{ width: '15%' }} />
                                    <Column field="qteLivrLs_restant" header="Restant" style={{ width: '15%' }} />
                                    <Column field="qteLivrLs" header="Livrer" style={{ width: '15%' }} editor={this.qteLivrLsEditor} />
                                </DataTable>
                                <center>
                                    <div className="p-grid" style={{ width: 300 }}>
                                        <div className="p-col"><Button label="Annuler" icon="pi pi-ban" onClick={(e) => this.annule_livraison()} /></div>
                                        <div className="p-col"><Button label="Valider" icon="pi pi-check" onClick={(e) => this.enreg_bl()} /></div>
                                    </div>
                                    {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : null}
                                </center>
                            </div>
                        </div>
                    </div>
                </Dialog>

                <div className="p-grid">
                    <div className="p-col-12">
                        <div className="card card-w-title" style={{ minHeight: '900px' }}>
                            <i className="fa fa-fw fa-search"></i>Option de recherche
                         <hr />
                            <div className="p-grid">
                                <div className="p-col-12 p-md-3">
                                    <Calendar value={this.state.pd1} dateFormat="dd/mm/yy" onChange={(e) => this.setState({ pd1: e.value })} />
                                </div>
                                <div className="p-col-12 p-md-3">
                                    <Calendar value={this.state.pd2} dateFormat="dd/mm/yy" onChange={(e) => this.setState({ pd2: e.value })} />
                                </div>
                                <div className="p-col-12 p-md-3">
                                    <InputText value={this.state.rlib_article} placeholder="Désignation" onChange={(e) => this.setState({ rlib_article: e.target.value })} />
                                </div>
                                <div className="p-col-12 p-md-3">
                                    <Dropdown value={this.state.ridBoutiq} options={this.state.lboutique_rech} optionLabel='label' filter={true} filterBy='label' onChange={(e) => this.setState({ ridBoutiq: e.value }, () => {
                                        this.get_liste_data();
                                    })} autoWidth={false} />
                                </div>
                                <div className="p-col-12 p-md-3">
                                    <Dropdown options={this.state.liste_statut_livraison} placeholder="Livraison" value={this.state.rstatutLivraison} optionLabel='label' filter={true} filterBy='label' filterMatchMode='contains' onChange={(e) => this.setState({ rstatutLivraison: e.value }, () => {
                                        this.get_liste_data();
                                    })} autoWidth={false} />
                                </div>
                                <div className="p-col-12 p-md-3">
                                    <Dropdown value={this.state.rtypeSortie} options={[
                                        { label: 'Type', value: 'Tous' },
                                        { label: 'Facture', value: 'Facture' },
                                        { label: 'Facture boutique', value: 'Facture boutique' },
                                        { label: 'Inter-magasin', value: 'Inter-magasin' },
                                        { label: 'Transfert', value: 'Transfert' }
                                    ]} optionLabel='label' filter={true} filterBy='label' onChange={(e) => this.setState({ rtypeSortie: e.value }, () => {
                                        this.get_liste_data();
                                    })} autoWidth={false} />
                                </div>
                                <div className="p-col-12 p-md-3">
                                    <Dropdown value={this.state.rConformite} options={[
                                        { label: 'Conformité marge', value: 'Tous' },
                                        { label: 'Conforme', value: 'Conforme' },
                                        { label: 'Non conforme', value: 'Non conforme' }
                                    ]} optionLabel='label' filter={true} filterBy='label' onChange={(e) => this.setState({ rConformite: e.value }, () => {
                                        this.get_liste_data();
                                    })} autoWidth={false} />
                                </div>
                                <div className="p-col-12 p-md-3">
                                    <div className="p-inputgroup">
                                        <SplitButton label="Rechercher" style={{ width: '140px' }} onClick={(e) => this.get_liste_data()} icon="pi pi-search" model={this.state.items}></SplitButton>
                                        {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : null}
                                    </div>
                                </div>
                            </div>

                            <Panel style={{ backgroundColor: '#f7f7f7' }}>
                                <div className="p-grid">
                                    <div className="p-col-12 p-md-1">
                                        <label style={{ fontSize: 16 }}>Total commande</label>
                                    </div>
                                    <div className="p-col-12 p-md-3">
                                        <label style={{ fontSize: 16, fontWeight: 'bold', color: 'black' }}> {this.state.total_facture} </label>
                                    </div>
                                    <div className="p-col-12 p-md-1">
                                        <label style={{ fontSize: 16 }}>Gain</label>
                                    </div>
                                    <div className="p-col-12 p-md-3">
                                        <label style={{ fontSize: 16, fontWeight: 'bold', color: 'green' }}> {this.state.total_gain} </label>
                                    </div>
                                    <div className="p-col-12 p-md-1">
                                        <label style={{ fontSize: 16 }}>Valeur non livré</label>
                                    </div>
                                    <div className="p-col-12 p-md-3">
                                        <label style={{ fontSize: 16, fontWeight: 'bold', color: 'red' }}> {this.state.total_non_livre} </label>
                                    </div>
                                </div>
                            </Panel>
                            {
                                this.state.userconnected.profilUser == 'PDG' ?
                                    <DataTable value={this.state.liste_article} paginatorPosition="top" paginator={true} rows={20}
                                        onRowClick={e => this.setState({ selectedData: e.data })} selectionMode="single" rowsPerPageOptions={[10, 20, 50, 100, 200]}
                                        responsive={true} selection={this.state.selectedData} csvSeparator=";" ref={(el) => { this.dt = el; }} >
                                        <Column header="#" body={this.actionTemplate.bind(this)} style={{ width: '3%' }} />
                                        <Column field="numFactureSortie" header="N° facture" style={{ width: '11%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                        <Column field="libArt" header="Désignation" style={{ width: '15%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                        <Column field="type_sortie" header="Type" style={{ width: '6%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                        <Column field="datesortie" header="Date" style={{ width: '6%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                        <Column field="nomCli" header="Client" style={{ width: '12%' }} body={(rowData, column) => {
                                            return <div>
                                                <span >{rowData.nomCli}</span><br />
                                                <span style={{ color: '#45535F', fontWeight: 'bold' }}>{rowData.idBoutiq.codeBoutiq}</span>
                                            </div>
                                        }} filter={true} filterMatchMode='contains' sortable={true} />
                                        <Column field="qte" header="Qté" body={(rowData, column) => {
                                            return <div>
                                                <span >{this.formaterMontant(parseFloat(rowData.qte))}</span>
                                            </div>
                                        }} style={{ width: '5%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                        <Column field="prix_ref_vente" body={(rowData, column) => {
                                            return <div>
                                                <span >{this.formaterMontant(parseFloat(rowData.prix_ref_vente))}</span>
                                            </div>
                                        }} header="Prix vente" style={{ width: '8%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                        <Column field="marge_article" body={(rowData, column) => {
                                            let mtgain = this.formaterMontant(parseFloat(rowData.marge_article));
                                            let color = 'black';
                                            if (parseFloat(rowData.marge_article) > 0) {
                                                color = 'green';
                                            } else if (parseFloat(rowData.marge_article) < 0) {
                                                color = 'red';
                                            }
                                            if (this.state.userconnected.profilUser !== 'PDG') {
                                                mtgain = '*****';
                                            }
                                            return <div>
                                                <span style={{ color: color, fontWeight: 'bold' }}>{mtgain}</span>
                                            </div>
                                        }} header="Marge" style={{ width: '7%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                        <Column field="prix_ref_revient" body={(rowData, column) => {
                                            let val = this.formaterMontant(parseFloat(rowData.prix_ref_revient));
                                            if (this.state.userconnected.profilUser !== 'PDG') {
                                                val = '*****';
                                            }
                                            return <div>
                                                <span style={{ color: 'black', fontWeight: 'bold' }}>{val}</span>
                                            </div>
                                        }} header="P revient" style={{ width: '8%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                        <Column field="qte_a_livrer" header="Non livré" style={{ width: '8%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                        <Column field="valeur_qte_a_livrer" body={(rowData, column) => {
                                            return <div>
                                                <span >{this.formaterMontant(parseFloat(rowData.valeur_qte_a_livrer))}</span>
                                            </div>
                                        }} header="Valeur non livré" style={{ width: '11%' }} filter={true} filterMatchMode='contains' sortable={true} />

                                    </DataTable>
                                    : 
                                    <DataTable value={this.state.liste_article} paginatorPosition="top" paginator={true} rows={20}
                                        onRowClick={e => this.setState({ selectedData: e.data })} selectionMode="single" rowsPerPageOptions={[10, 20, 50, 100, 200]}
                                        responsive={true} selection={this.state.selectedData} csvSeparator=";" ref={(el) => { this.dt = el; }} >
                                        <Column header="#" body={this.actionTemplate.bind(this)} style={{ width: '3%' }} />
                                        <Column field="numFactureSortie" header="N° facture" style={{ width: '11%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                        <Column field="libArt" header="Désignation" style={{ width: '16%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                        <Column field="type_sortie" header="Type" style={{ width: '6%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                        <Column field="datesortie" header="Date" style={{ width: '6%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                        <Column field="nomCli" header="Client" style={{ width: '16%' }} body={(rowData, column) => {
                                            return <div>
                                                <span >{rowData.nomCli}</span>
                                            </div>
                                        }} filter={true} filterMatchMode='contains' sortable={true} />
                                        <Column field="idBoutiq.codeBoutiq" header="Boutique" style={{ width: '8%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                        <Column field="qte" header="Qté" body={(rowData, column) => {
                                            return <div>
                                                <span >{this.formaterMontant(parseFloat(rowData.qte))}</span>
                                            </div>
                                        }} style={{ width: '8%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                        <Column field="prix_ref_vente" body={(rowData, column) => {
                                            return <div>
                                                <span >{this.formaterMontant(parseFloat(rowData.prix_ref_vente))}</span>
                                            </div>
                                        }} header="Prix vente" style={{ width: '8%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                        <Column field="qte_a_livrer" header="Non livré" style={{ width: '8%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                        <Column field="valeur_qte_a_livrer" body={(rowData, column) => {
                                            return <div>
                                                <span >{this.formaterMontant(parseFloat(rowData.valeur_qte_a_livrer))}</span>
                                            </div>
                                        }} header="Valeur non livré" style={{ width: '10%' }} filter={true} filterMatchMode='contains' sortable={true} />

                                    </DataTable>
                            }

                        </div>
                    </div>
                </div>
            </div>
        );
    }
}