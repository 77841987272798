import React, { Component } from 'react';
import { Xulaservice } from '../service/Xulaservice';
import { InputText } from 'primereact/inputtext';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dialog } from 'primereact/dialog';
import { Growl } from 'primereact/growl';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import './styles.css';
import CurrencyInput from 'react-currency-input';
import { ProgressSpinner } from 'primereact/progressspinner';

export class FormFournisseur extends Component {
    constructor() {
        super();
        this.state = {
            liste_fournisseur: [],
            selectedFournisseur: null,
            idFour: '0',
            nomFour: '',
            adrFour: '',
            telFour: '',
            tvaFour: 0,
            typeFour: null,
            emailFour: '',
            msg: '',
            modif: false,
            visible: false,
            showindicator: false,
            liste_type_fournisseur: [
                { label: 'INTERNE', value: 'INTERNE' },
                { label: 'EXTERNE ', value: 'EXTERNE' },
            ],
            userconnected: JSON.parse(localStorage.getItem('userconnected')),
        };
        this.edit_fournisseur = this.edit_fournisseur.bind(this);
        this.conf_delete_fournisseur = this.conf_delete_fournisseur.bind(this);
        this.xulaservice = new Xulaservice();
    }

    componentDidMount() {
        this.setState({showindicator: true});
        this.xulaservice.getListe_fournisseur().then(data => {
            this.setState({liste_fournisseur: data,showindicator: false });
        });
    }

    resultat(code, contenu) {
        if (code == '200') {
            this.growl.show({ severity: 'success', summary: 'Success', detail: contenu });
            this.annule_fournisseur();
            this.componentDidMount();
        } else {
            this.growl.show({ severity: 'error', summary: 'Error', detail: contenu });
        }
        this.setState({showindicator: false});
    }

    edit_fournisseur(data) {
        if (data != null && data != undefined) {
            this.setState({ idFour: data.idFour });
            this.setState({ nomFour: data.nomFour });
            this.setState({ adrFour: data.adrFour });
            this.setState({ tvaFour: data.tvaFour });
            this.setState({ telFour: data.telFour });
            this.setState({ emailFour: data.emailFour });
            this.setState({ typeFour: data.typeFour});
            this.setState({ modif: true });
            this.setState({ selectedFournisseur: data });
            window.scrollTo(0, 0);
        }
    }

    annule_fournisseur() {
        this.setState({ nomFour: '' });
        this.setState({ emailFour: '' });
        this.setState({ telFour: '' });
        this.setState({ adrFour: '' });
        this.setState({ idFour: '0' });
        this.setState({ tvaFour: 0 });
        this.setState({ modif: false });
        window.scrollTo(0, 0);
        this.forceUpdate();
    }

    enreg_fournisseur() {
        if (this.state.nomFour === "" || this.state.nomFour === undefined) {
            this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez saisir tous les champs obligatoires' });
        } else {
            this.setState({showindicator: true});
            if (this.state.modif == false) {
                let data = { idFour: '0', nomFour: this.state.nomFour, adrFour: this.state.adrFour, typeFour: this.state.typeFour, telFour: this.state.telFour, emailFour: this.state.emailFour , tvaFour: this.state.tvaFour };
                this.xulaservice.enreg_fournisseur(data).then(data => this.resultat(data.code, data.contenu));
            } else {
                let data=this.state.selectedFournisseur;
                data.nomFour = this.state.nomFour;
                data.typeFour = this.state.typeFour;
                data.tvaFour = this.state.tvaFour;
                data.telFour = this.state.telFour;
                data.adrFour = this.state.adrFour;
                this.xulaservice.enreg_fournisseur(data).then(data => this.resultat(data.code, data.contenu));
            }
        }
    }

    actionTemplate(rowData, column) {
        let disable=false;
        if(rowData.typeFour==='INTERNE'){
            disable=true;
        }
        return <div className="p-grid" style={{ width: 90 }}>
            <div className="p-col"><Button disabled={disable} onClick={(e) => this.edit_fournisseur(rowData)} className="p-button-info" style={{ width: 25, height: 25 }} icon="pi pi-pencil" /></div>
            <div className="p-col"><Button disabled={disable} onClick={(e) => this.conf_delete_fournisseur(rowData)} className="p-button-danger" style={{ width: 25, height: 25,marginLeft: -8 }} icon="pi pi-times" /></div>
        </div>
    }


    delete_fournisseur() {
        if (this.state.selectedFournisseur == null || this.state.selectedFournisseur == undefined) {
            this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez sélectionner la ligne à supprimer' });
        } else {
            this.xulaservice.delete_fournisseur(this.state.selectedFournisseur).then(data => this.resultat(data.code, data.contenu));
        }
    }


    conf_delete_fournisseur(data) {
        if (data != null && data != undefined) {
            this.setState({ selectedFournisseur: data });
            this.setState({ msg: data.nomFour });
            this.setState({ visible: true });
        }
    }



    render() {
        const dialogFooter = (
            <div>
                <Button icon="pi pi-check" onClick={() => {
                    this.setState({ visible: false });
                    this.delete_fournisseur();
                }} label="Oui" />
                <Button icon="pi pi-times" onClick={() => this.setState({ visible: false })} label="Annuler" className="p-button-secondary" />
            </div>
        );
        return (
            <div className="p-fluid">
                <Growl ref={(el) => this.growl = el} />
                <Dialog header="Confirmation" visible={this.state.visible} style={{ width: '250px' }} modal={true} footer={dialogFooter} onHide={(e) => this.setState({ visible: false })}>
                    <div>
                        <i className="fa fa-warning" style={{ color: '#FFD133', fontSize: 25 }}></i>
                        Voulez vous vraiment supprimer <b>{this.state.msg} </b>?
                    </div>
                </Dialog>
                <div className="p-grid">
                    <div className="p-col-12">
                        <div className="card card-w-title" style={{minHeight: '900px'}}>

                            <div className="p-grid">
                                <div className="p-col-12 p-md-2">
                                    <label htmlFor="nomFour">Nom fournisseur *</label>
                                </div>
                                <div className="p-col-12 p-md-4">
                                    <InputText id="nomFour" value={this.state.nomFour} onChange={(e) => this.setState({ nomFour: e.target.value, adrFour: e.target.value })} />
                                </div>
                                <div className="p-col-12 p-md-2">
                                    <label htmlFor="adrFour">Adresse </label>
                                </div>
                                <div className="p-col-12 p-md-4">
                                    <InputText id="adrFour" value={this.state.adrFour} onChange={(e) => this.setState({ adrFour: e.target.value })} />
                                </div>
                                <div className="p-col-12 p-md-2">
                                    <label htmlFor="telFour">Téléphone </label>
                                </div>
                                <div className="p-col-12 p-md-4">
                                    <InputText id="telFour" value={this.state.telFour} onChange={(e) => this.setState({ telFour: e.target.value })} />
                                </div>
                                <div className="p-col-12 p-md-2">
                                    <label htmlFor="emailFour">Email</label>
                                </div>
                                <div className="p-col-12 p-md-4">
                                    <InputText id="emailFour" liste_type_fournisseur="email" value={this.state.emailFour} onChange={(e) => this.setState({ emailFour: e.target.value })} />
                                </div>
                                <div className="p-col-12 p-md-2">
                                    <label htmlFor="type_fournisseur">Type fournisseur *</label>
                                </div>
                                <div className="p-col-12 p-md-4">
                                    <Dropdown options={this.state.liste_type_fournisseur} autoWidth={false} value={this.state.typeFour} onChange={(e) => this.setState({ typeFour: e.value })} />
                                </div>
                                <div className="p-col-12 p-md-2">
                                    <label htmlFor="tvaFour">TVA *</label>
                                </div>
                                <div className="p-col-12 p-md-4">
                                    <InputText id="tvaFour" liste_type_fournisseur='number' value={this.state.tvaFour} onChange={(e) => this.setState({ tvaFour: e.target.value })} />
                                </div>
                            </div>
                            <center>
                                <div className="p-grid" style={{ width: 300 }}>
                                    <div className="p-col"><Button label="Annuler" icon="pi pi-ban" onClick={(e) => this.annule_fournisseur()} /></div>
                                    <div className="p-col"><Button label="Valider" icon="pi pi-check" onClick={(e) => this.enreg_fournisseur()} /></div>
                                </div>
                                {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : null}
                            </center>

                           
                            <DataTable value={this.state.liste_fournisseur}
                                responsive={true} scrollable={true} scrollHeight="650px" selectionMode="single"
                                onRowClick={e => this.setState({ selectedFournisseur: e.data })}
                                selection={this.state.selectedFournisseur}>
                                <Column header="#" body={this.actionTemplate.bind(this)} style={{ width: '8%' }} />
                                <Column field="nomFour" header="Nom fournisseur" filter={true} filterMatchMode='contains' sortable={true} style={{ width: '30%' }} />
                                <Column field="telFour" header="Telephone" filter={true} filterMatchMode='contains' sortable={true} style={{ width: '10%' }} />
                                <Column field="adrFour" header="Adresse" filter={true} filterMatchMode='contains' sortable={true} style={{ width: '16%' }} />
                                <Column field="emailFour" header="Email" filter={true} filterMatchMode='contains' sortable={true} style={{ width: '20%' }} />
                                <Column field="typeFour" header="Type" filter={true} filterMatchMode='contains' sortable={true} style={{ width: '8%' }} />
                                <Column field="tvaFour" header="TVA" filter={true} filterMatchMode='contains' sortable={true} style={{ width: '8%' }} />
                            </DataTable>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}