import React, { Component } from 'react';
import { Xulaservice } from '../service/Xulaservice';
import { Calendar } from 'primereact/calendar';
import { DataTable } from 'primereact/datatable';
import { Dropdown } from 'primereact/dropdown';
import { Column } from 'primereact/column';
import { Growl } from 'primereact/growl';
import './styles.css';
import { SplitButton } from 'primereact/splitbutton';
import { Chart } from 'primereact/chart';
import 'moment';
import 'moment/locale/fr';
import moment from 'moment';
import { ProgressSpinner } from 'primereact/progressspinner';
import Numeral from 'numeral';
import { Panel } from 'primereact/panel';
import { OverlayPanel } from 'primereact/overlaypanel';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';

export class FormStat_vente extends Component {
    constructor() {
        super();
        this.state = {
            pd1: new Date(),
            pd2: new Date(),
            ridCli: null,
            liste_sortie: [],
            liste_boutique: [],
            liste_client: [],
            lclient: [],
            lboutique: [],
            ridBoutiq: { value: 'Tous', label: 'Boutique' },
            total_facture: '0',
            total_regle: '0',
            total_restant: '0',
            total_gain: '0',
            items: [
                {
                    label: 'Format PDF',
                    icon: 'pi pi-print',
                    command: (e) => {
                        this.conf_print('PDF');
                    }
                },
                {
                    label: 'Format XLS',
                    icon: 'pi pi-print',
                    command: (e) => {
                        this.conf_print('XLS');
                    }
                }
            ],
            userconnected: JSON.parse(localStorage.getItem('userconnected')),
            rfiltreClient: 'COMPTOIR'
        };
        this.myservice = new Xulaservice();
        this.conf_print = this.conf_print.bind(this);
        this.state.pd1.setDate(this.state.pd1.getDate() - 120);
    }

    componentDidMount() {
        this.get_liste_sortie();
        if (this.state.userconnected.matrPers.idBoutiq.typeBoutiq === 'Pharmacie') {
            this.setState({ rfiltreClient: 'Tous' }, () => {
                this.get_liste_client();
            })
        } else {
            if (this.state.userconnected.idEtabl.codeEtabl !== 'EDK') {
                this.setState({ rfiltreClient: 'Tous' }, () => {
                    this.get_liste_client();
                });
            }else{
                this.setState({ rfiltreClient: 'COMPTOIR' }, () => {
                    this.get_liste_client();
                });
            }
        }
        this.get_liste_boutique();
    }

    formaterMontant(montant) {
        return Numeral(parseFloat(montant)).format('0,0');
    }


    get_liste_boutique() {
        let lboutique = null;
        let lboutique_rech = [{ value: 'Tous', label: 'Boutique' }];
        this.myservice.getListe_boutique().then(data => this.setState({ liste_boutique: data }, () => {
            if (this.state.liste_boutique != null && this.state.liste_boutique != undefined) {
                lboutique = this.state.liste_boutique.map(x => {
                    return { value: x.idBoutiq, label: x.nomBoutiq }
                });
            }

            if (this.state.userconnected.profilUser === 'PDG' || this.state.userconnected.profilUser === 'Coordinateur') {
                this.setState({ lboutique: lboutique });
                lboutique_rech = lboutique_rech.concat(lboutique);
            } else {
                let id = this.state.userconnected.matrPers.idBoutiq.idBoutiq;
                if (lboutique.length > 0) {
                    let liste = lboutique.filter(function (el) {
                        return el.value === id;
                    });
                    this.setState({ lboutique: liste });
                    lboutique_rech = lboutique_rech.concat(liste);
                }
            }
            this.setState({ lboutique_rech: lboutique_rech })
        }));
    }

    get_liste_client() {
        let lclient = null;
        this.setState({ showindicator: true });
        this.myservice.getliste_client_light(this.state.rfiltreClient).then(data => this.setState({ liste_client: data }, () => {
            let lclient_rech = [{ value: 'Tous', label: 'Toutes les clients', remiseCli: 0, soldeCli_format: '0', telCli: 'x', tvaCli: 0, balanceCli: 0, caventeCli: 0, label_rech: 'Tous 0' }];
            if (this.state.liste_client != null && this.state.liste_client != undefined) {
                lclient = this.state.liste_client.map(x => {
                    return { value: x.idCli, label: x.nomCli, remiseCli: x.remiseCli, soldeCli_format: x.soldeCli_format, telCli: x.telCli, tvaCli: x.tvaCli, balanceCli: x.balanceCli, caventeCli: x.caventeCli, label_rech: x.nomCli + ' ' + x.idCli }
                });
                lclient_rech = lclient_rech.concat(lclient);
                this.setState({ lclient_rech: lclient_rech });
            }
            this.setState({ showindicator: false });
        }));
    }



    conf_print(format) {
        this.setState({ showindicator: true });
        let rboutique = 'Tous';
        let rid_client = 'Tous';
        let spd1 = moment(this.state.pd1).format('DD-MM-YYYY');
        let spd2 = moment(this.state.pd2).format('DD-MM-YYYY');
        if (this.state.ridBoutiq != undefined || this.state.ridBoutiq != null) {
            rboutique = this.state.ridBoutiq.value;
        }
        if (this.state.ridCli != undefined || this.state.ridCli != null) {
            rid_client = this.state.ridCli.value;
        }
        this.myservice.generer_st_sortie_by_month(spd1, spd2, rboutique, rid_client, format);
        this.setState({ showindicator: false });
    }

    get_liste_sortie() {
        this.setState({ showindicator: true, liste_sortie: [], total_facture: '0' });
        let rboutique = 'Tous';
        let rid_client = 'Tous';
        let spd1 = moment(this.state.pd1).format('DD-MM-YYYY');
        let spd2 = moment(this.state.pd2).format('DD-MM-YYYY');
        if (this.state.ridBoutiq != undefined || this.state.ridBoutiq != null) {
            rboutique = this.state.ridBoutiq.value;
        }
        if (this.state.ridCli != undefined || this.state.ridCli != null) {
            rid_client = this.state.ridCli.value;
        }

        this.myservice.get_st_sortie_by_month(spd1, spd2, rboutique, rid_client).then(data => this.setState({ liste_sortie: data, showindicator: false }, () => {
            let initialValue = 0
            if (data != null && data != undefined) {
                let tt_facture = data.reduce(function (accumulator, currentValue) {
                    return accumulator + currentValue.dval
                }, initialValue);
                let tt_gain = data.reduce(function (accumulator, currentValue) {
                    return accumulator + currentValue.dval2
                }, initialValue);
                let tt_rest = data.reduce(function (accumulator, currentValue) {
                    return accumulator + currentValue.dval3
                }, initialValue);
                this.setState({ total_facture: this.formaterMontant(tt_facture) });
                this.setState({ total_restant: this.formaterMontant(tt_rest) });
                if (this.state.userconnected.profilUser === 'PDG') {
                    this.setState({ total_gain: this.formaterMontant(tt_gain) });
                } else {
                    this.setState({ total_gain: '******' });
                }
            }

        }));
    }




    render() {

        let liste_label = null;
        let liste_ds_stat = null;

        if (this.state.liste_sortie != null && this.state.liste_sortie != undefined) {
            if (this.state.liste_sortie.length > 0) {
                liste_label = this.state.liste_sortie.map(x => {
                    return x.lib
                });
                liste_ds_stat = this.state.liste_sortie.map(x => {
                    return x.dval
                });
            }
        }

        const chart_data = {
            labels: liste_label,
            datasets: [
                {
                    label: 'Statistique des ventes',
                    backgroundColor: '#4071a7',
                    borderColor: '#4071a7',
                    fill: true,
                    data: liste_ds_stat
                }
            ]
        };

        const footer = <div style={{ textAlign: 'left' }}>
            <label style={{ fontWeight: 'bold', fontSize: 18 }} >{this.state.total_facture}</label>
        </div>;

        return (
            <div className="p-fluid">
                <Growl ref={(el) => this.growl = el} />
                <OverlayPanel ref={(el) => { this.op = el; }} style={{ width: '250px' }} >
                    <div className="p-grid">
                        <div className="p-col-10">
                            <InputText value={this.state.rfiltreClient} id="id_ovp" onKeyDown={(e) => {
                                if (e.keyCode === 13) {
                                    this.op.toggle(e);
                                    document.getElementById("new_dp_client").click();
                                    this.get_liste_client();
                                }
                            }} onChange={(e) => this.setState({ rfiltreClient: e.target.value })} autoFocus={true} />
                        </div>
                        <div className="p-col-2">
                            <Button type="button" icon="pi pi-search" className="p-button-warn" onClick={(e) => {
                                this.op.toggle(e);
                                document.getElementById("new_dp_client").click();
                                this.get_liste_client();
                            }} style={{ width: '30px' }} />
                        </div>
                    </div>
                </OverlayPanel>
                <div className="p-grid">
                    <div className="p-col-12">
                        <div className="card card-w-title" style={{ height: 700 }}>
                            <i className="fa fa-fw fa-search"></i>Option de recherche
                            <hr />
                            <div className="p-grid">
                                <div className="p-col-12">
                                    <div className="p-grid">
                                        <div className="p-col-12 p-md-2">
                                            <Calendar value={this.state.pd1} dateFormat="dd/mm/yy" onChange={(e) => this.setState({ pd1: e.value })} />
                                        </div>
                                        <div className="p-col-12 p-md-2">
                                            <Calendar value={this.state.pd2} dateFormat="dd/mm/yy" onChange={(e) => this.setState({ pd2: e.value })} />
                                        </div>
                                        <div className="p-col-12 p-md-4">
                                            <div className="p-grid">
                                                <div className="p-col-11">
                                                    <Dropdown id="new_dp_client"
                                                        options={this.state.lclient_rech}
                                                        value={this.state.ridCli}
                                                        placeholder="Client"
                                                        onChange={(e) => {
                                                            this.setState({ ridCli: e.value }, () => {
                                                                this.get_liste_sortie();
                                                            })
                                                        }}
                                                        style={{ width: '100%' }} panelStyle={{ width: '400px' }}
                                                        filter={true} filterBy='label_rech' filterMatchMode='contains'
                                                        optionLabel='label_rech'
                                                        itemTemplate={(option) => {
                                                            if (!option.value) {
                                                                return option.label;
                                                            }
                                                            else {
                                                                return (
                                                                    <div className="p-clearfix">
                                                                        <div className="p-grid">
                                                                            <div className="p-col-12 p-md-12">
                                                                                <label>{option.label}</label>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                );
                                                            }
                                                        }}
                                                    />
                                                </div>
                                                <div className="p-col-1">
                                                    <Button type="button" icon="pi pi-search" className="p-button-warning" onClick={(e) => {
                                                        this.op.toggle(e);
                                                    }} style={{ marginLeft: -15, width: '30px' }} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="p-col-12 p-md-2">
                                            <Dropdown options={this.state.lboutique} placeholder="Boutique" value={this.state.ridBoutiq} optionLabel='label' filter={true} filterBy='label' filterMatchMode='contains' onChange={(e) => this.setState({ ridBoutiq: e.value }, () => {
                                                this.get_liste_sortie();
                                            })} autoWidth={false} />
                                        </div>
                                        <div className="p-col-12 p-md-2">
                                            <div className="p-grid" style={{ width: 200 }}>
                                                <div className="p-col"><SplitButton label="Rechercher" style={{ width: '140px' }} onClick={(e) => this.get_liste_sortie()} icon="pi pi-search" model={this.state.items}></SplitButton></div>
                                                <div className="p-col">  {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : null}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="p-grid">
                                <div className="p-col-12 p-lg-5">
                                    <div className="p-grid">
                                        <div className="p-col-12">
                                            <DataTable value={this.state.liste_sortie} scrollable={true} scrollHeight="400px"
                                                responsive={true}  >
                                                <Column field="lib" header="Mois" style={{ width: '10%' }} />
                                                <Column field="str_dval" header="Montant" style={{ width: '25%' }} />
                                                <Column field="str_dval2" body={(rowData, column) => {
                                                    let val = rowData.str_dval2;
                                                    if (this.state.userconnected.profilUser !== 'PDG') {
                                                        val = '*****';
                                                    }
                                                    return <div>
                                                        <span style={{ color: '#000000', fontWeight: 'bold' }}>{val}</span>
                                                    </div>
                                                }} header="Marge" style={{ width: '25%' }} />
                                                <Column field="str_dval3" header="Restant" style={{ width: '25%' }} />
                                                <Column field="lib3" header="Prc." style={{ width: '15%' }} />
                                            </DataTable>
                                            <Panel style={{ backgroundColor: '#f5f5f5' }}>
                                                <div className="p-grid">
                                                    <div className="p-col-12 p-md-1">
                                                        <label style={{ fontSize: 16 }}>Total</label>
                                                    </div>
                                                    <div className="p-col-12 p-md-3">
                                                        <label style={{ fontWeight: 'bold', fontSize: 16 }} >{this.state.total_facture}</label>
                                                    </div>
                                                    <div className="p-col-12 p-md-3">
                                                        <label style={{ fontSize: 16, fontWeight: 'bold', color: 'green' }}> {this.state.total_gain} </label>
                                                    </div>
                                                    <div className="p-col-12 p-md-3">
                                                        <label style={{ fontSize: 16, fontWeight: 'bold', color: 'red' }}> {this.state.total_restant} </label>
                                                    </div>
                                                    <div className="p-col-12 p-md-2">
                                                        <label style={{ fontSize: 16 }}></label>
                                                    </div>
                                                </div>
                                            </Panel>
                                        </div>
                                    </div>
                                </div>

                                <div className="p-col-12 p-lg-7">
                                    <div className="p-grid">
                                        <div className="p-col-12">
                                            <div className="content-section implementation">
                                                <Chart type="line" data={chart_data} height="140w" />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>


        );
    }
}