import React, { Component } from 'react';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { AutoComplete } from 'primereact/autocomplete';
import { MultiSelect } from 'primereact/multiselect';
import { Calendar } from 'primereact/calendar';
import { DataTable } from 'primereact/datatable';
import { DataView, DataViewLayoutOptions } from 'primereact/dataview';
import { Tree } from 'primereact/tree';
import { Checkbox } from 'primereact/checkbox';
import { Menu } from 'primereact/menu';
import { PanelMenu } from 'primereact/panelmenu';
import { InputMask } from 'primereact/inputmask';
import { Dropdown } from 'primereact/dropdown';
import { Password } from 'primereact/password';
import { Spinner } from 'primereact/spinner';
import { Slider } from 'primereact/slider';
import { ListBox } from 'primereact/listbox';
import { RadioButton } from 'primereact/radiobutton';
import { PickList } from 'primereact/picklist';
import { OrderList } from 'primereact/orderlist';
import { ToggleButton } from 'primereact/togglebutton';
import { SelectButton } from 'primereact/selectbutton';
import { Button } from 'primereact/button';
import { SplitButton } from 'primereact/splitbutton';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { Panel } from 'primereact/panel';
import { TabView, TabPanel } from 'primereact/tabview';
import { ProgressBar } from 'primereact/progressbar';
import { Dialog } from 'primereact/dialog';
import { Column } from 'primereact/column';

export class SampleDemo extends Component {

   constructor() {
      super();
      this.state = {
         date: null,
         country: null,
         filteredCountries: null,
         countriesData: [],
         dropdownCity: null,
         cities: [
            { label: 'Select City', value: null },
            { label: 'New York', value: 'New York' },
            { label: 'Rome', value: 'Rome' },
            { label: 'London', value: 'London' },
            { label: 'Istanbul', value: 'Istanbul' },
            { label: 'Paris', value: 'Paris' },
         ],
         spinnerValue: null,
         checkboxValue: [],
         radioValue: null,
         sliderValue: null,
         toggleButtonValue: null,
         dialogVisible: false,
         dataTableValue: [],
         dataTableSelection: null,
         dataViewValue: [],
         treeData: [],
         selectedFile: null,
         picklistSourceCars: [],
         picklistTargetCars: [],
         orderlistCars: [],
         layout: 'list',
         selectedCars: [],
         carOptions: [
            { label: 'Audi', value: 'Audi' },
            { label: 'BMW', value: 'BMW' },
            { label: 'Fiat', value: 'Fiat' },
            { label: 'Honda', value: 'Honda' },
            { label: 'Jaguar', value: 'Jaguar' },
            { label: 'Mercedes', value: 'Mercedes' },
            { label: 'Renault', value: 'Renault' },
            { label: 'VW', value: 'VW' },
            { label: 'Volvo', value: 'Volvo' }
         ],
         listBoxCity: null,
         listBoxCities: [
            { label: 'Madrid', value: 'Madrid' },
            { label: 'Geneva', value: 'Geneva' },
            { label: 'Los Angeles', value: 'Los Angeles' },
            { label: 'Monaco', value: 'Monaco' },
            { label: 'Berlin', value: 'Berlin' }
         ],
         selectedType: null,
         types: [
            { label: 'Apartment', value: 'Apartment' },
            { label: 'House', value: 'House' },
            { label: 'Studio', value: 'Studio' }
         ],
         splitButtonItems: [
            { label: 'Update', icon: 'pi pi-refresh' },
            { label: 'Delete', icon: 'pi pi-times' },
            { label: 'Home', icon: 'pi pi-home', url: 'http://www.primefaces.org/primereact' }
         ],
         menuItems: [
            {
               label: 'Options',
               items: [{ label: 'New', icon: 'pi pi-fw pi-plus', command: () => window.location.hash = "/fileupload" },
               { label: 'Delete', icon: 'pi pi-fw pi-trash', url: 'http://primetek.com.tr' }]
            },
            {
               label: 'Account',
               items: [{ label: 'Options', icon: 'pi pi-fw pi-cog', command: () => window.location.hash = "/" },
               { label: 'Sign Out', icon: 'pi pi-fw pi-power-off' }]
            }
         ],
         panelMenuItems: [
            {
               label: 'Documents',
               icon: 'pi pi-fw pi-file',
               items: [
                  {
                     label: 'New',
                     icon: 'pi pi-fw pi-plus',
                     items: [
                        {
                           label: 'Bookmark',
                           icon: 'pi pi-fw pi-bookmark'
                        },
                        {
                           label: 'Video',
                           icon: 'pi pi-fw pi-video'
                        },

                     ]
                  },
                  {
                     label: 'Delete',
                     icon: 'pi pi-fw pi-trash'
                  },
                  {
                     separator: true
                  },
                  {
                     label: 'Export',
                     icon: 'pi pi-fw pi-external-link'
                  }
               ]
            },
            {
               label: 'Manage',
               icon: 'pi pi-fw pi-pencil',
               items: [
                  {
                     label: 'Left',
                     icon: 'pi pi-fw pi-align-left'
                  },
                  {
                     label: 'Right',
                     icon: 'pi pi-fw pi-align-right'
                  },
                  {
                     label: 'Center',
                     icon: 'pi pi-fw pi-align-center'
                  },
                  {
                     label: 'Justify',
                     icon: 'pi pi-fw pi-align-justify'
                  },

               ]
            },
            {
               label: 'Accounts',
               icon: 'pi pi-fw pi-user',
               items: [
                  {
                     label: 'New',
                     icon: 'pi pi-fw pi-user-plus',

                  },
                  {
                     label: 'Delete',
                     icon: 'pi pi-fw pi-user-minus',

                  },
                  {
                     label: 'Search',
                     icon: 'pi pi-fw pi-users',
                     items: [
                        {
                           label: 'Filter',
                           icon: 'pi pi-fw pi-filter',
                           items: [
                              {
                                 label: 'Print',
                                 icon: 'pi pi-fw pi-print'
                              }
                           ]
                        },
                        {
                           icon: 'pi pi-fw pi-bars',
                           label: 'List'
                        }
                     ]
                  }
               ]
            },
            {
               label: 'Calendar',
               icon: 'pi pi-fw pi-calendar',
               items: [
                  {
                     label: 'Edit',
                     icon: 'pi pi-fw pi-pencil',
                     items: [
                        {
                           label: 'Save',
                           icon: 'pi pi-fw pi-calendar-plus'
                        },
                        {
                           label: 'Delete',
                           icon: 'pi pi-fw pi-calendar-minus'
                        }
                     ]
                  },
                  {
                     label: 'Archieve',
                     icon: 'pi pi-fw pi-calendar-times',
                     items: [
                        {
                           label: 'Remove',
                           icon: 'pi pi-fw pi-calendar-minus'
                        }
                     ]
                  }
               ]
            }
         ]
      };


   }



   render() {
      return (
         <div className="p-fluid">

            <div className="p-grid">
               <div className="p-col-12 p-lg-4">
                  <div className="p-grid">
                     <div className="p-col-12">
                        <div className="card card-w-title" style={{ height: 200 }}>


                        </div>
                     </div>
                  </div>
               </div>

               <div className="p-col-12 p-lg-8">
                  <div className="p-grid">
                     <div className="p-col-12">
                        <div className="card card-w-title" style={{ height: 200 }}>



                        </div>
                     </div>
                  </div>
               </div>
            </div>



            

         </div>
      );
   }
}