import React, { Component } from 'react';
import { Xulaservice } from '../service/Xulaservice';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Growl } from 'primereact/growl';
import './styles.css';
import { ProgressSpinner } from 'primereact/progressspinner';
import Numeral from 'numeral';
import { Row } from 'primereact/row';
import { ColumnGroup } from 'primereact/columngroup';
import { Button } from 'primereact/button';

export class FormEdition_dette extends Component {
    constructor() {
        super();
        this.state = {
            liste_data: [],
            showindicator: false,
            total: '0',
            userconnected: JSON.parse(localStorage.getItem('userconnected'))
        };
        this.myservice = new Xulaservice();
    }

    componentDidMount() {
        this.get_liste_data();
    }

    formaterMontant(montant) {
        return Numeral(parseFloat(montant)).format('0,0');
    }


    get_liste_data() {

        this.setState({ total: '0' });
        this.setState({ showindicator: true, liste_data: [] });

        this.myservice.get_journal_dette().then(data => this.setState({ liste_data: data, showindicator: false }, () => {
            let initialValue = 0
            if (data != null && data != undefined) {
                let tt = data.reduce(function (accumulator, currentValue) {
                    return accumulator + currentValue.balanceCli
                }, initialValue);
                this.setState({ total: this.formaterMontant(tt) });
            }
        }));
    }



    render() {

        let total = <label style={{fontSize: 22,color: 'red'}}>{this.state.total} </label>

        return (
            <div className="p-fluid">
                <Growl ref={(el) => this.growl = el} />
                <div className="p-grid">
                    <div className="p-col-12">
                        <div className="card card-w-title" style={{ height: 700 }}>
                            <DataTable value={this.state.liste_data} scrollable={true} scrollHeight="400px"
                                paginatorRight={total}
                                header={<Button onClick={(e) => this.dt.exportCSV()} label="Exporter" style={{ width: 200 }} icon="pi pi-print" className="p-button-warning p-button-rounded" />}
                                paginatorLeft={this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : null}
                                responsive={true} ref={(el) => { this.dt = el; }} paginator={true} rows={20} alwaysShowPaginator={false}>
                                <Column field="nomCli" header="Client" filter={true} filterMatchMode='contains' />
                                <Column field="telCli" header="Telephone" filter={true} filterMatchMode='contains' style={{ width: '15%' }} />
                                <Column field="emailCli" header="Email" filter={true} filterMatchMode='contains' style={{ width: '30%' }} />
                                <Column field="balanceCli" body={(rowData, column) => {
                                    return <div>
                                        <span style={{ color: 'red' }}>{this.formaterMontant(rowData.balanceCli)}</span>
                                    </div>
                                }} header="Dette" filter={true} filterMatchMode='contains' sortable={true} style={{ width: '22%' }} />
                            </DataTable>
                        </div>
                    </div>
                </div>
            </div>


        );
    }
}