import React, { Component } from 'react';
import { Xulaservice } from '../service/Xulaservice';
import { InputText } from 'primereact/inputtext';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dialog } from 'primereact/dialog';
import { Growl } from 'primereact/growl';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import './styles.css';
import { Panel } from 'primereact/panel';
import { ProgressSpinner } from 'primereact/progressspinner';

export class FormSalle extends Component {
    constructor() {
        super();
        this.state = {
            liste_salle: [],
            liste_boutique: [],
            liste_table: [],
            selectedSalle: null,
            selectedTable: null,
            codif: null,
            idSalle: '0',
            nomSalle: '',
            capSalle: 0,
            idBoutiq: null,

            nomTable: '',
            idTable: '0',


            msg: '',
            modif_salle: false,
            modif_table: false,
            visible_salle: false,
            visible_table: false,

            userconnected: JSON.parse(localStorage.getItem('userconnected')),

        };
        this.edit_salle = this.edit_salle.bind(this);
        this.edit_table = this.edit_table.bind(this);
        this.conf_delete_salle = this.conf_delete_salle.bind(this);
        this.conf_delete_table = this.conf_delete_table.bind(this);
        this.xulaservice = new Xulaservice();
    }

    componentDidMount() {
        this.get_liste_salle();
        this.get_liste_boutique();
    }

    get_liste_salle() {
        this.xulaservice.get_liste_salle('Tous').then(data => this.setState({ liste_salle: data }));
    }

    getListe_table(idSalle) {
        this.xulaservice.get_liste_table(idSalle).then(data => {
            this.setState({ liste_table: data });
        });
    }

    get_liste_boutique() {
        let lboutique = null;
        this.xulaservice.getListe_boutique().then(data => {
            if (data != null && data != undefined) {
                lboutique = data.map(x => {
                    return { value: x.idBoutiq, label: x.nomBoutiq }
                });
            }
            this.setState({liste_boutique: lboutique });
        });
    }


    resultat_salle(code, contenu) {
        if (code == '200') {
            this.growl.show({ severity: 'success', summary: 'Success', detail: contenu });
            this.annule_salle();
            this.get_liste_salle();
        } else {
            this.growl.show({ severity: 'error', summary: 'Error', detail: contenu });
        }
    }

    resultat_table(code, contenu) {
        if (code == '200') {
            this.growl.show({ severity: 'success', summary: 'Success', detail: contenu });
            this.annule_table();
            this.getListe_table(this.state.selectedSalle.idSalle);
        } else {
            this.growl.show({ severity: 'error', summary: 'Error', detail: contenu });
        }
    }

    edit_salle(data) {
        if (data != null && data != undefined) {
            this.setState({ idSalle: data.idSalle });
            this.setState({ nomSalle: data.nomSalle });
            this.setState({ capSalle: data.capSalle });
            this.setState({ idBoutiq: data.idBoutiq.idBoutiq });
            this.setState({ modif_salle: true });
            this.setState({ selectedSalle: data });
        }
    }

    edit_table(data) {
        if (data != null && data != undefined) {
            this.setState({ idTable: data.idTable });
            this.setState({ idSalle: data.idSalle.idSalle });
            this.setState({ nomTable: data.nomTable });
            this.setState({ modif_table: true });
            this.setState({ selectedTable: data });
        }
    }

    annule_salle() {
        this.setState({ nomSalle: '' });
        this.setState({ capSalle: 0 });
        this.setState({ idSalle: '0' });
        this.setState({ modif_salle: false });
        this.forceUpdate();
    }
    annule_table() {
        this.setState({ idSalle: '' });
        this.setState({ nomTable: '' });
        this.setState({ idTable: '0' });
        this.setState({ modif_table: false });
        this.forceUpdate();
    }

    enreg_salle() {
        if (this.state.nomSalle === "" || this.state.capSalle === "" || this.state.capSalle === undefined || this.state.idBoutiq === "" || this.state.idBoutiq === undefined || this.state.idBoutiq === null) {
            this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez saisir tous les champs obligatoires' });
        } else {
            if (this.state.modif_salle == false) {
                let data = { idSalle: '0', nomSalle: this.state.nomSalle, capSalle: this.state.capSalle, idBoutiq: {idBoutiq: this.state.idBoutiq} };
                this.xulaservice.enreg_salle(data).then(data => this.resultat_salle(data.code, data.contenu));
            } else {
                let data = this.state.selectedSalle;
                data.nomSalle = this.state.nomSalle;
                data.capSalle = this.state.capSalle;
                this.xulaservice.enreg_salle(data).then(data => this.resultat_salle(data.code, data.contenu));
            }
        }
    }

    enreg_table() {
        if (this.state.selectedSalle != null && this.state.selectedSalle != undefined) {
            if (this.state.nomTable === "" || this.state.nomTable === undefined || this.state.nomTable === null) {
                this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez saisir le nom de la table' });
            } else {
                if (this.state.modif_table == false) {
                    let data = { idTable: '0', idSalle: { idSalle: this.state.selectedSalle.idSalle }, nomTable: this.state.nomTable };
                    this.xulaservice.enreg_table(data).then(data => this.resultat_table(data.code, data.contenu));
                } else {
                    let data = this.state.selectedTable;
                    data.nomTable = this.state.nomTable;
                    this.xulaservice.enreg_table(data).then(data => this.resultat_table(data.code, data.contenu));
                }
            }
        } else {
            this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez selectionner une salle' });

        }

    }

    actionTemplate(rowData, column) {
        return <div className="p-grid" style={{ width: 90 }}>
            <div className="p-col"><Button onClick={(e) => this.edit_salle(rowData)} className="p-button-info" style={{ width: 25, height: 25 }} icon="pi pi-pencil" /></div>
            <div className="p-col"><Button onClick={(e) => this.conf_delete_salle(rowData)} className="p-button-danger" style={{ width: 25, height: 25, marginLeft: -10 }} icon="pi pi-times" /></div>
        </div>
    }
    actionTemplate_table(rowData, column) {
        return <div className="p-grid" style={{ width: 90 }}>
            <div className="p-col"><Button onClick={(e) => this.edit_table(rowData)} className="p-button-info" style={{ width: 25, height: 25 }} icon="pi pi-pencil" /></div>
            <div className="p-col"><Button onClick={(e) => this.conf_delete_table(rowData)} className="p-button-danger" style={{ width: 25, height: 25, marginLeft: -10 }} icon="pi pi-times" /></div>
        </div>
    }


    delete_salle() {
        if (this.state.selectedSalle == null || this.state.selectedSalle == undefined) {
            this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez sélectionner la ligne à supprimer' });
        } else {
            this.xulaservice.supr_salle(this.state.selectedSalle).then(data => this.resultat(data.code, data.contenu));
        }
    }

    delete_table() {
        if (this.state.selectedTable == null || this.state.selectedTable == undefined) {
            this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez sélectionner la ligne à supprimer' });
        } else {
            this.xulaservice.supr_table(this.state.selectedTable).then(data => this.resultat(data.code, data.contenu));
        }
    }


    conf_delete_salle(data) {
        if (data != null && data != undefined) {
            this.setState({ selectedSalle: data });
            this.setState({ msg: data.nomSalle });
            this.setState({ visible: true });
        }
    }

    conf_delete_table(data) {
        if (data != null && data != undefined) {
            this.setState({ selectedTable: data });
            this.setState({ msg: data.idSalle.idSalle });
            this.setState({ visible: true });
        }
    }



    render() {

        const dialogFooter = (

            <div>
                <Button icon="pi pi-check" onClick={() => {
                    this.setState({ visible_salle: false });
                    this.delete_salle();
                }} label="Oui" />
                <Button icon="pi pi-times" onClick={() => this.setState({ visible_salle: false })} label="Annuler" className="p-button-secondary" />
              

            </div>
        );

        const dialogFooter_table = (

            <div>
                <Button icon="pi pi-check" onClick={() => {
                    this.setState({ visible_table: false });
                    this.delete_table();
                }} label="Oui" />
                <Button icon="pi pi-times" onClick={() => this.setState({ visible_table: false })} label="Annuler" className="p-button-secondary" />
                
            </div>
        );
        return (
            <div className="p-fluid ">
                <Growl ref={(el) => this.growl = el} />
                <Dialog header="Confirmation" visible={this.state.visible_salle} style={{ width: '250px' }} modal={true} footer={dialogFooter} onHide={(e) => this.setState({ visible_salle: false })}>
                    <div>
                        <i className="fa fa-warning" style={{ color: '#FFD133', fontSize: 25 }}></i>
                        Voulez vous vraiment supprimer <b>{this.state.msg} </b>?
                    </div>
                </Dialog>

                <Dialog header="Confirmation" visible={this.state.visible_table} style={{ width: '250px' }} modal={true} footer={dialogFooter_table} onHide={(e) => this.setState({ visible_table: false })}>
                    <div>
                        <i className="fa fa-warning" style={{ color: '#FFD133', fontSize: 25 }}></i>
                        Voulez vous vraiment supprimer <b>{this.state.msg} </b>?
                    </div>
                </Dialog>
                <div className="p-grid">

                    <div className="p-col-12 p-lg-8">
                        <div className="p-grid">
                            <div className="p-col-12">
                                <div className="card card-w-title" style={{ height: 550 }}>

                                    <h2><i className="fa fa-fw fa-sitemap"></i>Gestion des salles</h2>
                                    <hr />
                                    <Panel style={{ minHeight: '350px', marginBottom: 8 }}>
                                        <DataTable value={this.state.liste_salle}
                                            responsive={true} scrollable={true} scrollHeight="300px" selectionMode="single"
                                            onRowClick={e => this.setState({ selectedSalle: e.data }, () => {
                                                this.getListe_table(this.state.selectedSalle.idSalle);
                                            })}
                                            selection={this.state.selectedSalle}
                                        >
                                            <Column header="#" body={this.actionTemplate.bind(this)} style={{ width: '11%' }} />
                                            <Column field="nomSalle" header="NomSalle" sortable={true} style={{ width: '39%' }} />
                                            <Column field="capSalle" header="Capacité" sortable={true} style={{ width: '20%' }} />
                                            <Column field="idBoutiq.nomBoutiq" header="Boutique" sortable={true} style={{ width: '30%' }} />

                                        </DataTable>
                                    </Panel>


                                    <div className="p-grid">

                                        <div className="p-col-12 p-md-4">
                                            <span >
                                                <label style={{ fontSize: '13px', color: '#9A9C9C' }} htmlFor="inPu">Nom salle *</label>
                                            </span>
                                            <InputText id="nomSalle" value={this.state.nomSalle} onChange={(e) => this.setState({ nomSalle: e.target.value })} />
                                        </div>

                                        <div className="p-col-12 p-md-4">
                                            <span >
                                                <label style={{ fontSize: '13px', color: '#9A9C9C' }} htmlFor="inPu">Capacité *</label>
                                            </span>
                                            <InputText id="capSalle" value={this.state.capSalle} onChange={(e) => this.setState({ capSalle: e.target.value })} />
                                        </div>


                                        <div className="p-col-12 p-md-4">
                                            <span >
                                                <label style={{ fontSize: '13px', color: '#9A9C9C' }} htmlFor="inPu">Boutique *</label>
                                            </span>
                                            <Dropdown autoWidth={false} options={this.state.liste_boutique} value={this.state.idBoutiq} onChange={(e) => this.setState({ idBoutiq: e.value })} />
                                        </div>
                                    </div>
                                    <center>
                                        <div className="p-grid" style={{ width: 300 }}>
                                            <div className="p-col"><Button label="Annuler" icon="pi pi-ban" onClick={(e) => this.annule_salle()} /></div>
                                            <div className="p-col"><Button label="Valider" icon="pi pi-check" onClick={(e) => this.enreg_salle()} /></div>
                                        </div>
                                        {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : null}
                                    </center>

                                </div>
                            </div>
                        </div>
                    </div>


                    <div className="p-col-12 p-lg-4">
                        <div className="p-grid">
                            <div className="p-col-12">
                                <div className="card card-w-title" style={{ height: 550 }}>

                                    <h2><i className="fa fa-fw fa-sitemap"></i>Gestion des tables</h2>
                                    <hr />
                                    <Panel style={{ minHeight: '350px', marginBottom: 8 }}>
                                        <DataTable value={this.state.liste_table}
                                            responsive={true} scrollable={true} scrollHeight="300px" selectionMode="single"
                                            onRowClick={e => this.setState({ selectedTable: e.data})}
                                            selection={this.state.selectedTable}
                                        >
                                            <Column header="#" body={this.actionTemplate_table.bind(this)} style={{ width: '22%' }} />
                                            <Column field="nomTable" header="libellé" sortable={true}  />
                                        </DataTable>
                                    </Panel>

                                    <div className="p-grid">

                                        <div className="p-col-12">
                                            <span >
                                                <label style={{ fontSize: '13px', color: '#9A9C9C' }} htmlFor="inPu">Nom table *</label>
                                            </span>
                                            <InputText id="nomTable" value={this.state.nomTable} onChange={(e) => this.setState({ nomTable: e.target.value })} />
                                        </div>
                                    </div>
                                    <center>
                                        <div className="p-grid" style={{ width: 300 }}>
                                            <div className="p-col"><Button label="Annuler" icon="pi pi-ban" onClick={(e) => this.annule_table()} /></div>
                                            <div className="p-col"><Button label="Valider" icon="pi pi-check" onClick={(e) => this.enreg_table()} /></div>
                                        </div>
                                        {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : null}
                                    </center>

                                </div>
                            </div>
                        </div>
                    </div>

                </div>

            </div>
        );
    }
}