import React, { Component } from 'react';
import { Xulaservice } from '../service/Xulaservice';
import { InputText } from 'primereact/inputtext';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dialog } from 'primereact/dialog';
import { Growl } from 'primereact/growl';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { ProgressSpinner } from 'primereact/progressspinner';
import './styles.css';
import Cleave from 'cleave.js/react';
import Numeral from 'numeral';
import 'moment';
import 'moment/locale/fr';
import moment from 'moment';
import { Calendar } from 'primereact/calendar';
import { SplitButton } from 'primereact/splitbutton';

export class FormPlanifPrix extends Component {
    constructor() {
        super();
        this.state = {
            idPlan: '0',
            liste_boutique: [],
            lboutique: [],
            lprod: [],
            idBoutiq: null,
            liste_plan: [],
            liste_plan_to_create: [],
            liste_plan_selected: [],
            liste_article: [],
            selectedPlan: null,
            idArticle: null,
            stockPlan: 0,
            prixVentePlan: 0,
            prixRevientPlan: 0,
            ancienprixPlan: 0,
            pd1: new Date(),
            pd2: new Date(),
            msg: '',
            modif: false,
            visible: false,
            visible_dlg_new: false,
            visible_dlg_modif: false,
            showindicator: false,
            ridBoutiq: 'Tous',
            rid_article: 'Tous',
            userconnected: JSON.parse(localStorage.getItem('userconnected')),
        };
        this.edit_plan = this.edit_plan.bind(this);
        this.conf_delete_plan = this.conf_delete_plan.bind(this);

        this.prixVentePlanEditor = this.prixVentePlanEditor.bind(this);
        this.prixRevientPlanEditor = this.prixRevientPlanEditor.bind(this);
        this.stockPlanEditor = this.stockPlanEditor.bind(this);
        this.onEditorValueChange = this.onEditorValueChange.bind(this);
        this.show_new = this.show_new.bind(this);

        this.xulaservice = new Xulaservice();
    }

    show_new() {
        this.setState({ visible_dlg_new: true });
    }
    prixVentePlanEditor(props) {
        return this.inputTextEditor(props, 'prixVentePlan');
    }

    prixRevientPlanEditor(props) {
        return this.inputTextEditor(props, 'prixRevientPlan');
    }

    stockPlanEditor(props) {
        return this.inputTextEditor(props, 'stockPlan');
    }

    onEditorValueChange(props, value) {
        let updateds = [...props.value];
        updateds[props.rowIndex][props.field] = value;
        this.setState({ liste_plan_to_create: updateds });
    }

    inputTextEditor(props, field) {
        return <Cleave value={props.rowData[field]} options={{ numeral: true, delimiter: ' ' }} onChange={(e) => this.onEditorValueChange(props, e.target.rawValue)} className="p-inputtext" />
    }


    componentDidMount() {
        this.getliste_plan();
        this.get_liste_boutique();
        this.get_liste_article();
    }


    formaterMontant(montant) {
        return Numeral(parseFloat(montant)).format('0,0');
    }

    get_liste_boutique() {
        let lboutique = null;
        let lboutique_rech = [{ value: 'Tous', label: 'Boutique' }];

        this.xulaservice.getListe_boutique().then(data => this.setState({ liste_boutique: data }, () => {
            if (this.state.liste_boutique != null && this.state.liste_boutique != undefined) {
                lboutique = this.state.liste_boutique.map(x => {
                    return { value: x.idBoutiq, label: x.nomBoutiq }
                });
            }

            if (this.state.userconnected.profilUser === 'PDG' || this.state.userconnected.profilUser === 'Coordinateur') {
                this.setState({ lboutique: lboutique });
                lboutique_rech = lboutique_rech.concat(lboutique);

            } else {
                let id = this.state.userconnected.matrPers.idBoutiq.idBoutiq;
                if (lboutique.length > 0) {
                    let liste = lboutique.filter(function (el) {
                        return el.value === id;
                    });
                    this.setState({ lboutique: liste });
                    lboutique_rech = lboutique_rech.concat(liste);
                }
            }
            this.setState({ lboutique_rech: lboutique_rech })
        }));
    }



    get_liste_article() {
        this.xulaservice.get_liste_article_light().then(data => {
            this.setState({ liste_article: data }, () => {
                let lprod = null;
                if (this.state.liste_article != null && this.state.liste_article != undefined) {
                    lprod = this.state.liste_article.map(x => {
                        return { value: x.idArticle, label: x.libArticle, cbArt: x.cbArticle }
                    });
                    this.setState({ lprod: lprod });
                }
            })
        });
    }

    getliste_plan() {
        let spd1 = moment(this.state.pd1, 'DD/MM/YYYY').format('DD/MM/YYYY');
        let spd2 = moment(this.state.pd2, 'DD/MM/YYYY').format('DD/MM/YYYY');
        this.setState({ showindicator: true });
        let rid_boutique = 'Tous';
        let rid_article = 'Tous';
        if (this.state.ridBoutiq != undefined && this.state.ridBoutiq != null) {
            rid_boutique = this.state.ridBoutiq.value;
        }
        if (this.state.rid_article != undefined && this.state.rid_article != null) {
            rid_article = this.state.rid_article.value;
        }
        this.xulaservice.get_liste_planif_prix(spd1, spd2, rid_article, rid_boutique, 'Tous').then(data => {
            this.setState({ liste_plan: data, showindicator: false });
        });
    }


    resultat(code, contenu) {
        if (code == '200') {
            this.growl.show({ severity: 'success', summary: 'Success', detail: contenu });
            this.annule_plan();
            this.getliste_plan();
        } else {
            this.growl.show({ severity: 'error', summary: 'Error', detail: contenu });
        }
    }

    edit_plan(data) {
        if (data != null && data != undefined) {
            this.setState({ idPlan: data.idPlan });
            this.setState({ stockPlan: data.stockPlan });
            this.setState({ selectedPlan: data });
            this.setState({ prixVentePlan: data.prixVentePlan });
            this.setState({ prixRevientPlan: data.prixRevientPlan });
            this.setState({ ancienprixPlan: data.ancienprixPlan });
            this.setState({ libArticle: data.idArticle.libArticle });
            this.setState({ modif: true, visible_dlg_modif: true });
        }
    }

    annule_plan() {
        this.setState({ stockPlan: '' });
        this.setState({ idPlan: '0' });
        this.setState({ modif: false });
        this.setState({ prixVentePlan: '' });
        this.forceUpdate();
    }

    enreg_plan() {
        if (this.state.modif == false) {
            if (this.state.liste_plan_selected.length<=0 ) {
                this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez sélectionner les lignes à valider' });
            } else {
                this.setState({ showindicator: true });
                this.xulaservice.enreg_planif_prix(this.state.liste_plan_selected).then(data => {
                    this.resultat(data.code, data.contenu);
                    if (data.code == 200) {
                        this.setState({ liste_plan_selected: [] });
                    }
                });
            }
        } else {
            if (this.state.prixVentePlan === undefined || this.state.prixVentePlan === "" || this.state.stockPlan === undefined || this.state.stockPlan === "") {
                this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez saisir tous les champs obligatoires' });
            } else {
                let data = this.state.selectedPlan;
                data.stockPlan = this.state.stockPlan;
                data.prixRevientPlan = this.state.prixRevientPlan;
                data.prixVentePlan = this.state.prixVentePlan;
                data.ancienprixPlan = this.state.ancienprixPlan;
                data.etatPlan = 'En attente';
                this.xulaservice.update_planif_prix(data).then(rep => {
                    if (rep.code == 200) {
                        this.setState({ visible_dlg_modif: false });
                    }
                    this.resultat(rep.code, rep.contenu);
                });
            }
        }
    }

    actionTemplate(rowData, column) {
        let disabled = false;
        if (this.xulaservice.get_statut_offline() === 'true') {
            disabled = true;
        }
        return <div className="p-grid" style={{ width: 90 }}>
            <div className="p-col"><Button disabled={disabled} onClick={(e) => this.edit_plan(rowData)} className="p-button-info" style={{ width: 25, height: 25 }} icon="pi pi-pencil" /></div>
            <div className="p-col"><Button disabled={disabled} onClick={(e) => this.conf_delete_plan(rowData)} className="p-button-danger" style={{ width: 25, height: 25 }} icon="pi pi-times" /></div>
        </div>
    }

    actionTemplateNew(rowData, column) {
        return <div className="p-col"><Button onClick={(e) => this.delete_plan_new(rowData)} className="p-button-danger" style={{ width: 25, height: 25 }} icon="pi pi-times" /></div>
    }


    delete_plan() {
        if (this.state.selectedPlan == null || this.state.selectedPlan == undefined) {
            this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez sélectionner la ligne à supprimer' });
        } else {
            this.xulaservice.delete_planif_prix(this.state.selectedPlan).then(data => this.resultat(data.code, data.contenu));
        }
    }

    delete_plan_new(data) {
        for (var i = this.state.liste_plan_selected.length; i--;) {
            if (data.idBoutiq.idBoutiq == this.state.liste_plan_selected[i].idBoutiq.idBoutiq) {
                this.state.liste_plan_selected.splice(i, 1);
                break;
            }
        }
    }

    conf_delete_plan(data) {
        if (data != null && data != undefined) {
            this.setState({ selectedPlan: data });
            this.setState({ msg: data.stockPlan });
            this.setState({ visible: true });
        }
    }

    articleItemTemplate(option) {
        if (!option.value) {
            return option.label;
        }
        else {
            return (
                <div className="p-clearfix">
                    <div className="p-grid">
                        <div className="p-col-12 p-md-8">
                            <label>{option.label}</label>
                        </div>
                        <div className="p-col-12 p-md-2">
                            <label>{option.prix_ref_vente_format}</label>
                        </div>
                        <div className="p-col-12 p-md-2" >
                            <label style={{ fontWeight: 'bold', color: 'red', marginRight: '5px' }}>{option.qte_stock_format}</label>
                        </div>
                    </div>
                </div>
            );
        }
    }

    render() {
        const dialogFooter = (
            <div>
                <Button icon="pi pi-check" onClick={() => {
                    this.setState({ visible: false });
                    this.delete_plan();
                }} label="Oui" />
                <Button icon="pi pi-times" onClick={() => this.setState({ visible: false })} label="Annuler" className="p-button-secondary" />
            </div>
        );
        return (
            <div className="p-fluid">
                <Growl ref={(el) => this.growl = el} />
                <Dialog header="Confirmation" visible={this.state.visible} style={{ width: '250px' }} modal={true} footer={dialogFooter} onHide={(e) => this.setState({ visible: false })}>
                    <div>
                        <i className="fa fa-warning" style={{ color: '#FFD133', fontSize: 25 }}></i>
                        Voulez vous vraiment supprimer la ligne sélectionnée ?
                    </div>
                </Dialog>
                <Dialog header="Modification" visible={this.state.visible_dlg_modif} style={{ width: '450px' }} modal={true} onHide={(e) => {
                    this.setState({ visible_dlg_modif: false });
                }} >
                    <div className="p-grid">
                        <div className="p-col-12 p-md-3">
                            <label htmlFor="libPrime">Article *</label>
                        </div>
                        <div className="p-col-12 p-md-9">
                            <InputText disabled={true} value={this.state.libArticle} onChange={(e) => this.setState({ libArticle: e.target.value })} />
                        </div>
                        <div className="p-col-12 p-md-3">
                            <label htmlFor="descPrime">Ancien prix vente</label>
                        </div>
                        <div className="p-col-12 p-md-9">
                            <Cleave value={this.state.ancienprixPlan} options={{ numeral: true, delimiter: ' ' }} onChange={(e) => this.setState({ ancienprixPlan: e.target.rawValue })} className="p-inputtext" />
                        </div>
                        <div className="p-col-12 p-md-3">
                            <label htmlFor="mtPrime">Nouveau prix vente *</label>
                        </div>
                        <div className="p-col-12 p-md-9">
                            <Cleave value={this.state.prixVentePlan} options={{ numeral: true, delimiter: ' ' }} onChange={(e) => this.setState({ prixVentePlan: e.target.rawValue })} className="p-inputtext" />
                        </div>
                        <div className="p-col-12 p-md-3">
                            <label htmlFor="mtPrime">Nouveau prix revient *</label>
                        </div>
                        <div className="p-col-12 p-md-9">
                            <Cleave value={this.state.prixRevientPlan} options={{ numeral: true, delimiter: ' ' }} onChange={(e) => this.setState({ prixRevientPlan: e.target.rawValue })} className="p-inputtext" />
                        </div>
                        <div className="p-col-12 p-md-3">
                            <label htmlFor="mtPrime">Stock *</label>
                        </div>
                        <div className="p-col-12 p-md-9">
                            <Cleave value={this.state.stockPlan} options={{ numeral: true, delimiter: ' ' }} onChange={(e) => this.setState({ stockPlan: e.target.rawValue })} className="p-inputtext" />
                        </div>
                    </div>
                    <hr />
                    <center>
                        <div className="p-grid" style={{ width: 300 }}>
                            <div className="p-col"><Button label="Annuler" icon="pi pi-ban" onClick={(e) => { this.setState({ visible_dlg_modif: false }) }} /></div>
                            <div className="p-col"><Button label="Valider" icon="pi pi-check" onClick={(e) => this.enreg_plan()} /></div>
                        </div>
                        {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : null}
                    </center>

                </Dialog>

                <Dialog header="Nouveau" visible={this.state.visible_dlg_new} style={{ width: '70%' }} modal={true} onHide={(e) => {
                    this.setState({ visible_dlg_new: false });
                }} >
                    <div className="p-grid" style={{ width: 350 }}>
                        <div className="p-col">
                            <Dropdown options={this.state.lprod} placeholder="Article" value={this.state.rid_article} optionLabel='label' filter={true} filterBy='label' filterMatchMode='contains' onChange={(e) => this.setState({ rid_article: e.value }, () => {
                                this.setState({ showindicator: true });
                                this.xulaservice.get_liste_planifprix_for_adding(this.state.rid_article.value).then(data => this.setState({ liste_plan_to_create: data, showindicator: false }));
                            })} autoWidth={false} style={{ width: '250px' }} />
                        </div>
                        <div className="p-col">
                            {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : null}
                        </div>
                    </div>
                    <DataTable value={this.state.liste_plan_to_create}
                        responsive={true} scrollable={true} scrollHeight="200px"
                        onSelectionChange={e => this.setState({ liste_plan_selected: e.value })}
                        responsive={true} selection={this.state.liste_plan_selected}   >

                        <Column selectionMode="multiple" style={{ width: '6%' }} />
                        <Column field="idBoutiq.nomBoutiq" header="Boutique" filter={true} filterMatchMode='contains' sortable={true} />
                        <Column field="stockPlan" header="Qté stock" filter={true} filterMatchMode='contains' sortable={true} style={{ width: '12%' }} editor={this.stockPlanEditor} />
                        <Column field="prixRevientPlan" body={(rowData, column) => {
                            let prixRevientPlan = '*****';
                            if (this.state.userconnected.profilUser === 'PDG') {
                                prixRevientPlan = this.formaterMontant(parseFloat(rowData.prixRevientPlan));
                            }
                            return <div>
                                <span style={{ color: '#000' }}>{prixRevientPlan}</span>
                            </div>
                        }} header="Prix revient" filter={true} filterMatchMode='contains' sortable={true} style={{ width: '15%' }} editor={this.prixRevientPlanEditor} />
                        <Column field="prixVentePlan" body={(rowData, column) => {
                            return <div>
                                <span style={{ color: '#000' }}>{this.formaterMontant(rowData.prixVentePlan)}</span>
                            </div>
                        }} header="Prix vente" filter={true} filterMatchMode='contains' sortable={true} style={{ width: '15%' }} editor={this.prixVentePlanEditor} />
                        <Column field="ancienprixPlan" body={(rowData, column) => {
                            return <div>
                                <span style={{ color: '#000' }}>{this.formaterMontant(rowData.ancienprixPlan)}</span>
                            </div>
                        }} header="Ancien prix" filter={true} filterMatchMode='contains' sortable={true} style={{ width: '15%' }} />
                    </DataTable>
                    <hr />
                    <center>
                        <div className="p-grid" style={{ width: 300 }}>
                            <div className="p-col"><Button label="Annuler" icon="pi pi-ban" onClick={(e) => { this.setState({ visible_dlg_new: false }) }} /></div>
                            <div className="p-col"><Button label="Valider" icon="pi pi-check" onClick={(e) => this.enreg_plan()} /></div>
                        </div>
                        {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : null}
                    </center>
                </Dialog>
                <div className="p-grid">
                    <div className="p-col-12">
                        <div className="card card-w-title" style={{ minHeight: '900px' }}>
                            <div className="p-grid">
                                <div className="p-col-12">
                                    <div className="p-grid">
                                        <div className="p-col-12 p-md-2">
                                            <Calendar value={this.state.pd1} dateFormat="dd/mm/yy" onChange={(e) => this.setState({ pd1: e.value })} />
                                        </div>
                                        <div className="p-col-12 p-md-2">
                                            <Calendar value={this.state.pd2} dateFormat="dd/mm/yy" onChange={(e) => this.setState({ pd2: e.value })} />
                                        </div>
                                        <div className="p-col-12 p-md-3">
                                            <Dropdown options={[{ value: 'Tous', label: 'Boutique' }].concat(this.state.lboutique)} placeholder="Boutique" value={this.state.ridBoutiq} optionLabel='label' filter={true} filterBy='label' filterMatchMode='contains' onChange={(e) => this.setState({ ridBoutiq: e.value }, () => {
                                                this.getliste_plan();
                                            })} autoWidth={false} />
                                        </div>
                                        <div className="p-col-12 p-md-3">
                                            <Dropdown options={[{ value: 'Tous', label: 'Article', cbArt: 'Tous' }].concat(this.state.lprod)} placeholder="Article" value={this.state.rid_article} optionLabel='label' filter={true} filterBy='label' filterMatchMode='contains' onChange={(e) => this.setState({ rid_article: e.value }, () => {
                                                this.getliste_plan();
                                            })} autoWidth={false} />
                                        </div>
                                        <div className="p-col-12 p-md-2">
                                            <div className="p-grid" style={{ width: 200 }}>
                                                <div className="p-col"><SplitButton label="Rechercher" style={{ width: '140px' }} onClick={(e) => this.getliste_plan()} icon="pi pi-search" model={this.state.items}></SplitButton></div>
                                                <div className="p-col">  {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : null}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <DataTable value={this.state.liste_plan}
                                responsive={true} scrollable={true} scrollHeight="500px" selectionMode="single"
                                onRowClick={e => this.setState({ selectedPlan: e.data })} header={<Button onClick={(e) => this.show_new()} label="Nouveu" style={{ width: 250 }} icon="pi pi-plus" />}
                                responsive={true} selection={this.state.selectedPlan} >
                                <Column header="#" body={this.actionTemplate.bind(this)} style={{ width: '10%' }} />
                                <Column field="idArticle.libArticle" header="Article" filter={true} filterMatchMode='contains' sortable={true} style={{ width: '28%' }} />
                                <Column field="prixRevientPlan" body={(rowData, column) => {
                                    let prixRevientPlan = '*****';
                                    if (this.state.userconnected.profilUser === 'PDG') {
                                        prixRevientPlan = this.formaterMontant(parseFloat(rowData.prixRevientPlan));
                                    }
                                    return <div>
                                        <span style={{ color: '#000' }}>{prixRevientPlan}</span>
                                    </div>
                                }} header="Prix revient" filter={true} filterMatchMode='contains' sortable={true} style={{ width: '12%' }} />
                                <Column field="prixVentePlan" body={(rowData, column) => {
                                    return <div>
                                        <span style={{ color: '#000' }}>{this.formaterMontant(rowData.prixVentePlan)}</span>
                                    </div>
                                }} header="Prix vente" filter={true} filterMatchMode='contains' sortable={true} style={{ width: '12%' }} />
                                <Column field="ancienprixPlan" body={(rowData, column) => {
                                    return <div>
                                        <span style={{ color: '#000' }}>{this.formaterMontant(rowData.ancienprixPlan)}</span>
                                    </div>
                                }} header="Ancien prix" filter={true} filterMatchMode='contains' sortable={true} style={{ width: '12%' }} />
                                <Column field="stockPlan" header="Stock" filter={true} filterMatchMode='contains' sortable={true} style={{ width: '8%' }} />
                                <Column field="idBoutiq.nomBoutiq" header="Boutique" filter={true} filterMatchMode='contains' sortable={true} style={{ width: '15%' }} />
                                <Column field="etatPlan" header="Etat" filter={true} filterMatchMode='contains' sortable={true} style={{ width: '12%' }} />
                                <Column field="dateEtatPlan" header="Date" filter={true} filterMatchMode='contains' sortable={true} style={{ width: '12%' }} />
                            </DataTable>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}