import React, { Component } from 'react';
import { Xulaservice } from '../service/Xulaservice';
import { InputText } from 'primereact/inputtext';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dialog } from 'primereact/dialog';
import { Growl } from 'primereact/growl';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import Cleave from 'cleave.js/react';
import { Calendar } from 'primereact/calendar';
import { ProgressSpinner } from 'primereact/progressspinner';
import Numeral from 'numeral'; import 'moment';
import 'moment/locale/fr';
import moment from 'moment';
import './styles.css';
import { InputTextarea } from 'primereact/inputtextarea';
import { FormSituation_client } from './FormSituation_client';
import { OverlayPanel } from 'primereact/overlaypanel';

export class FormOperationClient extends Component {
    constructor() {
        super();
        this.state = {
            pd1: new Date(),
            pd2: new Date(),
            libOpcli: null,
            idBoutiq: null,
            ridBoutiq: null,
            ridCli: null,
            rlibOpcli: null,
            montant_total: '0',
            liste_data: [],
            liste_data_global: [],
            liste_boutique: [],
            liste_client: [],
            lboutique: [],
            lboutique_rech: [],
            selectedData: null,
            idCli: null,
            idCli_situation: null,
            descOpcli: '',
            montantOpcli: 0,
            confmontantOpcli: 0,
            visible_situation_client: false,
            visible_dlg_situation_global: false,
            liste_operation: [
                { label: 'Dépot', value: 'Dépot', typeOp: 'ENTREE' },
                { label: 'Retrait espèce', value: 'Retrait espèce', typeOp: 'SORTIE' },
                { label: 'Emprunt', value: 'Emprunt', typeOp: 'SORTIE' },
            ],
            msg: '',
            modif: false,
            visible: false,
            userconnected: JSON.parse(localStorage.getItem('userconnected')),
            rfiltreClient: 'COMPTOIR',
            showindicator: false
        };
        this.conf_print_recu = this.conf_print_recu.bind(this);
        this.show_situation_client_dlg = this.show_situation_client_dlg.bind(this);
        this.show_dlg_situation_global = this.show_dlg_situation_global.bind(this);
        this.xulaservice = new Xulaservice();
    }

    show_dlg_situation_global() {
        this.setState({ visible_dlg_situation_global: true }, () => {
            this.get_liste_data_global();
        });
    }
    formaterMontant(montant) {
        return Numeral(parseFloat(montant)).format('0,0');
    }

    show_situation_client_dlg() {
        if (this.state.idCli_situation != null && this.state.idCli_situation != undefined) {
            if (this.state.idCli_situation.value != 'Tous') {
                this.setState({ visible_situation_client: true });
            } else {
                this.growl.show({ severity: 'error', summary: 'Error', detail: 'Veuillez sélectionner un client' });
            }
        } else {
            this.growl.show({ severity: 'error', summary: 'Error', detail: 'Veuillez sélectionner un client' });
        }
    }

    clientItemTemplate(option) {
        if (!option.value) {
            return option.label;
        }
        else {
            return (
                <div className="p-clearfix">
                    <div className="p-grid">
                        <div className="p-col-12 p-md-7">
                            <label>{option.label}</label>
                        </div>
                        <div className="p-col-12 p-md-2">
                            <label>{option.telCli}</label>
                        </div>
                        <div className="p-col-12 p-md-3">
                            <label style={{ fontWeight: 'bold' }}>{option.balance_format}</label>
                        </div>
                    </div>
                </div>
            );
        }
    }


    get_liste_boutique() {
        let lboutique = null;
        let lboutique_rech = [{ value: 'Tous', label: 'Boutique' }];
        this.xulaservice.getListe_boutique().then(data => this.setState({ liste_boutique: data }, () => {
            if (this.state.liste_boutique != null && this.state.liste_boutique != undefined) {
                lboutique = this.state.liste_boutique.map(x => {
                    return { value: x.idBoutiq, label: x.nomBoutiq }
                });
            }
            if (this.state.userconnected != null && this.state.userconnected != undefined) {
                this.setState({ idBoutiq: { value: this.state.userconnected.matrPers.idBoutiq.idBoutiq, label: this.state.userconnected.matrPers.idBoutiq.nomBoutiq } });
            }
            if (this.state.userconnected.profilUser === 'PDG' || this.state.userconnected.profilUser === 'Coordinateur') {
                this.setState({ lboutique: lboutique });
                lboutique_rech = lboutique_rech.concat(lboutique);
            } else {
                let id = this.state.userconnected.matrPers.idBoutiq.idBoutiq;
                if (lboutique.length > 0) {
                    let liste = lboutique.filter(function (el) {
                        return el.value === id;
                    });
                    this.setState({ lboutique: liste });
                    lboutique_rech = lboutique_rech.concat(liste);
                }
            }
            this.setState({ lboutique_rech: lboutique_rech })
        }));
    }

    componentDidMount() {
        this.get_liste_boutique();
        if (this.state.userconnected.matrPers.idBoutiq.typeBoutiq === 'Pharmacie') {
            this.setState({ rfiltreClient: 'Tous' }, () => {
                this.get_liste_client();
            })
        } else {
            if (this.state.userconnected.idEtabl.codeEtabl !== 'EDK') {
                this.setState({ rfiltreClient: 'Tous' }, () => {
                    this.get_liste_client();
                });
            }
        }
        this.get_liste_data();
    }


    get_liste_client() {
        this.setState({ showindicator: true });
        this.xulaservice.getListe_client(this.state.rfiltreClient).then(data => {
            this.setState({ liste_client: data, showindicator: false });
        });
    }

    get_liste_data_global() {
        this.setState({ showindicator: true });
        this.xulaservice.get_liste_operation_global_client().then(data => this.setState({ liste_data_global: data, showindicator: false }));
    }


    get_liste_data() {
        let rlib = 'Tous';
        let rid_client = 'Tous';
        let rid_boutique = 'Tous';
        this.setState({ showindicator: true });
        let spd1 = moment(this.state.pd1, 'DD/MM/YYYY').format('DD/MM/YYYY');
        let spd2 = moment(this.state.pd2, 'DD/MM/YYYY').format('DD/MM/YYYY');
        if (this.state.ridBoutiq != undefined && this.state.ridBoutiq != null) {
            rid_boutique = this.state.ridBoutiq.value;
        }
        if (this.state.rlibOpcli != undefined && this.state.rlibOpcli != null && this.state.rlibOpcli != '') {
            rlib = this.state.rlibOpcli.value;
        }
        if (this.state.ridCli != undefined && this.state.ridCli != null && this.state.ridCli != '') {
            rid_client = this.state.ridCli.value;
        }
        this.setState({ montant_total: '0' });
        this.xulaservice.get_liste_operation_client(spd1, spd2, rid_client, rid_boutique, rlib).then(data => this.setState({ liste_data: data, showindicator: false }, () => {
            if (data != null && data != undefined) {
                let initialValue = 0;
                let tt = data.reduce(function (accumulator, currentValue) {
                    return accumulator + currentValue.montantOpcli
                }, initialValue);
                this.setState({ montant_total: this.formaterMontant(tt) });
            }
        }));
    }


    resultat(code, contenu) {
        if (code == '200') {
            this.growl.show({ severity: 'success', summary: 'Success', detail: contenu });
            this.annule_data();
            this.get_liste_data();
            this.get_liste_client();
        } else {
            this.growl.show({ severity: 'error', summary: 'Error', detail: contenu });
        }
        this.setState({ showindicator: false });
    }

    annule_data() {
        this.setState({ montantOpcli: 0 });
        this.setState({ descOpcli: '' });
        this.setState({ confmontantOpcli: 0 });
        this.setState({ modif: false });
        this.forceUpdate();
    }

    conf_print_recu(data) {
        this.xulaservice.generer_edition_recu_operation_client(data.idOpcli);
    }

    edit_data(data) {
        if (data != null && data != undefined) {
            if (data.statutSyncOpcli === 'Envoyé' && this.xulaservice.get_statut_offline() == 'true') {
                this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Vous ne pouvez plus modifier cette dépense en mode offline car déja synchronisée' });
                return;
            }
            this.setState({ montantOpcli: data.montantOpcli });
            this.setState({ confmontantOpcli: data.confmontantOpcli });
            this.setState({ descOpcli: data.descOpcli });
        }
    }

    enreg_data() {
        if (this.xulaservice.get_statut_offline() == 'true' && this.state.libOpcli.value == 'Retrait espèce') {
            this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Vous ne pouvez faire cette opération en mode offline ' });
            return;
        }

        if (this.state.montantOpcli === '' || this.state.montantOpcli === undefined || this.state.montantOpcli === 0 || this.state.idCli === '' || this.state.idCli === undefined || this.state.idCli === null || this.state.libOpcli === undefined || this.state.libOpcli === null || this.state.libOpcli === '') {
            this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez saisir tous les champs obligatoires' });
        } else if (this.state.montantOpcli != this.state.confmontantOpcli) {
            this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez vérifier le montant saisi ' });
        } else if (this.state.idBoutiq === "" || this.state.idBoutiq === undefined || this.state.idBoutiq === null) {
            this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez sélectionner la boutique ' });
        }
        else {
            this.setState({ showindicator: true });
            let data = { idOpcli: '0', statutSyncOpcli: this.xulaservice.get_statut_sync(), typeOpcli: this.state.libOpcli.typeOp, descOpcli: this.state.descOpcli, montantOpcli: this.state.montantOpcli, refOpcli: '', libOpcli: this.state.libOpcli.value, idboutiqOpcli: { idBoutiq: this.state.idBoutiq }, idCli: { idCli: this.state.idCli.value } };
            this.xulaservice.enreg_operation_client(data).then(data => this.resultat(data.code, data.contenu));
        }
    }

    actionTemplate(rowData, column) {
        return <div className="p-grid" style={{ width: 50 }}>
            <div className="p-col"><Button onClick={(e) => this.conf_print_recu(rowData)} className="p-button-warning" style={{ width: 25, height: 25 }} icon="pi pi-print" /></div>
        </div>
    }

    render() {
        let lclient = null;
        let lclient_rech = [{ value: 'Tous', label: 'Toutes les clients', remiseCli: 0, soldeCli_format: '0', telCli: 'x', tvaCli: 0, balanceCli: 0, caventeCli: 0, balance_format: '0', label_rech: 'Tous' }];
        if (this.state.liste_client != null && this.state.liste_client != undefined) {
            lclient = this.state.liste_client.map(x => {
                return { value: x.idCli, label: x.nomCli, remiseCli: x.remiseCli, soldeCli_format: x.soldeCli_format, telCli: x.telCli, tvaCli: x.tvaCli, balanceCli: x.balanceCli, caventeCli: x.caventeCli, balance_format: this.formaterMontant(x.balanceCli), label_rech: x.nomCli + ' ' + x.idCli }
            });
            lclient_rech = lclient_rech.concat(lclient);
        }

        let loperation_rech = [{ value: 'Tous', label: 'Toutes les opérations' }];
        loperation_rech = loperation_rech.concat(this.state.liste_operation);

        const dialogFooter_situation_client = (
            <div>
                <Button icon="pi pi-times" onClick={() => this.setState({ visible_situation_client: false })} label="Fermer" className="p-button-secondary" />
            </div>
        );

        let ptotal = <label style={{ fontSize: 20, fontWeight: 'bold', color: 'red', marginRight: 10 }}> {this.state.montant_total} </label>

        let header_print = <div style={{ textAlign: 'left' }}>
            <Button icon='pi pi-print' className="p-button-warning" label='Exporter' style={{ width: '140px', }} onClick={(e) => this.dt.exportCSV()} />
            <Button type="button" icon="pi pi-search" iconPos="left" label="Situaton par client" style={{ width: 150 }} onClick={this.show_dlg_situation_global}></Button>
        </div>;

        return (
            <div className="p-fluid">
                <Growl ref={(el) => this.growl = el} />
                <Dialog header="Situation client" visible={this.state.visible_situation_client} style={{ width: '90%' }} modal={true} footer={dialogFooter_situation_client} onHide={(e) => this.setState({ visible_situation_client: false })}>
                    <FormSituation_client client={this.state.idCli_situation} />
                </Dialog>
                <OverlayPanel ref={(el) => { this.op = el; }} style={{ width: '250px' }} >
                    <div className="p-grid">
                        <div className="p-col-10">
                            <InputText value={this.state.rfiltreClient} id="id_ovp" onKeyDown={(e) => {
                                if (e.keyCode === 13) {
                                    this.op.toggle(e);
                                    document.getElementById("new_dp_client").click();
                                    this.get_liste_client();
                                }
                            }} onChange={(e) => this.setState({ rfiltreClient: e.target.value })} autoFocus={true} />
                        </div>
                        <div className="p-col-2">
                            <Button type="button" icon="pi pi-search" className="p-button-info" onClick={(e) => {
                                this.op.toggle(e);
                                document.getElementById("new_dp_client").click();
                                this.get_liste_client();
                            }} style={{ width: '30px' }} />
                        </div>
                    </div>
                </OverlayPanel>
                <OverlayPanel ref={(el) => { this.oprech = el; }} style={{ width: '250px' }} >
                    <div className="p-grid">
                        <div className="p-col-10">
                            <InputText value={this.state.rfiltreClient} onKeyDown={(e) => {
                                if (e.keyCode === 13) {
                                    this.op.toggle(e);
                                    document.getElementById("new_dp_client_rech").click();
                                    this.get_liste_client();
                                }
                            }} onChange={(e) => this.setState({ rfiltreClient: e.target.value })} autoFocus={true} />
                        </div>
                        <div className="p-col-2">
                            <Button type="button" icon="pi pi-search" className="p-button-info" onClick={(e) => {
                                this.op.toggle(e);
                                document.getElementById("new_dp_client_rech").click();
                                this.get_liste_client();
                            }} style={{ width: '30px' }} />
                        </div>
                    </div>
                </OverlayPanel>
                <Dialog header="Situation globale par client" visible={this.state.visible_dlg_situation_global} style={{ width: '50%' }} modal={true} onHide={(e) => this.setState({ visible_dlg_situation_global: false })}>
                    <div className="card card-w-title" style={{ minHeight: '400px' }}>
                        <DataTable value={this.state.liste_data_global}
                            paginatorPosition="top" paginator={true} rows={10} rowsPerPageOptions={[10, 20, 50, 100, 200]} paginatorLeft={<Button icon='pi pi-print' className="p-button-warning" label='Exporter' style={{ width: '140px', }} onClick={(e) => this.dtg.exportCSV()} />}
                            responsive={true} csvSeparator=";" ref={(el) => { this.dtg = el; }} paginatorRight={this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : null}
                        >
                            <Column field="ordre" header="#" style={{ width: '10%' }} filter={true} filterMatchMode='contains' sortable={true} />
                            <Column field="nomCli" header="Client" style={{ width: '60%' }} filter={true} filterMatchMode='contains' sortable={true} />
                            <Column field="montantOpcli" body={(rowData, column) => {
                                let color = '#000000';
                                if (parseFloat(rowData.montantOpcli) < 0) {
                                    color = 'red';
                                }
                                return <div>
                                    <span style={{ color: color, fontWeight: 'bold' }}>{this.formaterMontant(rowData.montantOpcli)}</span>
                                </div>
                            }} header="Montant" style={{ width: '30%' }} filter={true} filterMatchMode='contains' sortable={true} />
                        </DataTable>
                    </div>
                </Dialog>

                <div className="p-grid">
                    <div className="p-col-12">
                        <div className="card card-w-title" style={{ minHeight: '700px' }}>

                            <div className="p-grid">

                                <div className="p-col-12 p-md-2">
                                    <label htmlFor="adrCli">Client *</label>
                                </div>
                                <div className="p-col-12 p-md-4">
                                    <div className="p-grid">
                                        <div className="p-col-1">
                                            <Button type="button" icon="pi pi-search" className="p-button-info" onClick={this.show_situation_client_dlg} style={{ width: '30px',marginRight: -40 }} />
                                        </div>
                                        <div className="p-col-10">
                                            <Dropdown id="new_dp_client"
                                                options={lclient}
                                                value={this.state.idCli}
                                                placeholder="Client"
                                                onChange={(e) => {
                                                    this.setState({ idCli: e.value });
                                                }} itemTemplate={(option) => {
                                                    if (!option.value) {
                                                        return option.label;
                                                    }
                                                    else {
                                                        return (
                                                            <div className="p-grid">
                                                                <div className="p-col-10">
                                                                    <label>{option.label}</label>
                                                                </div>
                                                                {option.balanceCli >= 0 ?
                                                                    <div className="p-col-2">
                                                                        <label style={{ fontWeight: 'bold', color: 'green', marginRight: '5px' }}>{option.balance_format}</label>
                                                                    </div>
                                                                    :
                                                                    <div className="p-col-2">
                                                                        <label style={{ fontWeight: 'bold', color: 'red', marginRight: '5px' }}>{option.balance_format}</label>
                                                                    </div>
                                                                }
                                                            </div>
                                                        );
                                                    }
                                                }}
                                                style={{ width: '100%' }} panelStyle={{ width: '500px' }}
                                                optionLabel='label_rech' filter={true} filterBy='label' filterMatchMode='contains'
                                            />
                                        </div>
                                        <div className="p-col-1">
                                            <Button type="button" icon="pi pi-search" className="p-button-warning" onClick={(e) => {
                                                this.op.toggle(e);
                                            }} style={{ marginLeft: -15, width: '30px' }} />
                                        </div>
                                    </div>

                                </div>
                                <div className="p-col-12 p-md-2">
                                    <label htmlFor="adrCli">Opération *</label>
                                </div>
                                <div className="p-col-12 p-md-4">
                                    <Dropdown value={this.state.libOpcli} options={this.state.liste_operation} optionLabel='label' panelStyle={{ width: '500px' }} filter={true} filterBy='label' onChange={(e) => this.setState({ libOpcli: e.value })} autoWidth={false} />
                                </div>
                                <div className="p-col-12 p-md-2">
                                    <label htmlFor="adrCli">Montant *</label>
                                </div>
                                <div className="p-col-12 p-md-4">
                                    <Cleave value={this.state.montantOpcli} options={{ numeral: true, delimiter: ' ' }} onChange={(e) => this.setState({ montantOpcli: e.target.rawValue })} className="p-inputtext" />
                                </div>
                                <div className="p-col-12 p-md-2">
                                    <label htmlFor="adrCli">Confirmer Montant *</label>
                                </div>
                                <div className="p-col-12 p-md-4">
                                    <Cleave value={this.state.confmontantOpcli} options={{ numeral: true, delimiter: ' ' }} onChange={(e) => this.setState({ confmontantOpcli: e.target.rawValue })} className="p-inputtext" />
                                </div>
                                <div className="p-col-12 p-md-2">
                                    <label htmlFor="idBoutiq">Boutique *</label>
                                </div>
                                <div className="p-col-12 p-md-4">
                                    <Dropdown value={this.state.idBoutiq} options={this.state.lboutique} filter={true} filterBy='label' onChange={(e) => this.setState({ idBoutiq: e.value }, () => {
                                        // alert(this.state.idBoutiq);
                                    })} autoWidth={false} />
                                </div>
                                <div className="p-col-12 p-md-2">
                                    <label htmlFor="cbArticle">Commentaire </label>
                                </div>
                                <div className="p-col-12 p-md-4">
                                    <InputTextarea rows={2} cols={30} id="cbArticle" value={this.state.descOpcli} onChange={(e) => this.setState({ descOpcli: e.target.value })} />
                                </div>
                            </div>

                            <center>
                                <div className="p-grid" style={{ width: 300 }}>
                                    <div className="p-col"><Button label="Annuler" icon="pi pi-ban" onClick={(e) => this.annule_data()} /></div>
                                    <div className="p-col"><Button label="Valider" icon="pi pi-check" onClick={(e) => this.enreg_data()} /></div>
                                </div>
                                {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : null}

                            </center>

                            <div className="p-grid">
                                <div className="p-col-12">
                                    <div className="p-grid">
                                        <div className="p-col-12 p-md-2">
                                            <Calendar value={this.state.pd1} dateFormat="dd/mm/yy" onChange={(e) => this.setState({ pd1: e.value })} />
                                        </div>
                                        <div className="p-col-12 p-md-2">
                                            <Calendar value={this.state.pd2} dateFormat="dd/mm/yy" onChange={(e) => this.setState({ pd2: e.value })} />
                                        </div>
                                        <div className="p-col-12 p-md-2">
                                            <div className="p-grid">
                                                <div className="p-col-10">
                                                    <Dropdown id="new_dp_client_rech"
                                                        options={lclient_rech}
                                                        value={this.state.ridCli}
                                                        placeholder="Client"
                                                        onChange={(e) => {
                                                            this.setState({ ridCli: e.value, idCli_situation: e.value });
                                                        }} itemTemplate={(option) => {
                                                            if (!option.value) {
                                                                return option.label;
                                                            }
                                                            else {
                                                                return (
                                                                    <div className="p-grid">
                                                                        <div className="p-col-10">
                                                                            <label>{option.label}</label>
                                                                        </div>
                                                                        {option.balanceCli >= 0 ?
                                                                            <div className="p-col-2">
                                                                                <label style={{ fontWeight: 'bold', color: 'green', marginRight: '5px' }}>{option.balance_format}</label>
                                                                            </div>
                                                                            :
                                                                            <div className="p-col-2">
                                                                                <label style={{ fontWeight: 'bold', color: 'red', marginRight: '5px' }}>{option.balance_format}</label>
                                                                            </div>
                                                                        }
                                                                    </div>
                                                                );
                                                            }
                                                        }}
                                                        style={{ width: '100%' }} panelStyle={{ width: '500px' }}
                                                        optionLabel='label_rech' filter={true} filterBy='label' filterMatchMode='contains'
                                                    />
                                                </div>
                                                <div className="p-col-1">
                                                    <Button type="button" icon="pi pi-search" className="p-button-warning" onClick={(e) => {
                                                        this.op.toggle(e);
                                                    }} style={{ marginLeft: -15, width: '30px' }} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="p-col-12 p-md-2">
                                            <Dropdown options={loperation_rech} placeholder="Opération" value={this.state.rlibOpcli} optionLabel='label' filter={true} filterBy='label' filterMatchMode='contains' onChange={(e) => this.setState({ rlibOpcli: e.value })} autoWidth={false} />
                                        </div>
                                        <div className="p-col-12 p-md-2">
                                            <Dropdown options={this.state.lboutique_rech} placeholder="Boutique" value={this.state.ridBoutiq} optionLabel='label' filter={true} filterBy='label' filterMatchMode='contains' onChange={(e) => this.setState({ ridBoutiq: e.value })} autoWidth={false} />
                                        </div>
                                        <div className="p-col-12 p-md-2">
                                            <div className="p-inputgroup">
                                                <Button icon='pi pi-search' label='Rechercher' style={{ width: '140px' }} onClick={(e) => this.get_liste_data()} />
                                                {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : null}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <DataTable value={this.state.liste_data}
                                paginatorPosition="top" paginator={true} rows={10} rowsPerPageOptions={[10, 20, 50, 100, 200]} paginatorRight={ptotal} paginatorLeft={header_print}
                                selectionMode="single" onRowClick={e => this.setState({ selectedData: e.data })}
                                responsive={true} selection={this.state.selectedData} csvSeparator=";" ref={(el) => { this.dt = el; }}>
                                <Column header="#" body={this.actionTemplate.bind(this)} style={{ width: '5%' }} />
                                <Column field="idOpcli" header="N°" style={{ width: '8%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                <Column field="dateOpcli" header="Date" style={{ width: '8%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                <Column field="libOpcli" header="Opération" style={{ width: '14%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                <Column field="montantOpcli" body={(rowData, column) => {
                                    return <div>
                                        <span style={{ color: '#000000', fontWeight: 'bold' }}>{this.formaterMontant(rowData.montantOpcli)}</span>
                                    </div>
                                }} header="Montant" style={{ width: '10%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                <Column field="idCli.nomCli" header="Client" style={{ width: '19%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                <Column field="idboutiqOpcli.nomBoutiq" header="Boutique" style={{ width: '12%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                <Column field="descOpcli" header="Commentaire" style={{ width: '17%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                <Column field="statutSyncOpcli" body={(rowData, column) => {
                                    if (rowData.statutSyncOpcli == 'Envoyé') {
                                        return <div>
                                            <Button className="p-button-success" style={{ height: 25, fontWeight: 'bold' }} label='ENV' />
                                        </div>
                                    } else {
                                        return <div>
                                            <Button className="p-button-warning" style={{ height: 25, fontWeight: 'bold' }} label='EC' />
                                        </div>
                                    }
                                }} header="Sync" style={{ width: '6%' }} filter={true} filterMatchMode='contains' sortable={true} />


                            </DataTable>

                        </div>
                    </div>
                </div>
            </div>
        );
    }
}