import React, { Component } from 'react';
import { InputText } from 'primereact/inputtext';
import { Button } from "primereact/button";
import { Xulaservice } from '../service/Xulaservice';
import { ProgressSpinner } from 'primereact/progressspinner';
import { InputSwitch } from 'primereact/inputswitch';
import 'moment';
import 'moment/locale/fr';
import moment, { utc } from 'moment';

export default class Login extends Component {
	constructor() {
		super();
		this.state = {
			login: '',
			pwd: '',
			msg: '',
			showindicator: false,
			offline: false,
			libmod: 'ON LINE',
		};
		this.identification = this.identification.bind(this);
		this.myservice = new Xulaservice();
	}

	handleKeyPress = e => {
		if (e.keyCode === 13) {
			this.identification();
		}
	}



	identification() {

		if (this.state.offline == false && localStorage.getItem('srv_url') == null) {
			localStorage.setItem('srv_url', this.myservice.get_wsurl('Remote'));
		}

		if (this.state.login == '' || this.state.pwd == '') {
			this.setState({ msg: 'Veuilllez saisir le login et le mot de passe' });
		} else {

			this.setState({ showindicator: true });
			this.myservice.get_identification(this.state.login, this.state.pwd).then(data => {
				if (data != null && data != undefined && data != '' && data.idUser != undefined) {
					if ([711, 471, 371,731].includes(data.idEtabl.idEtabl) == true) {
						this.setState({ msg: "Ce compte n'existe pas" });
						this.setState({ showindicator: false });
						return;
					}


					if (new Date() > moment(data.idEtabl.delaiEtabl, 'DD/MM/YYYY')) {
						this.setState({ msg: "Votre abonnement a expiré merci de contacté OPTION+ au 610 40 40 40 " });
						this.setState({ showindicator: false });
						return;
					}
					if (data.actifUser == 'Oui') {
						localStorage.setItem('userconnected', JSON.stringify(data));
						if (data.idEtabl.domaineEtabl === 'Pharmacie') {
							//this.changeTheme('green-light');
						}
						localStorage.setItem('token', data.token);
						this.myservice.get_last_msg(data.idUser, data.token).then(ret => localStorage.setItem('message', JSON.stringify(ret)));

						if (data.profilUser == 'DRH') {
							window.location = "#/dashboardrh";
						} else if (data.profilUser == 'Recouvreur') {
							window.location = "#/dashboardrcv";
						} else if (data.profilUser == 'Magasinier' || data.profilUser == 'Producteur') {
							window.location = "#/dashboardgas";
						} else {
							window.location = "#/dashboard";
						}
						this.setState({ showindicator: false });
					} else {
						this.setState({ showindicator: false });
						this.setState({ msg: "Vous n'etes pas autorisés à vous connecter, merci de contacter l'administrateur " });
					}
				} else {
					this.setState({ showindicator: false });
					this.setState({ msg: 'Login ou mot de passe incorrects' });
				}
			});
		}
	}

	render() {
		return <div className="login-body">
			<div className="body-container">
				<div className="p-grid">
					<div className="p-col-12 p-lg-6 left-side">
						<img src="assets/layout/images/logo_xula.png" style={{ width: '200px', height: '250px' }} alt="logo" />
						{/* <img src="assets/layout/images/logo_edk_2.png" style={{ width: '120px', height: '320px' }} alt="logo" /> */}
						<h1 style={{ paddingTop: 0, color: '#fff' }}>Bienvenue</h1>
						<p style={{ backgroundColor: '#FFC300', width: 500, padding: 5, color: '#000', fontWeight: 'bold' }}>
							Gestion de stock centralisée (votre boutique dans votre pôche)
						</p>
					</div>
					<div className="p-col-12 p-lg-6 right-side">
						<div className="login-wrapper">
							<div className="login-container">
								<span className="title">Identification</span>
								<div className="p-grid p-fluid">

									<div className="p-col-12">
										<InputText onKeyDown={this.handleKeyPress} value={this.state.login} onChange={(e) => this.setState({ login: e.target.value })} placeholder="Login" />
									</div>
									<div className="p-col-12">
										<InputText onKeyDown={this.handleKeyPress} value={this.state.pwd} onChange={(e) => this.setState({ pwd: e.target.value })} type="password" placeholder="Mot de passe" />
									</div>

									{this.myservice.get_serveur() == 'Local' ?
										<div className="p-col-12">
											<div className="p-grid p-fluid">
												<div className="p-col-7">
													<label htmlFor="libArticle">Mode connexion * ({this.state.libmod})</label>
												</div>
												<div className="p-col-5">
													<InputSwitch checked={this.state.offline} onChange={(e) => {
														this.setState({ offline: e.value }, () => {
															localStorage.removeItem('srv_url');
															localStorage.setItem('offline', e.value);
															if (this.state.offline == true) {
																localStorage.setItem('srv_url', this.myservice.get_wsurl('Local'));
																this.setState({ libmod: 'OFF LINE' });
															} else {
																localStorage.setItem('srv_url', this.myservice.get_wsurl('Remote'));
																this.setState({ libmod: 'ON LINE' });
															}
														});
													}}></InputSwitch>
												</div>
											</div>
										</div>
										: null}
									<div className="p-col-6">
										<Button label="Connexion" icon="pi pi-sign-in" onClick={() => { this.identification() }} />
										{this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px', marginTop: '10px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : null}
										<label style={{ color: 'red', marginTop: 10 }} >{this.state.msg}</label>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	}
}