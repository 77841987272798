import React, { Component } from 'react';
import { Xulaservice } from '../service/Xulaservice';
import { InputText } from 'primereact/inputtext';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Growl } from 'primereact/growl';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import './styles.css';
import { Panel } from 'primereact/panel';
import { ProgressSpinner } from 'primereact/progressspinner';
import 'moment';
import 'moment/locale/fr';
import moment from 'moment';
import Numeral from 'numeral';
import Cleave from 'cleave.js/react';


export class FormSituation_client extends Component {
    constructor() {
        super();
        this.state = {
            pd1: new Date(),
            pd2: new Date(),
            liste_operation: [],
            liste_facture_gnle: [],
            liste_facture_non_regle: [],
            liste_sortie_for_reglement_selecteds: [],
            nomCli: '',
            idCli: '0',
            telCli: '',
            soldeCli_format: '0',
            balanceCli_format: '0',
            balanceCli: 0,
            caventeCli: '0',
            total_restant: '0',
            color_balance: 'black',
            selectedOperation: null,
            selectedSortie: null,
            libOpcli: null,
            userconnected: JSON.parse(localStorage.getItem('userconnected')),
            modeRgl: { value: 'Espèce', label: 'Espèce' },
            total_reglement_format: '0',
            mtrglSortie: 0,
            montantOpcli: 0,
            confmontantOpcli: 0,
            liste_mode_reglement: [
                { label: 'Espèce', value: 'Espèce' },
                { label: 'Chèque', value: 'Chèque' },
                { label: 'Virement', value: 'Virement' },
                { label: 'Compte', value: 'Compte' },
                { label: 'Orange Money', value: 'Orange Money' },
                { label: 'Momo', value: 'Momo' },
            ],
            liste_type_operation: [
                { label: 'Dépot', value: 'Dépot', typeOp: 'ENTREE' },
                { label: 'Retrait espèce', value: 'Retrait espèce', typeOp: 'SORTIE' },
                { label: 'Emprunt', value: 'Emprunt', typeOp: 'SORTIE' },
            ],
        }
        this.conf_print_recu = this.conf_print_recu.bind(this);
        this.conf_print_facture = this.conf_print_facture.bind(this);
        this.conf_print_situation = this.conf_print_situation.bind(this);
        this.xulaservice = new Xulaservice();
    }

    componentDidMount() {
        var startdate = moment();
        this.setState({ pd1: startdate.subtract(180, "days").format("DD/MM/YYYY") });

    }

    statutTemplate(rowData, column) {
        return <div>
            <span style={{ color: '#000' }}>{rowData.statutLivraison}</span><br />
        </div>
    }

    factureTemplate(rowData, column) {
        return <div>
            <span style={{ color: '#F34C1F', fontWeight: 'bold' }}>{rowData.numFactureSortie}</span><br />
            <span style={{ color: '#000' }}>{rowData.dateSortie_format}</span>
        </div>
    }

    montantTemplate(rowData, column) {
        return <div>
            <span style={{ color: '#000', fontWeight: 'bold' }}>{rowData.montant_sortie_format}</span><br />
            <span style={{ color: 'red' }}>{rowData.mt_restant_format}</span>
        </div>
    }


    formaterMontant(montant) {
        return Numeral(parseFloat(montant)).format('0,0');
    }


    conf_print_recu(data) {
        this.xulaservice.generer_edition_recu_operation_client(data.idOpcli);
    }

    conf_print_facture(data, format) {
        this.xulaservice.generer_edition_facture(data.idSortie, data.typeSortie, format);
    }

    conf_print_situation(format) {
        if (this.state.r === null || this.state.idCli === undefined || this.state.idCli === '0') {
            this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez sélectionner un client' });
            return;
        }
        this.xulaservice.generer_compte_client(this.state.idCli, format);
    }

    actionTemplate_recu(rowData, column) {
        return <div className="p-grid" style={{ width: 50 }}>
            <div className="p-col"><Button onClick={(e) => this.conf_print_recu(rowData)} className="p-button-warning" style={{ width: 25, height: 25, marginLeft: -8 }} icon="pi pi-print" />
            </div>
        </div>
    }

    actionTemplate_facture(rowData, column) {
        return <div className="p-grid" style={{ width: 50 }}>
            <div className="p-col"><Button onClick={(e) => this.conf_print_facture(rowData, 'A4')} className="p-button-warning" style={{ width: 25, height: 25, marginLeft: -8 }} icon="pi pi-print" />
            </div>
        </div>
    }



    enreg_operation_client() {
        if (this.xulaservice.get_statut_offline() == 'true' && this.state.libOpcli.value == 'Retrait espèce') {
            this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Vous ne pouvez faire cette opération en mode offline ' });
            return;
        }
        if (this.state.montantOpcli === '' || this.state.montantOpcli === undefined || this.state.montantOpcli === 0 || this.state.idCli === '' || this.state.idCli === undefined || this.state.idCli === null || this.state.idCli === '0' || this.state.libOpcli === undefined || this.state.libOpcli === null) {
            this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez saisir tous les champs obligatoires' });
        } else {
            let data = { idOpcli: '0', statutSyncOpcli: this.xulaservice.get_statut_sync(), typeOpcli: this.state.libOpcli.typeOp, descOpcli: '', montantOpcli: this.state.montantOpcli, refOpcli: '', libOpcli: this.state.libOpcli.value, idboutiqOpcli: { idBoutiq: this.state.userconnected.matrPers.idBoutiq.idBoutiq }, idCli: { idCli: this.state.idCli } };
            this.xulaservice.enreg_operation_client(data).then(data => {
                if (data.code == '200') {
                    this.growl.show({ severity: 'success', summary: 'Success', detail: data.contenu });
                    this.get_liste_data();
                    this.setState({ montantOpcli: 0 });
                    this.setState({ confmontantOpcli: 0 });
                } else {
                    this.growl.show({ severity: 'error', summary: 'Error', detail: data.contenu });
                }
            });
        }
    }

    enreg_reglSortie_bloc() {
        if (this.state.liste_sortie_for_reglement_selecteds === null || this.state.liste_sortie_for_reglement_selecteds === undefined) {
            this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez saisir tous les champs obligatoires et sélectionner les factures à régler' });
        } else {
            if (this.state.liste_sortie_for_reglement_selecteds.length <= 0) {
                this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez sélectionner les factures à régler' });
                return;
            } else if (this.state.idCli === null || this.state.idCli === undefined || this.state.idCli === '' || this.state.idCli === '0') {
                this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez sélectionner un client' });
                return;
            }
            if (this.xulaservice.get_statut_offline() === 'true' && this.state.modeRgl.value === 'Compte') {
                this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Vous ne pouvez régler par compte en mode offline' });
                return;
            }
            this.xulaservice.regler_sortie_bloc(this.state.liste_sortie_for_reglement_selecteds, this.state.modeRgl.value, '', this.xulaservice.get_statut_sync()).then(reponse => {
                if (reponse != null && reponse != undefined) {
                    if (reponse.code === 200) {
                        this.setState({ total_reglement_format: '0' });
                        this.setState({ liste_sortie_for_reglement_selecteds: [] });
                        this.get_liste_data();
                        this.growl.show({ severity: 'success', summary: 'Success', detail: reponse.contenu });
                    } else {
                        this.growl.show({ severity: 'error', summary: 'Error', detail: reponse.contenu });

                    }
                } else {
                    this.growl.show({ severity: 'error', summary: 'Error', detail: 'Erreur inconnue' });
                }
            });
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.client != this.props.client) {
            if (this.props != undefined) {
                let client = this.props.client;
                if (client != null && client != undefined && client != '') {
                    this.setState({ nomCli: client.label });
                    this.setState({ telCli: client.telCli });
                    this.setState({ soldeCli_format: client.soldeCli_format });
                    this.setState({ balanceCli_format: this.formaterMontant(client.balanceCli) });
                    this.setState({ balanceCli: client.balanceCli });
                    this.setState({ idCli: client.value });
                    this.setState({ caventeCli: this.formaterMontant(client.caventeCli) });
                }
            }
            this.get_liste_data();
        }
    }

    get_liste_data() {
        if (this.props.client != null && this.props.client != undefined) {
            this.setState({ showindicator: true });
            let spd1 = moment(this.state.pd1, 'DD/MM/YYYY').format('DD/MM/YYYY');
            let spd2 = moment(this.state.pd2, 'DD/MM/YYYY').format('DD/MM/YYYY');
            this.xulaservice.get_situation_client(spd1, spd2, this.props.client.value).then(data => {
                this.setState({ showindicator: false });
                if (data !== undefined && data !== null) {
                    this.setState({ liste_operation: data.liste_operation });
                    this.setState({ liste_facture_gnle: data.liste_facture });
                    let liste_facture = data.liste_facture;
                    if (liste_facture != null && liste_facture != undefined) {
                        if (liste_facture.length > 0) {
                            let liste = liste_facture.filter(function (el) {
                                return el.mt_restant > 0;
                            });
                            this.setState({ liste_facture_non_regle: liste });
                        }
                    }
                    let client = data.client;
                    this.setState({ nomCli: client.nomCli });
                    this.setState({ telCli: client.telCli });
                    this.setState({ soldeCli_format: client.soldeCli_format });
                    this.setState({ balanceCli_format: this.formaterMontant(client.balanceCli) });
                    this.setState({ balanceCli: client.balanceCli });
                    this.setState({ idCli: client.idCli });
                    this.setState({ caventeCli: this.formaterMontant(client.caventeCli) });
                }
            });
        }
    }

    render() {
        const footer_rgl = <label style={{ color: 'red', fontSize: '18px', fontWeight: 'bold' }} htmlFor="in">{this.state.total_reglement_format}</label>

        let color_balance = 'black';
        if (this.state.balanceCli < 0) {
            color_balance = 'red';
        } else if (this.state.balanceCli > 0) {
            color_balance = 'green';
        }
        return (
            <div className="p-fluid">
                <Growl ref={(el) => this.growl = el} />
                <div className="p-grid">
                    <div className="p-col-12">
                        <Panel style={{ height: 70 }}>

                            <div className="p-grid">
                                <div className="p-col-12 p-lg-5">
                                    <div className="p-grid">
                                        <div className="p-col-12">
                                            <div className="p-grid">
                                                <div className="p-col-12 p-md-2">
                                                    <img src="assets/layout/images/avatar/avatar.png" style={{ height: '50px', width: '50px' }} />
                                                </div>
                                                <div className="p-col-12 p-md-10">
                                                    <label style={{ fontSize: 22, fontWeight: 'bold' }}>{this.state.nomCli}</label>
                                                    <br />
                                                    <label style={{ fontSize: 16 }}>{this.state.telCli}</label>
                                                </div>

                                            </div>

                                        </div>
                                    </div>
                                </div>

                                <div className="p-col-12 p-lg-7">
                                    <div className="p-grid">
                                        <div className="p-col-12">
                                            <div className="p-grid">
                                                <div className="p-col-12 p-md-1">
                                                    <label style={{ fontSize: 22, color: '#C9C9C8' }}>Solde</label>
                                                </div>
                                                <div className="p-col-12 p-md-3">
                                                    <label style={{ fontSize: 22, fontWeight: 'bold' }}>{this.state.soldeCli_format}</label>
                                                </div>
                                                <div className="p-col-12 p-md-2">
                                                    <label style={{ fontSize: 22, color: '#C9C9C8' }}>Balance</label>
                                                </div>
                                                <div className="p-col-12 p-md-2">
                                                    <label style={{ fontSize: 22, fontWeight: 'bold', color: color_balance }}>{this.state.balanceCli_format}</label>
                                                </div>
                                                <div className="p-col-12 p-md-1">
                                                    <label style={{ fontSize: 22, color: '#C9C9C8' }}>CA</label>
                                                </div>
                                                <div className="p-col-12 p-md-3">
                                                    <label style={{ fontSize: 22, fontWeight: 'bold', color: '#115EA7' }}>{this.state.caventeCli}</label>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Panel>
                    </div>
                </div>

                <div className="p-grid">
                    <div className="p-col-12">
                        <div className="p-grid">
                            <div className="p-col-12 p-md-2">
                                <Calendar value={this.state.pd1} dateFormat="dd/mm/yy" onChange={(e) => this.setState({ pd1: e.value })} />
                            </div>
                            <div className="p-col-12 p-md-2">
                                <Calendar value={this.state.pd2} dateFormat="dd/mm/yy" onChange={(e) => this.setState({ pd2: e.value })} />
                            </div>
                            <div className="p-col-12 p-md-3">
                                <div className="p-inputgroup">
                                    <Button icon='pi pi-search' label='Rechercher' style={{ width: '140px' }} onClick={(e) => this.get_liste_data()} />
                                    {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : null}
                                </div>
                            </div>
                            <div className="p-col-12 p-md-2">
                                <Button icon='pi pi-print' className="p-button-warning" label='Compte' style={{ width: '140px', }} onClick={(e) => this.conf_print_situation('PDF')} />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="p-grid">
                    <div className="p-col-12 p-lg-4">
                        <div className="p-grid">
                            <div className="p-col-12">
                                <Panel style={{ height: 370, marginBottom: '5px' }}>
                                    <i className="fa fa-fw fa-exchange"></i>Mouvements du compte
                                    <hr />
                                    <DataTable value={this.state.liste_operation} responsive={true} paginatorPosition="top" paginator={true} rows={6}
                                        selectionMode="single" onRowClick={e => this.setState({ selectedOperation: e.data })} selection={this.state.selectedOperation}>
                                        <Column header="#" body={this.actionTemplate_recu.bind(this)} style={{ width: '10%' }} />
                                        <Column field="dateOpcli" header="Date" style={{ width: '25%' }} />
                                        <Column body={(rowData, column) => {
                                            return <div>
                                                <span >{rowData.libOpcli}</span>
                                                <br />
                                                <span style={{ color: '#000000', fontWeight: 'bold' }}>{this.formaterMontant(rowData.montantOpcli)}</span>
                                            </div>
                                        }} header="Opération" style={{ width: '35%' }} />
                                        <Column header="Boutique" style={{ width: '40%' }} body={(rowData, column) => {
                                            let nomBoutiq = "";
                                            if (rowData.idboutiqOpcli != undefined) {
                                                nomBoutiq = rowData.idboutiqOpcli.nomBoutiq;
                                            }
                                            return <div>
                                                <span >{nomBoutiq}</span>
                                            </div>
                                        }} />
                                    </DataTable>

                                </Panel>

                                {this.state.userconnected.profilUser !== 'Recouvreur' ?
                                    <>
                                        <i className="fa fa-fw fa-briefcase" ></i>Nouveau mouvement
                                        <div className="p-grid" >
                                            <div className="p-col-12 p-md-5">
                                                <Cleave value={this.state.montantOpcli} placeholder="Montant" options={{ numeral: true, delimiter: ' ' }} onChange={(e) => this.setState({ montantOpcli: e.target.rawValue })} className="p-inputtext" />
                                            </div>
                                            <div className="p-col-12 p-md-4">
                                                <Dropdown placeholder="Opération" options={this.state.liste_type_operation} value={this.state.libOpcli} optionLabel='label' onChange={(e) => {
                                                    this.setState({ libOpcli: e.value })
                                                }
                                                } autoWidth={false} />
                                            </div>
                                            <div className="p-col-12 p-md-3">
                                                <Button label="Valider" style={{ width: '90px' }} icon="pi pi-check" onClick={(e) => this.enreg_operation_client()} />
                                            </div>
                                        </div>
                                    </>
                                    : null}

                            </div>
                        </div>
                    </div>

                    <div className="p-col-12 p-lg-4">
                        <div className="p-grid">
                            <div className="p-col-12">
                                <Panel style={{ height: 415 }}>
                                    <i className="fa fa-fw fa-list"></i>Liste générale facture
                                    <hr />
                                    <DataTable value={this.state.liste_facture_gnle} paginatorPosition="top" paginator={true} rows={6}
                                        responsive={true} selectionMode="single" onRowClick={e => this.setState({ selectedSortie: e.data })} selection={this.state.selectedSortie} >
                                        <Column header="#" body={this.actionTemplate_facture.bind(this)} style={{ width: '10%' }} />
                                        <Column body={this.factureTemplate} header="Facture" style={{ width: '40%' }} />
                                        <Column body={this.montantTemplate} header="Montant" style={{ width: '25%' }} />
                                        <Column body={this.statutTemplate} header="Statut" style={{ width: '25%' }} />
                                    </DataTable>
                                </Panel>
                            </div>
                        </div>
                    </div>


                    <div className="p-col-12 p-lg-4">
                        <div className="p-grid">
                            <div className="p-col-12">
                                <Panel style={{ height: 370, marginBottom: '5px' }}>
                                    <i className="fa fa-fw fa-credit-card"></i>Factures non soldées
                                    <hr />
                                    <DataTable value={this.state.liste_facture_non_regle} paginatorPosition="top" paginator={true} rows={6} footer={footer_rgl}
                                        onSelectionChange={e => {
                                            this.setState({ total_reglement_format: '0' });
                                            this.setState({ liste_sortie_for_reglement_selecteds: e.value }, () => {
                                                let total = 0;
                                                if (this.state.liste_sortie_for_reglement_selecteds != null && this.state.liste_sortie_for_reglement_selecteds != undefined) {
                                                    if (this.state.liste_sortie_for_reglement_selecteds.length > 0) {
                                                        for (var i = this.state.liste_sortie_for_reglement_selecteds.length; i--;) {
                                                            total += this.state.liste_sortie_for_reglement_selecteds[i].mt_restant;
                                                        }
                                                    }
                                                }
                                                this.setState({ total_reglement_format: this.formaterMontant(total) });
                                            });
                                        }
                                        } selection={this.state.liste_sortie_for_reglement_selecteds}
                                        responsive={true}  >
                                        <Column selectionMode="multiple" style={{ width: '10%' }} />
                                        <Column body={this.factureTemplate} header="Facture" style={{ width: '40%' }} />
                                        <Column body={this.montantTemplate} header="Montant" style={{ width: '30%' }} />
                                        <Column body={this.statutTemplate} header="Statut" style={{ width: '20%' }} />
                                    </DataTable>
                                </Panel>
                                {this.state.userconnected.profilUser !== 'Recouvreur' ?
                                    <>
                                        <i className="fa fa-fw fa-briefcase" ></i>Payement
                                        <div className="p-grid" >
                                            <div className="p-col-12 p-md-5">
                                                <InputText placeholder="Référence" value={this.state.refRgl} onChange={(e) => this.setState({ refRgl: e.target.value })} />
                                            </div>
                                            <div className="p-col-12 p-md-4">
                                                <Dropdown placeholder="Mode reglement" options={this.state.liste_mode_reglement} value={this.state.modeRgl} optionLabel='label' onChange={(e) => {
                                                    this.setState({ modeRgl: e.value })
                                                }
                                                } autoWidth={false} />
                                            </div>
                                            <div className="p-col-12 p-md-3">
                                                <Button label="Valider" style={{ width: '90px' }} icon="pi pi-check" onClick={(e) => this.enreg_reglSortie_bloc()} />
                                            </div>
                                        </div>
                                    </> : null}


                            </div>
                        </div>
                    </div>

                </div>



            </div>
        );
    }
}