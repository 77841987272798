import React, { Component } from 'react';
import { Xulaservice } from '../service/Xulaservice';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import Cleave from 'cleave.js/react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

import { Dialog } from 'primereact/dialog';
import { Growl } from 'primereact/growl';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import './styles.css';
import { ProgressSpinner } from 'primereact/progressspinner';
import { TabView, TabPanel } from 'primereact/tabview';
import { DataView, DataViewLayoutOptions } from "primereact/dataview";


export class EmptyPage extends Component {
    constructor() {
        super();
        this.state = {
            selectedSalle: null,
            selectedTable: null,
            selectedStype: null,
            selectedArticle: null,
            liste_sous_type_article: [],
            liste_table: [],
            liste_article: [],
            activeIndex: 0,
            activeIndex_stype: 0,
            liste_salle: [],
            visible_dlg_vente_restau: false,
            selectedTable: null,
            layout: 'grid',
            userconnected: JSON.parse(localStorage.getItem('userconnected')),
        }
        this.xulaservice = new Xulaservice();

    }


    componentDidMount() {
        this.get_liste_salle();
        this.get_liste_table();
        this.get_liste_sous_type_article();
        this.get_liste_article(this.state.userconnected.matrPers.idBoutiq.idBoutiq);
    }

    get_liste_article(idBoutiq) {
        this.xulaservice.get_inventaire('Tous', 'Tous', 'Tous', 'Tous', idBoutiq).then(data => {
            if (this.state.liste_article != null && this.state.liste_article != undefined) {
                let lprod = data.map(x => {
                    return { value: x.idArt, label: x.libArt, cbArt: x.cbArt, qte_stock_format: x.qte_stock_format, prix_ref_vente_format: x.prix_ref_vente_format, prix_ref_vente: x.prix_ref_vente, qte_stock: x.qte_stock, prix_ref_achat: x.prix_ref_achat, prix_ref_achat_format: x.prix_ref_achat_format, idstype: x.idstype, libsoustypearticle: x.libsoustypearticle, currentRemise: x.currentRemise, couvArticle: x.couvArticle }
                }).filter(function (el) {
                    return el.libsoustypearticle !== 'CONSOMMABLE' && el.libsoustypearticle !== 'INGREDIANT';
                });;
                this.setState({ liste_article: lprod });
            }
        });
    }

    get_liste_sous_type_article() {
        this.xulaservice.get_liste_all_stype_inart().then(data => this.setState({ liste_sous_type_article: data }));
    }
    get_liste_salle() {
        this.xulaservice.get_liste_salle(this.state.userconnected.matrPers.idBoutiq.idBoutiq).then(data => this.setState({ liste_salle: data }));
    }

    get_liste_table() {
        this.xulaservice.get_liste_table_sortie('Tous').then(data => this.setState({ liste_table: data }));
    }

    get_containt(idSalle) {
        if (this.state.liste_table != null && this.state.liste_table != undefined) {
            if (this.state.liste_table.length > 0) {
                let liste = this.state.liste_table.filter(function (el) {
                    return el.salle.idSalle === idSalle;
                });
                if (liste !== null && liste != undefined) {
                    if (liste.length > 0) {
                        return <DataView value={liste} layout='grid'
                            itemTemplate={(data, layout) => {
                                if (data != undefined && data != null) {
                                    let disable_commande = true;
                                    let disable_editer = true;
                                    let disable_rgl = true;
                                    if (data.aff_commande === 'true') {
                                        disable_commande = false;
                                    }
                                    if (data.aff_editer === 'true') {
                                        disable_editer = false;
                                    }
                                    if (data.aff_reglement === 'true') {
                                        disable_rgl = false;
                                    }
                                    return (
                                        <div style={{ padding: '.5em' }} className="p-col-12 p-md-3">
                                            <div className="card card-w-title" style={{ minHeight: '80px', borderRadius: 20, backgroundColor: '#eff2f6', marginBottom: -5 }}>
                                                <div className="p-grid">
                                                    <div className="p-col-12 p-md-6" style={{ marginBottom: -10 }}>
                                                        <label style={{ color: '#000', fontSize: '16px', fontWeight: 'bold' }}>{data.nom_table}</label>
                                                    </div>
                                                    <div className="p-col-12 p-md-6" style={{ marginBottom: -10 }}>
                                                        <label style={{ color: '#7D4D08', fontSize: '12px', fontWeight: 'bold' }}>{data.sortie.numFactureSortie}</label>
                                                    </div>
                                                    <div className="p-col-12" style={{ marginBottom: -10 }}>
                                                        <label style={{ color: '#108f10', fontSize: '18px', fontWeight: 'bold' }}>{data.sortie.montant_sortie_format}</label>
                                                    </div>
                                                    <div className="p-col-12" style={{ marginBottom: -10 }}>
                                                        <div className="p-grid" >
                                                            <div className="p-col"><Button label="Vendre" disabled={disable_commande} icon="pi pi-shopping-cart" onClick={(e) => this.setState({ selectedTable: data, visible_dlg_vente_restau: true })} className="p-button-secondary" /></div>
                                                            <div className="p-col"><Button label="Editer" disabled={disable_editer} icon="pi pi-pencil" onClick={(e) => this.setState({ selectedTable: data, visible_dlg_vente_restau: true })} className="p-button-warning" /></div>
                                                            <div className="p-col"><Button label="Payer" disabled={disable_rgl} icon="pi pi-briefcase" onClick={(e) => this.setState({ selectedTable: data, visible_dlg_vente_restau: true })} className="p-button-success" /></div>
                                                        </div>
                                                    </div>
                                                    <div className="p-col-12" style={{ marginBottom: -10 }}>
                                                        <label style={{ color: '#1B3F4A', fontSize: '14px', fontWeight: 'bold' }}>{data.sortie.livreur.nomPers}</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                } else {
                                    return (
                                        null
                                    );
                                }

                            }}
                        />
                    } else {
                        return null;
                    }
                } else {
                    return null;
                }
            }
        } else {
            return null;
        }

    }


    get_containt_article(idStype) {
        if (this.state.liste_article != null && this.state.liste_article != undefined) {
            if (this.state.liste_article.length > 0) {
                let liste = this.state.liste_article.filter(function (el) {
                    return el.idstype === idStype;
                });
                if (liste !== null && liste != undefined) {
                    if (liste.length > 0) {
                        return <DataView value={liste} layout='grid'
                            itemTemplate={(data, layout) => {
                                if (data != undefined && data != null) {
                                    return (
                                        <div style={{ padding: '.5em' }} className="p-col-12 p-md-3">
                                            <a onClick={(e) => {
                                                this.setState({ selectedArticle: data});
                                                alert(data.label);
                                            } }>
                                                <div className="card card-w-title" style={{ minHeight: '80px', marginBottom: -8, backgroundColor: '#ffffff' }}>
                                                    <div className="p-grid">
                                                        <div className="p-col-12" style={{ marginBottom: -10 }}>
                                                            <label style={{ color: '#000', fontSize: '14px', fontWeight: 'bold' }}>{data.label}</label>
                                                        </div>
                                                        <div className="p-col-12" style={{ marginBottom: -10 }}>
                                                            <img src={this.xulaservice.download_file(data.couvArticle, 'photos')} alt={data.label} style={{ width: '100%', height: '90px' }} />
                                                        </div>
                                                        <div className="p-col-12" style={{ marginBottom: -10 }}>
                                                            <label style={{ color: 'red', fontSize: '18px', fontWeight: 'bold' }}>{data.prix_ref_vente_format}</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                    );
                                } else {
                                    return (
                                        null
                                    );
                                }

                            }}
                        />
                    } else {
                        return null;
                    }
                } else {
                    return null;
                }
            }
        } else {
            return null;
        }

    }
    render() {
        return (
            <div className="p-fluid">
                <Growl ref={(el) => this.growl = el} />
                
                <Dialog header="Note" visible={this.state.visible_dlg_vente_restau} style={{ width: '90%' }} modal={true} onHide={(e) => this.setState({ visible_dlg_vente_restau: false })}>
                    <div className="p-grid" >

                        <div className="p-col-12 p-lg-4">
                            <div className="p-grid">
                                <div className="p-col-12">
                                    <div className="card card-w-title" style={{ height: 600, marginBottom: -10 }}>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="p-col-12 p-lg-8">
                            <div className="p-grid">
                                <div className="p-col-12">
                                    <div className="card card-w-title" style={{ height: 600, marginBottom: -10 }}>
                                        <TabView activeIndex={this.state.activeIndex_stype} onTabChange={(e) => this.setState({ activeIndex_stype: e.index })}>
                                            {
                                                this.state.liste_sous_type_article.map((tab, index) => (
                                                    <TabPanel header={tab.libStype} key={index} {...tab}>
                                                        {this.get_containt_article(tab.idStype)}
                                                    </TabPanel>
                                                ))
                                            }
                                        </TabView>
                                    </div>
                                </div>
                            </div>
                        </div>


                    </div>

                </Dialog>

                <div className="p-grid">
                    <div className="p-col-12">
                        <div className="card">
                            <TabView activeIndex={this.state.activeIndex} onTabChange={(e) => this.setState({ activeIndex: e.index })}>
                                {
                                    this.state.liste_salle.map((tab, index) => (
                                        <TabPanel header={tab.nomSalle} key={index} {...tab}>
                                            {this.get_containt(tab.idSalle)}
                                        </TabPanel>
                                    ))
                                }
                            </TabView>
                        </div>
                    </div>
                </div>



                
            </div>
        );
    }
}