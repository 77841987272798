import React, { Component } from 'react';
import { Xulaservice } from '../service/Xulaservice';
import { InputText } from 'primereact/inputtext';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dialog } from 'primereact/dialog';
import { Growl } from 'primereact/growl';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import './styles.css';
import { Panel } from 'primereact/panel';
import { ProgressSpinner } from 'primereact/progressspinner';
import Cleave from 'cleave.js/react';

export class FormCategorieFidelisation extends Component {
    constructor() {
        super();
        this.state = {
            liste_data: [],
            selectedData: null,
            libCatFid: '',
            prcCatFid: 0,
            idCatFid: '0',
            msg: '',
            modif: false,
            visible: false,

            userconnected: JSON.parse(localStorage.getItem('userconnected')),

        };
        this.edit = this.edit.bind(this);
        this.conf_delete = this.conf_delete.bind(this);
        this.xulaservice = new Xulaservice();
    }

    componentDidMount() {
        this.get_liste_data();
    }

    get_liste_data() {
        this.xulaservice.get_liste_catfidelisation().then(data => this.setState({ liste_data: data }));
    }

    resultat(code, contenu) {
        if (code == '200') {
            this.growl.show({ severity: 'success', summary: 'Success', detail: contenu });
            this.annule();
            this.get_liste_data();
        } else {
            this.growl.show({ severity: 'error', summary: 'Error', detail: contenu });
        }
    }


    edit(data) {
        if (data != null && data != undefined) {
            this.setState({ libCatFid: data.libCatFid });
            this.setState({ prcCatFid: data.prcCatFid });
            this.setState({ modif: true });
            this.setState({ selectedData: data });
        }
    }


    annule() {
        this.setState({ prcCatFid: 0 });
        this.setState({ idCatFid: '0' });
        this.setState({ libCatFid: '' });
        this.setState({ modif: false });
        this.forceUpdate();
    }



    enregistrer() {
        if (this.state.prcCatFid === undefined || this.state.prcCatFid === null || this.state.libCatFid === '') {
            this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez saisir tous les champs obligatoires' });
        } else {
            if (this.state.modif == false) {
                let data = { libCatFid: this.state.libCatFid, prcCatFid: this.state.prcCatFid, idCatFid: '0' };
                this.xulaservice.enreg_catfidelisation(data).then(data => this.resultat(data.code, data.contenu));
            } else {
                let data = this.state.selectedData;
                data.prcCatFid = this.state.prcCatFid;
                data.libCatFid = this.state.libCatFid;
                this.xulaservice.enreg_catfidelisation(data).then(data => this.resultat(data.code, data.contenu));
            }
        }
    }


    actionTemplate(rowData, column) {
        return <div className="p-grid" style={{ width: 90 }}>
            <div className="p-col"><Button onClick={(e) => this.edit(rowData)} className="p-button-info" style={{ width: 25, height: 25 }} icon="pi pi-pencil" /></div>
            <div className="p-col"><Button onClick={(e) => this.conf_delete(rowData)} className="p-button-danger" style={{ width: 25, height: 25, marginLeft: -8 }} icon="pi pi-times" /></div>
        </div>
    }


    delete() {
        if (this.state.selectedData == null || this.state.selectedData == undefined) {
            this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez sélectionner la ligne à supprimer' });
        } else {
            this.xulaservice.delete_catfidelisation(this.state.selectedData).then(data => this.resultat(data.code, data.contenu));
        }
    }


    conf_delete(data) {
        if (data != null && data != undefined) {
            this.setState({ selectedData: data });
            this.setState({ msg: data.libCatFid });
            this.setState({ visible: true });
        }
    }



    render() {

        const dialogFooter = (

            <div>
                <Button icon="pi pi-check" onClick={() => {
                    this.setState({ visible: false });
                    this.delete();
                }} label="Oui" />
                <Button icon="pi pi-times" onClick={() => this.setState({ visible: false })} label="Annuler" className="p-button-secondary" />

            </div>
        );

        return (
            <div className="p-fluid ">
                <Growl ref={(el) => this.growl = el} />
                <Dialog header="Confirmation" visible={this.state.visible} style={{ width: '250px' }} modal={true} footer={dialogFooter} onHide={(e) => this.setState({ visible: false })}>
                    <div>
                        <i className="fa fa-warning" style={{ color: '#FFD133', fontSize: 25 }}></i>
                        Voulez vous vraiment supprimer <b>{this.state.msg} </b>?
                    </div>
                </Dialog>


                <div className="p-grid">

                    <div className="p-grid">
                        <div className="p-col-12">
                            <div className="card card-w-title" style={{ height: 550 }}>

                                <div className="p-grid">

                                    <div className="p-col-12 p-md-2">
                                        <label htmlFor="inPu">Désignation *</label>
                                    </div>
                                    <div className="p-col-12 p-md-4">
                                        <InputText value={this.state.libCatFid} onChange={(e) => this.setState({ libCatFid: e.target.value })} />
                                    </div>

                                    <div className="p-col-12 p-md-2">
                                        <label htmlFor="inPu">Pourcentage *</label>
                                    </div>
                                    <div className="p-col-12 p-md-4">
                                        <Cleave value={this.state.prcCatFid}  options={{ numeral: true, delimiter: ' ' }} onChange={(e) => this.setState({ prcCatFid: e.target.rawValue })} className="p-inputtext" />
                                    </div>
                                </div>

                                <center>
                                    <div className="p-grid" style={{ width: 300 }}>
                                        <div className="p-col"><Button label="Annuler" icon="pi pi-ban" onClick={(e) => this.annule()} /></div>
                                        <div className="p-col"><Button label="Valider" icon="pi pi-check" onClick={(e) => this.enregistrer()} /></div>
                                    </div>
                                    {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : null}
                                </center>

                                <Panel style={{ minHeight: '350px', marginBottom: 8 }}>
                                    <DataTable value={this.state.liste_data}
                                        responsive={true} scrollable={true} scrollHeight="300px" selectionMode="single"
                                        onRowClick={e => this.setState({ selectedData: e.data })}
                                        responsive={true} selection={this.state.selectedData}
                                    >
                                        <Column header="#" body={this.actionTemplate.bind(this)} style={{ width: '10%' }} />
                                        <Column field="libCatFid" header="Catégorie" sortable={true} style={{ width: '70%' }} />
                                        <Column field="prcCatFid" header="Pourcentage" sortable={true} style={{ width: '20%' }} />

                                    </DataTable>
                                </Panel>


                            </div>
                        </div>
                    </div>
                </div>

            </div>
        );
    }
}