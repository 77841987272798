import React, { Component } from 'react';
import { Xulaservice } from '../service/Xulaservice';
import { Panel } from 'primereact/components/panel/Panel';
import { Dropdown } from 'primereact/components/dropdown/Dropdown';
import { Chart } from 'primereact/chart';
import { ProgressSpinner } from 'primereact/progressspinner';
import Numeral from 'numeral';
import 'moment';
import 'moment/locale/fr';
import moment from 'moment';
import { Button } from 'primereact/button';

export class Dashboardrh extends Component {

    constructor() {
        super();
        this.state = {
            nbRH: 0,
            masseSalariale: 0,
            totalBon: 0,
            totalPrime: 0,
            liste_stat_rh_fonction: [],
            liste_stat_salaire_mois: [],

            disable_etabl: true,
            showindicator: false,

            idBoutiq: { label: 'Toutes les boutiques', value: '-1' },
            userconnected: JSON.parse(localStorage.getItem('userconnected')),
            rannee: moment(new Date()).format('YYYY'),
        };
        this.xulaservice = new Xulaservice();
        if (this.state.userconnected.profilUser == 'PDG' || this.state.userconnected.profilUser == 'Coordinateur') {
            this.setState({ disable_etabl: false });
        }
    }



    formaterMontant(montant) {
        return Numeral(parseFloat(montant)).format('0,0');
    }




    charger_dashboard(idBoutiq, annee, show) {
        this.setState({
            nbRH: 0,
            masseSalariale: 0,
            totalBon: 0,
            totalPrime: 0,
            liste_stat_rh_fonction: [],
            liste_stat_salaire_mois: [],
        })
        this.setState({ showindicator: true });
        this.xulaservice.get_dashboard(idBoutiq, annee).then(data => {
            if (data !== null && data !== undefined) {
                this.setState({ nbRH: data.nbRH });
                this.setState({ liste_stat_rh_fonction: data.liste_stat_rh_fonction });
                this.setState({ liste_stat_salaire_mois: data.liste_stat_salaire_mois });
                this.setState({ masseSalariale: this.formaterMontant(parseFloat(data.masseSalariale)) });
                this.setState({ totalBon: this.formaterMontant(parseFloat(data.totalBon)) });
                this.setState({ totalPrime: this.formaterMontant(parseFloat(data.totalPrime)) });
            }
            this.setState({ showindicator: false });
        }
        );
    }

    componentDidMount() {
        let lboutique = [{ value: '-1', label: 'Toutes les boutiques' }];
        this.xulaservice.getListe_boutique().then(data => {
            this.setState({ liste_boutique: data }, () => {
                if (this.state.liste_boutique != null && this.state.liste_boutique != undefined) {
                    let liste = this.state.liste_boutique.map(x => {
                        return { value: x.idBoutiq, label: x.nomBoutiq }
                    });
                    lboutique = lboutique.concat(liste);
                }
            });
            this.setState({ lboutique: lboutique }, () => {
                this.setState({ idBoutiq: this.state.userconnected.matrPers.idBoutiq.idBoutiq }, () => {
                    this.charger_dashboard(this.state.idBoutiq, this.state.rannee, true);
                });
            });
        });

    }



    render() {
        let liste_label_stat_salaire = [];
        let liste_ds_stat_salaire = null;
        if (this.state.liste_stat_salaire_mois != null && this.state.liste_stat_salaire_mois != undefined) {
            if (this.state.liste_stat_salaire_mois.length > 0) {
                liste_label_stat_salaire = this.state.liste_stat_salaire_mois.map(x => {
                    return x.lib
                });
                liste_ds_stat_salaire = this.state.liste_stat_salaire_mois.map(x => {
                    return x.dval
                });
            }
        }

        const chart_data_stat_salaire = {
            labels: liste_label_stat_salaire,
            datasets: [
                {
                    label: 'Salaires année en cours',
                    backgroundColor: '#4071a7',
                    borderColor: '#4071a7',
                    fill: false,
                    data: liste_ds_stat_salaire
                }
            ]
        };


        let liste_label_rh = null;
        let liste_ds_stat_rh = null;

        if (this.state.liste_stat_rh_fonction != null && this.state.liste_stat_rh_fonction != undefined) {
            if (this.state.liste_stat_rh_fonction.length > 0) {
                liste_label_rh = this.state.liste_stat_rh_fonction.map(x => {
                    return x.lib
                });
                liste_ds_stat_rh = this.state.liste_stat_rh_fonction.map(x => {
                    return x.ival
                });
            }
        }

        const chart_data_rh = {
            labels: liste_label_rh,
            datasets: [
                {
                    backgroundColor: [
                        "#0673c7",
                        "#f07d82",
                        "#0d5943",
                        "#2c87b8",
                        "#FF6384",
                        "#36A2EB",
                        "#4071a7",
                        "#fcba03",
                        "#a6a5a2",
                        "#9cb090",
                        "#52ba9b",
                        "#06c1cf",
                        "#275996",
                        "#ff7900",
                        "#003366",
                        "#295380",
                        "#d87a3c",
                        "#36A2EB",
                        "#FFCE56",
                        "#293780",

                    ],
                    data: liste_ds_stat_rh
                }
            ]
        };

        return <div className="p-grid dashboard">
            <div className="p-col-12" style={{ marginTop: -10 }}>
                <div className="p-grid" style={{ float: "right", width: 350, marginBottom: -5, marginTop: -10 }}>
                    <div className="p-col">
                        <div className="p-inputgroup">
                            <Dropdown placeholder="Toutes les boutiques" style={{ width: 250, marginBottom: -8 }} value={this.state.idBoutiq} filter={true} filterBy='label' filterMatchMode='contains' options={this.state.lboutique} onChange={(e) => {
                                this.setState({ idBoutiq: e.value }, () => {
                                    this.charger_dashboard(e.value, this.state.rannee, false);
                                });
                            }
                            } disabled={this.state.userconnected.profilUser != 'PDG' && this.state.userconnected.profilUser != 'Coordinateur'} />
                            <Button type="button" style={{ marginBottom: -8, width: '30px' }} icon="pi pi-refresh" className="p-button-info" onClick={() => this.charger_dashboard(this.state.idBoutiq, this.state.rannee, true)} />
                        </div>
                    </div>
                    <div className="p-col">
                        {this.state.showindicator ? <ProgressSpinner style={{ width: '20px', height: '20px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : null}
                    </div>
                </div>

            </div>

            <div className="p-col-6 p-sm-6 p-md-3">
                <div className="overview-box overview-box-2"><h1>Totan RH</h1>
                    <div className="overview-value">{this.state.nbRH}</div>

                    <img src="assets/layout/images/dashboard/graph-green.svg" alt="apollo-layout" />
                </div>
            </div>

            <div className="p-col-6 p-sm-6 p-md-3">
                <div className="overview-box overview-box-3">
                    <h1>Masse salariale</h1>
                    <div className="overview-value">{this.state.masseSalariale}</div>

                    <img src="assets/layout/images/dashboard/graph-yellow.svg" alt="apollo-layout" />
                </div>
            </div>

            <div className="p-col-6 p-sm-6 p-md-3">
                <div className="overview-box overview-box-1">
                    <h1>Total bon</h1>
                    <div className="overview-value">{this.state.totalBon}</div>

                    <img src="assets/layout/images/dashboard/graph-blue.svg" alt="apollo-layout" />
                </div>
            </div>

            <div className="p-col-6 p-sm-6 p-md-3">
                <div className="overview-box overview-box-4">
                    <h1>Total prime</h1>
                    <div className="overview-value" >{this.state.totalPrime}</div>
                </div>
            </div>


            <div className="p-col-12 p-md-4">
                <Panel style={{height: 400}} className="no-pad">
                    <i className="fa fa-fw fa-line-chart"></i>Statistiques RH
                    <hr />
                    <div className="content-section implementation">
                        <Chart type="pie" data={chart_data_rh} height="200w" />
                    </div>
                </Panel>
            </div>

            <div className="p-col-12 p-md-8">
                <Panel style={{height: 400}} className="no-pad">
                    <i className="fa fa-fw fa-line-chart"></i>Statistiques des salaires
                    <hr />
                    <div className="content-section implementation">
                        <Chart responsive={true} type="line" data={chart_data_stat_salaire} height="120w" />
                    </div>
                </Panel>
            </div>

        </div>
    }
}