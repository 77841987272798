import React, { Component } from 'react';
import { Xulaservice } from '../service/Xulaservice';
import { InputText } from 'primereact/inputtext';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dialog } from 'primereact/dialog';
import { Growl } from 'primereact/growl';
import { Button } from 'primereact/button';
import './styles.css';
import { ProgressSpinner } from 'primereact/progressspinner';


export class FormCodif extends Component {
    constructor() {
        super();
        this.state = {
            listecodif: [],
            selectedCodif: null,
            codif: null,
            idCodif: 0,
            libCodif: '',
            descParamCodif: '',
            typecodif: 'TYPE_DEPENSE',
            msg: '',
            modif: false,
            visible: false,
            userconnected: JSON.parse(localStorage.getItem('userconnected')),
        };
        this.edit_typecodif = this.edit_typecodif.bind(this);
        this.conf_delete_typecodif = this.conf_delete_typecodif.bind(this);
        this.xulaservice = new Xulaservice();
    }

    componentDidMount() {
        this.xulaservice.getListe_codif(this.state.typecodif).then(data => {
            this.setState({ listecodif: data });
        });
    }

    resultat(code, contenu) {
        if (code == '200') {
            this.growl.show({ severity: 'success', summary: 'Success', detail: contenu });
            this.annule_typecodif();
            this.componentDidMount();
        } else {
            this.growl.show({ severity: 'error', summary: 'Error', detail: contenu });
        }
    }

    edit_typecodif(data) {
        if (data != null && data != undefined) {
            this.setState({ idCodif: data.idCodif });
            this.setState({ libCodif: data.libCodif });
            this.setState({ descParamCodif: data.descParamCodif });
            this.setState({ modif: true });
            this.setState({ typecodif: data.typeCodif });
            this.setState({ selectedCodif: data });
        }
    }
 
    annule_typecodif() {
        this.setState({ libCodif: '' });
        this.setState({ descParamCodif: '' });
        this.setState({ idCodif: 0 });
        this.setState({ modif: false });
        this.forceUpdate();
    }

    enreg_typecodif() {
        if (this.state.libCodif == "" || this.state.typecodif == "" || this.state.descParamCodif == "") {
            this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez saisir tous les champs obligatoires' });
        } else {
            if (this.state.modif == false) {
                let data = { idCodif: '0', libCodif: this.state.libCodif, descParamCodif: this.state.descParamCodif,typeCodif: this.state.typecodif };
                data.idCodif='0';
                this.xulaservice.enreg_codif(data).then(data => this.resultat(data.code, data.contenu));
            } else {
                let data =this.state.selectedCodif;
                data.libCodif=this.state.libCodif;
                data.descParamCodif=this.state.descParamCodif;
                this.xulaservice.enreg_codif(data).then(data => this.resultat(data.code, data.contenu));
            }
        }
    }

    
    actionTemplate(rowData, column) {
        let disabled=false;
        if(rowData.libCodif.toLowerCase()==='regularisation solde' || rowData.libCodif.toLowerCase()==='non livre ebp' || rowData.libCodif.toLowerCase()==='transport arrivage' ||rowData.libCodif==='Retour article' || rowData.libCodif==='Surplus article' || rowData.libCodif==='Annulation facture'){
            disabled=true;
        }
        return <div className="p-grid" style={{ width: 90 }}>
            <div className="p-col"><Button disabled={disabled} onClick={(e) => this.edit_typecodif(rowData)} className="p-button-info" style={{ width: 25, height: 25 }} icon="pi pi-pencil" /></div>
            <div className="p-col"><Button disabled={disabled}  onClick={(e) => this.conf_delete_typecodif(rowData)} className="p-button-danger" style={{ width: 25, height: 25 }} icon="pi pi-times" /></div>
        </div>
    }


    delete_typecodif() {
        if (this.state.selectedCodif == null || this.state.selectedCodif == undefined) {
            this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez sélectionner la ligne à supprimer' });
        } else {
            this.xulaservice.supr_codif(this.state.selectedCodif).then(data => this.resultat(data.code, data.contenu));
        }
    }


    conf_delete_typecodif(data) {
        if (data != null && data != undefined) {
            this.setState({ selectedCodif: data });
            this.setState({ msg: data.libCodif });
            this.setState({ visible: true });
        }
    }


    handleKeyPress = e => {
        if (e.keyCode === 13 ) {
            this.enreg_typecodif();
        } 
    }


    render() {
        const dialogFooter = (
            <div>
                <Button icon="pi pi-check" onClick={() => {
                    this.setState({ visible: false });
                    this.delete_typecodif();
                }} label="Oui" />
                <Button icon="pi pi-times" onClick={() => this.setState({ visible: false })} label="Annuler" className="p-button-secondary" />
            </div>
        );
        return (
            <div className="p-fluid">
                <Growl ref={(el) => this.growl = el} />
                <Dialog header="Confirmation" visible={this.state.visible} style={{ width: '250px' }} modal={true} footer={dialogFooter} onHide={(e) => this.setState({ visible: false })}>
                    <div>
                        <i className="fa fa-warning" style={{ color: '#FFD133', fontSize: 25 }}></i>
                        Voulez vous vraiment supprimer <b>{this.state.msg} </b>?
                    </div>
                </Dialog>
                <div className="p-grid">
                    <div className="p-col-12">
                        <div className="card card-w-title" style={{minHeight: '700px'}}>

                            <div className="p-grid">
                                <div className="p-col-12 p-md-2">
                                    <label htmlFor="libCodif">Libelle</label>
                                </div>
                                <div className="p-col-12 p-md-4">
                                    <InputText id="libCodif" onKeyDown={this.handleKeyPress}  value={this.state.libCodif} onChange={(e) => this.setState({ libCodif: e.target.value, descParamCodif: e.target.value })} />
                                </div>
                                <div className="p-col-12 p-md-2">
                                    <label htmlFor="descParamCodif">Description</label>
                                </div>
                                <div className="p-col-12 p-md-4">
                                    <InputText id="descParamCodif" onKeyDown={this.handleKeyPress}  value={this.state.descParamCodif} onChange={(e) => this.setState({ descParamCodif: e.target.value })} />
                                </div>
                                
                            </div>
                            <center>
                                <div className="p-grid" style={{ width: 300 }}>
                                    <div className="p-col"><Button label="Annuler" icon="pi pi-ban" onClick={(e) => this.annule_typecodif()} /></div>
                                    <div className="p-col"><Button label="Valider" icon="pi pi-check" onClick={(e) => this.enreg_typecodif()} /></div>
                                </div>
                                {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : null}

                            </center>

                            <DataTable value={this.state.listecodif} 
                                responsive={true} scrollable={true} scrollHeight="300" selectionMode="single"
                                onRowClick={e => this.setState({ selectedCodif: e.data })}
                                responsive={true} selection={this.state.selectedCodif} 
                                >
                                <Column header="#" body={this.actionTemplate.bind(this)} style={{ width: '8%' }} />
                                <Column field="libCodif" header="Type dépense" sortable={true} style={{ width: '25%' }}  filter={true} filterMatchMode='contains'  sortable={true} />
                                <Column field="descParamCodif" header="Description" sortable={true} style={{ width: '62%' }}  filter={true} filterMatchMode='contains'  sortable={true}/>
                            </DataTable>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}