import React, { Component } from 'react';
import { Xulaservice } from '../service/Xulaservice';
import { InputText } from 'primereact/inputtext';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dialog } from 'primereact/dialog';
import { Growl } from 'primereact/growl';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { ProgressSpinner } from 'primereact/progressspinner';
import './styles.css';
import Cleave from 'cleave.js/react';
import Numeral from 'numeral';


export class FormSeuilArticle extends Component {
    constructor() {
        super();
        this.state = {
            idSeuil: '0',
            liste_boutique: [],
            lboutique: [],
            lprod: [],
            idBoutiq: null,
            liste_seuil: [],
            liste_article: [],
            selectedSeuil: null,
            idArticle: null,
            qteSeuil: 0,
            qteSeuilMax: 0,
            prixVenteSeuil: 0,
            prixRevientSeuil: 0,
            msg: '',
            modif: false,
            visible: false,
            userconnected: JSON.parse(localStorage.getItem('userconnected')),
        };
        this.edit_seuil = this.edit_seuil.bind(this);
        this.conf_delete_seuil = this.conf_delete_seuil.bind(this);
        this.xulaservice = new Xulaservice();
    }

    componentDidMount() {
        this.getListe_seuil();
        this.get_liste_boutique();
    }


    formaterMontant(montant) {
        return Numeral(parseFloat(montant)).format('0,0');
    }

    get_liste_boutique() {
        let lboutique = null;
        let lboutique_rech = [{ value: 'Tous', label: 'Boutique' }];

        this.xulaservice.getListe_boutique().then(data => this.setState({ liste_boutique: data }, () => {
            if (this.state.liste_boutique != null && this.state.liste_boutique != undefined) {
                lboutique = this.state.liste_boutique.map(x => {
                    return { value: x.idBoutiq, label: x.nomBoutiq }
                });
            }
            this.setState({ idBoutiq: { value: this.state.userconnected.matrPers.idBoutiq.idBoutiq, label: this.state.userconnected.matrPers.idBoutiq.nomBoutiq } }, () => {
                this.get_liste_article(this.state.idBoutiq.value);
            });

            if (this.state.userconnected.profilUser === 'PDG' || this.state.userconnected.profilUser === 'Coordinateur') {
                this.setState({ lboutique: lboutique });
                lboutique_rech = lboutique_rech.concat(lboutique);

            } else {
                let id = this.state.userconnected.matrPers.idBoutiq.idBoutiq;
                if (lboutique.length > 0) {
                    let liste = lboutique.filter(function (el) {
                        return el.value === id;
                    });
                    this.setState({ lboutique: liste });
                    lboutique_rech = lboutique_rech.concat(liste);
                }
            }
            this.setState({ lboutique_rech: lboutique_rech })
        }));
    }

    get_liste_article(idBoutiq) {
        this.xulaservice.get_inventaire('Tous', 'Tous', 'Tous', 'Tous', idBoutiq).then(data => {
            this.setState({ liste_article: data }, () => {
                let lprod = null;
                if (this.state.liste_article != null && this.state.liste_article != undefined) {
                    lprod = this.state.liste_article.map(x => {
                        return { value: x.idArt, label: x.libArt, cbArt: x.cbArt, qte_stock_format: x.qte_stock_format, prix_ref_vente_format: x.prix_ref_vente_format, prix_ref_vente: x.prix_ref_vente, qte_stock: x.qte_stock, prix_ref_achat: x.prix_ref_achat, prix_ref_achat_format: x.prix_ref_achat_format, label_rech: x.libArt + ' ' + x.cbArt }
                    });
                    this.setState({ lprod: lprod });
                }
            })
        });
    }

    getListe_seuil() {
        this.xulaservice.get_liste_seuil_article().then(data => {
            this.setState({ liste_seuil: data });
        });
    }


    resultat(code, contenu) {
        if (code == '200') {
            this.growl.show({ severity: 'success', summary: 'Success', detail: contenu });
            this.annule_seuil();
            this.getListe_seuil();
        } else {
            this.growl.show({ severity: 'error', summary: 'Error', detail: contenu });
        }
    }

    edit_seuil(data) {
        if (data != null && data != undefined) {
            this.setState({ idSeuil: data.idSeuil });
            this.setState({ qteSeuil: data.qteSeuil,qteSeuilMax: data.qteSeuilMax });
            this.setState({ modif: true });
            let art = this.state.lprod.filter(function (el) {
                return el.value == data.idArticle.idArticle;
            })[0];
            this.setState({ idArticle: art });
            this.setState({ selectedSeuil: data });
            this.setState({ prixVenteSeuil: data.prixVenteSeuil });
        }
    }

    annule_seuil() {
        this.setState({ qteSeuil: '',qteSeuilMax: '' });
        this.setState({ idSeuil: '0' });
        this.setState({ modif: false });
        this.setState({ prixVenteSeuil: '' });
        this.forceUpdate();
    }

    enreg_seuil() {
        if (this.state.prixVenteSeuil === undefined || this.state.prixVenteSeuil === "" || this.state.qteSeuil === undefined || this.state.qteSeuil === "" || this.state.idArticle == "" || this.state.qteSeuilMax === undefined|| this.state.qteSeuilMax=="") {
            this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez saisir tous les champs obligatoires' });
        } else {
            if (this.state.modif == false) {
                let data = { idSeuil: '0', prixRevientSeuil: this.state.prixRevientSeuil, prixVenteSeuil: this.state.prixVenteSeuil, qteSeuil: this.state.qteSeuil,qteSeuilMax: this.state.qteSeuilMax, idArticle: { idArticle: this.state.idArticle.value }, idBoutiq: { idBoutiq: this.state.idBoutiq.value } };
                this.xulaservice.enreg_seuil_article(data).then(data => this.resultat(data.code, data.contenu));
            } else {
                let data = this.state.selectedSeuil;
                data.qteSeuil = this.state.qteSeuil;
                data.qteSeuilMax=this.state.qteSeuilMax;
                data.prixRevientSeuil = this.state.prixRevientSeuil;
                data.prixVenteSeuil = this.state.prixVenteSeuil;
                data.idArticle = { idArticle: this.state.idArticle.value };
                data.idBoutiq = { idBoutiq: this.state.idBoutiq.value };
                this.xulaservice.enreg_seuil_article(data).then(rep => this.resultat(rep.code, rep.contenu));
            }
        }
    }

    actionTemplate(rowData, column) {
        let disabled=false;
        if(this.xulaservice.get_statut_offline() === 'true'){
            disabled=true;
        }
        return <div className="p-grid" style={{ width: 90 }}>
            <div className="p-col"><Button disabled={disabled} onClick={(e) => this.edit_seuil(rowData)} className="p-button-info" style={{ width: 25, height: 25 }} icon="pi pi-pencil" /></div>
            <div className="p-col"><Button  disabled={disabled} onClick={(e) => this.conf_delete_seuil(rowData)} className="p-button-danger" style={{ width: 25, height: 25 }} icon="pi pi-times" /></div>
        </div>
    }


    delete_seuil() {
        if (this.state.selectedSeuil == null || this.state.selectedSeuil == undefined) {
            this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez sélectionner la ligne à supprimer' });
        } else {
            this.xulaservice.delete_seuil_article(this.state.selectedSeuil).then(data => this.resultat(data.code, data.contenu));
        }
    }


    conf_delete_seuil(data) {
        if (data != null && data != undefined) {
            this.setState({ selectedSeuil: data });
            this.setState({ msg: data.qteSeuil });
            this.setState({ visible: true });
        }
    }

    articleItemTemplate(option) {
        if (!option.value) {
            return option.label;
        }
        else {
            return (
                <div className="p-clearfix">
                    <div className="p-grid">
                        <div className="p-col-12 p-md-8">
                            <label>{option.label}</label>
                        </div>
                        <div className="p-col-12 p-md-2">
                            <label>{option.prix_ref_vente_format}</label>
                        </div>
                        <div className="p-col-12 p-md-2" >
                            <label style={{ fontWeight: 'bold', color: 'red', marginRight: '5px' }}>{option.qte_stock_format}</label>
                        </div>
                    </div>
                </div>
            );
        }
    }

    render() {


        const dialogFooter = (
            <div>
                <Button icon="pi pi-check" onClick={() => {
                    this.setState({ visible: false });
                    this.delete_seuil();
                }} label="Oui" />
                <Button icon="pi pi-times" onClick={() => this.setState({ visible: false })} label="Annuler" className="p-button-secondary" />
            </div>
        );
        return (
            <div className="p-fluid">
                <Growl ref={(el) => this.growl = el} />
                <Dialog header="Confirmation" visible={this.state.visible} style={{ width: '250px' }} modal={true} footer={dialogFooter} onHide={(e) => this.setState({ visible: false })}>
                    <div>
                        <i className="fa fa-warning" style={{ color: '#FFD133', fontSize: 25 }}></i>
                        Voulez vous vraiment supprimer <b>{this.state.msg} </b>?
                    </div>
                </Dialog>
                <div className="p-grid">
                    <div className="p-col-12">
                        <div className="card card-w-title" style={{ minHeight: '900px' }}>
                            {this.xulaservice.get_statut_offline() === 'false' ?
                                <div className="p-grid">
                                    <div className="p-col-12 p-md-2">
                                        <label >Boutique *</label>
                                    </div>
                                    <div className="p-col-12 p-md-4">
                                        <Dropdown options={this.state.lboutique} value={this.state.idBoutiq} optionLabel='label' filter={true} filterBy='label' filterMatchMode='contains'
                                            placeholder="Sélectionner" onChange={(e) => {
                                                this.setState({ idBoutiq: e.value }, () => {
                                                    this.get_liste_article(this.state.idBoutiq.value);
                                                });
                                            }} autoWidth={false} />
                                    </div>
                                    <div className="p-col-12 p-md-2">
                                        <label htmlFor="refRgl">Article *</label>
                                    </div>
                                    <div className="p-col-12 p-md-4">
                                        <Dropdown options={this.state.lprod} placeholder="Sélectionner" value={this.state.idArticle} panelStyle={{ width: '500px' }}
                                            optionLabel='label_rech' filter={true} filterBy='label_rech' filterMatchMode='contains'
                                            onChange={(e) => this.setState({ idArticle: e.value })} autoWidth={false} itemTemplate={this.articleItemTemplate} />
                                    </div>
                                    <div className="p-col-12 p-md-2">
                                        <label htmlFor="qteSeuil">Seuil Min *</label>
                                    </div>
                                    <div className="p-col-12 p-md-4">
                                        <InputText keyfilter="pint" id="qteSeuil" value={this.state.qteSeuil} onChange={(e) => this.setState({ qteSeuil: e.target.value })} />
                                    </div>

                                    <div className="p-col-12 p-md-2">
                                        <label htmlFor="qteSeuilMax">Seuil Max *</label>
                                    </div>
                                    <div className="p-col-12 p-md-4">
                                        <InputText keyfilter="pint" id="qteSeuilMax" value={this.state.qteSeuilMax} onChange={(e) => this.setState({ qteSeuilMax: e.target.value })} />
                                    </div>

                                    {this.state.userconnected.profilUser === 'PDG' ?
                                        <div className="p-col-12 p-md-2">
                                            <label htmlFor="qteSeuil">Prix revient *</label>
                                        </div>
                                        : null}
                                    {this.state.userconnected.profilUser === 'PDG' ?
                                        <div className="p-col-12 p-md-4">
                                            <Cleave value={this.state.prixRevientSeuil} options={{ numeral: true, delimiter: ' ' }} onChange={(e) => this.setState({ prixRevientSeuil: e.target.rawValue })} className="p-inputtext" />
                                        </div>
                                        : null}


                                    <div className="p-col-12 p-md-2">
                                        <label htmlFor="qteSeuil">Prix vente *</label>
                                    </div>
                                    <div className="p-col-12 p-md-4">
                                        <Cleave value={this.state.prixVenteSeuil} options={{ numeral: true, delimiter: ' ' }} onChange={(e) => this.setState({ prixVenteSeuil: e.target.rawValue })} className="p-inputtext" />
                                    </div>
                                </div>
                                : null}
                            <center>
                                {this.xulaservice.get_statut_offline() === 'false' ?
                                    <div className="p-grid" style={{ width: 300 }}>
                                        <div className="p-col"><Button label="Annuler" icon="pi pi-ban" onClick={(e) => this.annule_seuil()} /></div>
                                        <div className="p-col"><Button label="Valider" icon="pi pi-check" onClick={(e) => this.enreg_seuil()} /></div>
                                    </div>
                                    : null}
                                {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : null}
                            </center>

                            <DataTable value={this.state.liste_seuil}
                                responsive={true} scrollable={true} scrollHeight="500px" selectionMode="single"
                                onRowClick={e => this.setState({ selectedSeuil: e.data })}
                               selection={this.state.selectedSeuil} >
                                <Column header="#" body={this.actionTemplate.bind(this)} style={{ width: '10%' }} />
                                <Column field="idArticle.libArticle" header="Article" filter={true} filterMatchMode='contains' sortable={true} style={{ width: '31%' }} />
                                <Column field="idArticle.cbArticle" header="Code barre" filter={true} filterMatchMode='contains' sortable={true} style={{ width: '13%' }} />
                                <Column field="prixRevientSeuil" body={(rowData, column) => {
                                    let prixRevientSeuil = '*****';
                                    if (this.state.userconnected.profilUser === 'PDG') {
                                        prixRevientSeuil = this.formaterMontant(parseFloat(rowData.prixRevientSeuil));
                                    }
                                    return <div>
                                        <span style={{ color: '#000' }}>{prixRevientSeuil}</span>
                                    </div>
                                }} header="Prix revient" filter={true} filterMatchMode='contains' sortable={true} style={{ width: '12%' }} />
                                <Column field="qteSeuil" header="Seuil Min" filter={true} filterMatchMode='contains' sortable={true} style={{ width: '10%' }} />
                                <Column field="qteSeuilMax" header="Seuil Max" filter={true} filterMatchMode='contains' sortable={true} style={{ width: '11%' }} />
                                <Column field="prixVenteSeuil" body={(rowData, column) => {
                                    return <div>
                                        <span style={{ color: '#000' }}>{this.formaterMontant(rowData.prixVenteSeuil)}</span>
                                    </div>
                                }} header="Prix vente" filter={true} filterMatchMode='contains' sortable={true} style={{ width: '12%' }} />
                                <Column field="idBoutiq.nomBoutiq" header="Boutique" filter={true} filterMatchMode='contains' sortable={true} style={{ width: '20%' }} />
                            </DataTable>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}