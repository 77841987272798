import React, { Component } from 'react';
import { Xulaservice } from '../service/Xulaservice';
import { Calendar } from 'primereact/calendar';
import { DataTable } from 'primereact/datatable';
import { Dropdown } from 'primereact/dropdown';
import { Column } from 'primereact/column';
import { Growl } from 'primereact/growl';
import './styles.css';
import { SplitButton } from 'primereact/splitbutton';
import { Chart } from 'primereact/chart';
import 'moment';
import 'moment/locale/fr';
import moment from 'moment';
import { ProgressSpinner } from 'primereact/progressspinner';
import Numeral from 'numeral';

export class FormStat_depense extends Component {
    constructor() {
        super();
        this.state = {
            pd1: new Date(),
            pd2: new Date(),
            ridCli: null,
            liste_data: [],
            liste_boutique: [],
            lboutique: [],
            ridBoutiq: { value: 'Tous', label: 'Boutique' },
            total_depense: '0',
            items: [
                {
                    label: 'Format PDF',
                    icon: 'pi pi-print',
                    command: (e) => {
                        this.conf_print('PDF');
                    }
                },
                {
                    label: 'Format XLS',
                    icon: 'pi pi-print',
                    command: (e) => {
                        this.conf_print('XLS');
                    }
                }
            ],
            userconnected: JSON.parse(localStorage.getItem('userconnected'))
        };
        this.myservice = new Xulaservice();
        this.conf_print = this.conf_print.bind(this);
        this.state.pd1.setDate(this.state.pd1.getDate() - 120);
    }

    componentDidMount() {
        this.get_liste_boutique();
        this.get_liste_data();
    }

    formaterMontant(montant) {
        return Numeral(parseFloat(montant)).format('0,0');
    }

    get_liste_boutique() {
        let lboutique = null;
        let lboutique_rech = [{ value: 'Tous', label: 'Boutique' }];
        this.myservice.getListe_boutique().then(data => this.setState({ liste_boutique: data }, () => {
            if (this.state.liste_boutique != null && this.state.liste_boutique != undefined) {
                lboutique = this.state.liste_boutique.map(x => {
                    return { value: x.idBoutiq, label: x.nomBoutiq }
                });
            }
            
            if (this.state.userconnected.profilUser === 'PDG' || this.state.userconnected.profilUser === 'Coordinateur') {
                this.setState({ lboutique: lboutique });
                lboutique_rech = lboutique_rech.concat(lboutique);

            } else {
                let id = this.state.userconnected.matrPers.idBoutiq.idBoutiq;
                if (lboutique.length > 0) {
                    let liste = lboutique.filter(function (el) {
                        return el.value === id;
                    });
                    this.setState({ lboutique: liste });
                    lboutique_rech = lboutique_rech.concat(liste);
                }
            }
            this.setState({ lboutique: lboutique_rech })
        }));
    }

   
    conf_print(format) {
        this.setState({ showindicator: true});
        let rboutique = 'Tous';
        let spd1 = moment(this.state.pd1).format('DD-MM-YYYY');
        let spd2 = moment(this.state.pd2).format('DD-MM-YYYY');
        if (this.state.ridBoutiq != undefined || this.state.ridBoutiq != null) {
            rboutique = this.state.ridBoutiq.value;
        }
        this.myservice.generer_st_depense(spd1, spd2, rboutique, format);
        this.setState({ showindicator: false});
    }

    get_liste_data() {
        this.setState({ showindicator: true, liste_data: [],total_depense: '0' });
        let rboutique = 'Tous';
        let spd1 = moment(this.state.pd1).format('DD-MM-YYYY');
        let spd2 = moment(this.state.pd2).format('DD-MM-YYYY');
        if (this.state.ridBoutiq != undefined || this.state.ridBoutiq != null) {
            rboutique = this.state.ridBoutiq.value;
        }
        this.myservice.get_st_depense(spd1, spd2, rboutique).then(data => this.setState({ liste_data: data, showindicator: false }, () => {
            let initialValue = 0
            if (data != null && data != undefined) {
                let tt = data.reduce(function (accumulator, currentValue) {
                    return accumulator + currentValue.dval
                }, initialValue);
                this.setState({ total_depense: this.formaterMontant(tt) });
            }
        }));
    }




    render() {

        let liste_label = null;
        let liste_ds_stat = null;

        if (this.state.liste_data != null && this.state.liste_data != undefined) {
            if (this.state.liste_data.length > 0) {
                liste_label = this.state.liste_data.map(x => {
                    return x.lib
                });
                liste_ds_stat = this.state.liste_data.map(x => {
                    return x.dval
                });
            }
        }

        const chart_data = {
            labels: liste_label,
            datasets: [
                {
                    backgroundColor: [
                        "#0673c7",
                        "#f07d82",
                        "#0d5943",
                        "#2c87b8",
                        "#FF6384",
                        "#36A2EB",
                        "#4071a7",
                        "#fcba03",
                        "#a6a5a2",
                        "#9cb090",
                        "#52ba9b",
                        "#06c1cf",
                        "#275996",
                        "#ff7900",
                        "#003366",
                        "#295380",
                        "#d87a3c",
                        "#36A2EB",
                        "#FFCE56",
                        "#293780",

                        "#FADBD8",
                        "#EBDEF0",
                        "#C39BD3",
                        "#D1F2EB",
                        "#5499C7",
                        "#2874A6",
                        "#E74C3C",
                        "#154360",
                        "#F9E79F",
                        "#EDBB99",
                        "#1B2631",
                        "#5D6D7E",
                        "#A6ACAF",
                        "#95A5A6",
                        "#F4D03F",
                        "#28B463",
                        "#D4AC0D",
                        "#A3E4D7",
                        "#5DADE2",
                        "#138D75",
                        "#6C3483",
                        "#16A085",
                        "#82E0AA",
                        "#A2D9CE",
                        "#0B5345",
                        "#1B4F72",
                        "#78281F",
                        "#34495E",
                        "#F6DDCC",
                        "#566573",
                        "#F0B27A",
                        "#7B7D7D",
                        "#935116",
                        "#117A65",
                        "#2C3E50",
                        "#D5F5E3",
                        "#FBEEE6",
                        "#E67E22",
                        "#9B59B6",
                        "#1F618D",
                        "#641E16",
                        "#AAB7B8",
                    ],
                    data: liste_ds_stat
                }
            ]
        };
 
        const footer = <div style={{ textAlign: 'left' }}>
            <label style={{fontWeight: 'bold', fontSize: 18}} >{this.state.total_depense}</label>
        </div>;

        return (
            <div className="p-fluid">
                <Growl ref={(el) => this.growl = el} />
                <div className="p-grid">
                    <div className="p-col-12">
                        <div className="card card-w-title" style={{ height: 700 }}>
                            <i className="fa fa-fw fa-search"></i>Option de recherche
                         <hr />
                            <div className="p-grid">
                                <div className="p-col-12">
                                    <div className="p-grid">
                                        <div className="p-col-12 p-md-2">
                                            <Calendar value={this.state.pd1} dateFormat="dd/mm/yy" onChange={(e) => this.setState({ pd1: e.value })} />
                                        </div>
                                        <div className="p-col-12 p-md-2">
                                            <Calendar value={this.state.pd2} dateFormat="dd/mm/yy" onChange={(e) => this.setState({ pd2: e.value })} />
                                        </div>
                                        <div className="p-col-12 p-md-4">
                                            <Dropdown options={this.state.lboutique} placeholder="Boutique" value={this.state.ridBoutiq} optionLabel='label' filter={true} filterBy='label' filterMatchMode='contains' onChange={(e) => this.setState({ ridBoutiq: e.value },()=>{
                                                this.get_liste_data();
                                            })} autoWidth={false} />
                                        </div>
                                        <div className="p-col-12 p-md-3">
                                            <div className="p-grid" style={{ width: 200 }}>
                                                <div className="p-col"><SplitButton label="Rechercher" style={{ width: '140px' }} onClick={(e) => this.get_liste_data()} icon="pi pi-search" model={this.state.items}></SplitButton></div>
                                                <div className="p-col">  {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : null}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="p-grid">

                            <div className="p-col-12 p-lg-7">
                                    <div className="p-grid">
                                        <div className="p-col-12">
                                            <div className="content-section implementation">
                                                <Chart type="pie" data={chart_data} height="160w" />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="p-col-12 p-lg-5">
                                    <div className="p-grid">
                                        <div className="p-col-12">
                                            <DataTable value={this.state.liste_data} scrollable={true} scrollHeight="450px"
                                                responsive={true} footer={footer} >
                                                <Column field="lib" header="Type dépense" style={{ width: '50%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                                <Column field="str_dval" header="Montant" style={{ width: '25%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                                <Column field="lib3" header="Prc." style={{ width: '25%' }}  filter={true} filterMatchMode='contains' sortable={true} />
                                            </DataTable>
                                        </div>
                                    </div>
                                </div>

                               
                               

                            </div>
                        </div>
                    </div>
                </div>
            </div>


        );
    }
}