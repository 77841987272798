import React, { Component } from 'react';
import { Route, withRouter } from 'react-router-dom';
import App from "./App";
import Login from "./pages/Login";
import Error from "./pages/Error";
import NotFound from "./pages/NotFound";
import Access from "./pages/Access";


class AppWrapper extends Component {
	componentDidUpdate(prevProps) {
		if (this.props.location !== prevProps.location) {
			window.scrollTo(0, 0)
		}
	}
	verif_acces(profil,page){
		page=page.replace("/","");
		let liste_acces=[''];
		if(profil=='Administrateur'){
			liste_acces=['sample','changer_mot_de_passe','rapprochement_stock','sortie','transfert','Form_inventaire','sample','articles','users','regimes','sites','file_active','depots','commandes','edition_commandes','situation_commandes','commandes_produits','stat_commandes','/','dashboard','inventaire','arrivage'];
		}else if(profil=='Consultation'){
			liste_acces=['changer_mot_de_passe','rapprochement_stock','edition_commandes','situation_commandes','commandes_produits','stat_commandes','/','dashboard','inventaire'];
		}else if(profil=='Responsabe site'){
			liste_acces=['changer_mot_de_passe','edition_commandes','sortie','transfert','Form_inventaire','situation_commandes','commandes_produits','stat_commandes','/','dashboard','inventaire','commandes','arrivage'];
		}
		var ind = parseInt(liste_acces.indexOf(page));
		if(ind<0){
			window.location = "#/login";
		}
	}  
	
	render() { 
		switch (this.props.location.pathname) {
			case "/login":
				return <Route path="/login" component={Login} />
			case "/error":
				return <Route path="/error" component={Error} />
			case "/notfound":
				return <Route path="/notfound" component={NotFound} />
			case "/access":
				return <Route path="/access" component={Access} />
			default:
				let key = localStorage.getItem('userconnected');
				let token = localStorage.getItem('token');
				if (key == null || token == null) {
					window.location = "#/login";
					return <Route path="/login" component={Login} />
				} else {
					let user = JSON.parse(key);
					if (user == null && user == undefined) {
						window.location = "#/login";
					} 
					/* if(user.profilUser!=='PDG' && user.profilUser!=='Coordinateur' && user.idEtabl.codeEtabl=='EDK'){
						var dt = new Date();
						var h=dt.getHours();
						if(h<8 || h>18){
							window.location = "#/login";
						}
					} */
					/*
					else {
						this.verif_acces(user.profilUser, this.props.location.pathname);
					}
					*/
					return <App />;
				}
		}
	}
}

export default withRouter(AppWrapper);