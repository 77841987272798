import React, { Component } from 'react';
import { Xulaservice } from '../service/Xulaservice';
import { DataTable } from 'primereact/datatable';
import { Dropdown } from 'primereact/dropdown';
import { Column } from 'primereact/column';
import { Growl } from 'primereact/growl';
import './styles.css';
import { SplitButton } from 'primereact/splitbutton';
import { ProgressSpinner } from 'primereact/progressspinner';
import Numeral from 'numeral';
import { Button } from 'primereact/button';

export class FormSituationDette extends Component {
    constructor() {
        super();
        this.state = { 
            liste_data_du: [],
            liste_data_dette: [],
            liste_boutique: [],
            lboutique: [],
            ridBoutiq: { value: 'Tous', label: 'Boutique' },
            userconnected: JSON.parse(localStorage.getItem('userconnected'))
        };
        this.myservice = new Xulaservice();

    }

    componentDidMount() {
        this.get_liste_boutique();
    }

    formaterMontant(montant) {
        return Numeral(parseFloat(montant)).format('0,0');
    }

    get_liste_boutique() {
        let lboutique = null;
        let lboutique_rech = [{ value: 'Tous', label: 'Boutique' }];
        this.myservice.getListe_boutique().then(data => this.setState({ liste_boutique: data }, () => {
            if (this.state.liste_boutique != null && this.state.liste_boutique != undefined) {
                lboutique = this.state.liste_boutique.map(x => {
                    return { value: x.idBoutiq, label: x.nomBoutiq }
                });
            }

            if (this.state.userconnected.profilUser === 'PDG' || this.state.userconnected.profilUser === 'Coordinateur') {
                this.setState({ lboutique: lboutique });
                lboutique_rech = lboutique_rech.concat(lboutique);

            } else {
                let id = this.state.userconnected.matrPers.idBoutiq.idBoutiq;
                if (lboutique.length > 0) {
                    let liste = lboutique.filter(function (el) {
                        return el.value === id;
                    });
                    this.setState({ lboutique: liste });
                    lboutique_rech = lboutique_rech.concat(liste);
                }
            }
            this.setState({ lboutique: lboutique_rech })
        }));
    }




    get_liste_data() {
        this.setState({ showindicator: true, liste_data: [], total_depense: '0' });
        let rboutique = 'Tous';
        if (this.state.ridBoutiq != undefined || this.state.ridBoutiq != null) {
            rboutique = this.state.ridBoutiq.value;
        }
        this.myservice.get_detail_recouvrement(rboutique, 'Tous').then(data => this.setState({ liste_data_dette: data, showindicator: false }));
        this.myservice.get_detail_ttdu_client(rboutique, 'Tous').then(data => this.setState({ liste_data_du: data, showindicator: false }));
    }




    render() {
        return (
            <div className="p-fluid">
                <Growl ref={(el) => this.growl = el} />
                <div className="p-grid">
                    <div className="p-col-12">
                        <div className="card card-w-title" style={{ height: 700 }}>
                            <i className="fa fa-fw fa-search"></i>Option de recherche
                            <hr />
                            <div className="p-grid">
                                <div className="p-col-12">
                                    <div className="p-grid">
                                        <div className="p-col-12 p-md-5">
                                            <Dropdown options={this.state.lboutique} placeholder="Boutique" value={this.state.ridBoutiq} optionLabel='label' filter={true} filterBy='label' filterMatchMode='contains' onChange={(e) => this.setState({ ridBoutiq: e.value }, () => {
                                                this.get_liste_data();
                                            })} autoWidth={false} />
                                        </div>
                                        <div className="p-col-12 p-md-3">
                                            <div className="p-grid" style={{ width: 200 }}>
                                                <div className="p-col"><SplitButton label="Rechercher" style={{ width: '140px' }} onClick={(e) => this.get_liste_data()} icon="pi pi-search" model={this.state.items}></SplitButton></div>
                                                <div className="p-col">  {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : null}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="p-grid">

                                <div className="p-col-12 p-lg-6">
                                    <div className="p-grid">
                                        <div className="p-col-12">
                                            <div className="content-section implementation">
                                                <DataTable value={this.state.liste_data_dette}
                                                    paginatorPosition="top" paginator={true} rows={10} rowsPerPageOptions={[10, 15, 20]} paginatorLeft={<Button icon='pi pi-print' className="p-button-warning" label='Exporter' style={{ width: '140px', }} onClick={(e) => this.dtg.exportCSV()} />}
                                                    responsive={true} csvSeparator=";" ref={(el) => { this.dtg = el; }} paginatorRight={this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : null}
                                                >
                                                    <Column field="nomCli" header="Client" style={{ width: '25%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                                    <Column field="total_entree" body={(rowData, column) => {
                                                        return <div>
                                                            <span>{this.formaterMontant(rowData.total_entree)}</span>
                                                        </div>
                                                    }} header="Dépots" style={{ width: '15%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                                    <Column field="total_sortie" body={(rowData, column) => {
                                                        return <div>
                                                            <span >{this.formaterMontant(rowData.total_sortie)}</span>
                                                        </div>
                                                    }} header="Retraits" style={{ width: '20%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                                    <Column field="total_rest_facture" body={(rowData, column) => {
                                                        return <div>
                                                            <span >{this.formaterMontant(rowData.total_rest_facture)}</span>
                                                        </div>
                                                    }} header="Restant facture" style={{ width: '20%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                                    <Column field="balance" body={(rowData, column) => {
                                                        return <div>
                                                            <span style={{ fontWeight: 'bold', color: 'red' }}>{this.formaterMontant(rowData.balance)}</span>
                                                        </div>
                                                    }} header="Balance" style={{ width: '20%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                                </DataTable>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="p-col-12 p-lg-6">
                                    <div className="p-grid">
                                        <div className="p-col-12">
                                            <DataTable value={this.state.liste_data_du}
                                                paginatorPosition="top" paginator={true} rows={10} rowsPerPageOptions={[10, 15, 20]} paginatorLeft={<Button icon='pi pi-print' className="p-button-warning" label='Exporter' style={{ width: '140px', }} onClick={(e) => this.dtdu.exportCSV()} />}
                                                responsive={true} csvSeparator=";" ref={(el) => { this.dtdu = el; }} paginatorRight={this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : null}
                                            >
                                                <Column field="nomCli" header="Client" style={{ width: '25%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                                <Column field="total_depot" body={(rowData, column) => {
                                                    return <div>
                                                        <span>{this.formaterMontant(rowData.total_depot)}</span>
                                                    </div>
                                                }} header="Dépots" style={{ width: '15%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                                <Column field="total_emprunt" body={(rowData, column) => {
                                                    return <div>
                                                        <span >{this.formaterMontant(rowData.total_emprunt)}</span>
                                                    </div>
                                                }} header="Emprunts" style={{ width: '15%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                                <Column field="total_retrait" body={(rowData, column) => {
                                                    return <div>
                                                        <span >{this.formaterMontant(rowData.total_retrait)}</span>
                                                    </div>
                                                }} header="Retrait" style={{ width: '15%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                                <Column field="total_rgl_facture" body={(rowData, column) => {
                                                    return <div>
                                                        <span >{this.formaterMontant(rowData.total_rgl_facture)}</span>
                                                    </div>
                                                }} header="Règlements" style={{ width: '15%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                                <Column field="balance" body={(rowData, column) => {
                                                    return <div>
                                                        <span style={{ fontWeight: 'bold' }}>{this.formaterMontant(rowData.balance)}</span>
                                                    </div>
                                                }} header="Balance" style={{ width: '15%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                            </DataTable>
                                        </div>
                                    </div>
                                </div>




                            </div>
                        </div>
                    </div>
                </div>
            </div>


        );
    }
}