import React, { Component } from 'react';
import { Xulaservice } from '../service/Xulaservice';
import { Calendar } from 'primereact/calendar';
import { DataTable } from 'primereact/datatable';
import { Dropdown } from 'primereact/dropdown';
import { Column } from 'primereact/column';
import { Growl } from 'primereact/growl';
import './styles.css';
import { SplitButton } from 'primereact/splitbutton';
import { Chart } from 'primereact/chart';
import 'moment';
import 'moment/locale/fr';
import moment from 'moment';
import { ProgressSpinner } from 'primereact/progressspinner';
import Numeral from 'numeral';
import { InputText } from 'primereact/inputtext';

export class FormStat_vente_agent extends Component {
    constructor() {
        super();
        this.state = {
            pd1: new Date(),
            pd2: new Date(),
            liste_data: [],
            liste_boutique: [],
            lboutique: [],
            ridBoutiq: { value: 'Tous', label: 'Boutique' },
            items: [
                {
                    label: 'Exporter',
                    icon: 'pi pi-print',
                    command: (e) => {
                        this.dt.exportCSV();
                    }
                }
            ],
            userconnected: JSON.parse(localStorage.getItem('userconnected'))
        };
        this.myservice = new Xulaservice();
        //this.state.pd1.setDate(this.state.pd1.getDate() - 120);
    }

    componentDidMount() {
        this.get_liste_boutique();
        this.get_liste_data();
    }

    formaterMontant(montant) {
        return Numeral(parseFloat(montant)).format('0,0');
    }

   

   
    get_liste_boutique() {
        let lboutique = null;
        let lboutique_rech = [{ value: 'Tous', label: 'Boutique' }];
        this.myservice.getListe_boutique().then(data => this.setState({ liste_boutique: data }, () => {
            if (this.state.liste_boutique != null && this.state.liste_boutique != undefined) {
                lboutique = this.state.liste_boutique.map(x => {
                    return { value: x.idBoutiq, label: x.nomBoutiq }
                });
            }
            if (this.state.userconnected.profilUser === 'PDG' || this.state.userconnected.profilUser === 'Coordinateur') {
                this.setState({ lboutique: lboutique });
                lboutique_rech = lboutique_rech.concat(lboutique);

            } else {
                let id = this.state.userconnected.matrPers.idBoutiq.idBoutiq;
                if (lboutique.length > 0) {
                    let liste = lboutique.filter(function (el) {
                        return el.value === id;
                    });
                    this.setState({ lboutique: liste });
                    lboutique_rech = lboutique_rech.concat(liste);
                }
            }
            this.setState({ lboutique: lboutique_rech })
        }));
    }


    

    get_liste_data() {
       
        this.setState({ total:'0'});
        this.setState({ showindicator: true, liste_data: [] });
        let rboutique = 'Tous';
        let spd1 = moment(this.state.pd1).format('DD-MM-YYYY H:mm:ss');
        let spd2 = moment(this.state.pd2).format('DD-MM-YYYY H:mm:ss');
        if (this.state.ridBoutiq != undefined || this.state.ridBoutiq != null) {
            rboutique = this.state.ridBoutiq.value;
        } 
       
        this.myservice.get_st_sortie_by_agent(spd1, spd2, rboutique).then(data => this.setState({ liste_data: data, showindicator: false }, () => {
            let initialValue = 0
            if (data != null && data != undefined) {
                let tt = data.reduce(function (accumulator, currentValue) {
                    return accumulator + currentValue.dval
                }, initialValue);
                this.setState({ total: this.formaterMontant(tt) });
            }
        }));
    }



    render() {

        let liste_label = null;
        let liste_ds_stat = null;

        if (this.state.liste_data != null && this.state.liste_data != undefined) {
            if (this.state.liste_data.length > 0) {
                liste_label = this.state.liste_data.map(x => {
                    return x.lib2
                });
                liste_ds_stat = this.state.liste_data.map(x => {
                    return x.dval
                });
            }
        }

        const chart_data = {
            labels: liste_label,
            datasets: [
                {
                    backgroundColor: [
                        "#0673c7",
                        "#f07d82",
                        "#0d5943",
                        "#2c87b8",
                        "#FF6384",
                        "#36A2EB",
                        "#4071a7",
                        "#fcba03",
                        "#a6a5a2",
                        "#9cb090",
                        "#52ba9b",
                        "#06c1cf",
                        "#275996",
                        "#ff7900",
                        "#003366",
                        "#295380",
                        "#d87a3c",
                        "#36A2EB",
                        "#FFCE56",
                        "#293780",
                        "#9d42c7",
                        "#6e348a",
                        "#514a54",
                        "#6b7685",
                        "#51698a",
                        "#3362a3",
                        "#1269e0",
                        "#031938",
                        "#063578",
                        "#c70ac7",
                        "#630363",
                        "#61021a",
                        "#d6a020",
                        "#6e5b2f",
                        "#b35809",
                        "#73685e",
                        "#633207",

                    ],
                    label: '',
                    data: liste_ds_stat
                }
            ]
        };

        const footer = <div style={{ textAlign: 'left' }}>
            <label style={{ fontWeight: 'bold', fontSize: 18 }} >{this.state.total}</label>
        </div>;

        return (
            <div className="p-fluid">
                <Growl ref={(el) => this.growl = el} />
                <div className="p-grid">
                    <div className="p-col-12">
                        <div className="card card-w-title" style={{ height: 700 }}>
                            <i className="fa fa-fw fa-search"></i>Option de recherche
                         <hr />
                            <div className="p-grid">
                                <div className="p-col-12">
                                    <div className="p-grid">
                                        <div className="p-col-12 p-md-2">
                                            <Calendar value={this.state.pd1} showTime={true} dateFormat="dd/mm/yy" onChange={(e) => this.setState({ pd1: e.value })} />
                                        </div>
                                        <div className="p-col-12 p-md-2">
                                            <Calendar value={this.state.pd2}  showTime={true} dateFormat="dd/mm/yy" onChange={(e) => this.setState({ pd2: e.value })} />
                                        </div>
                                        <div className="p-col-12 p-md-3">
                                            <Dropdown options={this.state.lboutique} placeholder="Boutique" value={this.state.ridBoutiq} optionLabel='label' filter={true} filterBy='label' filterMatchMode='contains' onChange={(e) => this.setState({ ridBoutiq: e.value }, () => {
                                                this.get_liste_data();
                                            })} autoWidth={false} />
                                        </div>
                                        <div className="p-col-12 p-md-2">
                                            <div className="p-grid" style={{ width: 200 }}>
                                                <div className="p-col"><SplitButton label="Rechercher" style={{ width: '140px' }} onClick={(e) => this.get_liste_data()} icon="pi pi-search" model={this.state.items}></SplitButton></div>
                                                <div className="p-col">  {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : null}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="p-grid">

                                <div className="p-col-12 p-lg-7">
                                    <div className="p-grid">
                                        <div className="p-col-12">
                                            <div className="content-section implementation">
                                                <Chart type="bar" data={chart_data} height="170w" />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="p-col-12 p-lg-5">
                                    <div className="p-grid">
                                        <div className="p-col-12">
                                            <DataTable value={this.state.liste_data} scrollable={true} scrollHeight="400px"
                                                responsive={true} footer={footer}  ref={(el) => { this.dt = el; }}>
                                                <Column field="lib2" header="Utilisateur" style={{ width: '60%' }}  filter={true} filterMatchMode='contains'/>
                                                <Column field="str_dval" header="Montant" style={{ width: '20%' }}  filter={true} filterMatchMode='contains'/>
                                                <Column field="lib3" header="Prc." style={{ width: '20%' }}  filter={true} filterMatchMode='contains'/>
                                            </DataTable>
                                        </div>
                                    </div>
                                </div>



                            </div>
                        </div>
                    </div>
                </div>
            </div>


        );
    }
}