import React, { Component } from 'react';
import { Xulaservice } from '../service/Xulaservice';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { SplitButton } from 'primereact/splitbutton';
import './styles.css';
import { ProgressSpinner } from 'primereact/progressspinner';
import Numeral from 'numeral';
import 'moment';
import 'moment/locale/fr';
import moment from 'moment';
import { Calendar } from 'primereact/calendar';

export class FormEdition_Situation_bobine extends Component {
    constructor() {
        super();
        this.state = {
            pd1: new Date(),
            pd2: new Date(),
            liste_article: [],
            selectedData: null,
            rstatutBobine: { label: 'Statut', value: 'Tous' },
            items: [
                {
                    label: 'Format CSV',
                    icon: 'pi pi-print',
                    command: (e) => {
                        this.dt.exportCSV();
                    }
                }
            ],
            userconnected: JSON.parse(localStorage.getItem('userconnected')),
        };
        this.xulaservice = new Xulaservice();
    }




    formaterMontant(montant) {
        return Numeral(parseFloat(montant)).format('0,0');
    }


    componentDidMount() {
        this.get_liste_data();
    }

   
    get_liste_data() {
        let rstatut = 'Tous';
        if (this.state.rstatutBobine != undefined || this.state.rstatutBobine != null) {
            rstatut = this.state.rstatutBobine.value;
        }
        let spd1 = moment(this.state.pd1).format('DD-MM-YYYY');
        let spd2 = moment(this.state.pd2).format('DD-MM-YYYY');
        this.setState({ showindicator: true });
        this.xulaservice.get_situation_bobine(spd1, spd2, rstatut).then(data => this.setState({ liste_article: data, showindicator: false }, () => {
            if (data != null && data != undefined) {
                let initialValue = 0
              /*   let total_facture = data.reduce(function (accumulator, currentValue) {
                    return accumulator + currentValue.montant
                }, initialValue); */
            }
        }));
    }


   

    render() {
        return (
            <div className="p-fluid">

                <div className="p-grid">
                    <div className="p-col-12">
                        <div className="card card-w-title" style={{ minHeight: '900px' }}>
                            <i className="fa fa-fw fa-search"></i>Option de recherche
                            <hr />
                            <div className="p-grid">
                                <div className="p-col-12 p-md-2">
                                    <Calendar value={this.state.pd1} dateFormat="dd/mm/yy" onChange={(e) => this.setState({ pd1: e.value })} />
                                </div>
                                <div className="p-col-12 p-md-2">
                                    <Calendar value={this.state.pd2} dateFormat="dd/mm/yy" onChange={(e) => this.setState({ pd2: e.value })} />
                                </div>
                               
                                <div className="p-col-12 p-md-3">
                                    <Dropdown value={this.state.rstatutBobine} options={[
                                        { label: 'Statut', value: 'Tous' },
                                        { label: 'En cours', value: 'En cours' },
                                        { label: 'Consommé', value: 'Consommé' },
                                    ]} optionLabel='label' filter={true} filterBy='label' onChange={(e) => this.setState({ rstatutBobine: e.value }, () => {
                                        this.get_liste_data();
                                    })} autoWidth={false} />
                                </div>

                                <div className="p-col-12 p-md-2">
                                    <div className="p-inputgroup">
                                        <SplitButton label="Rechercher" style={{ width: '140px' }} onClick={(e) => this.get_liste_data()} icon="pi pi-search" model={this.state.items}></SplitButton>
                                        {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : null}
                                    </div>
                                </div>
                            </div>


                            <DataTable value={this.state.liste_article} paginatorPosition="top" paginator={true} rows={20}
                               rowsPerPageOptions={[10, 20, 50, 100, 200]}
                                responsive={true}  csvSeparator=";" ref={(el) => { this.dt = el; }} >
                                <Column field="numBC" header="N° BC" style={{ width: '13%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                <Column field="datesortie" header="Date BC" style={{ width: '10%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                <Column field="datesortie" header="Fournisseur" style={{ width: '15%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                <Column field="libArt" header="Désignation" filter={true} filterMatchMode='contains' sortable={true} />
                                <Column field="type_sortie" header="Référence" style={{ width: '10%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                <Column field="qte" header="M avarie" style={{ width: '8%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                <Column field="qte_livr" header="M produit" style={{ width: '8%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                <Column field="idBoutiq.codeBoutiq" header="Statut" style={{ width: '10%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                <Column field="idBoutiq.codeBoutiq" header="Remarque" style={{ width: '12%' }} filter={true} filterMatchMode='contains' sortable={true} />

                            </DataTable>

                        </div>
                    </div>
                </div>
            </div>
        );
    }
}