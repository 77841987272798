import React, { Component } from 'react';
import { Xulaservice } from '../service/Xulaservice';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { ProgressSpinner } from 'primereact/progressspinner';
import Numeral from 'numeral'; import 'moment';
import 'moment/locale/fr';
import moment from 'moment';
import './styles.css';
import { SplitButton } from 'primereact/splitbutton';


export class FormEdition_AlerteRupture extends Component {
    constructor() {
        super();
        this.state = {
            ridBoutiq: null,
            rstatut: null,
            liste_data: [],
            liste_boutique: [],
            items: [
                {
                    label: 'Format CSV',
                    icon: 'pi pi-print',
                    command: (e) => {
                        this.dt.exportCSV();
                    }
                },
            ],

            userconnected: JSON.parse(localStorage.getItem('userconnected')),
        };
        this.exportExcel = this.exportExcel.bind(this);
        this.xulaservice = new Xulaservice();
    }




    get_liste_boutique() {
        let lboutique = null;
        let lboutique_rech = [{ value: 'Tous', label: 'Boutique' }];

        this.xulaservice.getListe_boutique().then(data => this.setState({ liste_boutique: data }, () => {
            if (this.state.liste_boutique != null && this.state.liste_boutique != undefined) {
                lboutique = this.state.liste_boutique.map(x => {
                    return { value: x.idBoutiq, label: x.nomBoutiq }
                });
            }

            if (this.state.userconnected.profilUser === 'PDG' || this.state.userconnected.profilUser === 'Coordinateur') {
                this.setState({ lboutique: lboutique });
                lboutique_rech = lboutique_rech.concat(lboutique);

            } else {
                let id = this.state.userconnected.matrPers.idBoutiq.idBoutiq;
                if (lboutique.length > 0) {
                    let liste = lboutique.filter(function (el) {
                        return el.value === id;
                    });
                    this.setState({ lboutique: liste });
                    lboutique_rech = lboutique_rech.concat(liste);
                }
            }
            this.setState({ lboutique_rech: lboutique_rech }, () => {
                this.setState({ ridBoutiq: { value: this.state.userconnected.matrPers.idBoutiq.idBoutiq, label: this.state.userconnected.matrPers.idBoutiq.nomBoutiq } });
            })
        }));
    }


    componentDidMount() {
        this.get_liste_boutique();
    }

    formaterMontant(montant) {
        return Numeral(parseFloat(montant)).format('0,0');
    }

    get_liste_data() {
        this.setState({ showindicator: true });
        let rid_boutique = 'Tous';
        let rstatut = 'Tous';
        if (this.state.ridBoutiq != undefined && this.state.ridBoutiq != null) {
            rid_boutique = this.state.ridBoutiq.value;
        }
        if (this.state.rstatut !== undefined && this.state.rstatut !== null) {
            rstatut = this.state.rstatut.value;
        }
        this.xulaservice.get_liste_alerte_rupture(rid_boutique, rstatut).then(data => this.setState({ liste_data: data, showindicator: false }));
    }


    /* Exportation du tableau  en fichier XLSX */
    exportExcel = () => {
        import('xlsx').then(xlsx => {
            const worksheet = xlsx.utils.json_to_sheet(this.state.liste_data);
            const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
            const excelBuffer = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
            this.saveAsExcelFile(excelBuffer, 'ALERTE_RUPTURE_STOCK');
        });
    }

    /* Enregistrer le fichier sur le client */
    saveAsExcelFile = (buffer, fileName) => {
        import('file-saver').then(module => {
            if (module && module.default) {
                let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
                let EXCEL_EXTENSION = '.xlsx';
                const data = new Blob([buffer], {
                    type: EXCEL_TYPE
                });

                module.default.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
            }
        });
    }



    render() {

        return (
            <div className="p-fluid">

                <div className="p-grid">
                    <div className="p-col-12">
                        <div className="card card-w-title" style={{ minHeight: '900px' }}>
                            <div className="p-grid">
                                <div className="p-col-12">
                                    <div className="p-grid">
                                        <div className="p-col-12 p-md-4">
                                            <Dropdown options={this.state.lboutique_rech} placeholder="Boutique" value={this.state.ridBoutiq} optionLabel='label' filter={true} filterBy='label' filterMatchMode='contains' onChange={(e) => this.setState({ ridBoutiq: e.value }, () => {
                                                this.get_liste_data();
                                            })} autoWidth={false} panelStyle={{ width: '400px' }} />
                                        </div>
                                        <div className="p-col-12 p-md-2">
                                            <Dropdown autoWidth={false} placeholder="Statut" options={[
                                                { label: 'Statut', value: 'Tous' },
                                                { label: 'Rupture', value: 'Rupture' },
                                                { label: 'Stock normal', value: 'Stock normal' },
                                                { label: 'Surstock', value: 'Surstock' }
                                            ]} value={this.state.rstatut} onChange={e => this.setState({ rstatut: e.value })} filter={true} filterBy='label' filterMatchMode='contains' />
                                        </div>
                                        <div className="p-col-12 p-md-2">
                                            <div className="p-inputgroup">
                                                <div className="p-inputgroup">
                                                    <SplitButton label="Rechercher" style={{ width: '140px' }} onClick={(e) => this.get_liste_data()} icon="pi pi-search" model={this.state.items}></SplitButton>
                                                    {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : null}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <DataTable value={this.state.liste_data} ref={(el) => { this.dt = el; }} csvSeparator=";"
                                responsive={true} paginatorPosition="top" paginator={true} rows={15} >
                                <Column field="libArt" header="Désignation" filter={true} filterMatchMode='contains' sortable={true} />
                                <Column field="idBoutiq.nomBoutiq" header="Boutique" style={{ width: '15%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                <Column field="statutStock" header="Statut" body={(rowData, column) => {
                                    let color='red';
                                    if(rowData.statutStock=='Surstock'){
                                        color='#F1C40F';
                                    }else if(rowData.statutStock=='Stock normal'){
                                        color='green';
                                    }
                                    return <div>
                                        <span style={{
                                            height: 10,
                                            width: 10,
                                            backgroundColor: color,
                                            borderRadius: '50%',
                                            display: 'inline-block'
                                        }}></span>
                                        {" "}
                                        <span>{rowData.statutStock}</span>
                                    </div>
                                }} style={{ width: '12%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                <Column field="seuil_article" header="Seuil Min" style={{ width: '10%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                <Column field="seuil_max_article" header="Seuil Max" style={{ width: '10%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                <Column field="qte_stock" header="Stock P" style={{ width: '10%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                <Column field="qte_stock_reel" header="Stock T" style={{ width: '10%' }} filter={true} filterMatchMode='contains' sortable={true} />
                            </DataTable>

                        </div>
                    </div>
                </div >
            </div >
        );
    }
}