import React, { Component } from 'react';
import { Xulaservice } from '../service/Xulaservice';
import { InputText } from 'primereact/inputtext';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dialog } from 'primereact/dialog';
import { Growl } from 'primereact/growl';
import { Button } from 'primereact/button';
import { ProgressSpinner } from 'primereact/progressspinner';

import './styles.css';

export class FormTypeArticle extends Component {
    constructor() {
        super();
        this.state = {
            liste_type: [],
            liste_sous_type: [],
            selectedType: null,
            selectedSousType: null,

            idType: '0',
            libType: '',
            descType: '',

            descStype: '',
            libSType: '',
            idSType: '0',

            msg: '',
            modif: false,
            modif_stype: false,
            visible: false,
            visible_sous_type: false,
            userconnected: JSON.parse(localStorage.getItem('userconnected')),
        };
        this.edit_type = this.edit_type.bind(this);
        this.edit_sous_type = this.edit_sous_type.bind(this);
        this.conf_delete_type = this.conf_delete_type.bind(this);
        this.conf_delete_sous_type = this.conf_delete_sous_type.bind(this);
        this.xulaservice = new Xulaservice();
    }

    componentDidMount() {
        this.getListeType();
    }

    getListeType() {
        this.xulaservice.get_liste_type_article().then(data => {
            this.setState({ liste_type: data });
        });
    }

    getListe_sousType(idType) {
        this.xulaservice.get_liste_sous_type_article(idType).then(data => {
            this.setState({ liste_sous_type: data });
        });
    }

    resultat(code, contenu) {
        if (code == '200') {
            this.growl.show({ severity: 'success', summary: 'Success', detail: contenu });
            this.annule_type();
            this.getListeType();
        } else {
            this.growl.show({ severity: 'error', summary: 'Error', detail: contenu });
        }
    }

    resultat_sous_type(code, contenu) {
        if (code == '200') {
            this.growl.show({ severity: 'success', summary: 'Success', detail: contenu });
            this.annule_sous_type();
            this.getListe_sousType(this.state.selectedType.idType);
        } else {
            this.growl.show({ severity: 'error', summary: 'Error', detail: contenu });
        }
    }

    edit_type(data) {
        if (data != null && data != undefined) {
            this.setState({ idType: data.idType });
            this.setState({ libType: data.libType });
            this.setState({ descType: data.descType });
            this.setState({ modif: true });
            this.setState({ selectedType: data });
        }
    }

    edit_sous_type(data) {
        if (data != null && data != undefined) {
            this.setState({ idSType: data.idStype });
            this.setState({ libSType: data.libStype });
            this.setState({ descStype: data.descStype });

            this.setState({ modif_stype: true });
            this.setState({ selectedSousType: data });
        }
    }

    annule_type() {
        this.setState({ libType: '' });
        this.setState({ descType: '' });
        this.setState({ idType: '0' });
        this.setState({ modif: false });
        this.forceUpdate();
    }
    annule_sous_type() {
        this.setState({ idType: '' });
        this.setState({ libSType: '' });
        this.setState({ descStype: '' });
        this.setState({ idSType: '0' });
        this.setState({ modif_stype: false });
        this.forceUpdate();
    }

    enreg_type() {
        if (this.state.libType === "" || this.state.descType === "") {
            this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez saisir tous les champs obligatoires' });
        } else {
            if (this.state.modif === false) {
                let data = { idType: '0', libType: this.state.libType, descType: this.state.descType };
                this.xulaservice.enreg_type_article(data).then(data => this.resultat(data.code, data.contenu));
            } else {
                let data = this.state.selectedType;
                data.libType = this.state.libType;
                data.descType = this.state.descType;
                this.xulaservice.enreg_type_article(data).then(data => this.resultat(data.code, data.contenu));
            }
        }
    }
 
    enreg_sous_type() {
        if (this.state.selectedType != null && this.state.selectedType != undefined) {
            if (this.state.libSType === "" || this.state.libSType === undefined) {
                this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez saisir tous les champs obligatoires' });
            } else {
                if (this.state.modif_stype === false) {
                    let data = { idStype: '0', idType: { idType: this.state.selectedType.idType }, libStype: this.state.libSType, descStype: this.state.descStype };
                    this.xulaservice.enreg_sous_type_article(data).then(data => {
                        if (data != null && data != undefined) {
                            this.resultat_sous_type(data.code, data.contenu);
                        }
                    });
                } else {
                    let data = this.state.selectedSousType;
                    data.libStype = this.state.libSType;
                    data.descStype = this.state.descStype;
                    data.idType = { idType: this.state.selectedType.idType };
                    this.xulaservice.enreg_sous_type_article(data).then(data => {
                        if (data != null && data != undefined) {
                            this.resultat_sous_type(data.code, data.contenu);
                        }
                    });
                }
            }
        } else {
            this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez selectionner un type article' });
        }
    }

    actionTemplate(rowData, column) {
        let disabled=false;
        if(rowData.libType.toUpperCase().includes("CONSOM") || rowData.libType.toUpperCase().includes("INGR") || rowData.libType.toUpperCase().includes("SERVICE")){
            disabled=true;
        }
        return <div className="p-grid" style={{ width: 90 }}>
            <div className="p-col"><Button disabled={disabled} onClick={(e) => this.edit_type(rowData)} className="p-button-info" style={{ width: 25, height: 25 }} icon="pi pi-pencil" /></div>
            <div className="p-col"><Button disabled={disabled} onClick={(e) => this.conf_delete_type(rowData)} className="p-button-danger" style={{ width: 25, height: 25,marginLeft: -10 }} icon="pi pi-times" /></div>
        </div>
    }
    actionTemplate_sous_type(rowData, column) {
        let disabled=false;
        if(rowData.libStype.toUpperCase().includes("INCONNU") ){
            disabled=true;
        }
        return <div className="p-grid" style={{ width: 90 }}>
            <div className="p-col"><Button disabled={disabled} onClick={(e) => this.edit_sous_type(rowData)} className="p-button-info" style={{ width: 25, height: 25 }} icon="pi pi-pencil" /></div>
            <div className="p-col"><Button disabled={disabled} onClick={(e) => this.conf_delete_sous_type(rowData)} className="p-button-danger" style={{ width: 25, height: 25,marginLeft: -10 }} icon="pi pi-times" /></div>
        </div>
    }


    delete_type() {
        if (this.state.selectedType == null || this.state.selectedType == undefined) {
            this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez sélectionner la ligne à supprimer' });
        } else {
            this.xulaservice.delete_type_article(this.state.selectedType).then(data => this.resultat(data.code, data.contenu));
        }
    }

    delete_sous_type() {
        if (this.state.selectedSousType == null || this.state.selectedSousType == undefined) {
            this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez sélectionner la ligne à supprimer' });
        } else {
            this.xulaservice.delete_sous_type_article(this.state.selectedSousType).then(data => {
                if (data != null && data != undefined) {
                    this.resultat_sous_type(data.code, data.contenu);
                }
            });
        }
    }


    conf_delete_type(data) {
        if (data != null && data != undefined) {
            this.setState({ selectedType: data });
            this.setState({ msg: data.libType });
            this.setState({ visible: true });
        }
    }

    conf_delete_sous_type(data) {
        if (data != null && data != undefined) {
            this.setState({ selectedSousType: data });
            this.setState({ msg: data.libStype });
            this.setState({ visible_sous_type: true });
        }
    }



    render() {
        const dialogFooter = (
            <div>
                <Button icon="pi pi-check" onClick={() => {
                    this.setState({ visible: false });
                    this.delete_type();
                }} label="Oui" />
                <Button icon="pi pi-times" onClick={() => this.setState({ visible: false })} label="Annuler" className="p-button-secondary" />
                }
            </div>
        );

        const dialogFooter_stype = (
            <div>
                <Button icon="pi pi-check" onClick={() => {
                    this.setState({ visible_sous_type: false });
                    this.delete_sous_type();
                }} label="Oui" />
                <Button icon="pi pi-times" onClick={() => this.setState({ visible_sous_type: false })} label="Annuler" className="p-button-secondary" />
            </div>
        );


        return (
            <div className="p-fluid " style={{ backgroundColor: '#ffffff' }}>
                <Growl ref={(el) => this.growl = el} />
                <Dialog header="Confirmation" visible={this.state.visible} style={{ width: '250px' }} modal={true} footer={dialogFooter} onHide={(e) => this.setState({ visible: false })}>
                    <div>
                        <i className="fa fa-warning" style={{ color: '#FFD133', fontSize: 25 }}></i>
                        Voulez vous vraiment supprimer <b>{this.state.msg} </b>?
                    </div>
                </Dialog>

                <Dialog header="Confirmation" visible={this.state.visible_sous_type} style={{ width: '250px' }} modal={true} footer={dialogFooter_stype} onHide={(e) => this.setState({ visible_sous_type: false })}>
                    <div>
                        <i className="fa fa-warning" style={{ color: '#FFD133', fontSize: 25 }}></i>
                        Voulez vous vraiment supprimer <b>{this.state.msg} </b>?
                    </div>
                </Dialog>

                <div className="p-grid" style={{ marginBottom: -20 }}>
                    <div className="p-col-12 p-lg-4">
                        <div className="p-grid">
                            <div className="p-col-12">
                                <div className="card card-w-title" style={{ height: 300 }}>

                                    <i className=""></i>Enregistrement Type Article
                                    <hr />
                                    <div className="p-grid">
                                        <div className="p-col-12 p-md-3">
                                            <label htmlFor="libType">Libellé *</label>
                                        </div>
                                        <div className="p-col-12 p-md-9">
                                            <InputText id="libType" value={this.state.libType} onChange={(e) => this.setState({ libType: e.target.value, descType: e.target.value })} />
                                        </div>
                                        <div className="p-col-12 p-md-3">
                                            <label htmlFor="descType">Description </label>
                                        </div>
                                        <div className="p-col-12 p-md-9">
                                            <InputText id="descType" value={this.state.descType} onChange={(e) => this.setState({ descType: e.target.value })} />
                                        </div>
                                    </div>
                                    <center>
                                        <div className="p-grid" style={{ width: 300 }}>
                                            <div className="p-col"><Button label="Annuler" icon="pi pi-ban" onClick={(e) => this.annule_type()} /></div>
                                            <div className="p-col"><Button label="Valider" icon="pi pi-check" onClick={(e) => this.enreg_type()} /></div>
                                        </div>
                                        {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : null}
                                    </center>

                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="p-col-12 p-lg-8">
                        <div className="p-grid">
                            <div className="p-col-12">
                                <div className="card card-w-title" style={{ height: 300 }}>

                                    <i className=""></i>Liste Type Article
                                            <hr />
                                    <DataTable value={this.state.liste_type}
                                        responsive={true} scrollable={true} scrollHeight="200px" selectionMode="single"
                                        onRowClick={e => {
                                            this.setState({ selectedType: e.data }, () => {
                                                this.getListe_sousType(this.state.selectedType.idType);
                                            });
                                        }}
                                        responsive={true} selection={this.state.selectedType}
                                    >
                                        <Column header="Action" body={this.actionTemplate.bind(this)} style={{ width: '10%' }} />
                                        <Column field="idType" header="ID" sortable={true} style={{ width: '20%' }} />
                                        <Column field="libType" header="Libellé" sortable={true} style={{ width: '40%' }} />
                                        <Column field="descType" header="Description" sortable={true} style={{ width: '30%' }} />
                                    </DataTable>

                                </div>
                            </div>
                        </div>
                    </div>

                </div>




                <div className="p-grid">
                    <div className="p-col-12 p-lg-4">
                        <div className="p-grid">
                            <div className="p-col-12">
                                <div className="card card-w-title" style={{ height: 400 }}>

                                    <i className=""></i>Enregistrement Sous Type
                                    <hr />

                                    <div className="p-grid" >
                                        <div className="p-col-12 p-md-3">
                                            <label htmlFor="libSType">Libellé  *</label>
                                        </div>
                                        <div className="p-col-12 p-md-9">
                                            <InputText id="libSType" value={this.state.libSType} onChange={(e) => this.setState({ libSType: e.target.value, descStype: e.target.value })} />
                                        </div>
                                        <div className="p-col-12 p-md-3">
                                            <label htmlFor="libType">Description</label>
                                        </div>
                                        <div className="p-col-12 p-md-9">
                                            <InputText id="libType" value={this.state.descStype} onChange={(e) => this.setState({ descStype: e.target.value })} />
                                        </div>
                                    </div>

                                    <center>
                                        <div className="p-grid" style={{ width: 300 }}>
                                            <div className="p-col"><Button label="Annuler" icon="pi pi-ban" onClick={(e) => this.annule_sous_type()} /></div>
                                            <div className="p-col"><Button label="Valider" icon="pi pi-check" onClick={(e) => this.enreg_sous_type()} /></div>
                                        </div>
                                        {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : null}
                                    </center>
                                </div>
                            </div>
                        </div>
                    </div> 

                    <div className="p-col-12 p-lg-8">
                        <div className="p-grid">
                            <div className="p-col-12">
                                <div className="card card-w-title" style={{ height: 400 }}>
                                    <i className=""></i>Liste Sous Type
                                    <hr />
                                    <DataTable value={this.state.liste_sous_type}
                                        responsive={true} scrollable={true} scrollHeight="300px" selectionMode="single"
                                        onRowClick={e => this.setState({ selectedSousType: e.data })}
                                        responsive={true} selection={this.state.selectedSousType}
                                    >
                                        <Column header="Action" body={this.actionTemplate_sous_type.bind(this)} style={{ width: '10%' }} />
                                        <Column field="idStype" header="ID" sortable={true} style={{ width: '20%' }} />
                                        <Column field="libStype" header="libellé" sortable={true} style={{ width: '40%' }} />
                                        <Column field="descStype" header="Description" sortable={true} style={{ width: '30%' }} />
                                    </DataTable>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>


            </div>
        );
    }
}