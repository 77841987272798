import React, { Component } from 'react';
import { Xulaservice } from '../service/Xulaservice';
import { InputText } from 'primereact/inputtext';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dialog } from 'primereact/dialog';
import { Growl } from 'primereact/growl';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import './styles.css';
import { ProgressSpinner } from 'primereact/progressspinner';


export class FormBoutique extends Component {
    constructor() {
        super();
        this.state = {
            liste_boutique: [],
            liste_etabl: [],
            idEtabl: null,
            selectedBoutique: null,
            idBoutiq: '0',
            nomBoutiq: '',
            codeBoutiq: '',
            typeBoutiq: null,
            liste_domaine: [
                { label: 'Alimentation générale', value: 'Alimentation générale' },
                { label: 'Banque', value: 'Banque' },
                { label: 'Caisse', value: 'Caisse' },
                { label: 'Construction', value: 'Construction'},
                { label: 'Restaurant', value: 'Restaurant'},
                { label: 'Ingénierie', value: 'Ingénierie' },
                { label: 'Meuble et décor', value: 'Meuble et décor' },
                { label: 'Pharmacie', value: 'Pharmacie' },
                { label: 'Sanitaire', value: 'Sanitaire' },
                { label: 'Bijouterie', value: 'Bijouterie' },
                { label: 'Electrique', value: 'Electrique' },
                { label: 'Habillement', value: 'Habillement' },
                { label: 'Electronique', value: 'Electronique' },
                { label: 'Fonderie', value: 'Fonderie' },
                { label: 'Imprimerie', value: 'Imprimerie' },
                { label: 'Divers', value: 'Divers' },
                { label: 'Usine', value: 'Usine' }
            ],
            adrBoutiq: '',
            msg: '',
            modif: false,
            disable_etabl: false,
            showindicator: false,
            visible: false,
            userconnected: JSON.parse(localStorage.getItem('userconnected')),
       };
        this.edit_boutique = this.edit_boutique.bind(this);
        this.conf_delete_boutique = this.conf_delete_boutique.bind(this);
        this.xulaservice = new Xulaservice();
    }

    componentDidMount() {
       
        this.xulaservice.getListe_etabl().then(data => {
            let letabl = null;
            if (data != null && data != undefined) {
                letabl = data.map(x => {
                    return { value: x.idEtabl, label: x.nomEtabl, codeEtabl: x.codeEtabl }
                });
            }
            this.setState({ liste_etabl: letabl,showindicator: false  },()=>{
                this.setState({ idEtabl: { value: this.state.userconnected.idEtabl.idEtabl, label: this.state.userconnected.idEtabl.nomEtabl, codeEtabl: this.state.userconnected.idEtabl.codeEtabl } });
            });
        });
        if (this.state.userconnected.idEtabl.codeEtabl != 'OPTION') {
            this.setState({ disable_etabl: true });
        }
        this.get_liste_boutique();
    }

    get_liste_boutique(){
        this.setState({showindicator: true});
        this.xulaservice.getListe_boutique().then(data => {
            this.setState({ liste_boutique: data,showindicator: false });
        });
    }
    resultat(code, contenu) {
        if (code == '200') {
            this.growl.show({ severity: 'success', summary: 'Success', detail: contenu });
            this.annule_boutique();
            this.get_liste_boutique();
        } else {
            this.growl.show({ severity: 'error', summary: 'Error', detail: contenu });
        }
        this.setState({ showindicator: false });
    }

    edit_boutique(data) {
        if (data != null && data != undefined) {
            this.setState({ idBoutiq: data.idBoutiq });
            this.setState({ nomBoutiq: data.nomBoutiq });
            this.setState({ codeBoutiq: data.codeBoutiq });
            this.setState({ adrBoutiq: data.adrBoutiq });
            this.setState({ modif: true });
            this.setState({ typeBoutiq: { label: data.typeBoutiq, value: data.typeBoutiq } });
            this.setState({ selectedBoutique: data });
            this.setState({ idEtabl: { value: data.idEtabl.idEtabl, label: data.idEtabl.nomEtabl , codeEtabl: data.idEtabl.codeEtabl} });

        }
    }

    annule_boutique() {
        this.setState({ nomBoutiq: '' });
        this.setState({ codeBoutiq: '' });
        this.setState({ adrBoutiq: '' });
        this.setState({ idBoutiq: 0 });
        this.setState({ modif: false });
        this.forceUpdate();
    }

    enreg_boutique() {
        if (this.state.nomBoutiq === "" || this.state.typecodif === "" || this.state.codeBoutiq === "") {
            this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez saisir tous les champs obligatoires' });
        } else {
            this.setState({ showindicator: true });
            if (this.state.modif === false) {
                if (this.state.userconnected.idEtabl.codeEtabl !== 'OPTION' && this.state.userconnected.idEtabl.codeEtabl !== 'EDK' && this.state.userconnected.idEtabl.codeEtabl !== 'RDJO' && this.state.userconnected.idEtabl.codeEtabl !== 'MOUTOU' && this.state.userconnected.idEtabl.codeEtabl !== 'EDKMI'&& this.state.userconnected.idEtabl.codeEtabl !== 'EDKS') {
                    this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Vous ne pouvez pas ajouter de nouvelles boutiques, merci de contacter le revendeur' });
                    return;
                }
                let data = { idBoutiq: '0', nomBoutiq: this.state.nomBoutiq, codeBoutiq: this.state.codeBoutiq, adrBoutiq: this.state.adrBoutiq,typeBoutiq: this.state.typeBoutiq.value, idEtabl:  this.state.idEtabl.value };
                this.xulaservice.enreg_boutique(data).then(data => this.resultat(data.code, data.contenu));
            } else {
                let data = this.state.selectedBoutique;
                data.adrBoutiq = this.state.adrBoutiq;
                data.nomBoutiq = this.state.nomBoutiq;
                data.codeBoutiq = this.state.codeBoutiq;
                data.typeBoutiq= this.state.typeBoutiq.value;
                this.xulaservice.enreg_boutique(data).then(data => this.resultat(data.code, data.contenu));
            }
        }
    }

    actionTemplate(rowData, column) {
        return <div className="p-grid" style={{ width: 90 }}>
            <div className="p-col"><Button onClick={(e) => this.edit_boutique(rowData)} className="p-button-info" style={{ width: 25, height: 25 }} icon="pi pi-pencil" /></div>
            <div className="p-col"><Button onClick={(e) => this.conf_delete_boutique(rowData)} className="p-button-danger" style={{ width: 25, height: 25,marginLeft: -8 }} icon="pi pi-times" /></div>
        </div>
    }


    delete_boutique() {
        if (this.state.selectedBoutique == null || this.state.selectedBoutique == undefined) {
            this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez sélectionner la ligne à supprimer' });
        } else {
            this.xulaservice.delete_boutique(this.state.selectedBoutique).then(data => this.resultat(data.code, data.contenu));
        }
    }


    conf_delete_boutique(data) {
        if (data != null && data != undefined) {
            this.setState({ selectedBoutique: data });
            this.setState({ msg: data.nomBoutiq });
            this.setState({ visible: true });
        }
    }



    render() {
        const dialogFooter = (
            <div>
                <Button icon="pi pi-check" onClick={() => {
                    this.setState({ visible: false });
                    this.delete_boutique();
                }} label="Oui" />
                <Button icon="pi pi-times" onClick={() => this.setState({ visible: false })} label="Annuler" className="p-button-secondary" />
            </div>
        );


        return (
            <div className="p-fluid">
                <Growl ref={(el) => this.growl = el} />
                <Dialog header="Confirmation" visible={this.state.visible} style={{ width: '250px' }} modal={true} footer={dialogFooter} onHide={(e) => this.setState({ visible: false })}>
                    <div>
                        <i className="fa fa-warning" style={{ color: '#FFD133', fontSize: 25 }}></i>
                        Voulez vous vraiment supprimer <b>{this.state.msg} </b>?
                    </div>
                </Dialog>
                <div className="p-grid">
                    <div className="p-col-12">
                        <div className="card card-w-title" style={{ minHeight: '700px' }}>

                            <div className="p-grid">
                                <div className="p-col-12 p-md-2">
                                    <label htmlFor="nomBoutiq">Nom boutique *</label>
                                </div>
                                <div className="p-col-12 p-md-4">
                                    <InputText id="nomBoutiq" value={this.state.nomBoutiq} onChange={(e) => this.setState({ nomBoutiq: e.target.value})} />
                                </div>
                                <div className="p-col-12 p-md-2">
                                    <label htmlFor="codeBoutiq">Code boutique *</label>
                                </div>
                                <div className="p-col-12 p-md-4">
                                    <InputText id="codeBoutiq" value={this.state.codeBoutiq} onChange={(e) => this.setState({ codeBoutiq: e.target.value })} />
                                </div>
                                <div className="p-col-12 p-md-2">
                                    <label htmlFor="codeBoutiq">Adresse </label>
                                </div>
                                <div className="p-col-12 p-md-4">
                                    <InputText id="codeBoutiq" value={this.state.adrBoutiq} onChange={(e) => this.setState({ adrBoutiq: e.target.value })} />
                                </div>

                                <div className="p-col-12 p-md-2">
                                    <label htmlFor="adrCli">Type * </label>
                                </div>
                                <div className="p-col-12 p-md-4">
                                    <Dropdown options={this.state.liste_domaine} value={this.state.typeBoutiq} optionLabel='label' filter={true} filterBy='label' filterMatchMode='contains' onChange={(e) => { this.setState({ typeBoutiq: e.value }) }} autoWidth={false} />
                                </div>


                                <div className="p-col-12 p-md-2">
                                    <label htmlFor="adrCli">Etablissement * </label>
                                </div>
                                <div className="p-col-12 p-md-4">
                                    <Dropdown disabled={this.state.disable_etabl} options={this.state.liste_etabl} value={this.state.idEtabl} optionLabel='label' filter={true} filterBy='label' filterMatchMode='contains' onChange={(e) => { this.setState({ idEtabl: e.value }) }} autoWidth={false} />
                                </div>

                            </div>
                            <center>
                                <div className="p-grid" style={{ width: 300 }}>
                                    <div className="p-col"><Button label="Annuler" icon="pi pi-ban" onClick={(e) => this.annule_boutique()} /></div>
                                    <div className="p-col"><Button label="Valider" icon="pi pi-check" onClick={(e) => this.enreg_boutique()} /></div>
                                </div>
                                {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : null}
                            </center>

                            <DataTable value={this.state.liste_boutique}
                                responsive={true} scrollable={true} scrollHeight="500px" selectionMode="single"
                                onRowClick={e => this.setState({ selectedBoutique: e.data })}
                                selection={this.state.selectedBoutique}
                            >
                                <Column header="#" body={this.actionTemplate.bind(this)} style={{ width: '10%' }} />
                                <Column field="nomBoutiq" header="Nom boutique" sortable={true}  filter={true} filterMatchMode='contains'/>
                                <Column field="codeBoutiq" header="Code" sortable={true} style={{ width: '10%' }} filter={true} filterMatchMode='contains' />
                                <Column field="adrBoutiq" header="Adresse" sortable={true} style={{ width: '20%' }}  filter={true} filterMatchMode='contains'/>
                                <Column field="typeBoutiq" header="Type" sortable={true} style={{ width: '20%' }}  filter={true} filterMatchMode='contains' />
                                <Column field="idEtabl.codeEtabl" header="Etablissement" sortable={true} style={{ width: '12%' }} filter={true} filterMatchMode='contains' />
                            </DataTable>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}