import React, { Component } from 'react';
import { Xulaservice } from '../service/Xulaservice';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { SplitButton } from 'primereact/splitbutton';
import './styles.css';
import { ProgressSpinner } from 'primereact/progressspinner';
import Numeral from 'numeral';
import 'moment';
import 'moment/locale/fr';
import moment from 'moment';
import { Calendar } from 'primereact/calendar';

export class FormEdition_Journal_livraison extends Component {
    constructor() {
        super();
        this.state = {
            pd1: new Date(),
            pd2: new Date(),
            liste_article: [],
            lboutique_rech: [],
            liste_livraison_lsortie: [],
            selectedData: null,
            rcb_article: '',
            rlib_article: '',
            rtypeSortie: { label: 'Type', value: 'Tous' },
            total_facture: '0',
            total_non_livre: '0',
            total_gain: '0',
            ridBoutiq: { value: 'Tous', label: 'Boutique' },
            items: [
                {
                    label: 'Format CSV',
                    icon: 'pi pi-print',
                    command: (e) => {
                        this.dt.exportCSV();
                    }
                }
            ],
            userconnected: JSON.parse(localStorage.getItem('userconnected')),
        };
        this.xulaservice = new Xulaservice();
    }



    get_liste_livraison_lsortie_by_sortie(idSortie) {
        this.setState({ showindicator: true });
        this.xulaservice.get_liste_livraison_lsortie_by_sortie(idSortie).then(data => {
            this.setState({ liste_livraison_lsortie: data, showindicator: false });
        });
    }

    show_dlg_livraison(data) {
        if (data != null && data != undefined) {
            this.setState({ visible_dlg_livraison: true }, () => {
                this.setState({ receveurBl: data.nomCli });
                this.get_liste_livraison_lsortie_by_sortie(data.idSortie);
            })
        }
    }

    onEditorValueChange_livraison(props, value) {
        let updateds = [...props.value];
        updateds[props.rowIndex][props.field] = value;
        this.setState({ liste_livraison_lsortie: updateds });
    }

    inputTextEditor_livraison(props, field) {
        return <InputText keyfilter="pint" type="text" value={props.rowData[field]} onChange={(e) => this.onEditorValueChange_livraison(props, e.target.value)} />;
    }

    qteLivrLsEditor(props) {
        return this.inputTextEditor_livraison(props, 'qteLivrLs');
    }

    enreg_bl() {
        if (this.state.selectedData === null || this.state.selectedData === undefined || this.state.dateBl === '' || this.state.dateBl === undefined || this.state.dateBl === null || this.state.receveurBl === undefined || this.state.receveurBl === null || this.state.receveurBl === '') {
            this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez saisir tous les champs obligatoires et sélectionner une commande' });
        } else {
            let date_bl = moment(this.state.dateBl, 'DD/MM/YYYY').format('DD/MM/YYYY');
            let data = { numBl: '0', dateBl: date_bl, receveurBl: this.state.receveurBl, idSortie: { idSortie: this.state.selectedData.idSortie } };

            if (this.state.liste_livraison_lsortie == null || this.state.liste_livraison_lsortie == undefined) {
                this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez sélectionner les articles à livrer' });
                return;
            } else if (this.state.liste_livraison_lsortie.length <= 0) {
                this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez sélectionner les articles à livrer' });
                return;
            }
            for (var i = this.state.liste_livraison_lsortie.length; i--;) {
                this.state.liste_livraison_lsortie[i].numBlLs = data;
                this.state.liste_livraison_lsortie[i].iduser = this.state.userconnected.idUser;
                if (this.state.liste_livraison_lsortie[i].qteLivrLs > this.state.liste_livraison_lsortie[i].qteLivrLs_restant) {
                    this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez vérifier les quantités à livrer' });
                    return;
                }
            }
            for (var i = this.state.liste_livraison_lsortie.length; i--;) {
                if (this.state.liste_livraison_lsortie[i].qteLivrLs === 0) {
                    this.state.liste_livraison_lsortie.splice(i, 1);
                }
            }
            this.setState({ showindicator: true });
            this.xulaservice.enreg_livraison_lsortie_batch(this.state.liste_livraison_lsortie).then(reponse => {
                if (reponse != null && reponse != undefined) {
                    this.resultat_livraison(reponse.code, reponse.contenu);
                }
            });

        }
    }

    resultat_livraison(code, contenu) {
        if (code == '200') {
            this.growl.show({ severity: 'success', summary: 'Success', detail: contenu });
            this.setState({ receveurBl: '' });
            this.setState({ liste_livraison_lsortie: [] }, () => {
                this.get_liste_data();
            });
            this.setState({ visible_dlg_livraison: false });
        } else {
            this.growl.show({ severity: 'error', summary: 'Error', detail: contenu });
        }
        this.setState({ showindicator: false });
    }


    formaterMontant(montant) {
        return Numeral(parseFloat(montant)).format('0,0');
    }


    componentDidMount() {
        this.get_liste_data();
        this.get_liste_boutique();
    }

    annule_livraison() {
        this.setState({ visible_dlg_livraison: false }, () => {
            this.setState({ receveurBl: '' });
        });
    }

    get_liste_data() {
        let rid_boutique = 'Tous';
        let rtype = 'Tous';
        if (this.state.ridBoutiq != undefined && this.state.ridBoutiq != null) {
            rid_boutique = this.state.ridBoutiq.value;
        }
        if (this.state.rtypeSortie != undefined || this.state.rtypeSortie != null) {
            rtype = this.state.rtypeSortie.value;
        }
        let spd1 = moment(this.state.pd1).format('DD-MM-YYYY');
        let spd2 = moment(this.state.pd2).format('DD-MM-YYYY');
        this.setState({ showindicator: true });
        this.xulaservice.get_journal_livraison(spd1, spd2, rid_boutique, this.state.rlib_article, 'Tous', rtype).then(data => this.setState({ liste_article: data, showindicator: false }, () => {
            if (data != null && data != undefined) {
                let initialValue = 0
                let total_facture = data.reduce(function (accumulator, currentValue) {
                    return accumulator + currentValue.montant
                }, initialValue);

                let tt_marge = data.reduce(function (accumulator, currentValue) {
                    return accumulator + currentValue.marge_article
                }, initialValue);

                let tt_nl = data.reduce(function (accumulator, currentValue) {
                    return accumulator + currentValue.valeur_qte_a_livrer
                }, initialValue);

                this.setState({ total_facture: this.formaterMontant(total_facture), total_non_livre: this.formaterMontant(tt_nl) });
                if (this.state.userconnected.profilUser === 'PDG') {
                    this.setState({ total_gain: this.formaterMontant(tt_marge) });
                } else {
                    this.setState({ total_gain: '******' });
                }
            }
        }));
    }


    get_liste_boutique() {
        let lboutique_rech = [{ value: 'Tous', label: 'Boutique' }];
        let lboutique = null;
        this.xulaservice.getListe_boutique().then(data => this.setState({ liste_boutique: data }, () => {
            if (this.state.liste_boutique != null && this.state.liste_boutique != undefined) {
                lboutique = this.state.liste_boutique.map(x => {
                    return { value: x.idBoutiq, label: x.nomBoutiq }
                });
            }

            if (this.state.userconnected.profilUser === 'PDG' || this.state.userconnected.profilUser === 'Coordinateur') {
                this.setState({ lboutique: lboutique });
                lboutique_rech = lboutique_rech.concat(lboutique);
            } else {
                let id = this.state.userconnected.matrPers.idBoutiq.idBoutiq;
                if (lboutique.length > 0) {
                    let liste = lboutique.filter(function (el) {
                        return el.value === id;
                    });
                    this.setState({ lboutique: liste });
                    lboutique_rech = liste;
                }
            }
            this.setState({ lboutique_rech: lboutique_rech })
        }));
    }



    render() {
        return (
            <div className="p-fluid">

                <div className="p-grid">
                    <div className="p-col-12">
                        <div className="card card-w-title" style={{ minHeight: '900px' }}>
                            <i className="fa fa-fw fa-search"></i>Option de recherche
                            <hr />
                            <div className="p-grid">
                                <div className="p-col-12 p-md-2">
                                    <Calendar value={this.state.pd1} dateFormat="dd/mm/yy" onChange={(e) => this.setState({ pd1: e.value })} />
                                </div>
                                <div className="p-col-12 p-md-2">
                                    <Calendar value={this.state.pd2} dateFormat="dd/mm/yy" onChange={(e) => this.setState({ pd2: e.value })} />
                                </div>
                                <div className="p-col-12 p-md-2">
                                    <InputText value={this.state.rlib_article} placeholder="Désignation" onChange={(e) => this.setState({ rlib_article: e.target.value })} />
                                </div>
                                <div className="p-col-12 p-md-2">
                                    <Dropdown value={this.state.ridBoutiq} options={this.state.lboutique_rech} optionLabel='label' filter={true} filterBy='label' onChange={(e) => this.setState({ ridBoutiq: e.value }, () => {
                                        this.get_liste_data();
                                    })} autoWidth={false} />
                                </div>

                                <div className="p-col-12 p-md-2">
                                    <Dropdown value={this.state.rtypeSortie} options={[
                                        { label: 'Type', value: 'Tous' },
                                        { label: 'Facture', value: 'Facture' },
                                        { label: 'Facture boutique', value: 'Facture boutique' },
                                        { label: 'Inter-magasin', value: 'Inter-magasin' },
                                        { label: 'Transfert', value: 'Transfert' }
                                    ]} optionLabel='label' filter={true} filterBy='label' onChange={(e) => this.setState({ rtypeSortie: e.value }, () => {
                                        this.get_liste_data();
                                    })} autoWidth={false} />
                                </div>

                                <div className="p-col-12 p-md-2">
                                    <div className="p-inputgroup">
                                        <SplitButton label="Rechercher" style={{ width: '140px' }} onClick={(e) => this.get_liste_data()} icon="pi pi-search" model={this.state.items}></SplitButton>
                                        {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : null}
                                    </div>
                                </div>
                            </div>


                            <DataTable value={this.state.liste_article} paginatorPosition="top" paginator={true} rows={20}
                               rowsPerPageOptions={[10, 20, 50, 100, 200]}
                                responsive={true}  csvSeparator=";" ref={(el) => { this.dt = el; }} >
                                <Column field="numFactureSortie" header="N° facture" style={{ width: '13%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                <Column field="libArt" header="Désignation" filter={true} filterMatchMode='contains' sortable={true} />
                                <Column field="type_sortie" header="Type" style={{ width: '8%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                <Column field="datesortie" header="Date" style={{ width: '8%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                <Column field="nomCli" header="Client" style={{ width: '16%' }} body={(rowData, column) => {
                                    return <div>
                                        <span >{rowData.nomCli}</span><br />
                                        <span style={{ color: '#45535F', fontWeight: 'bold' }}>{rowData.idBoutiq.codeBoutiq}</span>
                                    </div>
                                }} filter={true} filterMatchMode='contains' sortable={true} />
                                <Column field="prix_ref_vente" body={(rowData, column) => {
                                    return <div>
                                        <span >{this.formaterMontant(parseFloat(rowData.prix_ref_vente))}</span>
                                    </div>
                                }} header="Prix vente" style={{ width: '10%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                <Column field="qte" header="Qté vendue" style={{ width: '10%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                <Column field="qte_livr" header="Qté livrée" style={{ width: '9%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                <Column field="idBoutiq.codeBoutiq" header="Boutique" style={{ width: '10%' }} filter={true} filterMatchMode='contains' sortable={true} />

                            </DataTable>

                        </div>
                    </div>
                </div>
            </div>
        );
    }
}