import React, { Component } from 'react';
import { Xulaservice } from '../service/Xulaservice';
import { Panel } from 'primereact/components/panel/Panel';
import { Dropdown } from 'primereact/components/dropdown/Dropdown';
import { DataTable } from 'primereact/components/datatable/DataTable';
import { Column } from 'primereact/components/column/Column';
import { Chart } from 'primereact/chart';
import { ProgressSpinner } from 'primereact/progressspinner';
import Numeral from 'numeral';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import 'moment';
import 'moment/locale/fr';
import moment from 'moment';
import { Link } from 'react-router-dom';

export class Dashboardgas extends Component {

    constructor() {
        super();
        this.state = {
            liste_article: [],
            idBoutiq: { label: 'Toutes les boutiques', value: '-1' },
            userconnected: JSON.parse(localStorage.getItem('userconnected')),
            rannee: moment(new Date()).format('YYYY'),
        };
        this.xulaservice = new Xulaservice();
        if (this.state.userconnected.profilUser == 'PDG' || this.state.userconnected.profilUser == 'Coordinateur') {
            this.setState({ disable_etabl: false });
        }
    }

    formaterMontant(montant) {
        return Numeral(parseFloat(montant)).format('0,0');
    }

    charger_dashboard(idBoutiq, annee) {
        this.setState({ liste_article: [] });
        this.setState({ showindicator: true });
        this.xulaservice.get_dashboard(idBoutiq, annee).then(data => {
            if (data !== null && data !== undefined) {
                this.setState({ liste_article: data.liste_article });
            }
            this.setState({ showindicator: false });
        }
        );
    }

    componentDidMount() {
        this.setState({ idBoutiq: this.state.userconnected.matrPers.idBoutiq.idBoutiq }, () => {
            this.charger_dashboard(this.state.idBoutiq, this.state.rannee, true);
        });
    }

    render() {
        let liste_label_top_prod = null;
        let liste_ds_top_prod = null;
        if (this.state.liste_article != null && this.state.liste_article != undefined) {
            if (this.state.liste_article.length > 0) {
                let liste = this.state.liste_article;
                liste.sort(this.compare);
                let items = liste.slice(0, 10);
                liste_label_top_prod = items.map(x => {
                    return x.libArt
                });

                liste_ds_top_prod = items.map(x => {
                    return x.montant
                });
            }
        }

        const chart_hdata_topProd = {
            labels: liste_label_top_prod,
            datasets: [
                {
                    label: 'Top 10 prdouits',
                    backgroundColor: '#4071a7',
                    data: liste_ds_top_prod
                }
            ]
        };

        return <div className="p-grid dashboard">
           
            <div className="p-col-12 p-md-6">
                <Panel style={{ minHeight: '545px' }}>
                    <i className="fa fa-fw fa-shopping-cart"></i>Sorties du jour par article
                    <hr />
                    <DataTable value={this.state.liste_article} selectionMode="single" scrollable={true} scrollHeight="300px"
                    >
                        <Column field="libArt" filter={true} filterMatchMode='contains' sortable={true} header="Désignation" style={{ width: '57%' }} />
                        <Column field="qte" filter={true} filterMatchMode='contains' header="Quantité" sortable={true} style={{ width: '18%' }} />
                    </DataTable>

                </Panel>
            </div>

            <div className="p-col-12 p-md-6">
                <Panel style={{ minHeight: '545px' }}>
                    <i className="fa fa-fw fa-bar-chart"></i>Top sortie article du jour
                    <hr />
                    <Chart type="horizontalBar" data={chart_hdata_topProd} height="225w" />
                </Panel>
            </div>


        </div>
    }
}