import React, { Component } from 'react';
import { Xulaservice } from '../service/Xulaservice';
import { Panel } from 'primereact/components/panel/Panel';
import { Dropdown } from 'primereact/components/dropdown/Dropdown';
import { DataTable } from 'primereact/components/datatable/DataTable';
import { Column } from 'primereact/components/column/Column';
import { Chart } from 'primereact/chart';
import { ProgressSpinner } from 'primereact/progressspinner';
import Numeral from 'numeral';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import 'moment';
import 'moment/locale/fr';
import moment from 'moment';
import { Link } from 'react-router-dom';

export class Dashboardrcv extends Component {

    constructor() {
        super();
        this.state = {
            nb_client_endete: 0,
            total_credit: 0,
            total_du: 0,
            taux_recouvrement_annee: 0,
            liste_situation_boutique: [],
            liste_stat_dette_boutique: [],
            idBoutiq: { label: 'Toutes les boutiques', value: '-1' },
            userconnected: JSON.parse(localStorage.getItem('userconnected')),
            rannee: moment(new Date()).format('YYYY'),
        };
        this.xulaservice = new Xulaservice();
        if (this.state.userconnected.profilUser == 'PDG' || this.state.userconnected.profilUser == 'Coordinateur') {
            this.setState({ disable_etabl: false });
        }
    }

    formaterMontant(montant) {
        return Numeral(parseFloat(montant)).format('0,0');
    }

    charger_dashboard(idBoutiq, annee, show) {
        this.setState({ nb_client_endete: 0 });
        this.setState({ total_credit: 0 });
        this.setState({ total_du: 0 });
        this.setState({ taux_recouvrement_annee: 0 });
        this.setState({ liste_situation_boutique: [] });
        this.setState({ liste_stat_dette_boutique: [] });

        this.setState({ showindicator: true });
        this.xulaservice.get_dashboard(idBoutiq, annee).then(data => {
            if (data !== null && data !== undefined) {
                this.setState({ nb_client_endete: data.nb_client_endete });
                this.setState({ total_credit: this.formaterMontant(parseFloat(data.total_credit)) });
                this.setState({ total_du: this.formaterMontant(parseFloat(data.total_du)) });
                this.setState({ taux_recouvrement_annee: data.taux_recouvrement_annee });
                this.setState({ liste_situation_boutique: data.liste_situation_boutique });
                this.setState({ liste_stat_dette_boutique: data.liste_stat_dette_boutique });
            }
            this.setState({ showindicator: false });
        }
        );
    }

    componentDidMount() {
        let lboutique = [{ value: '-1', label: 'Toutes les boutiques' }];
        this.xulaservice.getListe_boutique().then(data => {
            this.setState({ liste_boutique: data }, () => {
                if (this.state.liste_boutique != null && this.state.liste_boutique != undefined) {
                    let liste = this.state.liste_boutique.map(x => {
                        return { value: x.idBoutiq, label: x.nomBoutiq }
                    });
                    lboutique = lboutique.concat(liste);
                }
            });
            this.setState({ lboutique: lboutique }, () => {
                if(this.state.userconnected.profilUser=='Recouvreur'){
                    this.setState({ idBoutiq: '-1'}, () => {
                        this.charger_dashboard(this.state.idBoutiq, this.state.rannee, true);
                    });
                }else{
                    this.setState({ idBoutiq: this.state.userconnected.matrPers.idBoutiq.idBoutiq }, () => {
                        this.charger_dashboard(this.state.idBoutiq, this.state.rannee, true);
                    });
                }
                
            });
        });
    }

    render() {
        let liste_label_stat_dette = null;
        let liste_ds_stat_vente = null;
        let liste_ds_stat_regl = null;

        if (this.state.liste_situation_boutique != null && this.state.liste_situation_boutique != undefined) {
            if (this.state.liste_situation_boutique.length > 0) {
                liste_label_stat_dette = this.state.liste_situation_boutique.filter(function (el) {
                    return el.ttventeBoutiq>0;
                }).map(x => {
                    return x.nomBoutiq
                });
                liste_ds_stat_vente = this.state.liste_situation_boutique.filter(function (el) {
                    return el.ttventeBoutiq>0;
                }).map(x => {
                    return x.ttventeBoutiq
                });
                liste_ds_stat_regl = this.state.liste_situation_boutique.filter(function (el) {
                    return el.ttventeBoutiq>0;
                }).map(x => {
                    return x.ttreglementBoutique
                });
            }
        }

        const chart_data_stat_dette = {
            labels: liste_label_stat_dette,
            datasets: [
                {
                    label: 'Ventes',
                    backgroundColor: '#4283e5',
                    borderColor: '#4283e5',
                    fill: false,
                    data: liste_ds_stat_vente
                },
                {
                    label: 'Règlements',
                    backgroundColor: '#58D68D',
                    borderColor: '#58D68D',
                    fill: false,
                    data: liste_ds_stat_regl
                }
            ]
        }; 

        return <div className="p-grid dashboard">
            <div className="p-col-12" style={{ marginTop: -10 }}>
                <div className="p-grid" style={{ float: "right", width: 350, marginBottom: -5, marginTop: -10 }}>
                    <div className="p-col">
                        <div className="p-inputgroup">
                            <Dropdown placeholder="Toutes les boutiques" style={{ width: 250, marginBottom: -8 }} value={this.state.idBoutiq} filter={true} filterBy='label' filterMatchMode='contains' options={this.state.lboutique} onChange={(e) => {
                                this.setState({ idBoutiq: e.value }, () => {
                                    this.charger_dashboard(e.value, this.state.rannee, false);
                                });
                            }
                            } disabled={this.state.userconnected.profilUser != 'PDG' && this.state.userconnected.profilUser != 'Coordinateur' && this.state.userconnected.profilUser != 'Recouvreur'} />
                            <Button type="button" style={{ marginBottom: -8, width: '30px' }} icon="pi pi-refresh" className="p-button-info" onClick={() => this.charger_dashboard(this.state.idBoutiq, this.state.rannee, true)} />
                        </div>
                    </div>
                    <div className="p-col">
                        {this.state.showindicator ? <ProgressSpinner style={{ width: '20px', height: '20px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : null}
                    </div>
                </div>

            </div>

            <div className="p-col-6 p-sm-6 p-md-3">
                <div className="overview-box overview-box-1"><h1>Total client endetté</h1>
                    <div className="overview-value">{this.state.nb_client_endete}</div>

                    <img src="assets/layout/images/dashboard/graph-blue.svg" alt="apollo-layout" />
                </div>
            </div>
 
            <div className="p-col-6 p-sm-6 p-md-3">
                <div className="overview-box overview-box-5">
                    <h1>Total dette</h1>
                    <div className="overview-value">{this.state.total_credit}</div>

                    <img src="assets/layout/images/dashboard/graph-red.svg" alt="apollo-layout" />
                </div>
            </div>

            <div className="p-col-6 p-sm-6 p-md-3">
                <div className="overview-box overview-box-2">
                    <h1>Taux de recouvrement</h1>
                    <div className="overview-value" >{this.state.taux_recouvrement_annee} %</div>
                    <img src="assets/layout/images/dashboard/graph-green.svg" alt="apollo-layout" />
                </div>
            </div>

            <div className="p-col-6 p-sm-6 p-md-3">
                <div className="overview-box overview-box-3">
                    <h1>Total dù</h1>
                    <div className="overview-value">{this.state.total_du}</div>

                    <img src="assets/layout/images/dashboard/graph-yellow.svg" alt="apollo-layout" />
                </div>
            </div>






            <div className="p-col-12 p-md-8">
                <Panel style={{ minHeight: '445px' }}>
                    <i className="fa fa-fw fa-bar-chart"></i>Taux de recouvrement des factures
                    <hr />
                    <Chart responsive={true} type="bar" data={chart_data_stat_dette} height="120w"   />

                </Panel>
            </div>

            <div className="p-col-12 p-md-4">
                <Panel style={{ minHeight: '445px' }}>
                    <i className="fa fa-fw fa-money"></i>Situation de recouvrement des factures
                    <hr />
                    <DataTable value={this.state.liste_situation_boutique} scrollHeight="230px"
                        scrollable={true} >
                        <Column field="nomBoutiq" header="Boutique" />
                        <Column header="Dette" body={(rowData, column) => {
                            return <div>
                                <span style={{ color: '#000' }}>{this.formaterMontant(rowData.ttcreditExterneBoutiq)}</span>
                            </div>
                        }}  style={{width: '25%'}} />
                        <Column header="Taux recouv." body={(rowData, column) => {
                            let color = 'green';
                            if (parseFloat(rowData.tauxRecouvrBoutiq) < 80) {
                                color = 'red';
                            }
                            return <div>
                                <span style={{ color: color, fontWeight: 'bold' }}>{rowData.tauxRecouvrBoutiq} %</span>
                            </div>
                        }} style={{width: '28%'}} />

                    </DataTable>
                </Panel>
            </div>



        </div>
    }
}