import React, { Component } from 'react';
import { Xulaservice } from '../service/Xulaservice';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { SplitButton } from 'primereact/splitbutton';
import './styles.css';
import { ProgressSpinner } from 'primereact/progressspinner';
import Numeral from 'numeral';
import { Growl } from 'primereact/growl';
import 'moment';
import 'moment/locale/fr';
import moment from 'moment';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import Cleave from 'cleave.js/react';
import { Calendar } from 'primereact/calendar';

export class FormEdition_Journal_appro extends Component {
    constructor() {
        super();
        this.state = {
            pd1: new Date(),
            pd2: new Date(),
            liste_article: [],
            selectedData: null,
            rtypeAppro: { value: 'CMDE', label: 'Commande' },
            lboutique_rech: [],
            rcb_article: '',
            rlib_article: '',
            rnum_cmde: '',
            visible_dlg_edit: false,
            visible: false,
            puvMajLcmde: 0,
            puLcmde: 0,
            qteLcmde: 0,
            dateExpLcmde: null,
            ridBoutiq: { value: 'Tous', label: 'Boutique' },
            liste_type_appro: [
                { value: 'CMDE', label: 'Commande' },
                { value: 'APPRO', label: 'Appro' },
                { value: 'RETOUR', label: 'Retour' },
            ],
            items: [
                {
                    label: 'Format CSV',
                    icon: 'pi pi-print',
                    command: (e) => {
                        this.dt.exportCSV();
                    }
                }
            ],
            userconnected: JSON.parse(localStorage.getItem('userconnected')),
        };
        this.state.pd1.setDate(this.state.pd1.getDate() - 2);
        this.show_dlg_edit = this.show_dlg_edit.bind(this);
        this.conf_delete_ligne = this.conf_delete_ligne.bind(this);
        this.xulaservice = new Xulaservice();
    }



    formaterMontant(montant) {
        return Numeral(parseFloat(montant)).format('0,0');
    }


    componentDidMount() {
        this.get_liste_data();
        this.get_liste_boutique();
    }

    annule_lcmde() {
        this.setState({ visible_dlg_edit: false });
    }

    resultat(code, contenu) {
        if (code == '200') {
            this.growl.show({ severity: 'success', summary: 'Success', detail: contenu });
            this.annule_lcmde();
            this.get_liste_data();
        } else {
            this.growl.show({ severity: 'error', summary: 'Error', detail: contenu });
        }
        this.setState({ showindicator: false });
    }

    enreg_lcmde() {
        if (this.state.qteLcmde == '' || this.state.qteLcmde == undefined) {
            this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez saisir la quantité' });
            return;
        }
        if (this.state.rtypeAppro.value === 'CMDE') {

            if (this.state.puLcmde == undefined || this.state.puvMajLcmde == undefined || parseFloat(this.state.puLcmde) == 'NaN' || parseFloat(this.state.puvMajLcmde) == 'NaN' || parseFloat(this.state.puLcmde) == 'NaN') {
                this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez saisir les informations obligatoires' });
            } else {
                let date = null;
                try {
                    date = moment(this.state.dateExpLcmde, 'DD/MM/YYYY').format('DD/MM/YYYY');
                } catch (error) {
                    console.error(error);
                }
                if (date === 'Invalid date') {
                    date = null;
                    if (this.state.userconnected.matrPers.idBoutiq.typeBoutiq === 'Pharmacie') {
                        this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez saisir la date de péremption' });
                        return;
                    }
                }
                let data = { idLcmde: this.state.selectedData.idlcmde, cbMajLcmde: this.state.selectedData.cbArt, idArticle: { idArticle: this.state.selectedData.idarticle }, idCmde: { idCmde: this.state.selectedData.idcmde } };
                if (this.state.qteLcmde > this.state.selectedData.qte) {
                    data.qteReapLcmde = this.state.qteLcmde - this.state.selectedData.qte;
                }
                data.qteLcmde = this.state.qteLcmde;
                data.puLcmde = this.state.puLcmde;
                data.puvMajLcmde = this.state.puvMajLcmde;
                data.dateExpLcmde = date;
                this.setState({ showindicator: true });
                this.xulaservice.enreg_lcmde(data).then(data => this.resultat(data.code, data.contenu));
            }
        } else {
            this.setState({ showindicator: true });
            let data = { idReap: this.state.selectedData.idReap, dateReap: this.state.selectedData.date_reap, qteReap: this.state.qteLcmde, idLcmdeReap: { idLcmde: this.state.selectedData.idlcmde }, idBoutiq: this.state.selectedData.idCmde.idBoutiq };
            this.xulaservice.enreg_reap(data).then(data => this.resultat(data.code, data.contenu));
        }
    }

    get_liste_data() {
        let rid_boutique = 'Tous';
        let rtype = 'CMDE';
        if (this.state.ridBoutiq != undefined && this.state.ridBoutiq != null) {
            rid_boutique = this.state.ridBoutiq.value;
        }
        if (this.state.rtypeAppro != undefined && this.state.rtypeAppro != null) {
            rtype = this.state.rtypeAppro.value;
        }
        let spd1 = moment(this.state.pd1).format('DD-MM-YYYY');
        let spd2 = moment(this.state.pd2).format('DD-MM-YYYY');
        this.setState({ showindicator: true });
        this.xulaservice.get_journal_appro(spd1, spd2, rid_boutique, this.state.rlib_article, 'Tous', this.state.rcb_article, this.state.rnum_cmde, rtype).then(data => this.setState({ liste_article: data, showindicator: false }));
    }


    get_liste_boutique() {
        let lboutique_rech = [{ value: 'Tous', label: 'Boutique' }];
        let lboutique = null;
        this.xulaservice.getListe_boutique().then(data => this.setState({ liste_boutique: data }, () => {
            if (this.state.liste_boutique != null && this.state.liste_boutique != undefined) {
                lboutique = this.state.liste_boutique.map(x => {
                    return { value: x.idBoutiq, label: x.nomBoutiq }
                });
            }

            if (this.state.userconnected.profilUser === 'PDG' || this.state.userconnected.profilUser === 'Coordinateur') {
                this.setState({ lboutique: lboutique });
                lboutique_rech = lboutique_rech.concat(lboutique);
            } else {
                let id = this.state.userconnected.matrPers.idBoutiq.idBoutiq;
                if (lboutique.length > 0) {
                    let liste = lboutique.filter(function (el) {
                        return el.value === id;
                    });
                    this.setState({ lboutique: liste });
                    lboutique_rech = liste;
                }
            }
            this.setState({ lboutique_rech: lboutique_rech })
        }));
    }

    actionTemplate(rowData, column) {
        let disable = false;
        if (rowData.idCmde.typeCmde === 'Transfert') {
            disable = true;
        }
        return <div className="p-grid" style={{ width: 90 }}>
            <div className="p-col"><Button disabled={disable} onClick={(e) => this.show_dlg_edit(rowData)} className="p-button-info" style={{ width: 25, height: 25 }} icon="pi pi-pencil" /></div>
            <div className="p-col"><Button disabled={disable} onClick={(e) => this.conf_delete_ligne(rowData)} className="p-button-danger" style={{ width: 25, height: 25, marginLeft: -8 }} icon="pi pi-times" /></div>
        </div>
    }

    delete_lcmde() {
        if (this.state.selectedData === null || this.state.selectedData === undefined) {
            this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez sélectionner la ligne à supprimer' });
        } else {
            if (this.state.rtypeAppro.value === 'CMDE') {
                let val = { idLcmde: this.state.selectedData.idlcmde }
                this.xulaservice.delete_lcmde(val).then(data => this.resultat(data.code, data.contenu));
            } else {
                let val = { idReap: this.state.selectedData.idReap }
                this.xulaservice.delete_reap(val).then(data => this.resultat(data.code, data.contenu));
            }

        }
    }

    show_dlg_edit(data) {
        if (data !== null && data !== undefined) {
            if (this.state.rtypeAppro.value === 'CMDE') {
                this.setState({ qteLcmde: data.qte });
                this.setState({ puLcmde: data.pulcmde });
                this.setState({ puvMajLcmde: data.prix_ref_vente });
                this.setState({ dateExpLcmde: data.date_exp_format });
            } else {
                this.setState({ qteLcmde: data.qte_reap });
            }

            this.setState({ visible_dlg_edit: true });
        }
    }

    conf_delete_ligne(data) {
        if (data !== null && data !== undefined) {
            this.setState({ selectedData: data });
            this.setState({ msg: ' la ligne sélectionner ' });
            this.setState({ visible: true });
        }
    }

    render() {
        const dialogFooter = (
            <div>
                <Button icon="pi pi-check" onClick={() => {
                    this.setState({ visible: false });
                    this.delete_lcmde();
                }} label="Oui" />
                <Button icon="pi pi-times" onClick={() => this.setState({ visible: false })} label="Annuler" className="p-button-secondary" />
            </div>
        );

        return (
            <div className="p-fluid">
                <Growl ref={(el) => this.growl = el} />
                <Dialog header="Confirmation" visible={this.state.visible} style={{ width: '250px' }} modal={true} footer={dialogFooter} onHide={(e) => this.setState({ visible: false })}>
                    <div>
                        <i className="fa fa-warning" style={{ color: '#FFD133', fontSize: 25 }}></i>
                        Voulez vous vraiment supprimer <b>{this.state.msg} </b>?
                    </div>
                </Dialog>
                <Dialog header="Modification" visible={this.state.visible_dlg_edit} style={{ width: '30%' }} modal={true} onHide={(e) => this.setState({ visible_dlg_edit: false })}>
                    <div className="p-grid" style={{ backgroundColor: '#f7f7f7' }}>
                        <div className="p-col-12">
                            <div className="card card-w-title" style={{ marginBottom: '-7px', backgroundColor: '#f7f7f7' }}>
                                <div className="p-grid">
                                    <div className="p-col-12 p-md-4">
                                        <label htmlFor="adrCli">Quantité *</label>
                                    </div>
                                    <div className="p-col-12 p-md-8">
                                        <Cleave value={this.state.qteLcmde} options={{ numeral: true, delimiter: ' ' }} onChange={(e) => this.setState({ qteLcmde: e.target.rawValue })} className="p-inputtext" />
                                    </div>

                                    {this.state.rtypeAppro.value === 'CMDE' ?
                                        <div className="p-col-12 p-md-4">
                                            <label htmlFor="adrCli">Prix d'achat *</label>
                                        </div>
                                        :
                                        null}
                                    {this.state.rtypeAppro.value === 'CMDE' ?
                                        <div className="p-col-12 p-md-8">
                                            <Cleave value={this.state.puLcmde} options={{ numeral: true, delimiter: ' ' }} onChange={(e) => this.setState({ puLcmde: e.target.rawValue })} className="p-inputtext" />
                                        </div>
                                        :
                                        null}
                                    {this.state.rtypeAppro.value === 'CMDE' ?
                                        <div className="p-col-12 p-md-4">
                                            <label htmlFor="adrCli">Prix de vente *</label>
                                        </div>
                                        :
                                        null}
                                    {this.state.rtypeAppro.value === 'CMDE' ?
                                        <div className="p-col-12 p-md-8">
                                            <Cleave value={this.state.puvMajLcmde} options={{ numeral: true, delimiter: ' ' }} onChange={(e) => this.setState({ puvMajLcmde: e.target.rawValue })} className="p-inputtext" />
                                        </div>
                                        :
                                        null}
                                    {this.state.rtypeAppro.value === 'CMDE' ?
                                        <div className="p-col-12 p-md-4">
                                            <label htmlFor="cbArticle">Date péremption </label>
                                        </div>
                                        :
                                        null}
                                    {this.state.rtypeAppro.value === 'CMDE' ?
                                        <div className="p-col-12 p-md-8">
                                            <Calendar value={this.state.dateExpLcmde} dateFormat="dd/mm/yy" onChange={(e) => this.setState({ dateExpLcmde: e.value })} />
                                        </div>
                                        :
                                        null}
                                </div>
                                <center>
                                    <div className="p-grid" style={{ width: 300 }}>
                                        <div className="p-col"><Button label="Annuler" icon="pi pi-ban" onClick={(e) => this.annule_lcmde()} /></div>
                                        <div className="p-col"><Button label="Valider" icon="pi pi-check" onClick={(e) => this.enreg_lcmde()} /></div>
                                        <div className="p-col">{this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : null}</div>
                                    </div>
                                </center>
                            </div>
                        </div>
                    </div>
                </Dialog>



                <div className="p-grid">
                    <div className="p-col-12">
                        <div className="card card-w-title" style={{ minHeight: '900px' }}>
                            <i className="fa fa-fw fa-search"></i>Option de recherche
                            <hr />
                            <div className="p-grid">
                                <div className="p-col-12 p-md-3">
                                    <Calendar value={this.state.pd1} dateFormat="dd/mm/yy" onChange={(e) => this.setState({ pd1: e.value })} />
                                </div>
                                <div className="p-col-12 p-md-3">
                                    <Calendar value={this.state.pd2} dateFormat="dd/mm/yy" onChange={(e) => this.setState({ pd2: e.value })} />
                                </div>
                                <div className="p-col-12 p-md-3">
                                    <InputText value={this.state.rlib_article} placeholder="Désignation" onChange={(e) => this.setState({ rlib_article: e.target.value })} />
                                </div>
                                <div className="p-col-12 p-md-3">
                                    <InputText value={this.state.rcb_article} placeholder="Code barre" onChange={(e) => this.setState({ rcb_article: e.target.value })} />
                                </div>
                                <div className="p-col-12 p-md-3">
                                    <InputText value={this.state.rnum_cmde} placeholder="N° BC" onChange={(e) => this.setState({ rnum_cmde: e.target.value })} />
                                </div>
                                <div className="p-col-12 p-md-3">
                                    <Dropdown value={this.state.rtypeAppro} options={this.state.liste_type_appro} optionLabel='label' onChange={(e) => this.setState({ rtypeAppro: e.value }, () => {
                                        this.get_liste_data();
                                        this.forceUpdate();
                                    })} autoWidth={false} />
                                </div>
                                <div className="p-col-12 p-md-3">
                                    <Dropdown value={this.state.ridBoutiq} options={this.state.lboutique_rech} optionLabel='label' filter={true} filterBy='label' onChange={(e) => this.setState({ ridBoutiq: e.value }, () => {
                                        this.get_liste_data();
                                    })} autoWidth={false} />
                                </div>
                                <div className="p-col-12 p-md-3">
                                    <div className="p-inputgroup">
                                        <SplitButton label="Rechercher" style={{ width: '140px' }} onClick={(e) => this.get_liste_data()} icon="pi pi-search" model={this.state.items}></SplitButton>
                                        {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : null}
                                    </div>
                                </div>

                            </div>
                            {this.state.rtypeAppro.value === 'RETOUR' ?
                                <DataTable value={this.state.liste_article} paginatorPosition="top" paginator={true} rows={20}
                                    onRowClick={e => this.setState({ selectedData: e.data })} selectionMode="single" rowsPerPageOptions={[10, 20, 50, 100, 200]}
                                    responsive={true} selection={this.state.selectedData} csvSeparator=";" ref={(el) => { this.dt = el; }}>
                                    <Column header="#" body={this.actionTemplate.bind(this)} style={{ width: '8%' }} />
                                    <Column field="libArt" header="Désignation" style={{ width: '28%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                    <Column field="num_bc" header="N° BC" style={{ width: '16%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                    <Column field="datecmde" header="Date" style={{ width: '8%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                    <Column field="qte_format" header="Qté" style={{ width: '8%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                    <Column field="pulcmde_format" header="Prix retour" style={{ width: '10%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                    <Column field="idCmde.idBoutiq.nomBoutiq" header="Boutique" style={{ width: '20%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                </DataTable> :
                                <>
                                    {
                                        this.state.userconnected.profilUser == 'PDG' ?
                                            <DataTable value={this.state.liste_article} paginatorPosition="top" paginator={true} rows={20}
                                                onRowClick={e => this.setState({ selectedData: e.data })} selectionMode="single" rowsPerPageOptions={[10, 20, 50, 100, 200]}
                                                responsive={true} selection={this.state.selectedData} csvSeparator=";" ref={(el) => { this.dt = el; }}>
                                                <Column header="#" body={this.actionTemplate.bind(this)} style={{ width: '6%' }} />
                                                <Column field="libArt" header="Désignation" style={{ width: '21%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                                <Column field="num_bc" header="N° BC" style={{ width: '14%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                                <Column field="datecmde" header="Date BC" style={{ width: '8%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                                <Column field="qte_format" header="Qté BC" style={{ width: '7%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                                <Column field="qte_reap_format" header="Qté appro" style={{ width: '8%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                                <Column field="date_reap" header="Date appro" style={{ width: '10%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                                <Column field="pulcmde_format" header="Prix achat" style={{ width: '8%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                                <Column field="prix_ref_vente_format" header="Prix vente" style={{ width: '8%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                                <Column field="date_exp_format" header="Date per." style={{ width: '8%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                            </DataTable>
                                            :
                                            <DataTable value={this.state.liste_article} paginatorPosition="top" paginator={true} rows={20}
                                                onRowClick={e => this.setState({ selectedData: e.data })} selectionMode="single" rowsPerPageOptions={[10, 20, 50, 100, 200]}
                                                responsive={true} selection={this.state.selectedData} csvSeparator=";" ref={(el) => { this.dt = el; }}>
                                                <Column header="#" body={this.actionTemplate.bind(this)} style={{ width: '6%' }} />
                                                <Column field="libArt" header="Désignation" style={{ width: '28%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                                <Column field="num_bc" header="N° BC" style={{ width: '14%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                                <Column field="datecmde" header="Date BC" style={{ width: '8%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                                <Column field="qte_format" header="Qté BC" style={{ width: '8%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                                <Column field="qte_reap_format" header="Qté appro" style={{ width: '8%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                                <Column field="date_reap" header="Date appro" style={{ width: '10%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                                <Column field="prix_ref_vente_format" header="Prix vente" style={{ width: '8%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                                <Column field="date_exp_format" header="Date per." style={{ width: '8%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                            </DataTable>
                                    }
                                </>
                            }
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}