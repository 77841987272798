import React, { Component } from 'react';
import { Xulaservice } from '../service/Xulaservice';
import { InputText } from 'primereact/inputtext';
import { DataTable } from 'primereact/datatable';
import { Dropdown } from 'primereact/dropdown';
import { Column } from 'primereact/column';
import { Dialog } from 'primereact/dialog';
import { Growl } from 'primereact/growl';
import { Button } from 'primereact/button';
import './styles.css';
import { ProgressSpinner } from 'primereact/progressspinner';
import 'moment';
import 'moment/locale/fr';
import { ScrollPanel } from 'primereact/scrollpanel';
import { InputTextarea } from 'primereact/inputtextarea';
import { MultiSelect } from 'primereact/multiselect';
import { utc } from 'moment';

export class FormMsg extends Component {
    constructor() {
        super();
        this.state = {
            idMsg: '0',
            contenuMsg: '',
            expMsg: '',
            liste_pers: [],
            liste_selected_pers: [],
            matrPers: null,
            objMsg: '',
            canalMsg: { label: 'SMS', value: 'SMS' },
            liste_canal: [
                { value: 'SMS', label: 'SMS' },
                { value: 'Mail', label: 'Mail' },
                { label: 'Défilant', value: 'Défilant' }
            ],
            liste_msg: [],
            liste_dest_msg: [],
            liste_client: [],
            liste_client_selected: [],
            selectedMsg: null,
            selectedDestMsg: null,
            modif: false,
            visible_conf_delete_msg: false,
            visible_conf_delete_dest: false,
            showindicator: false,
            visible_dlg_new: false,
            visible_dlg_contenu_msg: false,
            userconnected: JSON.parse(localStorage.getItem('userconnected')),
            msg: ''
        };
        this.show_new_msg_dlg = this.show_new_msg_dlg.bind(this);
        this.conf_delete_msg = this.conf_delete_msg.bind(this);
        this.conf_delete_dest = this.conf_delete_dest.bind(this);
        this.persTemplate = this.persTemplate.bind(this);
        this.show_msg = this.show_msg.bind(this);
        this.send_msg= this.send_msg.bind(this);

        this.selectedPersTemplate = this.selectedPersTemplate.bind(this);


        this.myservice = new Xulaservice();
    }

    show_msg(rowData,source) {
        this.setState({msg: source=='MSG'?rowData.contenuMsg:rowData.contenuMsgDest})
        this.setState({ visible_dlg_contenu_msg: true });
    }


    persTemplate(option) {
        return (
            <div className="p-clearfix">
                <span >{option.label}</span>
            </div>
        );
    }

    selectedPersTemplate(option) {
        if (option) {
            return (
                <div>
                    <span>{option}</span>
                </div>
            );
        }
        else {
            return <span className="my-multiselected-empty-token">Sélectionner</span>
        }
    }

    show_new_msg_dlg() {
        this.setState({ visible_dlg_new: true });
        if (this.get_type_operation() == 'CLIENT') {
            this.get_liste_client();
        }
    }

    get_liste_client() {
        this.setState({ showindicator: true });
        this.myservice.getListe_client(this.state.objMsg.value !== 'Recouvrement' ? 'Tous' : this.state.objMsg.value).then(data => {
            this.setState({ liste_client: data, showindicator: false });
        });
    }


    conf_delete_msg(data) {
        if (data != null && data != undefined) {
            this.setState({ selectedMsg: data });
            this.setState({ msg: data.idMsg });
            this.setState({ visible_conf_delete_msg: true });
        }
    }

    conf_delete_dest(data) {
        if (data != null && data != undefined) {
            this.setState({ selectedDestMsg: data });
            this.setState({ msg: data.idRcv.nomPers });
            this.setState({ visible_conf_delete_dest: true });
        }
    }

    get_type_operation() {
        let val = 'PERSONNEL';
        if (this.props.match.params.typeOp != null && this.props.match.params.typeOp != undefined) {
            if (this.props.match.params.typeOp === 'interne') {
                val = 'PERSONNEL';
            } else {
                val = 'CLIENT';
            }
        }
        return val;
    }


    componentDidMount() {
        this.get_liste_msg();
        this.get_liste_personnel();
    }

    get_liste_personnel() {
        this.myservice.get_liste_personnel().then(data => {
            let liste = data.map(x => {
                return { label: x.nomPers, value: x.matrPers, telPers: x.telPers, emailPers: x.emailPers }
            });
            this.setState({ liste_pers: liste });
        });
    }

    get_liste_msg() {
        this.setState({ showindicator: true });
        this.myservice.get_liste_msg(this.get_type_operation()).then(data => this.setState({ liste_msg: data, showindicator: false }));
    }

    get_liste_dest_msg(idMsg) {
        this.setState({ showindicator: true });
        this.myservice.get_liste_dest_msg(idMsg).then(data => this.setState({ liste_dest_msg: data, showindicator: false }));
    }


    resultat(code, contenu) {
        if (code == '200') {
            this.growl.show({ severity: 'success', summary: 'Success', detail: contenu });
            this.annule_msg();
            this.setState({ liste_dest_msg: [] });
            this.get_liste_msg();
        } else {
            this.growl.show({ severity: 'error', summary: 'Error', detail: contenu });
        }
        this.setState({ showindicator: false });
    }

    resultat_dest_msg(code, contenu) {
        if (code == '200') {
            this.growl.show({ severity: 'success', summary: 'Success', detail: contenu });
            this.annule_msg();
            this.setState({ visible_dlg_new: false });
            if (this.state.selectedMsg != null && this.state.selectedMsg != undefined) {
                this.get_liste_dest_msg(this.state.selectedMsg.idMsg);
            }
        } else {
            this.growl.show({ severity: 'error', summary: 'Error', detail: contenu });
        }
        this.setState({ showindicator: false });
    }



    annule_msg() {
        this.setState({ idMsg: '0' });
        this.setState({ contenuMsg: '' });
        this.setState({ expMsg: '' });
        this.setState({ objMsg: '' });
        this.setState({ expMsg: '' });
        this.setState({ modif: false });
        this.setState({ visible_dlg_new: false });
        this.forceUpdate();
    }

    annule_msg_dlg() {
        this.annule_msg();
        this.setState({ liste_ligne: [] });
    }


    enreg_msg() {
        let liste_data = [];
        if (this.state.contenuMsg.length > 160 && this.state.canalMsg.value=='SMS') {
            this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Le message ne doit faire plus de 160 caractères' });
            return;
        }
        if (this.get_type_operation() == 'PERSONNEL') {
            if (this.state.liste_selected_pers === null || this.state.liste_selected_pers === undefined || this.state.objMsg === undefined || this.state.objMsg === '' || this.state.contenuMsg === undefined || this.state.contenuMsg === '') {
                this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez saisir tous les champs obligatoires' });
                return;
            }
            if (this.state.liste_selected_pers.length <= 0) {
                this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez ajouter les destinataires' });
                return;
            }
            let listeDestMsg = this.state.liste_selected_pers.map(x => {
                return x.label
            }).join(',');
            
            let data = { idMsg: '0', listeDestMsg: listeDestMsg, objMsg: this.state.objMsg, expMsg: this.state.userconnected.matrPers, contenuMsg: this.state.contenuMsg, cibleMsg: this.get_type_operation(), canalMsg: this.state.canalMsg.value };
            for (var i = this.state.liste_selected_pers.length; i--;) {
                let valide = true;
                let contact = 'NA';
                let pers = this.myservice.getValue(this.state.liste_pers, this.state.liste_selected_pers[i]);
                if (this.state.canalMsg.value == 'Mail') {
                    contact = pers.emailPers;
                    valide = this.myservice.validateEmail(contact);
                } else if (this.state.canalMsg.value == 'SMS') {
                    contact = pers.telPers;
                    valide = this.myservice.validatePhoneNumber(contact);
                }
                if (valide == true) {
                    liste_data.push({
                        idDestmsg: '0',
                        contenuMsgDest: this.state.contenuMsg,
                        idMsg: data,
                        listeDestMsg: listeDestMsg,
                        idRcv: this.state.liste_selected_pers[i],
                        nomRcv: pers.label,
                        refMsg: '',
                        contactDest: contact,
                        statutMsg: (this.state.canalMsg.value=='SMS'||this.state.canalMsg.value=='Mail')?'En attente':'Envoyé'
                    });
                }
            }
        } else {
            if (this.state.liste_client_selected.length <= 0) {
                this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez sélectionner les clients' });
                return;
            }
            let listeDestMsg = this.state.liste_client_selected.map(x => {
                return x.nomCli
            }).join(',');
            let data = { idMsg: '0', listeDestMsg: listeDestMsg, objMsg: this.state.objMsg.value, expMsg: this.state.userconnected.matrPers, contenuMsg: 'Rappel recouvrement', cibleMsg: this.get_type_operation(), canalMsg: this.state.canalMsg.value };
            for (var i = this.state.liste_client_selected.length; i--;) {
                let valide = true;
                let contact = '';
                if (this.state.canalMsg.value == 'Mail') {
                    contact=this.state.liste_client_selected[i].emailCli;
                    valide = this.myservice.validateEmail(contact);
                } else if (this.state.canalMsg.value == 'SMS') {
                    contact=this.state.liste_client_selected[i].telCli;
                    valide = this.myservice.validatePhoneNumber(contact);
                }

                if (valide == true) {
                    liste_data.push({
                        idDestmsg: '0',
                        contenuMsgDest: this.state.objMsg.value !== 'Recouvrement'?this.state.contenuMsg:'Bonjour, après vérification de votre compte client et sauf erreur de notre part, vous nous êtes redevable de la somme de '+this.myservice.formaterMontant(Math.abs(this.state.liste_client_selected[i].balanceCli))+ ' GNF, prière de vous acquiter dans les 7 prochains jours, sincères salutions ',
                        idMsg: data,
                        listeDestMsg: listeDestMsg,
                        idRcv: this.state.liste_client_selected[i].idCli,
                        nomRcv: this.state.liste_client_selected[i].nomCli,
                        refMsg: Math.abs(this.state.liste_client_selected[i].balanceCli),
                        contactDest: contact,
                        statutMsg: (this.state.canalMsg.value=='SMS'||this.state.canalMsg.value=='Mail')?'En attente':'Envoyé'
                    });
                }
            }
        }

        if(liste_data.length==0){
            this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Les adresses de tous les destinataires sélectionnés sont incorrestes' });
        }else{
            this.setState({ showindicator: true });
            this.myservice.enreg_dest_msg_batch(liste_data).then(data => {
                if (data != null && data != undefined) {
                    this.resultat(data.code, data.contenu);
                }
            });
        }
        

    }

    send_msg(rowData) {
        this.setState({showindicator: true});
        this.myservice.send_message(rowData.canalMsg,rowData.idMsg).then(data => this.resultat(data.code, data.contenu));
    }

    delete_msg() {
        if (this.state.selectedMsg == null || this.state.selectedMsg == undefined) {
            this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez sélectionner la ligne à supprimer' });
        } else {
            this.myservice.delete_msg(this.state.selectedMsg).then(data => this.resultat(data.code, data.contenu));
        }
    }

    actionTemplate_msg(rowData, column) {
        return <div className="p-grid" >
            <div className="p-col"><Button onClick={(e) => this.conf_delete_msg(rowData)} className="p-button-danger" style={{ width: 25, height: 25, marginLeft: -5 }} icon="pi pi-times" /></div>
            <div className="p-col"><Button onClick={(e) => this.show_msg(rowData,'MSG')} className="p-button-info" style={{ width: 25, height: 25, marginLeft: -8 }} icon="pi pi-search" /></div>
            <div className="p-col"><Button onClick={(e) => this.send_msg(rowData)} className="p-button-warning" style={{ width: 25, height: 25, marginLeft: -8 }} icon="pi pi-comment" /></div>
        </div>
    }

    actionTemplate_dest_msg(rowData, column) {
        return <div className="p-grid" >
            <div className="p-col"><Button onClick={(e) => this.conf_delete_dest(rowData)} className="p-button-danger" style={{ width: 25, height: 25, marginLeft: -5 }} icon="pi pi-times" /></div>
            <div className="p-col"><Button onClick={(e) => this.show_msg(rowData,'DEST')} className="p-button-info" style={{ width: 25, height: 25, marginLeft: -8 }} icon="pi pi-search" /></div>
        </div>
    }


    delete_dest_msg() {
        if (this.state.selectedDestMsg == null || this.state.selectedDestMsg == undefined) {
            this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez sélectionner la ligne à supprimer' });
        } else {
            this.myservice.delete_dest_msg(this.state.selectedDestMsg).then(data => this.resultat_dest_msg(data.code, data.contenu));
        }
    }

    getTabMsg() {
        const header = <div style={{ textAlign: 'left' }}>
            <Button type="button" icon="pi pi-plus" iconPos="left" label="Nouveau message" style={{ width: 200 }} onClick={this.show_new_msg_dlg}></Button>
            {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : null}
        </div>;
        if (this.get_type_operation() == 'PERSONNEL') {
            return <div className="p-col-12 p-lg-7">
                <div className="p-grid">
                    <div className="p-col-12">
                        <div className="card card-w-title" style={{ height: 600 }}>
                            <ScrollPanel style={{ width: '100%', height: '590px' }}>
                                <DataTable value={this.state.liste_msg} selectionMode="single" paginatorPosition="top" paginator={true} rows={10}
                                    onRowClick={e => this.setState({ selectedMsg: e.data })} onRowSelect={e =>
                                        this.setState({ selectedMsg: e.data }, () => {
                                            this.get_liste_dest_msg(this.state.selectedMsg.idMsg);
                                        })
                                    }
                                    header={header} style={{ width: '100%' }}
                                    responsive={true} selection={this.state.selectedMsg} >
                                    <Column header="#" body={this.actionTemplate_msg.bind(this)} style={{ width: '18%' }} />
                                    <Column body={(rowData, column) => {
                                        return <div>
                                            <span style={{ fontWeight: 'bold' }}>{rowData.dateMsg}</span><br />
                                        </div>
                                    }} field="dateMsg" header="Date" style={{ width: '12%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                    <Column field="objMsg" header="Objet" style={{ width: '19%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                    <Column field="contenuMsg" body={(rowData, column) => {
                                        return <div>
                                            <span>{rowData.contenuMsg}</span>
                                        </div>
                                    }} header="Message" style={{ width: '60%' }} filter={true} filterMatchMode='contains' sortable={true} />

                                </DataTable>
                            </ScrollPanel>
                        </div>
                    </div>
                </div>
            </div>
        } else {
            return <div className="p-col-12 p-lg-5">
                <div className="p-grid">
                    <div className="p-col-12">
                        <div className="card card-w-title" style={{ height: 600 }}>
                            <ScrollPanel style={{ width: '100%', height: '590px' }}>
                                <DataTable value={this.state.liste_msg} selectionMode="single" paginatorPosition="top" paginator={true} rows={10}
                                    onRowClick={e => this.setState({ selectedMsg: e.data })} onRowSelect={e =>
                                        this.setState({ selectedMsg: e.data }, () => {
                                            this.get_liste_dest_msg(this.state.selectedMsg.idMsg);
                                        })
                                    }
                                    header={header} style={{ width: '100%' }}
                                    responsive={true} selection={this.state.selectedMsg} >
                                    <Column header="#" body={this.actionTemplate_msg.bind(this)} style={{ width: '24%' }} />
                                    <Column body={(rowData, column) => {
                                        return <div>
                                            <span style={{ fontWeight: 'bold' }}>{rowData.dateMsg}</span><br />
                                        </div>
                                    }} field="dateMsg" header="Date" style={{ width: '18%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                    <Column field="canalMsg" header="Canal" style={{ width: '20%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                    <Column field="objMsg" header="Objet" filter={true} filterMatchMode='contains' sortable={true} />
                                </DataTable>
                            </ScrollPanel>
                        </div>
                    </div>
                </div>
            </div>
        }
    }

    getTabDetail() {
        if (this.get_type_operation() == 'PERSONNEL') {
            return <div className="p-col-12 p-lg-5">
                <div className="p-grid">
                    <div className="p-col-12">
                        <div className="card card-w-title" style={{ height: 600 }}>
                            <DataTable value={this.state.liste_dest_msg} selectionMode="single" scrollable={true} scrollHeight="460px"
                                onRowClick={e => this.setState({ selectedDestMsg: e.data })} onRowSelect={e => this.setState({ selectedDestMsg: e.data })}
                                responsive={true} selection={this.state.selectedDestMsg}  >
                                <Column header="#" body={this.actionTemplate_dest_msg.bind(this)} style={{ width: '16%' }} />
                                <Column field="nomRcv" header="Destinataire" filter={true} filterMatchMode='contains' sortable={true} />
                                <Column field="statutMsg" header="Statut" style={{ width: '17%' }} filter={true} filterMatchMode='contains' sortable={true} />
                            </DataTable>
                        </div>
                    </div>
                </div>
            </div>
        } else {
            return <div className="p-col-12 p-lg-7">
                <div className="p-grid">
                    <div className="p-col-12">
                        <div className="card card-w-title" style={{ height: 600 }}>
                            <DataTable value={this.state.liste_dest_msg} selectionMode="single" scrollable={true} scrollHeight="460px"
                                onRowClick={e => this.setState({ selectedDestMsg: e.data })} onRowSelect={e => this.setState({ selectedDestMsg: e.data })}
                                responsive={true} selection={this.state.selectedDestMsg}  >
                                <Column header="#" body={this.actionTemplate_dest_msg.bind(this)} style={{ width: '12%' }} />
                                <Column field="nomRcv" header="Destinataire" filter={true} filterMatchMode='contains' sortable={true} />
                                <Column field="refMsg" header="Référence" style={{ width: '15%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                <Column field="contactDest" header="Contact" style={{ width: '30%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                <Column field="statutMsg" header="Statut" style={{ width: '12%' }} filter={true} filterMatchMode='contains' sortable={true} />
                            </DataTable>
                        </div>
                    </div>
                </div>
            </div>
        }
    }
    render() {

        const dialogFooter_msg = (
            <div>
                <Button icon="pi pi-check" onClick={() => {
                    this.setState({ visible_conf_delete_msg: false });
                    this.delete_msg();
                }} label="Oui" />
                <Button icon="pi pi-times" onClick={() => this.setState({ visible_conf_delete_msg: false })} label="Annuler" className="p-button-secondary" />
            </div>
        );

        const dialogFooter_dest = (
            <div>
                <Button icon="pi pi-check" onClick={() => {
                    this.setState({ visible_conf_delete_dest: false });
                    this.delete_dest_msg();
                }} label="Oui" />
                <Button icon="pi pi-times" onClick={() => this.setState({ visible_conf_delete_dest: false })} label="Annuler" className="p-button-secondary" />
            </div>
        );

        return (
            <div className="p-fluid">
                <Growl ref={(el) => this.growl = el} />

                <Dialog header="Message" visible={this.state.visible_dlg_contenu_msg} style={{ width: '350px' }} modal={true}  onHide={() => this.setState({ visible_dlg_contenu_msg: false })}>
                    <div>
                        {this.state.msg}
                    </div>
                </Dialog>


                <Dialog header="Confirmation" visible={this.state.visible_conf_delete_msg} style={{ width: '250px' }} modal={true} footer={dialogFooter_msg} onHide={() => this.setState({ visible_conf_delete_msg: false })}>
                    <div>
                        <i className="fa fa-warning" style={{ color: '#FFD133', fontSize: 25 }}></i>
                        Voulez vous vraiment supprimer <b>{this.state.msg} </b>?
                    </div>
                </Dialog>

                <Dialog header="Confirmation" visible={this.state.visible_conf_delete_dest} style={{ width: '250px' }} modal={true} footer={dialogFooter_dest} onHide={() => this.setState({ visible_conf_delete_dest: false })}>
                    <div>
                        <i className="fa fa-warning" style={{ color: '#FFD133', fontSize: 25 }}></i>
                        Voulez vous vraiment supprimer <b>{this.state.msg} </b>?
                    </div>
                </Dialog>

                <Dialog header="Nouveau message" visible={this.state.visible_dlg_new} style={{ width: '50%' }} modal={true} onHide={(e) => this.setState({ visible_dlg_new: false })} >
                    <div className="p-grid">
                        <div className="p-col-12">
                            <div className="card card-w-title" style={{ height: 450, marginBottom: -10 }}>

                                {this.get_type_operation() == 'PERSONNEL' ?
                                    <div className="p-grid">
                                        <div className="p-col-12">
                                            <span >
                                                <label style={{ fontSize: '13px', color: '#9A9C9C' }} htmlFor="inPu">Objet *</label>
                                            </span>
                                            <InputText value={this.state.objMsg} onChange={(e) => this.setState({ objMsg: e.target.value })} />
                                        </div>
                                        <div className="p-col-12">
                                            <span >
                                                <label style={{ fontSize: '13px', color: '#9A9C9C' }} htmlFor="inPu">Canal *</label>
                                            </span>
                                            <Dropdown options={this.state.liste_canal} value={this.state.canalMsg} optionLabel='label' onChange={(e) => { this.setState({ canalMsg: e.value }) }} autoWidth={false} />
                                        </div>

                                        <div className="p-col-12">
                                            <span >
                                                <label style={{ fontSize: '13px', color: '#9A9C9C' }} htmlFor="inPu">Destinataires *</label>
                                            </span>
                                            <MultiSelect value={this.state.liste_selected_pers} options={this.state.liste_pers} onChange={(e) => this.setState({ liste_selected_pers: e.value })}
                                                maxSelectedLabels={3} style={{ minWidth: '12em' }} filter={true} filterPlaceholder="Rechercher" />
                                        </div>
                                        <div className="p-col-12">
                                            <span >
                                                <label style={{ fontSize: '13px', color: '#9A9C9C' }} htmlFor="inPu">Message *</label>
                                            </span>
                                            <InputTextarea rows={5} cols={30} value={this.state.contenuMsg} onChange={(e) => this.setState({ contenuMsg: e.target.value })} />
                                        </div>
                                    </div> :
                                    <>
                                        <div className="p-grid">
                                            <div className="p-col-12 p-md-2">
                                                <label style={{ fontSize: '13px', color: '#9A9C9C' }} htmlFor="inPu">Canal *</label>
                                            </div>
                                            <div className="p-col-12 p-md-4">
                                                <Dropdown options={[{ value: 'SMS', label: 'SMS' }, { value: 'Mail', label: 'Mail' }]} value={this.state.canalMsg} optionLabel='label' onChange={(e) => { this.setState({ canalMsg: e.value }) }} autoWidth={false} />
                                            </div>
                                            <div className="p-col-12 p-md-2">
                                                <label style={{ fontSize: '13px', color: '#9A9C9C' }} htmlFor="inPu">Objet *</label>
                                            </div>
                                            <div className="p-col-12 p-md-4">
                                                <Dropdown options={[{ value: 'Recouvrement', label: 'Recouvrement' }, { value: 'Promo', label: 'Promo' }, { value: 'Bonne fête', label: 'Bonne fête' }, { value: 'Remerciement', label: 'Remerciement' }, { value: 'Information', label: 'Information' }]} value={this.state.objMsg} optionLabel='label' onChange={(e) => this.setState({ objMsg: e.value }, () => {
                                                    this.get_liste_client();
                                                })} autoWidth={false} />
                                            </div>
                                            {this.state.objMsg.value !== 'Recouvrement' && this.state.objMsg.value !==undefined ?
                                                <div className="p-col-12">
                                                    <InputTextarea rows={2} cols={30} value={this.state.contenuMsg} onChange={(e) => this.setState({ contenuMsg: e.target.value })} />
                                                </div> : null}
                                        </div>
                                        <br />
                                        <DataTable value={this.state.liste_client}
                                            responsive={true} paginator={true} rows={5}
                                            onSelectionChange={e => this.setState({ liste_client_selected: e.value })}
                                            selection={this.state.liste_client_selected}>
                                            <Column selectionMode="multiple" style={{ width: '8%' }} />
                                            <Column field="nomCli" header="Nom client" filter={true} filterMatchMode='contains' sortable={true} />
                                            <Column field="telCli" header="Téléphone" filter={true} filterMatchMode='contains' sortable={true} style={{ width: '20%' }} />
                                            <Column field="balanceCli" header="Balance" body={(rowData, column) => {
                                                let color = 'black';
                                                if (parseFloat(rowData.balanceCli) < 0) {
                                                    color = 'red';
                                                }
                                                return <div>
                                                    <span style={{ color: color, fontWeight: 'bold' }}>{this.myservice.formaterMontant(rowData.balanceCli)}</span>
                                                </div>
                                            }} style={{ width: '22%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                        </DataTable>

                                    </>

                                }
                                <center>
                                    <div className="p-grid" style={{ width: 400 }}>
                                        <div className="p-col"><Button label="Annuler" icon="pi pi-ban" onClick={(e) => this.annule_msg()}  style={{width: 150}}/></div>
                                        
                                        <div className="p-col">{this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : null}</div>

                                        <div className="p-col"><Button label="Valider" icon="pi pi-check" onClick={(e) => this.enreg_msg()}  style={{width: 150}}/></div>
                                    </div>
                                </center>

                            </div>
                        </div>
                    </div>
                </Dialog>


                <div className="p-grid">
                    <div className="p-col-12">
                        <div className="card card-w-title" >

                            <div className="p-grid">

                                {this.getTabMsg()}
                                {this.getTabDetail()}

                            </div>
                        </div>

                    </div>

                </div>
            </div>
        );
    }
}