import React, { Component } from 'react';
import { Xulaservice } from '../service/Xulaservice';
import { Calendar } from 'primereact/calendar';
import { DataTable } from 'primereact/datatable';
import { Dropdown } from 'primereact/dropdown';
import { Column } from 'primereact/column';
import { Growl } from 'primereact/growl';
import './styles.css';
import { SplitButton } from 'primereact/splitbutton';
import { Chart } from 'primereact/chart';
import 'moment';
import 'moment/locale/fr';
import moment from 'moment';
import { ProgressSpinner } from 'primereact/progressspinner';
import Numeral from 'numeral';
import { InputText } from 'primereact/inputtext';
import { OverlayPanel } from 'primereact/overlaypanel';
import { Button } from 'primereact/button';

export class FormStat_VenteArticle_Client extends Component {
    constructor() {
        super();
        this.state = {
            pd1: new Date(),
            pd2: new Date(),
            ridArticle: null,
            topN: 20,
            liste_data: [],
            liste_article:  [{ value: 'Tous', label: 'Article' }],
            total: '0',
            items: [
                {
                    label: 'Format PDF',
                    icon: 'pi pi-print',
                    command: (e) => {
                        this.dt.exportCSV();
                    }
                }
            ],
            rfiltreArticle: '',
            userconnected: JSON.parse(localStorage.getItem('userconnected'))
        };
        this.myservice = new Xulaservice();
    }

    componentDidMount() {
        this.get_liste_article();
        this.get_liste_data();
    }

    formaterMontant(montant) {
        return Numeral(parseFloat(montant)).format('0,0');
    }


    get_liste_article() {
        let liste = [];
        this.myservice.get_liste_article_light().then(data => {
            if (data !== null && data !== undefined) {
                liste = data.map(x => {
                    return { value: x.idArticle, label: x.libArticle }
                });
                this.setState({ liste_article: liste });
            }
        });
    }


    get_liste_data() {
        if (this.state.topN === '' || this.state.topN === undefined) {
            this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez saisir le top N' });
            return;
        }
        let ridArticle = 'Tous';
        this.setState({ total: '0' });
        this.setState({ showindicator: true, liste_data: [] });

        let spd1 = moment(this.state.pd1).format('DD-MM-YYYY');
        let spd2 = moment(this.state.pd2).format('DD-MM-YYYY');

        if (this.state.ridArticle != undefined || this.state.ridArticle != null) {
            ridArticle = this.state.ridArticle.value;
        }
        this.myservice.get_st_vente_by_client(spd1, spd2, ridArticle, this.state.topN).then(data => this.setState({ liste_data: data, showindicator: false }, () => {
            let initialValue = 0
            if (data != null && data != undefined) {
                let tt = data.reduce(function (accumulator, currentValue) {
                    return accumulator + currentValue.dval
                }, initialValue);
                this.setState({ total: this.formaterMontant(tt) });
            }
        }));
    }



    render() {

        let liste_label = null;
        let liste_ds_stat = null;

        if (this.state.liste_data != null && this.state.liste_data != undefined) {
            if (this.state.liste_data.length > 0) {
                liste_label = this.state.liste_data.map(x => {
                    return x.lib
                });
                liste_ds_stat = this.state.liste_data.map(x => {
                    return x.dval
                });
            }
        }

        const chart_data = {
            labels: liste_label,
            datasets: [
                {
                    label: '',
                    backgroundColor: '#4071a7',
                    data: liste_ds_stat
                }
            ]
        };
 
        const footer = <div style={{ textAlign: 'left' }}>
            <label style={{ fontWeight: 'bold', fontSize: 18 }} >{this.state.total}</label>
        </div>;

        return (
            <div className="p-fluid">
                <Growl ref={(el) => this.growl = el} />
                <div className="p-grid">
                    <div className="p-col-12">
                        <div className="card card-w-title" style={{ height: 1200 }}>
                            <i className="fa fa-fw fa-search"></i>Option de recherche
                         <hr />
                            <div className="p-grid">
                                <div className="p-col-12">
                                    <div className="p-grid">
                                        <div className="p-col-12 p-md-2">
                                            <Calendar value={this.state.pd1} dateFormat="dd/mm/yy" onChange={(e) => this.setState({ pd1: e.value })} />
                                        </div>
                                        <div className="p-col-12 p-md-2">
                                            <Calendar value={this.state.pd2} dateFormat="dd/mm/yy" onChange={(e) => this.setState({ pd2: e.value })} />
                                        </div>
                                        <div className="p-col-12 p-md-2">
                                            <InputText placeholder="Top " value={this.state.topN} onChange={(e) => this.setState({ topN: e.target.value })} />
                                        </div>
                                        <div className="p-col-12 p-md-3">
                                            <Dropdown options={[{ value: 'Tous', label: 'Article' }].concat(this.state.liste_article)} placeholder="Article" value={this.state.ridArticle} optionLabel='label' filter={true} filterBy='label' filterMatchMode='contains' onChange={(e) => this.setState({ ridArticle: e.value }, () => {
                                                this.get_liste_data();
                                            })} autoWidth={false} panelStyle={{ width: '550px' }} />
                                        </div>
                                        <div className="p-col-12 p-md-2">
                                            <div className="p-grid" style={{ width: 200 }}>
                                                <div className="p-col"><SplitButton label="Rechercher" style={{ width: '140px' }} onClick={(e) => this.get_liste_data()} icon="pi pi-search" model={this.state.items}></SplitButton></div>
                                                <div className="p-col">  {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : null}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="p-grid">

                            <div className="p-col-12 p-lg-5">
                                    <div className="p-grid">
                                        <div className="p-col-12">
                                            <DataTable value={this.state.liste_data} scrollable={true} scrollHeight="600px"
                                                responsive={true} footer={footer} csvSeparator=";" ref={(el) => { this.dt = el; }}>
                                                <Column field="lib" header="Nom client" style={{ width: '60%' }} filter={true} filterMatchMode='contains' />
                                                <Column field="str_dval" header="Montant" style={{ width: '20%' }} filter={true} filterMatchMode='contains' />
                                                <Column field="lib3" header="Taux" style={{ width: '20%' }} filter={true} filterMatchMode='contains' />
                                            </DataTable>
                                        </div>
                                    </div>
                                </div>

                                <div className="p-col-12 p-lg-7">
                                    <div className="p-grid">
                                        <div className="p-col-12">
                                            <div className="content-section implementation">
                                                <Chart type="horizontalBar" data={chart_data} height="370w" />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>


        );
    }
}