import React, { Component } from 'react';
import { Xulaservice } from '../service/Xulaservice';
import { InputText } from 'primereact/inputtext';
import { Calendar } from 'primereact/calendar';
import { DataTable } from 'primereact/datatable';
import { Dropdown } from 'primereact/dropdown';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import './styles.css';
import { ProgressSpinner } from 'primereact/progressspinner';
import 'moment';
import 'moment/locale/fr';
import moment, { utc } from 'moment';
import { SplitButton } from 'primereact/splitbutton';
import { Panel } from 'primereact/panel';
import { OverlayPanel } from 'primereact/overlaypanel';
import Numeral from 'numeral';


export class FormEdition_facturesechues extends Component {
    constructor() {
        super();
        this.state = {
            total_facture: '0',
            total_regle: '0',
            total_restant: '0',
            pd1: new Date(),
            pd2: new Date(),
            lclient_rech: [],
            ridClient: null,
            rstatutLivraison: null,
            showindicator: false,
            liste_statut_livraison: [
                { label: 'Livraison', value: 'Tous' },
                { label: 'Livré', value: 'LIVRE' },
                { label: 'Non livré', value: 'NON LIVRE' }
            ],
            items: [
                {
                    label: 'Exporter',
                    icon: 'pi pi-print',
                    command: (e) => {
                        this.dt.exportCSV();
                    }
                }
            ],
            rfiltreClient: 'COMPTOIR',
            userconnected: JSON.parse(localStorage.getItem('userconnected'))
        };

        this.myservice = new Xulaservice();

    }

  
    clientItemTemplate(option) {
        if (!option.value) {
            return option.label;
        }
        else {
            return (
                <div className="p-clearfix">
                    <div className="p-grid">
                        <div className="p-col-12 p-md-6">
                            <label>{option.label}</label>
                        </div>
                        <div className="p-col-12 p-md-2">
                            <label>{option.telCli}</label>
                        </div>
                        <div className="p-col-12 p-md-3">
                            <label style={{ fontWeight: 'bold' }}>{option.soldeCli_format}</label>
                        </div>
                        <div className="p-col-12 p-md-1">
                            <label>{option.remiseCli}</label>
                        </div>
                    </div>
                </div>
            );
        }
    }

    componentDidMount() {
        if (this.state.userconnected.idEtabl.domaineEtabl === 'Pharmacie') {
            this.setState({ rfiltreClient: 'Tous' }, () => {
                this.get_liste_client();
            })
        } else {
            if (this.state.userconnected.idEtabl.codeEtabl !== 'EDK') {
                this.setState({ rfiltreClient: 'Tous' }, () => {
                    this.get_liste_client();
                });
            }
        }
    }


    handleKeyPress = e => {
        if (e.keyCode === 13) {
            this.get_liste_sortie();
        }
    }

    get_liste_client() {
        let lclient = null;
        this.setState({ showindicator: true });
        this.myservice.getListe_client(this.state.rfiltreClient).then(data => this.setState({ liste_client: data }, () => {
            this.setState({ showindicator: false });
            let lclient_rech = [{ value: 'Tous', label: 'Toutes les clients', remiseCli: 0, soldeCli_format: '0', telCli: 'x', tvaCli: 0, balanceCli: 0, caventeCli: 0, label_rech: 'Tous' }];
            if (this.state.liste_client != null && this.state.liste_client != undefined) {
                lclient = this.state.liste_client.map(x => {
                    return { value: x.idCli, label: x.nomCli, remiseCli: x.remiseCli, soldeCli_format: x.soldeCli_format, telCli: x.telCli, tvaCli: x.tvaCli, balanceCli: x.balanceCli, caventeCli: x.caventeCli, label_rech: x.nomCli + ' ' + x.idCli }
                });
                lclient_rech = lclient_rech.concat(lclient);
                this.setState({ lclient: lclient });
                this.setState({ lclient_rech: lclient_rech });
            }
        }));
    }




    get_liste_sortie() {
        this.setState({ showindicator: true, liste_sortie: [] });
        let rstatut_livraison = 'Tous';
        let rid_client = 'Tous';
        let spd1 = moment(this.state.pd1).format('DD-MM-YYYY');
        let spd2 = moment(this.state.pd2).format('DD-MM-YYYY');

        if (this.state.rstatutLivraison != undefined || this.state.rstatutLivraison != null) {
            rstatut_livraison = this.state.rstatutLivraison.value;
        }

        if (this.state.ridClient != undefined || this.state.ridClient != null) {
            rid_client = this.state.ridClient.value;
        }

        this.setState({ total_facture: '0' });
        this.setState({ total_restant: '0' });

        this.myservice.get_liste_factures_echues(spd1, spd2, rstatut_livraison, rid_client).then(data => this.setState({ liste_sortie: data, showindicator: false }, () => {
            let initialValue = 0
            if (data != null && data != undefined) {
                let tt_facture = data.reduce(function (accumulator, currentValue) {
                    return accumulator + currentValue.montant_sortie
                }, initialValue);
                let tt_restant = data.reduce(function (accumulator, currentValue) {
                    return accumulator + currentValue.mt_restant
                }, initialValue);
                let tt_regl = data.reduce(function (accumulator, currentValue) {
                    return accumulator + currentValue.mt_regle
                }, initialValue);
                this.setState({ total_facture: this.formaterMontant(tt_facture) });
                this.setState({ total_restant: this.formaterMontant(tt_restant) });
                this.setState({ total_regle: this.formaterMontant(tt_regl) });
            }

        }));
    }

    assureTemplate(option) {
        if (!option.value) {
            return option.label;
        }
        else {
            return (
                <div className="p-clearfix">
                    <div className="p-grid">
                        <div className="p-col-12 p-md-10">
                            <label style={{ color: '#000000', fontWeight: 'bold' }}>{option.label}</label>
                        </div>
                        <div className="p-col-12 p-md-2">
                            <label>{option.matrAss}</label>
                        </div>
                    </div>
                </div>
            );
        }
    }


    formaterMontant(montant) {
        return Numeral(parseFloat(montant)).format('0,0');
    }



    option_recherche() {
        return <div className="p-grid">
            <div className="p-col-12 p-md-2">
                <Calendar value={this.state.pd1} dateFormat="dd/mm/yy" onChange={(e) => this.setState({ pd1: e.value })} />
            </div>
            <div className="p-col-12 p-md-2">
                <Calendar value={this.state.pd2} dateFormat="dd/mm/yy" onChange={(e) => this.setState({ pd2: e.value })} />
            </div>
            <div className="p-col-12 p-md-3">
                <div className="p-grid">
                    <div className="p-col-11">
                        <Dropdown id="new_dp_client_rech"
                            options={this.state.lclient_rech}
                            value={this.state.ridClient}
                            placeholder="Client"
                            onChange={(e) => this.setState({ ridClient: e.value, idCli_situation: e.value }, () => {
                                this.get_liste_sortie();
                                if (this.state.userconnected.idEtabl.domaineEtabl === 'Pharmacie') {
                                    this.select_parent_assure_by_client(this.state.ridClient.value);
                                }
                            })}
                            style={{ width: '100%' }}
                            panelStyle={{ width: '500px' }} optionLabel='label_rech' filter={true} filterBy='label_rech' filterMatchMode='contains' autoWidth={false} itemTemplate={this.clientItemTemplate}
                        />
                    </div>
                    <div className="p-col-1">
                        <Button type="button" icon="pi pi-search" className="p-button-warning" onClick={(e) => {
                            this.oprech.toggle(e);
                        }} style={{ marginLeft: -15, width: '30px' }} />
                    </div>
                </div>
            </div>
            <div className="p-col-12 p-md-2">
                    <Dropdown options={this.state.liste_statut_livraison} placeholder="Livraison" value={this.state.rstatutLivraison} optionLabel='label' filter={true} filterBy='label' filterMatchMode='contains' onChange={(e) => this.setState({ rstatutLivraison: e.value })} autoWidth={false} />
                </div>
            <div className="p-col-12 p-md-2">
                <SplitButton label="Rechercher" style={{ width: '140px' }} onClick={(e) => this.get_liste_sortie()} icon="pi pi-search" model={this.state.items}></SplitButton>
            </div>
            <div className="p-col-12 p-md-1">
                {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : null}            
            </div>
        </div>

    }

    render() {


        return (
            <div className="p-fluid">
                <OverlayPanel ref={(el) => { this.oprech = el; }} style={{ width: '250px' }} >
                    <div className="p-grid">
                        <div className="p-col-10">
                            <InputText value={this.state.rfiltreClient} onKeyDown={(e) => {
                                if (e.keyCode === 13) {
                                    this.oprech.toggle(e);
                                    document.getElementById("new_dp_client_rech").click();
                                    this.get_liste_client();
                                }
                            }} onChange={(e) => this.setState({ rfiltreClient: e.target.value })} autoFocus={true} />
                        </div>
                        <div className="p-col-2">
                            <Button type="button" icon="pi pi-search" className="p-button-warn" onClick={(e) => {
                                this.oprech.toggle(e);
                                document.getElementById("new_dp_client_rech").click();
                                this.get_liste_client();
                            }} style={{ width: '30px' }} />
                        </div>
                    </div>
                </OverlayPanel>


                <div className="p-grid">
                    <div className="p-col-12">
                        <div className="card card-w-title" style={{ minHeight: this.state.min_height, marginBottom: -5,height: 700 }}>
                            <i className="fa fa-fw fa-search"></i>Option de recherche
                            <hr />
                            {this.option_recherche()} 

                            <Panel style={{ backgroundColor: '#f7f7f7' }}>
                                <div className="p-grid">
                                    <div className="p-col-12 p-md-1">
                                        <label style={{ fontSize: 16 }}>Total</label>
                                    </div>
                                    <div className="p-col-12 p-md-3">
                                        <label style={{ fontSize: 16, fontWeight: 'bold', color: 'black' }}> {this.state.total_facture} </label>
                                    </div>
                                    <div className="p-col-12 p-md-1">
                                        <label style={{ fontSize: 16 }}>Payé</label>
                                    </div>
                                    <div className="p-col-12 p-md-3">
                                        <label style={{ fontSize: 16, fontWeight: 'bold', color: 'black' }}> {this.state.total_regle} </label>
                                    </div>
                                    <div className="p-col-12 p-md-1">
                                        <label style={{ fontSize: 16 }}>Restant</label>
                                    </div>
                                    <div className="p-col-12 p-md-3">
                                        <label style={{ fontSize: 16, fontWeight: 'bold', color: 'red' }}> {this.state.total_restant} </label>
                                    </div>
                                </div>
                            </Panel>


                            <DataTable value={this.state.liste_sortie} selectionMode="single" paginatorPosition="top" paginator={true} rows={10} rowsPerPageOptions={[5, 10, 20, 100, 200]}
                                responsive={true}  ref={(el) => { this.dt = el; }}   >
                                <Column header="#" field="ordre" style={{ width: '3%'}} />
                                <Column field="numFactureSortie" body={(rowData, column) => {
                                    return <div>
                                        <span style={{ color: '#F34C1F', fontWeight: 'bold' }}>{rowData.numFactureSortie}</span><br />
                                    </div>
                                }} header="N° Facture" style={{ width: '13%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                <Column field="dateSortie" header="Date" style={{ width: '14%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                <Column field="montant_sortie" body={(rowData, column) => {
                                    return <div>
                                        <span style={{ color: '#000000', fontWeight: 'bold' }}>{rowData.montant_sortie_format}</span>
                                    </div>
                                }} header="Montant" style={{ width: '12%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                <Column field="mt_restant" body={(rowData, column) => {
                                    return <div>
                                        <span style={{ color: 'red', fontWeight: 'bold' }}>{rowData.mt_restant_format}</span>
                                    </div>
                                }} header="Total restant" style={{ width: '12%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                <Column field="statutLivraison" body={(rowData, column) => {
                                    let color = 'black';
                                    if (rowData.statutLivraison != 'LIVRE') {
                                        color = 'red';
                                    }
                                    return <div>
                                        <span style={{ color: color }}>{rowData.statutLivraison}</span><br />
                                    </div>
                                }} header="Statut" style={{ width: '9%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                <Column field="idCli.nomCli" body={(rowData, column) => {
                                    let nom_souscripteur = rowData.nom_souscripteur;
                                    let nom_assure = rowData.nom_assure;
                                    if (nom_assure === '-') {
                                        nom_assure = '';
                                    }
                                    if (nom_souscripteur === '-') {
                                        nom_souscripteur = '';
                                    }
                                    return <div>
                                        <span>{rowData.idCli.nomCli}</span>
                                        <span> {nom_souscripteur}</span>
                                        <span> {nom_assure}</span>
                                    </div>
                                }} header="Client" filter={true} filterMatchMode='contains' sortable={true} />
                                <Column field="nom_boutique" header="Boutique" style={{ width: '14%' }} filter={true} filterMatchMode='contains' sortable={true} />
                            </DataTable>

                        </div>

                    </div>

                </div>
            </div>
        );
    }
}