import React, { Component } from 'react';
import { Xulaservice } from '../service/Xulaservice';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import Cleave from 'cleave.js/react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dialog } from 'primereact/dialog';
import { Growl } from 'primereact/growl';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import './styles.css';
import { ProgressSpinner } from 'primereact/progressspinner';
import 'moment';
import 'moment/locale/fr';
import moment from 'moment';
import Numeral from 'numeral';
import { Panel } from 'primereact/components/panel/Panel';
import { OverlayPanel } from 'primereact/overlaypanel';
import { SplitButton } from 'primereact/splitbutton';

export class FormInventaire extends Component {
    constructor() {
        super();
        this.state = {
            liste_article: [],
            liste_boutique: [],
            liste_inventaire: [],
            liste_sous_type_article: [],
            selectedArticle: null,
            idArticle: null,
            idBoutiq: null,
            showindicator: false,
            libArticle: '',
            codeArticle: '',
            cbArticle: '',
            idArt: '',
            uniteArticle: { label: 'Qauntité', value: '-' },
            remiseArticle: 0,
            finRemiseArticle: null,
            utilisationArticle: '',
            debutRemiseArticle: null,
            tvaArticle: 0,
            prixRefAchat: 0,
            prixRefVente: 0,
            date_exp: null,
            qte_inv: 0,
            liste_unite: [
                { label: 'Qauntité', value: '-' },
                { label: 'Kilometre', value: 'Km' },
                { label: 'Kilometre carré', value: 'Km2' },
                { label: 'Hectar', value: 'ha' },
                { label: 'Metre', value: 'm' },
                { label: 'Metre carré', value: 'm2' },
                { label: 'Kilogramme', value: 'Kg' },
                { label: 'Gramme', value: 'g' },
                { label: 'Litre', value: 'l' },
                { label: 'Jour', value: 'j' },
                { label: 'Heure', value: 'h' },
                { label: 'Pacquet', value: 'p' },
                { label: 'Boite', value: 'b' },
                { label: 'NA', value: '.' },
            ],
            msg: '',
            disable_modif: false,
            modif: false,
            visible: false,
            visible_dlg_new: false,
            idStype: null,
            userconnected: JSON.parse(localStorage.getItem('userconnected')),
            rfiltreArticle: ''
        };

        this.conf_delete_article = this.conf_delete_article.bind(this);
        this.qte_inventEditor = this.qte_inventEditor.bind(this);
        this.prix_ref_achatEditor = this.prix_ref_achatEditor.bind(this);
        this.prix_ref_venteEditor = this.prix_ref_venteEditor.bind(this);

        this.articleItemTemplate = this.articleItemTemplate.bind(this);
        this.show_new_dlg = this.show_new_dlg.bind(this);
        this.xulaservice = new Xulaservice();
    }



    qte_inventEditor(props) {
        return this.inputTextEditor(props, 'qte_invent');
    }

    prix_ref_achatEditor(props) {
        return this.inputTextEditor(props, 'prix_ref_achat');
    }

    prix_ref_venteEditor(props) {
        return this.inputTextEditor(props, 'prix_ref_vente');
    }

    onEditorValueChange(props, value) {
        let updateds = [...props.value];
        updateds[props.rowIndex][props.field] = value;
        this.setState({ liste_inventaire: updateds });
    }

    inputTextEditor(props, field) {
        return <Cleave value={props.rowData[field]} options={{ numeral: true, delimiter: ' ' }} onChange={(e) => this.onEditorValueChange(props, e.target.rawValue)} className="p-inputtext" />
    }

    get_liste_boutique() {
        this.xulaservice.getListe_boutique().then(data => this.setState({ liste_boutique: data }));
    }

    articleItemTemplate(option) {
        if (!option.value) {
            return option.label;
        }
        else {
            return (
                <div className="p-clearfix">
                    <div className="p-grid">
                        <div className="p-col-12 p-md-7">
                            <label>{option.label}</label>
                        </div>
                        {this.state.userconnected.profilUser === 'PDG' ?
                            <div style={{ fontWeight: 'bold' }} className="p-col-12 p-md-2">
                                <label>{option.prix_ref_achat_format}</label>
                            </div>
                            : null}
                        <div className="p-col-12 p-md-2">
                            <label>{option.prix_ref_vente_format}</label>
                        </div>
                        <div className="p-col-12 p-md-1" >
                            <label style={{ fontWeight: 'bold', color: 'red', marginRight: '5px' }}>{option.qte_stock_format}</label>
                        </div>
                    </div>
                </div>
            );
        }
    }

    handleKeyPress = e => {
        if (e.keyCode === 13 && e.ctrlKey) {
            this.enreg_inventaire();
        } else if (e.key === 'u' && e.ctrlKey) {
            this.annule_inventaire();
        } else if (e.key === 'a' && e.ctrlKey) {
            this.add_article();
        } else if (e.key === 'k' && e.ctrlKey) {
            document.getElementById("new_dp_prod").click();
        }
    }

    componentDidMount() {
        this.get_liste_sous_type_article();
        this.get_liste_boutique();
    }

    get_liste_sous_type_article() {
        this.xulaservice.get_liste_sous_type_article('Tous').then(data => this.setState({ liste_sous_type_article: data }));
    }

    get_liste_article(idBoutiq) {
        this.setState({ showindicator: true });
        this.xulaservice.get_inventaire_mins(this.state.rfiltreArticle, idBoutiq).then(data => this.setState({ liste_article: data, showindicator: false }));
    }

    resultat(code, contenu) {
        if (code == '200') {
            this.growl.show({ severity: 'success', summary: 'Success', detail: contenu });
            this.annule_inventaire();
            window.location.reload();
        } else {
            this.growl.show({ severity: 'error', summary: 'Error', detail: contenu });
        }
        this.setState({ showindicator: false });
    }

    edit_article(data) {
        if (data != null && data != undefined) {
            let unite = this.state.liste_unite.filter(function (el) {
                return el.value == data.uniteArticle;
            })[0];
            this.setState({ idArticle: data.idArt });
            this.setState({ libArticle: data.libArt });
            this.setState({ codeArticle: data.codeArt });
            this.setState({ cbArticle: data.cbArt });
            this.setState({ descArticle: data.descArt });
            this.setState({ uniteArticle: unite });
            this.setState({ remiseArticle: data.remiseArt });
            this.setState({ idStype: { value: data.idstype, label: data.libsoustypearticle } });
            this.setState({ finRemiseArticle: data.finRemiseArticle });
            this.setState({ debutRemiseArticle: data.debutRemiseArticle });
            this.setState({ tvaArticle: data.tvaArticle });
            this.setState({ prixRefAchat: data.prix_ref_achat });
            this.setState({ prixRefVente: data.prix_ref_vente });
            this.setState({ couvArticle: data.couvArticle });
            this.setState({ selectedArticle: data });
            this.setState({ modif: true });
            window.scrollTo(0, 0);
        }
    }

    annule_add() {
        this.setState({ idArticle: '' });
        this.setState({ libArticle: '' });
        this.setState({ codeArticle: '' });
        this.setState({ cbArticle: '' });
        this.setState({ descArticle: '' });
        this.setState({ remiseArticle: 0 });
        this.setState({ finRemiseArticle: '' });
        this.setState({ debutRemiseArticle: '' });
        this.setState({ tvaArticle: 0 });
        this.setState({ prixRefVente: 0 });
        this.setState({ capturePiPers: 0 });
        this.setState({ couvArticle: 'nocouv.png' });
        this.setState({ modif: false });
        this.setState({ visible_dlg_new: false });
        this.forceUpdate();
    }

    annule_inventaire() {
        this.annule_add();
        this.setState({ liste_inventaire: [] });
    }

    add_article() {
        if (this.state.libArticle === null || this.state.libArticle === undefined || this.state.libArticle === '') {
            this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez choisir un article' });
            return;
        } else if (this.state.idStype === null || this.state.idStype === undefined || this.state.idStype === '') {
            this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez choisir un sous type' });
            return;
        }
        Array.prototype.insert = function (index) {
            this.splice.apply(this, [index, 0].concat(this.slice.call(arguments, 1)));
        };
        let trouve = false;
        if (this.state.qte_inv === null || this.state.qte_inv === '') {
            this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez saisir la quantité ' });
        }
        else {
            this.setState({ num_ligne: this.state.num_ligne + 1 });
            let date = moment(this.state.date_exp, 'DD/MM/YYYY').format('DD/MM/YYYY');
            if (date === 'Invalid date') {
                date = null;
                if (this.state.userconnected.matrPers.idBoutiq.typeBoutiq === 'Pharmacie') {
                    this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez saisir la date de péremption' });
                    return;
                }
            }
            if (this.state.liste_inventaire.length == 0) {
                this.state.liste_inventaire.insert(this.state.num_ligne, { idArt: this.state.idArt, qte_invent: this.state.qte_inv, date_exp_article: date, idstype: this.state.idStype.value, libArt: this.state.libArticle, cbArt: this.state.cbArticle, prix_ref_achat: this.state.prixRefAchat, prix_ref_vente: this.state.prixRefVente, uniteArticle: this.state.uniteArticle.value, qte_stock: this.state.qte_stock, qte_stock_format: this.state.qte_stock, idBoutiq: this.state.idBoutiq.value, libsoustypearticle: this.state.idStype.label });
            } else {
                for (var i = this.state.liste_inventaire.length; i--;) {
                    if (this.state.idArticle.value == this.state.liste_inventaire[i].idArt) {
                        trouve = true;
                        this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Cet article existe déja, vous pouvez le modifier dans le tableau' });
                        return;
                    }
                }
                if (trouve == false) {
                    this.state.liste_inventaire.insert(this.state.num_ligne, { idArt: this.state.idArt, qte_invent: this.state.qte_inv, date_exp_article: date, idstype: this.state.idStype.value, libArt: this.state.libArticle, cbArt: this.state.cbArticle, prix_ref_achat: this.state.prixRefAchat, prix_ref_vente: this.state.prixRefVente, uniteArticle: this.state.uniteArticle.value, qte_stock: this.state.qte_stock, qte_stock_format: this.state.qte_stock, idBoutiq: this.state.idBoutiq.value, libsoustypearticle: this.state.idStype.label });
                }
            }
            document.getElementById("new_dp_prod").click();
            this.forceUpdate();
        }
    }


    enreg_inventaire() {
        if (this.state.liste_inventaire === null || this.state.liste_inventaire === undefined) {
            this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez ajouter les articles' });
        } else if (this.state.liste_inventaire.length <= 0) {
            this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez ajouter les articles' });
        } else {
            this.setState({ showindicator: true });
            this.xulaservice.enreg_inventaire(this.state.liste_inventaire)
                .then(data => {
                    if (data != undefined && data != null) {
                        this.resultat(data.code, data.contenu);
                    }
                });

        }
    }

    actionTemplate(rowData) {
        return <div className="p-grid" style={{ width: 50 }}>
            <div className="p-col"><Button onClick={() => this.conf_delete_article(rowData)} className="p-button-danger" style={{ width: 25, height: 25 }} icon="pi pi-times" /></div>
        </div>
    }


    conf_delete_article(data) {
        if (data != null && data != undefined) {
            for (var i = this.state.liste_inventaire.length; i--;) {
                if (data.idArt === this.state.liste_inventaire[i].idArt) {
                    this.state.liste_inventaire.splice(i, 1);
                    break;
                }
            }
            this.forceUpdate();
        }
    }

    formaterMontant(montant) {
        return Numeral(parseFloat(montant)).format('0,0');
    }

    show_new_dlg() {
        this.setState({ visible_dlg_new: true });
    }

    render() {
        let lsous_type = null;
        if (this.state.liste_sous_type_article != null && this.state.liste_sous_type_article != undefined) {
            lsous_type = this.state.liste_sous_type_article.map(x => {
                return { value: x.idStype, label: x.libStype }
            });
        }
        let liste = null;
        let lprod = [{ value: 'NEW', label: 'Nouveau', cbArt: '0', qte_stock_format: '0', prix_ref_vente_format: 0, prix_ref_vente: 0, qte_stock: 0, prix_ref_achat: 0, prix_ref_achat_format: '0', idstype: '0', libsoustypearticle: '0', label_rech: 'Nouveau 0' }];
        if (this.state.liste_article != null && this.state.liste_article != undefined) {
            liste = this.state.liste_article.map(x => {
                return { value: x.idArt, label: x.libArt + ' - ' + x.cbArt, cbArt: x.cbArt, qte_stock_format: this.formaterMontant(x.qte_stock_reel), prix_ref_vente_format: this.formaterMontant(x.prix_ref_vente), prix_ref_vente: x.prix_ref_vente, qte_stock: x.qte_stock_reel, prix_ref_achat: x.prix_ref_achat, prix_ref_achat_format: this.formaterMontant(x.prix_ref_achat), idstype: x.idstype, libsoustypearticle: x.libsoustypearticle, label_rech: x.libArt + ' ' + x.idArt }
            });
            lprod = lprod.concat(liste);
        }

        let lboutique = null;
        if (this.state.liste_boutique != null && this.state.liste_boutique != undefined) {
            lboutique = this.state.liste_boutique.map(x => {
                return { value: x.idBoutiq, label: x.nomBoutiq }
            });
        }
 
        const header = <div className="p-grid" style={{ textAlign: 'left',height: 40 }}>
                <div className="p-col-10">
                    <SplitButton label="Nouveau" onClick={this.show_new_dlg} icon="pi pi-plus" style={{ width: 150 }} model={[
                        {
                            label: 'Corriger stock négatif',
                            icon: 'pi pi-pencil',
                            command: (e) => {
                                this.setState({ showindicator: true });
                                this.xulaservice.get_inventaire_mins2("Tous", this.state.userconnected.matrPers.idBoutiq.idBoutiq, "Tous", "Tous").then(data => this.setState({ showindicator: false }, () => {
                                    if (data != null && data != undefined) {
                                        let idBoutiq=this.state.userconnected.matrPers.idBoutiq;
                                        let lprod = data.map(x => {
                                            return {idArt: x.idArt,idBoutiq: idBoutiq,codeArt: x.codeArt,libtypearticle: x.libtypearticle,idEtabl: x.idEtabl, libArt: x.libArt, cbArt: x.cbArt, libsoustypearticle: x.libsoustypearticle, prix_ref_vente: x.prix_ref_vente, prix_ref_achat: x.prix_ref_achat, qte_stock: x.qte_stock, qte_stock_reel: x.qte_stock_reel, qte_invent: 0,date_exp_article: moment(new Date()).format('DD/MM/YYYY') }
                                        }).filter(function (el) {
                                            return el.qte_stock < 0;
                                        });
                                        this.setState({ liste_inventaire: lprod });
                                    }
                                }));
                            }
                        }
                    ]} />
                </div>
                <div className="p-col-2" >
                    {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : null}
                </div>
            </div>;

        return (
            <div className="p-fluid">
                <Growl ref={(el) => this.growl = el} />
                <OverlayPanel ref={(el) => { this.op = el; }} style={{ width: '250px' }} >
                    <div className="p-grid">
                        <div className="p-col-10">
                            <InputText value={this.state.rfiltreArticle} onKeyDown={(e) => {
                                if (e.keyCode === 13) {
                                    this.op.toggle(e);
                                    document.getElementById("new_dp_prod").click();
                                    this.get_liste_article(this.state.idBoutiq.value);
                                }
                            }} onChange={(e) => this.setState({ rfiltreArticle: e.target.value })} autoFocus={true} />
                        </div>
                        <div className="p-col-2">
                            <Button type="button" icon="pi pi-search" className="p-button-info" onClick={(e) => {
                                this.op.toggle(e);
                                document.getElementById("new_dp_prod").click();
                                this.get_liste_article(this.state.idBoutiq.value);
                            }} style={{ width: '30px' }} />
                        </div>
                    </div>
                </OverlayPanel>

                <Dialog header="Nouveau" visible={this.state.visible_dlg_new} style={{ width: '40%' }} modal={true} onHide={(e) => {
                    this.setState({ visible_dlg_new: false });
                }} >
                    <div className="p-grid">
                        <div className="p-col-12">
                            <div className="card card-w-title" style={{ height: 450 }}>
                                <div className="p-grid">
                                    <div className="p-col-12 p-md-3">
                                        <label htmlFor="libArticle">Boutique *</label>
                                    </div>
                                    <div className="p-col-12 p-md-9">
                                        <Dropdown options={lboutique} value={this.state.idBoutiq} optionLabel='label' filter={true} filterBy='label' filterMatchMode='contains' onChange={(e) => {
                                            this.setState({ idBoutiq: e.value }, () => {
                                                if (this.xulaservice.wsurl().indexOf(":9077") > 0) {
                                                    this.setState({ rfiltreArticle: 'Tous' }, () => {
                                                        this.get_liste_article(this.state.idBoutiq.value);
                                                    })
                                                } else {
                                                    if (this.state.userconnected.matrPers.idBoutiq.typeBoutiq !== 'Pharmacie') {
                                                        this.setState({ rfiltreArticle: 'Tous' }, () => {
                                                            this.get_liste_article(this.state.idBoutiq.value);
                                                        })
                                                    }
                                                }

                                            })
                                        }
                                        } autoWidth={false} />
                                    </div>

                                    <div className="p-col-12 p-md-3">
                                        <label htmlFor="libArticle">Article *</label>
                                    </div>
                                    <div className="p-col-12 p-md-9">
                                        {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : null}
                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon" style={{ backgroundColor: '#f5f8f9', color: 'red', fontWeight: 'bold', width: 70 }}>{this.state.qte_stock}</span>
                                            <Button type="button" icon="pi pi-search" className="p-button-warning" onClick={(e) => {
                                                this.op.toggle(e);
                                            }} style={{ marginLeft: -15, width: '30px' }} />
                                            <Dropdown id="new_dp_prod"
                                                style={{ width: '250px' }}
                                                value={this.state.idArticle}
                                                options={lprod}
                                                placeholder="Articles"
                                                panelStyle={{ width: '500px' }}
                                                onChange={(e) => {
                                                    this.setState({ idArticle: e.value }, () => {
                                                        if (this.state.idArticle.value === 'NEW') {
                                                            this.setState({ idArt: '0' });
                                                            this.setState({ qte_stock: '0' });
                                                            this.setState({ qte_inv: 0 });
                                                            this.setState({ libArticle: '' });
                                                            this.setState({ cbArticle: '' });
                                                            this.setState({ date_exp: '' });
                                                            this.setState({ prixRefVente: 0 });
                                                            this.setState({ prixRefAchat: 0 });
                                                            this.setState({ disable_modif: false });
                                                        } else {
                                                            this.setState({ idArt: this.state.idArticle.value });
                                                            this.setState({ qte_stock: this.state.idArticle.qte_stock });
                                                            this.setState({ qte_inv: this.state.idArticle.qte_stock });
                                                            this.setState({ libArticle: this.state.idArticle.label });
                                                            this.setState({ cbArticle: this.state.idArticle.cbArt });
                                                            this.setState({ date_exp: this.state.idArticle.date_exp_article });
                                                            this.setState({ prixRefVente: this.state.idArticle.prix_ref_vente });
                                                            this.setState({ prixRefAchat: this.state.idArticle.prix_ref_achat });
                                                            this.setState({ disable_modif: true });
                                                            this.setState({ idStype: { value: this.state.idArticle.idstype, label: this.state.idArticle.libsoustypearticle } });
                                                        }
                                                    })
                                                }} autoWidth={false}
                                                optionLabel='label' filter={true} filterBy='label,cbArt' filterMatchMode='contains' itemTemplate={this.articleItemTemplate}
                                            />

                                        </div>
                                    </div>
                                    <div className="p-col-12 p-md-3">
                                        <label htmlFor="libArticle">Désignation *</label>
                                    </div>
                                    <div className="p-col-12 p-md-9">
                                        <InputText onKeyDown={this.handleKeyPress} id="libArticle" value={this.state.libArticle} onChange={(e) => this.setState({ libArticle: e.target.value, descArticle: e.target.value })} />
                                    </div>
                                    <div className="p-col-12 p-md-3">
                                        <label htmlFor="remiseArticle">Qauntité *</label>
                                    </div>
                                    <div className="p-col-6 p-md-9">
                                        <Cleave onKeyDown={this.handleKeyPress} value={this.state.qte_inv} options={{ numeral: true, delimiter: ' ' }} onChange={(e) => this.setState({ qte_inv: e.target.rawValue })} className="p-inputtext" />
                                    </div>
                                    {this.state.userconnected.profilUser === 'PDG' ?
                                        <div className="p-col-12 p-md-3">
                                            <label htmlFor="prixRefAchat">Prix d'achat *</label>
                                        </div>
                                        : null}
                                    {this.state.userconnected.profilUser === 'PDG' ?
                                        <div className="p-col-12 p-md-9">
                                            <Cleave onKeyDown={this.handleKeyPress} value={this.state.prixRefAchat} options={{ numeral: true, delimiter: ' ' }} onChange={(e) => this.setState({ prixRefAchat: e.target.rawValue }, () => {
                                                let pv = Math.ceil(this.state.prixRefAchat * this.state.userconnected.idEtabl.commissionEtabl);
                                                this.setState({ prixRefVente: pv });
                                                this.setState({ prixRefRevient: this.state.prixRefAchat });
                                            })} className="p-inputtext" />
                                        </div>
                                        : null}
                                    <div className="p-col-12 p-md-3">
                                        <label htmlFor="prixRefAchat">Prix de vente *</label>
                                    </div>
                                    <div className="p-col-12 p-md-9">
                                        <Cleave onKeyDown={this.handleKeyPress} value={this.state.prixRefVente} options={{ numeral: true, delimiter: ' ' }} onChange={(e) => this.setState({ prixRefVente: e.target.rawValue }, () => {
                                            if (this.state.userconnected.idEtabl.commissionEtabl !== 0) {
                                                let prix = 0;
                                                prix = Math.ceil(this.state.prixRefVente / this.state.userconnected.idEtabl.commissionEtabl);
                                                this.setState({ prixRefAchat: prix });
                                                this.setState({ prixRefRevient: prix });
                                            }
                                        })} className="p-inputtext" />
                                    </div>
                                    <div className="p-col-12 p-md-3">
                                        <label htmlFor="cbArticle">Code barre </label>
                                    </div>
                                    <div className="p-col-12 p-md-9">
                                        <InputText onKeyDown={this.handleKeyPress} id="cbArticle" value={this.state.cbArticle} onChange={(e) => this.setState({ cbArticle: e.target.value })} />
                                    </div>
                                    <div className="p-col-12 p-md-3">
                                        <label htmlFor="idBoutiq">Sous type *</label>
                                    </div>
                                    <div className="p-col-12 p-md-9">
                                        <Dropdown value={this.state.idStype} placeholder="Sélectionner" options={lsous_type} optionLabel='label' filter={true} filterBy='label' onChange={(e) => {
                                            this.setState({ idStype: e.value });
                                        }} autoWidth={false} />
                                    </div>
                                    <div className="p-col-12 p-md-3">
                                        <label >Date péremption </label>
                                    </div>
                                    <div className="p-col-12 p-md-9">
                                        <Calendar readOnlyInput={false} dateFormat="dd/mm/yy" value={this.state.date_exp} viewDate={new Date()} onChange={e => this.setState({ date_exp: e.value })}></Calendar>
                                    </div>
                                </div>
                            </div>

                            <center>
                                <div className="p-grid" style={{ width: 300 }}>
                                    <div className="p-col"><Button label="Annuler" className="p-button-info" icon="pi pi-ban" onClick={(e) => this.annule_add()} /></div>
                                    <div className="p-col"><Button label="Ajouter" className="p-button-info" icon="pi pi-plus" onClick={(e) => this.add_article()} /></div>
                                </div>
                            </center>

                        </div>
                    </div>
                </Dialog>


                <div className="p-grid">
                    <div className="p-col-12">
                        <div className="card card-w-title" style={{ minHeight: '800px' }}>
                            <Panel style={{ minHeight: '700px' }}> 
                                <DataTable value={this.state.liste_inventaire} scrollable={true} scrollHeight="580px"
                                    onRowClick={e => this.setState({ selectedArticle: e.data })} selectionMode="single"
                                    responsive={true} selection={this.state.selectedArticle} editable={true} header={header}>
                                    <Column header="#" body={this.actionTemplate.bind(this)} style={{ width: '5%' }} />
                                    <Column field="libArt" header="Désignation" style={{ width: '28%' }} />
                                    <Column field="libsoustypearticle" header="Sous type" style={{ width: '18%' }} />
                                    <Column field="cbArt" header="Code barre" style={{ width: '15%' }} />
                                    <Column field="prix_ref_achat" body={(rowData, column) => {
                                        let prix_aff = this.formaterMontant(parseFloat(rowData.prix_ref_achat));
                                        if (this.state.userconnected.profilUser !== 'PDG') {
                                            prix_aff = '*****';
                                        }
                                        return <div>
                                            <span style={{ color: '#000000', fontWeight: 'bold' }}>{prix_aff}</span>
                                        </div>
                                    }} header="Prix d'achat" style={{ width: '10%' }} editor={this.prix_ref_achatEditor} />
                                    <Column field="prix_ref_vente" body={(rowData, column) => {
                                        return <div>
                                            <span style={{ color: '#000000', fontWeight: 'bold' }}>{this.formaterMontant(parseFloat(rowData.prix_ref_vente))}</span>
                                        </div>
                                    }} header="Prix de vente" style={{ width: '10%' }} editor={this.prix_ref_venteEditor} />
                                    <Column field="qte_stock_format" body={(rowData, column) => {
                                        return <div>
                                            <span style={{ color: '#000000', fontWeight: 'bold' }}>{this.formaterMontant(parseFloat(rowData.qte_stock))}</span>
                                        </div>
                                    }} header="Stock" style={{ width: '6%' }} />
                                    <Column field="qte_invent" body={(rowData, column) => {
                                        return <div>
                                            <span style={{ color: '#000000', fontWeight: 'bold' }}>{this.formaterMontant(parseFloat(rowData.qte_invent))}</span>
                                        </div>
                                    }} editor={this.qte_inventEditor} header="Qté réelle" style={{ width: '8%' }} />
                                    <Column field="date_exp_article" header="Date perem." style={{ width: '10%' }} />
                                </DataTable>
                            </Panel>
                            <center>
                                <div className="p-grid" style={{ width: 300 }}>
                                    <div className="p-col"><Button label="Annuler" icon="pi pi-ban" onClick={() => this.annule_inventaire()} /></div>
                                    <div className="p-col"><Button label="Valider" icon="pi pi-check" onClick={() => this.enreg_inventaire()} /></div>
                                </div>
                                {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : null}
                            </center>

                        </div>
                    </div>
                </div>
            </div>
        );
    }
}