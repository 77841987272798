import React, { Component } from 'react';
import { Xulaservice } from '../service/Xulaservice';
import { InputText } from 'primereact/inputtext';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { ProgressSpinner } from 'primereact/progressspinner';
import Numeral from 'numeral'; import 'moment';
import 'moment/locale/fr';
import moment from 'moment';
import './styles.css';
import { SplitButton } from 'primereact/splitbutton';
import { OverlayPanel } from 'primereact/overlaypanel';

export class FormEdition_Operationclient extends Component {
    constructor() {
        super();
        this.state = {
            pd1: new Date(),
            pd2: new Date(),
            ridBoutiq: null,
            rlibOpcli: null,
            liste_operation: [
                { value: 'Tous', label: 'Toutes les opérations' },
                { label: 'Dépot', value: 'Dépot', typeOp: 'ENTREE' },
                { label: 'Retrait espèce', value: 'Retrait espèce', typeOp: 'SORTIE' },
                { label: 'Emprunt', value: 'Emprunt', typeOp: 'SORTIE' },
                { label: 'Règlement facture', value: 'Règlement facture', typeOp: 'SORTIE' },
            ],
            ridCli: null,
            liste_data: [],
            liste_boutique: [],
            lclient_rech: [],
            selectedData: null,
            montant_total: '0',
            items: [
                {
                    label: 'Format CSV',
                    icon: 'pi pi-print',
                    command: (e) => {
                        this.dt.exportCSV();
                    }
                }
            ],
            userconnected: JSON.parse(localStorage.getItem('userconnected')),
            rfiltreClient: 'COMPTOIR'
        };
        this.state.pd1.setDate(this.state.pd1.getDate() - 7);
        this.conf_print_recu = this.conf_print_recu.bind(this);
        this.xulaservice = new Xulaservice();
    }

    sortieItemTemplate(option) {
        if (!option.value) {
            return option.label;
        }
        else {
            return (
                <div className="p-clearfix">
                    <div className="p-grid">
                        <div className="p-col-12 p-md-3">
                            <label>{option.label}</label>
                        </div>
                        <div className="p-col-12 p-md-6">
                            <label>{option.nomCli}</label>
                        </div>
                        <div className="p-col-12 p-md-3">
                            <label style={{ fontWeight: 'bold', color: 'red' }}>{option.mt_restant_format}</label>
                        </div>
                    </div>
                </div>
            );
        }
    }

    get_liste_boutique() {
        this.xulaservice.getListe_boutique().then(data => this.setState({ liste_boutique: data }));
    }

    get_liste_client() {
        let lclient = null;
        this.setState({ showindicator: true });
        this.xulaservice.getliste_client_light(this.state.rfiltreClient).then(data => this.setState({ liste_client: data }, () => {
            let lclient_rech = [{ value: 'Tous', label: 'Toutes les clients', remiseCli: 0, soldeCli_format: '0', telCli: 'x', tvaCli: 0, balanceCli: 0, caventeCli: 0, label_rech: 'Tous' }];
            if (this.state.liste_client != null && this.state.liste_client != undefined) {
                lclient = this.state.liste_client.map(x => {
                    return { value: x.idCli, label: x.nomCli, remiseCli: x.remiseCli, soldeCli_format: x.soldeCli_format, telCli: x.telCli, tvaCli: x.tvaCli, balanceCli: x.balanceCli, caventeCli: x.caventeCli, label_rech: x.nomCli + ' ' + x.idCli }
                });
                lclient_rech = lclient_rech.concat(lclient);
                this.setState({ lclient_rech: lclient_rech });
            }
            this.setState({ showindicator: false });
        }));
    }

    componentDidMount() {
        if (this.state.userconnected.matrPers.idBoutiq.typeBoutiq === 'Pharmacie') {
            this.setState({ rfiltreClient: 'Tous' }, () => {
                this.get_liste_client();
            })
        } else {
            if (this.state.userconnected.idEtabl.codeEtabl !== 'EDK') {
                this.setState({ rfiltreClient: 'Tous' }, () => {
                    this.get_liste_client();
                });
            } else {
                this.setState({ rfiltreClient: 'COMPTOIR' }, () => {
                    this.get_liste_client();
                });
            }
        }
        this.get_liste_data();
        this.get_liste_boutique();
    }

    formaterMontant(montant) {
        return Numeral(parseFloat(montant)).format('0,0');
    }

    get_liste_data() {
        let rid_cli = 'Tous';
        let rlib = 'Tous';
        this.setState({ showindicator: true });
        let rid_boutique = 'Tous';
        let spd1 = moment(this.state.pd1, 'DD/MM/YYYY').format('DD/MM/YYYY');
        let spd2 = moment(this.state.pd2, 'DD/MM/YYYY').format('DD/MM/YYYY');

        if (this.state.ridCli != undefined && this.state.ridCli != null) {
            rid_cli = this.state.ridCli.value;
        }
        if (this.state.rlibOpcli != undefined && this.state.rlibOpcli != null && this.state.rlibOpcli != '') {
            rlib = this.state.rlibOpcli.value;
        }
        if (this.state.ridBoutiq != undefined && this.state.ridBoutiq != null) {
            rid_boutique = this.state.ridBoutiq.value;
        }

        this.xulaservice.get_liste_mvt_client(spd1, spd2, rid_cli, rid_boutique, rlib).then(data => this.setState({ liste_data: data, showindicator: false }, () => {
            if (data != null && data != undefined) {
                let initialValue = 0;
                let tt = data.reduce(function (accumulator, currentValue) {
                    return accumulator + currentValue.montantOpcli
                }, initialValue);
                this.setState({ montant_total: this.formaterMontant(tt) });
            }
        }));
    }

    conf_print_recu(data) {
        if (data.libOpcli == 'Règlement facture') {
            this.xulaservice.generer_edition_recu(data.idRecuOpcli);
        } else {
            this.xulaservice.generer_edition_recu_operation_client(data.idRecuOpcli);
        }
    }

    actionTemplate(rowData, column) {
        return <div className="p-grid" style={{ width: 50 }}>
            <div className="p-col"><Button onClick={(e) => this.conf_print_recu(rowData)} className="p-button-warning" style={{ width: 25, height: 25, marginLeft: -8 }} icon="pi pi-print" />
            </div>
        </div>
    }

    render() {
        let ptotal = <label style={{ fontSize: 20, fontWeight: 'bold', color: 'red', marginRight: 10 }}> {this.state.montant_total} </label>
        let lboutique_rech = [{ value: 'Tous', label: 'Boutique' }];
        let lboutique = null;
        if (this.state.liste_boutique != null && this.state.liste_boutique != undefined) {
            lboutique = this.state.liste_boutique.map(x => {
                return { value: x.idBoutiq, label: x.nomBoutiq }
            });
            lboutique_rech = lboutique_rech.concat(lboutique);
        }
        return (
            <div className="p-fluid">
                <OverlayPanel ref={(el) => { this.op = el; }} style={{ width: '250px' }} >
                    <div className="p-grid">
                        <div className="p-col-10">
                            <InputText value={this.state.rfiltreClient} id="id_ovp" onKeyDown={(e) => {
                                if (e.keyCode === 13) {
                                    this.op.toggle(e);
                                    document.getElementById("new_dp_client").click();
                                    this.get_liste_client();
                                }
                            }} onChange={(e) => this.setState({ rfiltreClient: e.target.value })} autoFocus={true} />
                        </div>
                        <div className="p-col-2">
                            <Button type="button" icon="pi pi-search" className="p-button-warn" onClick={(e) => {
                                this.op.toggle(e);
                                document.getElementById("new_dp_client").click();
                                this.get_liste_client();
                            }} style={{ width: '30px' }} />
                        </div>
                    </div>
                </OverlayPanel>
                <div className="p-grid">
                    <div className="p-col-12">
                        <div className="card card-w-title" style={{ minHeight: '900px' }}>
                            <div className="p-grid">
                                <div className="p-col-12">
                                    <div className="p-grid">
                                        <div className="p-col-12 p-md-2">
                                            <Calendar value={this.state.pd1} dateFormat="dd/mm/yy" onChange={(e) => this.setState({ pd1: e.value })} />
                                        </div>
                                        <div className="p-col-12 p-md-2">
                                            <Calendar value={this.state.pd2} dateFormat="dd/mm/yy" onChange={(e) => this.setState({ pd2: e.value })} />
                                        </div>
                                        <div className="p-col-12 p-md-2">
                                            <div className="p-grid">
                                                <div className="p-col-11">
                                                    <Dropdown id="new_dp_client"
                                                        options={this.state.lclient_rech}
                                                        value={this.state.ridCli}
                                                        placeholder="Client"
                                                        onChange={(e) => {
                                                            this.setState({ ridCli: e.value }, () => {
                                                                this.get_liste_data();
                                                            })
                                                        }}
                                                        style={{ width: '100%' }} panelStyle={{ width: '400px' }}
                                                        filter={true} filterBy='label_rech' filterMatchMode='contains'
                                                        optionLabel='label_rech'
                                                        itemTemplate={(option) => {
                                                            if (!option.value) {
                                                                return option.label;
                                                            }
                                                            else {
                                                                return (
                                                                    <div className="p-clearfix">
                                                                        <div className="p-grid">
                                                                            <div className="p-col-12 p-md-12">
                                                                                <label>{option.label}</label>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                );
                                                            }
                                                        }}
                                                    />
                                                </div>
                                                <div className="p-col-1">
                                                    <Button type="button" icon="pi pi-search" className="p-button-warning" onClick={(e) => {
                                                        this.op.toggle(e);
                                                    }} style={{ marginLeft: -15, width: '30px' }} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="p-col-12 p-md-2">
                                            <Dropdown options={lboutique_rech} placeholder="Boutique" value={this.state.ridBoutiq} optionLabel='label' filter={true} filterBy='label' filterMatchMode='contains' onChange={(e) => this.setState({ ridBoutiq: e.value }, () => {
                                                this.get_liste_data();
                                            })} autoWidth={false} panelStyle={{ width: '400px' }} />
                                        </div>
                                        <div className="p-col-12 p-md-2">
                                            <Dropdown options={this.state.liste_operation} placeholder="Opération" value={this.state.rlibOpcli} optionLabel='label' filter={true} filterBy='label' filterMatchMode='contains' onChange={(e) => this.setState({ rlibOpcli: e.value }, () => {
                                                this.get_liste_data();
                                            })} autoWidth={false} />
                                        </div>
                                        <div className="p-col-12 p-md-2">
                                            <div className="p-inputgroup">
                                                <div className="p-inputgroup">
                                                    <SplitButton label="Rechercher" style={{ width: '140px' }} onClick={(e) => this.get_liste_data()} icon="pi pi-search" model={this.state.items}></SplitButton>
                                                    {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : null}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <DataTable value={this.state.liste_data} ref={(el) => { this.dt = el; }}
                                responsive={true} paginatorPosition="top" paginator={true} rows={15} selectionMode="single"
                                onRowClick={e => this.setState({ selectedData: e.data })} paginatorRight={ptotal}
                                responsive={true} selection={this.state.selectedData}>
                                <Column header="#" body={this.actionTemplate.bind(this)} style={{ width: '4%' }} />
                                <Column field="dateOpcli" header="Date" style={{ width: '8%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                <Column field="libOpcli" header="Opération" style={{ width: '16%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                <Column field="montantOpcli" body={(rowData, column) => {
                                    let color = 'red';
                                    if (rowData.montantOpcli >= 0) {
                                        color = 'green';
                                    }
                                    return <div>
                                        <span style={{ color: color, fontWeight: 'bold' }}>{this.formaterMontant(rowData.montantOpcli)}</span>
                                    </div>
                                }} header="Montant" style={{ width: '12%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                <Column field="numFactureOpcli" header="N° facture" style={{ width: '15%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                <Column field="nomCli" header="Client" style={{ width: '30%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                <Column field="nomboutiq" header="Boutique" style={{ width: '15%' }} filter={true} filterMatchMode='contains' sortable={true} />
                            </DataTable>

                        </div>
                    </div>
                </div>
            </div>
        );
    }
}