import React, { Component } from 'react';
import { Xulaservice } from '../service/Xulaservice';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { SplitButton } from 'primereact/splitbutton';
import './styles.css';
import { ProgressSpinner } from 'primereact/progressspinner';
import Numeral from 'numeral';
import { Growl } from 'primereact/growl';
import { Panel } from 'primereact/panel';
import { FormOptArticle } from './FormOptArticle';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';

export class FormEdition_Inventaire extends Component {
    constructor() {
        super();
        this.state = {
            liste_article: [],
            liste_type: [],
            liste_stype: [],
            liste_all_stype: [],
            selectedArticle: null,
            idArticle_opt: null,
            vm_prix_achat: '******',
            vm_prix_vente: '******',
            ridStype: { value: 'Tous', label: 'Sous type' },
            ridType: { value: 'Tous', label: 'Type' },
            liste_boutique: [],
            lboutique_rech: [],
            lboutique: [],
            rcb: '',
            rlib: '',
            ridBoutiq: { value: 'Tous', label: 'Boutique' },
            items: [
                {
                    label: 'Format PDF',
                    icon: 'pi pi-print',
                    command: (e) => {
                        this.conf_print('PDF');
                    }
                },
                {
                    label: 'Format XLS',
                    icon: 'pi pi-print',
                    command: (e) => {
                        this.conf_print('XLS');
                    }
                },
                {
                    label: 'Photos',
                    icon: 'pi pi-print',
                    command: (e) => {
                        this.conf_print('Photo');
                    }
                },
                {
                    label: 'Format brut',
                    icon: 'pi pi-print',
                    command: (e) => {
                        this.dt.exportCSV();
                    }
                }
            ],
            visible_dlg_article: false,
            userconnected: JSON.parse(localStorage.getItem('userconnected')),
        };
        this.show_dlg_article = this.show_dlg_article.bind(this);
        this.xulaservice = new Xulaservice();
    }


    select_stype(idType) {
        if (this.state.liste_all_stype != null && this.state.liste_all_stype != undefined) {
            if (this.state.liste_all_stype.length > 0) {
                let liste_filtre = this.state.liste_all_stype.filter(function (el) {
                    return el.idType === idType;
                });
                this.setState({ liste_stype: liste_filtre });
            }
        }
    }

    get_liste_sous_type_article() {
        this.xulaservice.get_liste_all_stype_inart().then(data => {
            if (data !== null && data !== undefined) {
                let liste = data.map(x => {
                    return { value: x.idStype, label: x.libStype, idType: x.idType.idType }
                });
                this.setState({ liste_stype: liste, liste_all_stype: liste });

                let liste_type_with_doublon = data.map(x => {
                    return { value: x.idType.idType, label: x.idType.libType }
                });
                let liste_type = Array.from(new Set(liste_type_with_doublon.map(JSON.stringify))).map(JSON.parse);
                this.setState({ liste_type: liste_type });
            }
        });
    }


    conf_print(format) {
        let rid_boutique = 'Tous';
        let rstype = 'Tous';
        let rtype = 'Tous';
        if (this.state.userconnected.profilUser !== 'PDG' && this.state.userconnected.profilUser !== 'Coordinateur') {
            if (format !== 'Photo') {
                this.growl.show({ severity: 'warn', summary: 'Attention', detail: "Vous n'êtes pas autorisé à éditer ce rapport" });
                return;
            }
        }
        if (this.state.ridBoutiq != undefined && this.state.ridBoutiq != null) {
            rid_boutique = this.state.ridBoutiq.value;
        }
        if (this.state.ridStype != undefined && this.state.ridStype != null) {
            rstype = this.state.ridStype.value;
        }
        if (this.state.ridType != undefined && this.state.ridType != null) {
            rtype = this.state.ridType.value;
        }
        this.xulaservice.generer_edition_inventaire(this.state.rlib, this.state.rcb, rtype, rstype, rid_boutique, format);

    }

    show_dlg_article(x) {
        if (this.state.ridBoutiq.value === 'Tous' || this.state.ridBoutiq.value === null || this.state.ridBoutiq === undefined) {
            this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez sélectionner une boutique' });
            return;
        }
        let art = { value: x.idArt, label: x.libArt, cbArt: x.cbArt, qte_stock_format: x.qte_stock_format, prix_ref_vente_format: x.prix_ref_vente_format, prix_ref_vente: x.prix_ref_vente, qte_stock: x.qte_stock, prix_ref_achat: x.prix_ref_achat, prix_ref_achat_format: x.prix_ref_achat_format, idstype: x.idstype, libsoustypearticle: x.libsoustypearticle, label_rech: x.libArt + ' ' + x.cbArt, prix_ref_revient: x.prix_ref_revient };

        art.idBoutiq = this.state.ridBoutiq.value;
        let param_opt = { idArticle: art, lprod: [art] };
        this.setState({ idArticle_opt: param_opt }, () => {
            this.setState({ visible_dlg_article: true });
        });
    }

    formaterMontant(montant) {
        return Numeral(parseFloat(montant)).format('0,0');
    }


    componentDidMount() {
        this.get_liste_sous_type_article();
        this.get_liste_boutique();
    }

    

    get_liste_article() {
        let rid_boutique = 'Tous';
        let rstype = 'Tous';
        let rtype = 'Tous';
        if (this.state.ridBoutiq != undefined && this.state.ridBoutiq != null) {
            rid_boutique = this.state.ridBoutiq.value;
        }
        if (this.state.ridStype != undefined && this.state.ridStype != null) {
            rstype = this.state.ridStype.value;
        }
        if (this.state.ridType != undefined && this.state.ridType != null) {
            rtype = this.state.ridType.value;
        }

        this.setState({ showindicator: true });
        this.setState({ vm_prix_achat: '********' });
        this.setState({ vm_prix_vente: '********' });
        this.xulaservice.get_inventaire_mins2(this.state.rlib, rid_boutique, rtype, rstype).then(data => this.setState({ showindicator: false }, () => {
            if (data != null && data != undefined) {
                let lprod = data.map(x => {
                    return { libArt: x.libArt, prix_ref_vente: x.prix_ref_vente, prix_ref_achat: x.prix_ref_achat, qte_stock: x.qte_stock, vm_p_achat: x.qte_stock < 0 ? 0 : (x.qte_stock * x.prix_ref_achat), vm_p_vente: x.qte_stock < 0 ? 0 : (x.qte_stock * x.prix_ref_vente), qte_stock_reel: x.qte_stock_reel, valeur_stock_reel: x.qte_stock_reel < 0 ? 0 : ((x.qte_stock_reel) * x.prix_ref_vente), qte_a_livrer: x.qte_a_livrer, valeur_qte_a_livrer: x.qte_a_livrer * x.prix_ref_vente }
                });
                let initialValue = 0;
                if (lprod !== undefined && lprod !== null) {
                    let tt_vm_prix_achat = lprod.reduce(function (accumulator, currentValue) {
                        if (currentValue.qte_stock > 0) {
                            return accumulator + currentValue.qte_stock * currentValue.prix_ref_achat
                        } else {
                            return accumulator + 0;
                        }
                    }, initialValue);
                    let tt_vm_prix_vente = lprod.reduce(function (accumulator, currentValue) {
                        if (currentValue.qte_stock > 0) {
                            return accumulator + currentValue.qte_stock * currentValue.prix_ref_vente
                        } else {
                            return accumulator + 0;
                        }
                    }, initialValue);
                    if (this.state.userconnected.profilUser === 'PDG') {
                        this.setState({ vm_prix_achat: this.formaterMontant(tt_vm_prix_achat) });
                        this.setState({ vm_prix_vente: this.formaterMontant(tt_vm_prix_vente) });
                    }
                }
                this.setState({ liste_article: lprod });
            }
        }));
    }

    get_liste_boutique() {
        let lboutique = null;
        let lboutique_rech = [{ value: 'Tous', label: 'Boutique' }];
        this.xulaservice.getListe_boutique().then(data => this.setState({ liste_boutique: data }, () => {
            if (this.state.liste_boutique != null && this.state.liste_boutique != undefined) {
                lboutique = this.state.liste_boutique.map(x => {
                    return { value: x.idBoutiq, label: x.nomBoutiq }
                });
            }
            if (this.state.userconnected != null && this.state.userconnected != undefined) {
                this.setState({ ridBoutiq: { value: this.state.userconnected.matrPers.idBoutiq.idBoutiq, label: this.state.userconnected.matrPers.idBoutiq.nomBoutiq } }, () => {
                    this.get_liste_article();
                });
            }
            if (this.state.userconnected.profilUser === 'PDG' || this.state.userconnected.profilUser === 'Coordinateur') {
                this.setState({ lboutique: lboutique });
                lboutique_rech = lboutique_rech.concat(lboutique);

            } else {
                let id = this.state.userconnected.matrPers.idBoutiq.idBoutiq;
                if (lboutique.length > 0) {
                    let liste = lboutique.filter(function (el) {
                        return el.value === id;
                    });
                    this.setState({ lboutique: liste });
                    lboutique_rech = liste;
                }
            }
            this.setState({ lboutique_rech: lboutique_rech })
        }));
    }

    actionTemplate(rowData, column) {
        let disable = false;
        if (this.state.ridBoutiq.value === 'Tous' || (this.state.userconnected.profilUser !== 'PDG' && this.state.userconnected.profilUser !== 'Coordinateur' && this.state.userconnected.profilUser !== 'Administrateur')) {
            disable = true;
        }
        return <div className="p-grid" style={{ width: 90 }}>
            <div className="p-col"><Button disabled={disable} onClick={(e) => this.show_dlg_article(rowData)} className="p-button-warning" style={{ width: 25, height: 25 }} icon="pi pi-pencil" /></div>
        </div>
    }

    render() {
        let lsous_type_rech = [{ value: 'Tous', label: 'Sous type' }];
        if (this.state.liste_sous_type_article != null && this.state.liste_sous_type_article != undefined) {
            let lsous_type = this.state.liste_sous_type_article.map(x => {
                return { value: x.idStype, label: x.libStype }
            });
            lsous_type_rech = lsous_type_rech.concat(lsous_type);
        }
        return (
            <div className="p-fluid">
                <Growl ref={(el) => this.growl = el} />
                <Dialog header="Inventaire" visible={this.state.visible_dlg_article} style={{ width: '40%' }} modal={true} onHide={(e) => {
                    this.get_liste_article();
                    this.setState({ visible_dlg_article: false });
                }}>
                    <FormOptArticle idArticle={this.state.idArticle_opt} />
                </Dialog>
                <div className="p-grid">
                    <div className="p-col-12">
                        <div className="card card-w-title" style={{ minHeight: '900px' }}>
                            <i className="fa fa-fw fa-search"></i>Option de recherche
                            <hr />
                            <div className="p-grid">
                                <div className="p-col-12 p-md-2">
                                    <InputText value={this.state.rlib} placeholder="Désignation ou code barre" onChange={(e) => this.setState({ rlib: e.target.value })} />
                                </div>
                                <div className="p-col-12 p-md-3">
                                    <Dropdown options={this.state.liste_type} placeholder="Type" value={this.state.ridType} optionLabel='label' filter={true} filterBy='label' filterMatchMode='contains' onChange={(e) => this.setState({ ridType: e.value }, () => {
                                        this.select_stype(this.state.ridType.value);
                                    })} autoWidth={false} />
                                </div>
                                <div className="p-col-12 p-md-2">
                                    <Dropdown options={[{ value: 'Tous', label: 'Sous type' }].concat(this.state.liste_stype)} placeholder="Sous type" value={this.state.ridStype} optionLabel='label' filter={true} filterBy='label' filterMatchMode='contains' onChange={(e) => this.setState({ ridStype: e.value }, () => {
                                    })} autoWidth={false} />
                                </div>
                                <div className="p-col-12 p-md-2">
                                    <Dropdown value={this.state.ridBoutiq} options={this.state.lboutique_rech} optionLabel='label' filter={true} filterBy='label' onChange={(e) => this.setState({ ridBoutiq: e.value }, () => {
                                        this.get_liste_article();
                                    })} autoWidth={false} />
                                </div>
                                <div className="p-col-12 p-md-3">
                                    <div className="p-inputgroup">
                                        <SplitButton label="Rechercher" style={{ width: '140px' }} onClick={(e) => this.get_liste_article()} icon="pi pi-search" model={this.state.items}></SplitButton>
                                        {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : null}
                                    </div>
                                </div>

                            </div>

                            <Panel style={{ backgroundColor: '#f7f7f7' }}>
                                <div className="p-grid">
                                    <div className="p-col-12 p-md-2">
                                        <label style={{ fontSize: 16 }}>VM achat stock physique</label>
                                    </div>
                                    <div className="p-col-12 p-md-4">
                                        <label style={{ fontSize: 16, fontWeight: 'bold', color: 'black' }}> {this.state.vm_prix_achat} </label>
                                    </div>
                                    <div className="p-col-12 p-md-2">
                                        <label style={{ fontSize: 16 }}>VM vente stock physique</label>
                                    </div>
                                    <div className="p-col-12 p-md-4">
                                        <label style={{ fontSize: 16, fontWeight: 'bold', color: 'black' }}> {this.state.vm_prix_vente} </label>
                                    </div>
                                </div>
                            </Panel>

                            {
                                this.state.userconnected.profilUser == 'PDG' ?
                                    <DataTable value={this.state.liste_article} paginatorPosition="top" paginator={true} rows={20}
                                        onRowClick={e => this.setState({ selectedArticle: e.data })} selectionMode="single"
                                        responsive={true} selection={this.state.selectedArticle} csvSeparator=";" ref={(el) => { this.dt = el; }}>
                                        <Column header="#" body={this.actionTemplate.bind(this)} style={{ width: '3%' }} />
                                        <Column field="libArt" header="Désignation" style={{ width: '22%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                        <Column field="prix_ref_vente" body={(rowData, column) => {
                                            return <div>
                                                <span style={{ color: '#000000' }}>{this.formaterMontant(parseFloat(rowData.prix_ref_vente))}</span>
                                            </div>
                                        }} header="Prix de vente" style={{ width: '10%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                        <Column field="qte_stock" header="Stock P" style={{ width: '8%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                        <Column field="vm_p_achat" header="VM P achat" body={(rowData, column) => {
                                            let val = parseFloat(rowData.vm_p_achat);
                                            if (val < 0) {
                                                val = 0;
                                            }
                                            let vm = this.formaterMontant(val);
                                            if (this.state.userconnected.profilUser !== 'PDG') {
                                                vm = '*******';
                                            }
                                            return <div>
                                                <span style={{ color: '#000000' }}>{vm}</span>
                                            </div>
                                        }} style={{ width: '10%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                        <Column field="vm_p_vente" header="VM P vente" body={(rowData, column) => {
                                            let val = parseFloat(rowData.vm_p_vente);
                                            if (val < 0) {
                                                val = 0;
                                            }
                                            let vm = this.formaterMontant(val);

                                            return <div>
                                                <span style={{ color: '#000000' }}>{vm}</span>
                                            </div>
                                        }} style={{ width: '10%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                        <Column field="qte_stock_reel" header="Stock T" style={{ width: '8%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                        <Column field="valeur_stock_reel" header="VM T" body={(rowData, column) => {
                                            let vm = this.formaterMontant(parseFloat(rowData.valeur_stock_reel));
                                            if (this.state.userconnected.profilUser !== 'PDG') {
                                                vm = '*******';
                                            }
                                            return <div>
                                                <span style={{ color: '#000000' }}>{vm}</span>
                                            </div>
                                        }} style={{ width: '12%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                        <Column field="qte_a_livrer" body={(rowData, column) => {
                                            return <div>
                                                <span style={{ color: 'red' }}>{rowData.qte_a_livrer}</span>
                                            </div>
                                        }} header="Non livré" style={{ width: '8%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                        <Column field="valeur_qte_a_livrer" body={(rowData, column) => {
                                            return <div>
                                                <span style={{ color: '#000000', color: 'red' }}>{this.formaterMontant(parseFloat(rowData.valeur_qte_a_livrer))}</span>
                                            </div>
                                        }} header="Valeur non livré" style={{ width: '12%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                    </DataTable>
                                    :
                                    <DataTable value={this.state.liste_article} paginatorPosition="top" paginator={true} rows={20}
                                        onRowClick={e => this.setState({ selectedArticle: e.data })} selectionMode="single"
                                        responsive={true} selection={this.state.selectedArticle} csvSeparator=";" ref={(el) => { this.dt = el; }}>
                                        <Column header="#" body={this.actionTemplate.bind(this)} style={{ width: '3%' }} />
                                        <Column field="libArt" header="Désignation" style={{ width: '32%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                        <Column field="prix_ref_vente" body={(rowData, column) => {
                                            return <div>
                                                <span style={{ color: '#000000' }}>{this.formaterMontant(parseFloat(rowData.prix_ref_vente))}</span>
                                            </div>
                                        }} header="Prix de vente" style={{ width: '12%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                        <Column field="qte_stock" header="Stock P" style={{ width: '10%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                        <Column field="vm_p_vente" header="VM P vente" body={(rowData, column) => {
                                            let val = parseFloat(rowData.vm_p_vente);
                                            if (val < 0) {
                                                val = 0;
                                            }
                                            let vm = this.formaterMontant(val);
                                            return <div>
                                                <span style={{ color: '#000000' }}>{vm}</span>
                                            </div>
                                        }} style={{ width: '12%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                        <Column field="qte_stock_reel" header="Stock T" style={{ width: '8%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                        <Column field="qte_a_livrer" body={(rowData, column) => {
                                            return <div>
                                                <span style={{ color: 'red' }}>{rowData.qte_a_livrer}</span>
                                            </div>
                                        }} header="Non livré" style={{ width: '10%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                        <Column field="valeur_qte_a_livrer" body={(rowData, column) => {
                                            return <div>
                                                <span style={{ color: '#000000', color: 'red' }}>{this.formaterMontant(parseFloat(rowData.valeur_qte_a_livrer))}</span>
                                            </div>
                                        }} header="Valeur non livré" style={{ width: '16%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                    </DataTable>
                            }
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}