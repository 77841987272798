import React, { Component } from 'react';
import { Xulaservice } from '../service/Xulaservice';
import { Calendar } from 'primereact/calendar';
import { DataTable } from 'primereact/datatable';
import { Dropdown } from 'primereact/dropdown';
import { Column } from 'primereact/column';
import './styles.css';
import { ProgressSpinner } from 'primereact/progressspinner';
import 'moment';
import 'moment/locale/fr';
import moment from 'moment';
import { SplitButton } from 'primereact/splitbutton';
import { components, createFilter } from 'react-windowed-select';
import WindowedSelect from "react-windowed-select";
import Numeral from 'numeral';

export class FormHistoriqueArticle extends Component {
    constructor() {
        super();
        this.state = {
            lprod: [],
            pd1: new Date(),
            pd2: new Date(),
            qte_stock: '0',
            liste_data: [],
            selectedArticle: null,
            idArticle: null,
            items: [
                {
                    label: 'Format CSV',
                    icon: 'pi pi-print',
                    command: (e) => {
                        this.dt.exportCSV();
                    }
                }
            ],
            showindicator: false,
            userconnected: JSON.parse(localStorage.getItem('userconnected')),
        };
        this.conf_print = this.conf_print.bind(this);
        this.state.pd1.setDate(this.state.pd1.getDate() - 120);
        this.xulaservice = new Xulaservice();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.idArticle != this.props.idArticle) {
            if (this.props != undefined) {
                let val = this.props.idArticle;
                if (val != null && val != undefined && val != '') {
                    let liste_prod = this.props.idArticle.lprod;
                    this.setState({ lprod: liste_prod }, () => {
                        this.setState({ idArticle: this.props.idArticle.idArticle }, () => {
                            this.setState({ qte_stock: this.state.idArticle.qte_stock_format });
                            this.get_liste_data();
                        });
                    });

                }
            }
        }
    }


    get_liste_data() {
        this.setState({ showindicator: true });
        let spd1 = moment(this.state.pd1, 'DD/MM/YYYY').format('DD/MM/YYYY');
        let spd2 = moment(this.state.pd2, 'DD/MM/YYYY').format('DD/MM/YYYY');
        let rid = this.state.idArticle.value;
        this.xulaservice.liste_mv_article(spd1, spd2, rid).then(data => this.setState({ liste_data: data, showindicator: false }));
    }

    conf_print(format) {
        this.setState({ showindicator: true });
        let spd1 = moment(this.state.pd1, 'DD/MM/YYYY').format('DD/MM/YYYY');
        let spd2 = moment(this.state.pd2, 'DD/MM/YYYY').format('DD/MM/YYYY');
        let rid = this.state.idArticle.value;
        this.xulaservice.generer_liste_mv_article(spd1, spd2, rid, format);
        this.setState({ showindicator: false });
    }


    articleItemTemplate(option) {
        if (!option.value) {
            return option.label;
        }
        else {
            return (
                <div className="p-clearfix">
                    <div className="p-grid">
                        <div className="p-col-12 p-md-9">
                            <label>{option.label}</label>
                        </div>
                        <div className="p-col-12 p-md-2">
                            <label>{option.prix_ref_vente_format}</label>
                        </div>
                        <div className="p-col-12 p-md-1" >
                            <label style={{ fontWeight: 'bold', color: 'red', marginRight: '5px' }}>{option.qte_stock_format}</label>
                        </div>
                    </div>
                </div>
            );
        }
    }

    componentDidMount() {
        //this.get_liste_article();
    }

    formaterMontant(montant) {
        return Numeral(parseFloat(montant)).format('0,0');
    }

    get_liste_article() {
        this.xulaservice.get_inventaire_mins('Tous', 'Tous', 'Tous', 'Tous', this.state.userconnected.matrPers.idBoutiq.idBoutiq).then(data => {
            if (data != null && data != undefined) {
                let lprod = data.map(x => {
                    return { value: x.idArt, label: x.libArt, cbArt: x.cbArt, qte_stock_format: this.formaterMontant(x.qte_stock), prix_ref_vente_format: this.formaterMontant(x.prix_ref_vente), prix_ref_vente: x.prix_ref_vente, qte_stock: x.qte_stock, prix_ref_achat: x.prix_ref_achat, prix_ref_achat_format: this.formaterMontant(x.prix_ref_achat), idstype: x.idstype, libsoustypearticle: x.libsoustypearticle, currentRemise: x.currentRemise, couvArticle: x.couvArticle, label_rech: x.libArt + ' ' + x.cbArt, idBoutiq: this.state.userconnected.matrPers.idBoutiq.idBoutiq }
                });
                this.setState({ lprod: lprod });
            }
        });
    }

    render() {

        const formatOptionLabel = ({ value, label, prix_ref_vente_format, prix_except, qte_stock_format }) => (
            <div className="p-grid">
                <div className="p-col-9">
                    <label>{label}</label>
                </div>
                <div className="p-col-1" >
                    <label style={{ fontWeight: 'bold', color: 'red', marginRight: '5px' }}>{qte_stock_format}</label>
                </div>
                {prix_except > 0 ?
                    <div style={{ fontWeight: 'bold' }} className="p-col-2">
                        <label>{this.formaterMontant(parseFloat(prix_except))}</label>
                    </div>
                    :
                    <div style={{ fontWeight: 'bold' }} className="p-col-2">
                        <label>{prix_ref_vente_format}</label>
                    </div>
                }

            </div>
        );

        let color = '#0d8ced';
        let hovercolor = '#39a3f4';
        if (this.state.userconnected.idEtabl.domaineEtabl === 'Pharmacie') {
            color = '#4e805c';
            hovercolor = '#b4df98';
        } else if (this.state.userconnected.idEtabl.domaineEtabl === 'Station') {
            color = '#e10032';
            hovercolor = '#f8b4cb';
        }
        const selectStyles = {
            control: (base, state) => ({
                ...base,
                height: 30,
                minHeight: 30,
                width: 240,
                boxShadow: state.isFocused ? 0 : 0,
                borderColor: state.isFocused
                    ? color
                    : base.borderColor,
                '&:hover': {
                    borderColor: color
                }
            }),
            indicatorSeparator: base => ({
                ...base,
                height: 15,
                minHeight: 15
            }),
            dropdownIndicator: base => ({
                ...base,
                padding: 5
            }),

            option: (styles, state) => ({
                ...styles,
                backgroundColor: state.isDisabled
                    ? null
                    : state.isSelected
                        ? color
                        : state.isFocused
                            ? hovercolor
                            : null,
                ':active': {
                    ...styles[':active'],
                    backgroundColor: !state.isDisabled && (state.isSelected ? color : color),
                },
                '&:hover': {
                    borderColor: color
                }
            }),
            menu: (provided, state) => ({
                ...provided,
                width: 600,
            }),
        };

        const selectStyles_client = {
            control: (base, state) => ({
                ...base,
                height: 30,
                minHeight: 30,
                boxShadow: state.isFocused ? 0 : 0,
                borderColor: state.isFocused
                    ? color
                    : base.borderColor,
                '&:hover': {
                    borderColor: color
                }
            }),
            indicatorSeparator: base => ({
                ...base,
                height: 15,
                minHeight: 15
            }),
            dropdownIndicator: base => ({
                ...base,
                padding: 5
            }),

            option: (styles, state) => ({
                ...styles,
                backgroundColor: state.isDisabled
                    ? null
                    : state.isSelected
                        ? color
                        : state.isFocused
                            ? hovercolor
                            : null,
                ':active': {
                    ...styles[':active'],
                    backgroundColor: !state.isDisabled && (state.isSelected ? color : color),
                },
                '&:hover': {
                    borderColor: color
                }
            }),
            menu: (provided, state) => ({
                ...provided,
                width: 400,
            }),
        };

        const customFilter = createFilter({ ignoreAccents: false });


        return (
            <div className="p-fluid">

                <div className="card card-w-title" style={{ marginBottom: -8 }}>
                    <div className="p-grid">
                        <div className="p-col-12 p-md-2">
                            <Calendar value={this.state.pd1} dateFormat="dd/mm/yy" onChange={(e) => this.setState({ pd1: e.value })} />
                        </div>
                        <div className="p-col-12 p-md-2">
                            <Calendar value={this.state.pd2} dateFormat="dd/mm/yy" onChange={(e) => this.setState({ pd2: e.value })} />
                        </div>
                        <div className="p-col-12 p-md-6">
                            <div className="p-inputgroup">
                                <span className="p-inputgroup-addon" style={{ backgroundColor: '#f5f8f9', color: 'red', fontWeight: 'bold', width: 70 }}>{this.state.qte_stock}</span>
                                <WindowedSelect id="new_dp_prod"
                                    formatOptionLabel={formatOptionLabel}
                                    isClearable={false}
                                    value={this.state.idArticle}
                                    filterOption={customFilter}
                                    options={this.state.lprod}
                                    styles={selectStyles}
                                    placeholder="Articles"
                                    onChange={(value) => {
                                        this.setState({ idArticle: value }, () => {
                                            this.get_liste_data();
                                            this.setState({ qte_stock: this.state.idArticle.qte_stock_format });
                                        })
                                    }}
                                />
                            </div>
                        </div>
                        <div className="p-col-12 p-md-2">
                            <div className="p-inputgroup">
                                <SplitButton label="Rechercher" style={{ width: '140px' }} onClick={(e) => this.get_liste_data()} icon="pi pi-search" model={this.state.items}></SplitButton>
                                {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : null}
                            </div>
                        </div>
                    </div>
                    <DataTable value={this.state.liste_data} paginatorPosition="top" paginator={true} rows={10}
                        ref={(el) => { this.dt = el; }} rowsPerPageOptions={[5, 10, 20, 100, 200]}>
                        <Column field="dateMv" header="Date" style={{ width: '10%' }} />
                        <Column field="idArticle.libArticle" header="Désignation" style={{ width: '20%' }} />
                        <Column field="libMv" header="Opération" style={{ width: '12%' }} />
                        <Column field="qteMv" header="Qté op." style={{ width: '8%' }} />
                        <Column field="qteStock" header="Stock" style={{ width: '8%' }} />
                        <Column field="cmtMv" header="Commentaire" style={{ width: '26%' }} />
                        <Column field="user.matrPers.nomPers" header="Utilisateur" style={{ width: '16%' }} />
                    </DataTable>
                </div>




            </div>

        );
    }
}