import React, { Component } from 'react';
import { Xulaservice } from '../service/Xulaservice';
import { InputText } from 'primereact/inputtext';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dialog } from 'primereact/dialog';
import { Growl } from 'primereact/growl';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Calendar } from 'primereact/calendar';
import Cleave from 'cleave.js/react';
import 'moment';
import 'moment/locale/fr';
import moment, { utc } from 'moment';
import './styles.css';

export class FormObjectifVente extends Component {
    constructor() {
        super();
        this.state = {
            liste_data: [],
            liste_boutique: [],
            idObj: null,
            idBoutiq: null,
            periodeObj: new Date(),
            montantObj: 0,
            bonusObj: 0,
            selectedData: null,
            msg: '',
            modif: false,
            visible: false,
            userconnected: JSON.parse(localStorage.getItem('userconnected')),
        };
        this.edit_data = this.edit_data.bind(this);
        this.conf_delete_data = this.conf_delete_data.bind(this);
        this.xulaservice = new Xulaservice();
    }


    componentDidMount() {
        this.get_liste_boutique();
        this.get_liste_data();
    }

    get_liste_boutique() {
        let lboutique = null;
        this.xulaservice.getListe_boutique().then(data => this.setState({ liste_boutique: data }, () => {
            if (this.state.liste_boutique != null && this.state.liste_boutique != undefined) {
                lboutique = this.state.liste_boutique.map(x => {
                    return { value: x.idBoutiq, label: x.nomBoutiq }
                });
            }
            if (this.state.userconnected != null && this.state.userconnected != undefined) {
                this.setState({ idBoutiq: { value: this.state.userconnected.matrPers.idBoutiq.idBoutiq, label: this.state.userconnected.matrPers.idBoutiq.nomBoutiq } });
            }
            if (this.state.userconnected.profilUser === 'PDG' || this.state.userconnected.profilUser === 'Coordinateur') {
                this.setState({ liste_boutique: lboutique });

            } else {
                let id = this.state.userconnected.matrPers.idBoutiq.idBoutiq;
                if (lboutique.length > 0) {
                    let liste = lboutique.filter(function (el) {
                        return el.value === id;
                    });
                    this.setState({ liste_boutique: liste });
                }
            }
        }));
    }

    get_liste_data() {
        this.xulaservice.get_liste_obj().then(data => {
            this.setState({ liste_data: data });
        });
    }

    resultat(code, contenu) {
        if (code == '200') {
            this.growl.show({ severity: 'success', summary: 'Success', detail: contenu });
            this.annuler();
            this.get_liste_data();
        } else {
            this.growl.show({ severity: 'error', summary: 'Error', detail: contenu });
        }
        this.setState({ showindicator: false });
    }
 

    edit_data(data) {
        if (data != null && data != undefined) {
            this.setState({ idObj: data.idObj });
            this.setState({ idBoutiq: data.idBoutiq.idBoutiq });
            this.setState({ periodeObj: new Date(moment(data.periodeObj, 'DD/MM/YYYY').format('YYYY-MM-DD')) });
            this.setState({ montantObj: data.montantObj });
            this.setState({bonusObj: data.bonusObj});
            this.setState({ modif: true });
            this.setState({ selectedData: data });
        }
    } 

    annuler() {
        this.setState({ idObj: '0' });
        this.setState({ montantObj: 0,bonusObj: 0 });
        this.setState({ periodeObj: '' });
        this.setState({ modif: false });
        this.forceUpdate();
    }


    enreg_data() {
        if (this.state.bonusObj === '' || this.state.periodeObj === '' || this.state.periodeObj === undefined || this.state.periodeObj === null || this.state.montantObj === '' || this.state.montantObj === undefined || this.state.montantObj === null || this.state.idBoutiq === undefined || this.state.idBoutiq === null) {
            this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez saisir tous les champs obligatoires' });
        } else {
            this.setState({ showindicator: true });
            if (this.state.modif === false) {
                let date = moment(this.state.periodeObj, 'DD/MM/YYYY').format('DD/MM/YYYY');
                let data = { idObj: '0', periodeObj: date, montantObj: this.state.montantObj,bonusObj: this.state.bonusObj, idBoutiq: { idBoutiq: this.state.idBoutiq } };
                this.xulaservice.enreg_obj(data).then(data => this.resultat(data.code, data.contenu));
            } else {
                let data = this.state.selectedData;
                let date = moment(this.state.periodeObj, 'DD/MM/YYYY').format('DD/MM/YYYY');
                data.montantObj = this.state.montantObj;
                data.periodeObj = date;
                data.bonusObj=this.state.bonusObj;
                data.idBoutiq = {idBoutiq: this.state.idBoutiq};
                alert(JSON.stringify(data));
                this.xulaservice.enreg_obj(data).then(data => this.resultat(data.code, data.contenu));
            }
        }
    }

    actionTemplate(rowData, column) {
        return <div className="p-grid" style={{ width: 90 }}>
            <div className="p-col"><Button onClick={(e) => this.edit_data(rowData)} className="p-button-info" style={{ width: 25, height: 25 }} icon="pi pi-pencil" /></div>
            <div className="p-col"><Button onClick={(e) => this.conf_delete_data(rowData)} className="p-button-danger" style={{ width: 25, height: 25, marginLeft: -10 }} icon="pi pi-times" /></div>
        </div>
    }

    delete_data() {
        if (this.state.selectedData == null || this.state.selectedData == undefined) {
            this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez sélectionner la ligne à supprimer' });
        } else {
            this.xulaservice.delete_obj(this.state.selectedData).then(data => this.resultat(data.code, data.contenu));
        }
    }

    conf_delete_data(data) {
        if (data != null && data != undefined) {
            this.setState({ selectedData: data });
            this.setState({ msg: data.periodeObj + ' ' + data.idBoutiq.nomBoutiq });
            this.setState({ visible: true });
        }
    }

    render() {
        const dialogFooter = (
            <div>
                <Button icon="pi pi-check" onClick={() => {
                    this.setState({ visible: false });
                    this.delete_data();
                }} label="Oui" />
                <Button icon="pi pi-times" onClick={() => this.setState({ visible: false })} label="Annuler" className="p-button-secondary" />
            </div>
        );

        return (
            <div className="p-fluid">
                <Growl ref={(el) => this.growl = el} />
                <div className="p-grid">
                    <div className="p-col-12">
                        <div className="card card-w-title" style={{ minHeight: '900px' }}>

                            <div className="p-grid">
                                <div className="p-col-12 p-md-2">
                                    <label htmlFor="codeBoutiq">Période </label>
                                </div>
                                <div className="p-col-12 p-md-4">
                                    <Calendar view="month" value={this.state.periodeObj} dateFormat="mm/yy" onChange={(e) => this.setState({ periodeObj: e.value })} />
                                </div>

                                <div className="p-col-12 p-md-2">
                                    <label htmlFor="codeBoutiq">Montant objectif * </label>
                                </div>
                                <div className="p-col-12 p-md-4">
                                    <Cleave value={this.state.montantObj} options={{ numeral: true, delimiter: ' ' }} onChange={(e) => this.setState({ montantObj: e.target.rawValue })} className="p-inputtext" />
                                </div>

                                <div className="p-col-12 p-md-2">
                                    <label htmlFor="codeBoutiq">Bonus * </label>
                                </div>
                                <div className="p-col-12 p-md-4">
                                    <Cleave value={this.state.bonusObj} options={{ numeral: true, delimiter: ' ' }} onChange={(e) => this.setState({ bonusObj: e.target.rawValue })} className="p-inputtext" />
                                </div>

                                <div className="p-col-12 p-md-2">
                                    <label htmlFor="idBoutiq">Boutique *</label>
                                </div>
                                <div className="p-col-12 p-md-4">
                                    <Dropdown value={this.state.idBoutiq} options={this.state.liste_boutique} filter={true} filterMatchMode='contains' filterBy='label' onChange={(e) => this.setState({ idBoutiq: e.value })} autoWidth={false} />
                                </div>
                            </div>

                            <center>
                                <div className="p-grid" style={{ width: 300 }}>
                                    <div className="p-col"><Button label="Annuler" icon="pi pi-ban" onClick={(e) => this.annuler()} /></div>
                                    <div className="p-col"><Button label="Valider" icon="pi pi-check" onClick={(e) => this.enreg_data()} /></div>
                                </div>
                                {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : null}
                            </center>

                            <DataTable value={this.state.liste_data} selectionMode="single" paginatorPosition="top" paginator={true} rows={50}
                                onRowClick={e => this.setState({ selectedData: e.data })} onRowSelect={e => this.setState({ selectedData: e.data })}
                                responsive={true} selection={this.state.selectedData} >
                                <Column header="#" body={this.actionTemplate.bind(this)} style={{ width: '8%' }} />
                                <Column field="periodeObj" body={(rowData, column) => {
                                    return <div>
                                        <span style={{ color: '#000000', fontWeight: 'bold' }}>{rowData.periodeObj.slice(-7)}</span>
                                    </div>
                                }} header="Mois" sortable={true} style={{ width: '15%' }} filter={true} filterMatchMode='contains' />
                                <Column field="montantObj" body={(rowData, column) => {
                                    return <div>
                                        <span style={{ color: '#000000', fontWeight: 'bold' }}>{this.xulaservice.formaterMontant(rowData.montantObj)}</span>
                                    </div>
                                }} header="Montant objectif" filter={true} filterMatchMode='contains' sortable={true} style={{ width: '20%' }} />
                                 <Column field="bonusObj" body={(rowData, column) => {
                                    return <div>
                                        <span style={{ color: '#000000', fontWeight: 'bold' }}>{this.xulaservice.formaterMontant(rowData.bonusObj)}</span>
                                    </div>
                                }} header="Montant bonus" filter={true} filterMatchMode='contains' sortable={true} style={{ width: '20%' }} />
                                <Column field="idBoutiq.nomBoutiq" header="Boutique" filter={true} filterMatchMode='contains' sortable={true} />
                            </DataTable>

                            <Dialog header="Confirmation" visible={this.state.visible} style={{ width: '250px' }} modal={true} footer={dialogFooter} onHide={() => this.setState({ visible: false })}>
                                <div>
                                    <i className="fa fa-warning" style={{ color: '#FFD133', fontSize: 25 }}></i>
                                    Voulez vous vraiment supprimer <b>{this.state.msg} </b>?
                                </div>
                            </Dialog>

                        </div>
                    </div>
                </div>
            </div>
        );
    }
}