import React, { Component } from 'react';
import { Xulaservice } from '../service/Xulaservice';
import { InputText } from 'primereact/inputtext';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dialog } from 'primereact/dialog';
import { Growl } from 'primereact/growl';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Calendar } from 'primereact/calendar';
import { InputTextarea } from 'primereact/inputtextarea';
import 'moment';
import 'moment/locale/fr';
import moment, { utc } from 'moment';
import './styles.css';

export class FormEtabl extends Component {
    constructor() {
        super();
        this.state = {
            liste_param: [],
            liste_etabl: [],
            liste_val_selection: [],
            idEtabl: null,
            idVal: null,
            nomEtabl: '',
            codeEtabl: '',
            etatEtabl: null,
            adrEtabl: '',
            telEtabl: '',
            emailEtabl: '',
            paysEtabl: '',
            villeEtabl: '',
            deviseEtabl: '',
            rccmEtabl: '',
            compteEtabl: '',
            codeNif: '',
            tva: '',
            domaineEtabl: null,
            delaiEtabl: new Date(),
            commissionEtabl: 0,
            selectedEtabl: null,
            idParam: '0',
            valueParam: null,
            selectedParam: null,
            adrBoutiq: '',
            msg: '',
            modif: false,
            modif_param: false,
            disable_etabl: false,
            visible_dlg_param: false,
            visible: false, 
            liste_domaine: [
                { label: 'Alimentation générale', value: 'Alimentation générale' },
                { label: 'Construction', value: 'Construction'},
                { label: 'Restaurant', value: 'Restaurant'},
                { label: 'Ingénierie', value: 'Ingénierie' },
                { label: 'Meuble et décor', value: 'Meuble et décor' },
                { label: 'Pharmacie', value: 'Pharmacie' },
                { label: 'Sanitaire', value: 'Sanitaire' },
                { label: 'Bijouterie', value: 'Bijouterie' },
                { label: 'Electrique', value: 'Electrique' },
                { label: 'Habillement', value: 'Habillement' },
                { label: 'Electronique', value: 'Electronique' },
                { label: 'Imprimerie', value: 'Imprimerie' },
                { label: 'Divers', value: 'Divers' }
            ],
            file: null,
            userconnected: JSON.parse(localStorage.getItem('userconnected')),
        };
        this.edit_etabl = this.edit_etabl.bind(this);
        this.show_param = this.show_param.bind(this);

        this.xulaservice = new Xulaservice();
    }

    show_param(rowData) {
        this.xulaservice.getListe_param(rowData.idEtabl).then(data => this.setState({ liste_param: data }));
        this.setState({ visible_dlg_param: true });
    }

    componentDidMount() {
        this.setState({ showindicator: true });
        this.xulaservice.getListe_param().then(data => {
            this.setState({ liste_param: data });
        });
        this.xulaservice.getListe_etabl().then(data => {
            if (this.state.userconnected.idEtabl.codeEtabl === 'OPTION') {
                this.setState({ liste_etabl: data, showindicator: false });
            }else{
                let id=this.state.userconnected.idEtabl.idEtabl;
                let liste =data.filter(function (el) {
                    return el.idEtabl === id;
                });
                this.setState({ liste_etabl: liste, showindicator: false });
            }
        });
        if (this.state.userconnected.idEtabl.codeEtabl != 'OPTION') {
            this.setState({ disable_etabl: true });
        }
    }

    resultat(code, contenu) {
        if (code == '200') {
            this.growl.show({ severity: 'success', summary: 'Success', detail: contenu });
            this.annule_etabl();
            this.componentDidMount();
        } else {
            this.growl.show({ severity: 'error', summary: 'Error', detail: contenu });
        }
        this.setState({ showindicator: false });
    }

    
    edit_etabl(data) {
        if (data != null && data != undefined) {
            this.setState({ idEtabl: data.idEtabl });
            this.setState({ codeEtabl: data.codeEtabl });
            this.setState({ nomEtabl: data.nomEtabl });
            this.setState({ paysEtabl: data.paysEtabl });
            this.setState({ adrEtabl: data.adrEtabl });
            this.setState({ tva: data.tva });
            this.setState({ rccmEtabl: data.rccmEtabl });
            this.setState({ villeEtabl: data.villeEtabl });
            this.setState({ emailEtabl: data.emailEtabl });
            this.setState({ telEtabl: data.telEtabl });
            this.setState({ deviseEtabl: data.deviseEtabl });
            this.setState({ compteEtabl: data.compteEtabl });
            this.setState({ codeNif: data.codeNif });
            this.setState({ delaiEtabl: data.delaiEtabl });
            this.setState({ commissionEtabl: data.commissionEtabl});
            this.setState({ domaineEtabl: {value: data.domaineEtabl, label: data.domaineEtabl } });
            this.setState({ modif: true });
            this.setState({ selectedEtabl: data });
        }
    }

    annule_etabl() {
        this.setState({ idEtabl: '0' });
        this.setState({ codeEtabl: '' });
        this.setState({ paysEtabl: '' });
        this.setState({ adrEtabl: '' });
        this.setState({ tva: '' });
        this.setState({ rccmEtabl: '' });
        this.setState({ villeEtabl: '' });
        this.setState({ emailEtabl: '' });
        this.setState({ telEtabl: '' });
        this.setState({ deviseEtabl: '' });
        this.setState({ compteEtabl: '' });
        this.setState({ codeNif: '' });
        this.setState({ nomEtabl: ''});
        this.setState({ commissionEtabl: 0});
        this.setState({ modif: false });
        this.forceUpdate();
    }

    edit_param(data) {
        if (data != null && data != undefined) {
            this.xulaservice.getListe_valeur(data.codeParam).then(rep => {
                let liste = rep.map(x => {
                    return { value: x.valSelParam, label: x.valSelParam }
                });
                this.setState({ liste_val_selection: liste }, () => {
                    this.setState({ valueParam: data.valueParam}, () => {
                        this.setState({ modif_param: true });
                    });
                });
            });
        }
    }


    enreg_param() {
        if (this.state.valueParam === '' || this.state.valueParam === undefined || this.state.valueParam === null || this.state.selectedParam === '' || this.state.selectedParam === undefined || this.state.selectedParam === null) {
            this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez sélectionner un paramètre à modifier et choisir une valeur' });
        } else {
            this.setState({ showindicator: true });
            let val = this.state.selectedParam;
            val.valueParam = this.state.valueParam;
            this.xulaservice.enreg_param(val).then(data => {
                if (data != undefined && data != null) {
                    if (data.code == '200') {
                        this.growl.show({ severity: 'success', summary: 'Success', detail: 'Modification réussie' });
                        this.state.selectedParam.valSelParam = this.state.valueParam.value;
                    } else {
                        this.growl.show({ severity: 'error', summary: 'Error', detail: 'Echec modification' });
                    }
                    this.setState({ showindicator: false });
                } else {
                    this.setState({ showindicator: true });
                }
            });

        }
    }

    enreg_etabl() {
        if (this.state.nomEtabl === '' || this.state.nomEtabl === undefined || this.state.nomEtabl === null || this.state.codeEtabl === '' || this.state.codeEtabl === undefined || this.state.codeEtabl === null || this.state.delaiEtabl === '' || this.state.delaiEtabl === undefined || this.state.delaiEtabl === null) {
            this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez saisir tous les champs obligatoires' });
        } else {
            this.setState({ showindicator: true });
            if (this.state.modif === false) {
                if (this.state.userconnected.idEtabl.codeEtabl != 'OPTION' && this.state.userconnected.idEtabl.codeEtabl != 'EDK') {
                    this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Vous ne pouvez pas ajouter de nouveaux établissements ' });
                    return;
                }
                let date = moment(this.state.delaiEtabl,'DD/MM/YYYY').format('DD/MM/YYYY');
                let data = { idEtabl: '0', delaiEtabl: date, nomEtabl: this.state.nomEtabl, telEtabl: this.state.telEtabl, emailEtabl: this.state.emailEtabl, codeEtabl: this.state.codeEtabl, adrEtabl: this.state.adrEtabl, domaineEtabl: this.state.domaineEtabl.value, codeNif: this.state.codeNif, rccmEtabl: this.state.rccmEtabl, deviseEtabl: this.state.deviseEtabl, tva: this.state.tva, compteEtabl: this.state.compteEtabl, commissionEtabl: this.state.commissionEtabl, villeEtabl: this.state.villeEtabl, paysEtabl: this.state.paysEtabl };
                this.xulaservice.enreg_etabl(data).then(data => this.resultat(data.code, data.contenu));
            } else {
                let data = this.state.selectedEtabl;
                let date = moment(this.state.delaiEtabl,'DD/MM/YYYY').format('DD/MM/YYYY');
                data.adrEtabl = this.state.adrEtabl;
                data.nomEtabl = this.state.nomEtabl;
                data.codeEtabl = this.state.codeEtabl;
                data.commissionEtabl = this.state.commissionEtabl;
                data.telEtabl = this.state.telEtabl;
                data.emailEtabl = this.state.emailEtabl;
                data.codeNif = this.state.codeNif;
                data.paysEtabl = this.state.paysEtabl;
                data.deviseEtabl = this.state.deviseEtabl;
                data.rccmEtabl = this.state.rccmEtabl;
                data.villeEtabl = this.state.villeEtabl;
                data.delaiEtabl = date;
                data.tva = this.state.tva;
                data.compteEtabl = this.state.compteEtabl;
                this.xulaservice.enreg_etabl(data).then(data => this.resultat(data.code, data.contenu));
            }
        }
    }

    actionTemplate(rowData, column) {
        return <div className="p-grid" style={{ width: 90 }}>
            <div className="p-col"><Button onClick={(e) => this.edit_etabl(rowData)} className="p-button-info" style={{ width: 25, height: 25 }} icon="pi pi-pencil" /></div>
            <div className="p-col"><Button onClick={(e) => this.show_param(rowData)} className="p-button-warning" style={{ width: 25, height: 25, marginLeft: -8 }} icon="pi pi-cog" /></div>
        </div>
    }

    actionTemplate_param(rowData, column) {
        return <div className="p-grid" style={{ width: 50 }}>
            <div className="p-col"><Button onClick={(e) => this.edit_param(rowData)} className="p-button-info" style={{ width: 25, height: 25 }} icon="pi pi-pencil" /></div>
        </div>
    }

    render() {
        const dialogFooter = (
            <div>
                <Button icon="pi pi-times" onClick={() => this.setState({ visible_dlg_param: false, modif_param: false })} label="Annuler" className="p-button-secondary" />
            </div>
        );
        return (
            <div className="p-fluid">
                <Growl ref={(el) => this.growl = el} />

                <Dialog header="Paramétrage" visible={this.state.visible_dlg_param} style={{ width: '70%' }} modal={true} onHide={(e) => this.setState({ visible_dlg_param: false, modif_param: false })} footer={dialogFooter}>
                    <div className="p-grid">
                        <div className="p-col-12">
                            <div className="card card-w-title" style={{ height: 270 }}>
                                {this.state.modif_param === true ?
                                    <div className="p-grid">
                                        <div className="p-col-12 p-md-2">
                                            <label htmlFor="nomBoutiq">Nouvelle valeur *</label>
                                        </div>
                                        <div className="p-col-12 p-md-4">
                                            <Dropdown value={this.state.valueParam} options={this.state.liste_val_selection}
                                                onChange={(e) => { 
                                                    this.setState({ valueParam: e.value },()=>{
                                                        //alert(this.state.valueParam);
                                                    }) 
                                                    }} placeholder="Selectionner" style={{ width: '200px' }} autoWidth={false}/>
                                        </div>
                                        <div className="p-col-12 p-md-2">
                                            <div className="p-inputgroup">
                                                <Button icon='pi pi-check' style={{ width: '200px' }} label='Valider' onClick={(e) => this.enreg_param()} />
                                                {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : null}
                                            </div>
                                        </div>
                                    </div>
                                    : null}

                                <DataTable value={this.state.liste_param} scrollable={true} scrollHeight="150px"
                                    selectionMode="single" responsive={true} selection={this.state.selectedParam} style={{ marginBottom: 12 }}
                                    onRowClick={e => this.setState({ selectedParam: e.data })} onRowSelect={e => this.setState({ selectedParam: e.data })}
                                >
                                    <Column header="#" body={this.actionTemplate_param.bind(this)} style={{ width: '5%' }} />
                                    <Column field="libParam" header="Paramètre" style={{ width: '20%' }} />
                                    <Column field="descParam" header="Description" style={{ width: '45%' }} />
                                    <Column field="valueParam" header="Valeur" style={{ width: '10%' }} editor={this.valueParamEditor} />
                                    <Column field="idBoutiq.nomBoutiq" header="Boutique" style={{ width: '20%' }} />
                                </DataTable>
                            </div>
                        </div>
                    </div>
                </Dialog>

                <div className="p-grid">
                    <div className="p-col-12">
                        <div className="card card-w-title" style={{ minHeight: '900px' }}>

                            <div className="p-grid">
                                <div className="p-col-12 p-md-2">
                                    <label htmlFor="nomBoutiq">Nom établissement *</label>
                                </div>
                                <div className="p-col-12 p-md-4">
                                    <InputText id="nomBoutiq" value={this.state.nomEtabl} onChange={(e) => this.setState({ nomEtabl: e.target.value })} />
                                </div>
                                <div className="p-col-12 p-md-2">
                                    <label htmlFor="codeBoutiq">Code  *</label>
                                </div>
                                <div className="p-col-12 p-md-4">
                                    <InputText disabled={this.state.disable_etabl} value={this.state.codeEtabl} onChange={(e) => this.setState({ codeEtabl: e.target.value })} />
                                </div>
                                <div className="p-col-12 p-md-2">
                                    <label htmlFor="codeBoutiq">Adresse </label>
                                </div>
                                <div className="p-col-12 p-md-4">
                                    <InputText id="codeBoutiq" value={this.state.adrEtabl} onChange={(e) => this.setState({ adrEtabl: e.target.value })} />
                                </div>

                                <div className="p-col-12 p-md-2">
                                    <label htmlFor="codeBoutiq">Téléphone </label>
                                </div>
                                <div className="p-col-12 p-md-4">
                                    <InputText id="codeBoutiq" value={this.state.telEtabl} onChange={(e) => this.setState({ telEtabl: e.target.value })} />
                                </div>

                                <div className="p-col-12 p-md-2">
                                    <label htmlFor="codeBoutiq">Email </label>
                                </div>
                                <div className="p-col-12 p-md-4">
                                    <InputText id="codeBoutiq" value={this.state.emailEtabl} onChange={(e) => this.setState({ emailEtabl: e.target.value })} />
                                </div>

                                <div className="p-col-12 p-md-2">
                                    <label htmlFor="codeBoutiq">RCCM </label>
                                </div>
                                <div className="p-col-12 p-md-4">
                                    <InputText id="codeBoutiq" value={this.state.rccmEtabl} onChange={(e) => this.setState({ rccmEtabl: e.target.value })} />
                                </div>

                                <div className="p-col-12 p-md-2">
                                    <label htmlFor="codeBoutiq">Code NIF </label>
                                </div>
                                <div className="p-col-12 p-md-4">
                                    <InputText id="codeBoutiq" value={this.state.codeNif} onChange={(e) => this.setState({ codeNif: e.target.value })} />
                                </div>

                                <div className="p-col-12 p-md-2">
                                    <label htmlFor="codeBoutiq">Clée TVA </label>
                                </div>
                                <div className="p-col-12 p-md-4">
                                    <InputText id="codeBoutiq" value={this.state.tva} onChange={(e) => this.setState({ tva: e.target.value })} />
                                </div>

                                <div className="p-col-12 p-md-2">
                                    <label htmlFor="codeBoutiq">Dévise </label>
                                </div>
                                <div className="p-col-12 p-md-4">
                                    <InputText id="codeBoutiq" value={this.state.deviseEtabl} onChange={(e) => this.setState({ deviseEtabl: e.target.value })} />
                                </div>
                                <div className="p-col-12 p-md-2">
                                    <label htmlFor="codeBoutiq">Ville </label>
                                </div>
                                <div className="p-col-12 p-md-4">
                                    <InputText id="codeBoutiq" value={this.state.villeEtabl} onChange={(e) => this.setState({ villeEtabl: e.target.value })} />
                                </div>


                                <div className="p-col-12 p-md-2">
                                    <label htmlFor="codeBoutiq">Commission * </label>
                                </div>
                                <div className="p-col-12 p-md-4">
                                    <InputText id="codeBoutiq" value={this.state.commissionEtabl} onChange={(e) => this.setState({ commissionEtabl: e.target.value })} />
                                </div>

                                <div className="p-col-12 p-md-2">
                                    <label htmlFor="codeBoutiq">Délai </label>
                                </div>

                                <div className="p-col-12 p-md-4">
                                    <Calendar disabled={this.state.disable_etabl} value={this.state.delaiEtabl} dateFormat="dd/mm/yy" onChange={(e) => this.setState({ delaiEtabl: e.value })} />
                                </div>


                                <div className="p-col-12 p-md-2">
                                    <label htmlFor="adrCli">Domaine * </label>
                                </div>
                                <div className="p-col-12 p-md-4">
                                    <Dropdown disabled={this.state.disable_etabl}  options={this.state.liste_domaine} value={this.state.domaineEtabl} optionLabel='label' filter={true} filterBy='label' filterMatchMode='contains' onChange={(e) => { this.setState({ domaineEtabl: e.value }) }} autoWidth={false} />
                                </div>

                                <div className="p-col-12 p-md-2">
                                    <label htmlFor="codeBoutiq">Comptes bancaires </label>
                                </div>
                                <div className="p-col-12 p-md-4">
                                    <InputTextarea rows={2} cols={30} id="codeBoutiq" value={this.state.compteEtabl} onChange={(e) => this.setState({ compteEtabl: e.target.value })} />
                                </div>

                            </div>
                            <center>
                                <div className="p-grid" style={{ width: 300 }}>
                                    <div className="p-col"><Button label="Annuler" icon="pi pi-ban" onClick={(e) => this.annule_etabl()} /></div>
                                    <div className="p-col"><Button label="Valider" icon="pi pi-check" onClick={(e) => this.enreg_etabl()} /></div>
                                </div>
                                {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : null}
                            </center>

                            <DataTable value={this.state.liste_etabl} selectionMode="single" paginatorPosition="top" paginator={true} rows={50}
                                onRowClick={e => this.setState({ selectedEtabl: e.data })} onRowSelect={e => this.setState({ selectedEtabl: e.data })}
                                responsive={true} selection={this.state.selectedEtabl} >
                                <Column header="#" body={this.actionTemplate.bind(this)} style={{ width: '8%' }} />
                                <Column field="idEtabl" header="ID" sortable={true} style={{ width: '8%' }}  filter={true} filterMatchMode='contains' />
                                <Column field="nomEtabl" header="Nom établissement" sortable={true}  filter={true} filterMatchMode='contains' />
                                <Column field="codeEtabl" header="Code" sortable={true} style={{ width: '8%' }}  filter={true} filterMatchMode='contains' />
                                <Column field="adrEtabl" header="Adresse" sortable={true} style={{ width: '18%' }}  filter={true} filterMatchMode='contains' />
                                <Column field="telEtabl" header="Téléphone" sortable={true} style={{ width: '10%' }}  filter={true} filterMatchMode='contains' />
                                <Column field="emailEtabl" header="Email" sortable={true} style={{ width: '15%' }}  filter={true} filterMatchMode='contains' />
                                <Column field="domaineEtabl" header="Domaine" sortable={true} style={{ width: '12%' }}  filter={true} filterMatchMode='contains' />
                            </DataTable>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}