import React, { Component } from 'react';
import { Xulaservice } from '../service/Xulaservice';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import Cleave from 'cleave.js/react';
import { Growl } from 'primereact/growl';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import './styles.css';
import { ProgressSpinner } from 'primereact/progressspinner';
import 'moment';
import 'moment/locale/fr';
import moment from 'moment';
import Numeral from 'numeral';

export class FormOptArticle extends Component {
    constructor() {
        super();
        this.state = {
            liste_article: [],
            lprod: [],
            liste_boutique: [],
            liste_inventaire: [],
            liste_sous_type_article: [],
            selectedArticle: null,
            idArticle: null,
            idBoutiq: '',
            libArticle: '',
            codeArticle: '',
            cbArticle: '',
            idArt: '',
            uniteArticle: { label: 'Qauntité', value: '-' },
            remiseArticle: 0,
            finRemiseArticle: null,
            utilisationArticle: '',
            debutRemiseArticle: null,
            tvaArticle: 0,
            prixRefAchat: 0,
            prixRefVente: 0,
            date_exp: null,
            qte_inv: 0,
            qte_stock: 0,
            constRefArticle: 1,
            liste_unite: [
                { label: 'Qauntité', value: '-' },
                { label: 'Kilometre', value: 'Km' },
                { label: 'Kilometre carré', value: 'Km2' },
                { label: 'Hectar', value: 'ha' },
                { label: 'Metre', value: 'm' },
                { label: 'Metre carré', value: 'm2' },
                { label: 'Kilogramme', value: 'Kg' },
                { label: 'Gramme', value: 'g' },
                { label: 'Litre', value: 'l' },
                { label: 'Jour', value: 'j' },
                { label: 'Heure', value: 'h' },
                { label: 'Pacquet', value: 'p' },
                { label: 'Boite', value: 'b' },
                { label: 'NA', value: '.' },
            ],
            msg: '',
            disable_modif: false,
            modif: false,
            visible: false,
            visible_dlg_new: false,
            idStype: null,
            userconnected: JSON.parse(localStorage.getItem('userconnected')),
        };

        this.xulaservice = new Xulaservice();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.idArticle != this.props.idArticle) {
            if (this.props != undefined) {
                let val = this.props.idArticle;
                let liste = [{ value: 'NEW', label: 'Nouveau', cbArt: '0', qte_stock_format: '0', prix_ref_vente_format: 0, prix_ref_vente: 0, qte_stock: 0, prix_ref_achat: 0, prix_ref_achat_format: '0', idstype: '0', libsoustypearticle: '0', currentRemise: 0, couvArticle: 'noimage.png', label_rech: 'NEW', idBoutiq: this.state.userconnected.matrPers.idBoutiq.idBoutiq }];
                let liste_prod = this.props.idArticle.lprod;
                liste_prod = liste_prod.concat(liste);
                if (val != null && val != undefined && val != '') {
                    this.setState({ lprod: liste_prod }, () => {
                        this.setState({ idArticle: this.props.idArticle.idArticle }, () => {
                            this.setState({ idArt: this.state.idArticle.value });
                            this.setState({ qte_stock: this.state.idArticle.qte_stock });
                            this.setState({ qte_inv: this.state.idArticle.qte_stock });
                            this.setState({ libArticle: this.state.idArticle.label });
                            this.setState({ constRefArticle: this.state.idArticle.constRefArticle });
                            this.setState({ cbArticle: this.state.idArticle.cbArt });
                            this.setState({ date_exp: this.state.idArticle.date_exp_article });
                            this.setState({ idBoutiq: this.state.userconnected.matrPers.idBoutiq.idBoutiq });
                            this.setState({ prixRefVente: this.state.idArticle.prix_ref_vente });
                            this.setState({ prixRefAchat: this.state.idArticle.prix_ref_achat });
                            this.setState({ disable_modif: true });
                            this.setState({ idStype: { value: this.state.idArticle.idstype, label: this.state.idArticle.libsoustypearticle } });
                        });
                    });
                }
            }
        }
    }


    get_liste_boutique() {
        this.xulaservice.getListe_boutique().then(data => this.setState({ liste_boutique: data }));
    }

    articleItemTemplate(option) {
        if (!option.value) {
            return option.label;
        }
        else {
            return (
                <div className="p-clearfix">
                    <div className="p-grid">
                        <div className="p-col-12 p-md-7">
                            <label>{option.label}</label>
                        </div>
                        <div style={{ fontWeight: 'bold' }} className="p-col-12 p-md-2">
                            <label>{option.prix_ref_achat_format}</label>
                        </div>
                        <div className="p-col-12 p-md-2">
                            <label>{option.prix_ref_vente_format}</label>
                        </div>
                        <div className="p-col-12 p-md-1" >
                            <label style={{ fontWeight: 'bold', color: 'red', marginRight: '5px' }}>{option.qte_stock_format}</label>
                        </div>
                    </div>
                </div>
            );
        }
    }

    formaterMontant(montant) {
        return Numeral(parseFloat(montant)).format('0,0');
    }

    componentDidMount() {
        this.setState({ idBoutiq: this.state.userconnected.matrPers.idBoutiq.idBoutiq });
        this.get_liste_sous_type_article();
        this.setState({ lprod: [{ value: 'NEW', label: 'Nouveau', cbArt: '0', qte_stock_format: '0', prix_ref_vente_format: 0, prix_ref_vente: 0, qte_stock: 0, prix_ref_achat: 0, prix_ref_achat_format: '0', idstype: '0', libsoustypearticle: '0', currentRemise: 0, couvArticle: 'noimage.png', label_rech: 'NEW 0', idBoutiq: this.state.userconnected.matrPers.idBoutiq.idBoutiq }] });
        //this.get_liste_article();
    }

    get_liste_sous_type_article() {
        this.xulaservice.get_liste_sous_type_article('Tous').then(data => this.setState({ liste_sous_type_article: data }));
    }

    get_liste_article() {
        let liste = [{ value: 'NEW', label: 'Nouveau', cbArt: '0', qte_stock_format: '0', prix_ref_vente_format: 0, prix_ref_vente: 0, qte_stock: 0, prix_ref_achat: 0, prix_ref_achat_format: '0', idstype: '0', libsoustypearticle: '0', currentRemise: 0, couvArticle: 'noimage.png', label_rech: 'NEW', idBoutiq: this.state.userconnected.matrPers.idBoutiq.idBoutiq }];
        this.xulaservice.get_inventaire_mins('Tous', 'Tous', 'Tous', 'Tous', this.state.userconnected.matrPers.idBoutiq.idBoutiq).then(data => {
            if (data != null && data != undefined) {
                let lprod = data.map(x => {
                    return { value: x.idArt, label: x.libArt, cbArt: x.cbArt, qte_stock_format: this.formaterMontant(x.qte_stock_reel), prix_ref_vente_format: this.formaterMontant(x.prix_ref_vente), prix_ref_vente: x.prix_ref_vente, qte_stock: x.qte_stock_reel, prix_ref_achat: x.prix_ref_achat, prix_ref_achat_format: this.formaterMontant(x.prix_ref_achat), idstype: x.idstype, libsoustypearticle: x.libsoustypearticle, currentRemise: x.currentRemise, couvArticle: x.couvArticle, label_rech: x.libArt + ' ' + x.cbArt, idBoutiq: this.state.userconnected.matrPers.idBoutiq.idBoutiq }
                });
                lprod = lprod.concat(liste);
                this.setState({ lprod: lprod });
            }
        });
    }

    resultat(code, contenu) {
        if (code == '200') {
            this.growl.show({ severity: 'success', summary: 'Success', detail: contenu });
            this.annule_add();
            this.get_liste_article();
        } else {
            this.growl.show({ severity: 'error', summary: 'Error', detail: contenu });
        }
        this.setState({ showindicator: false });
    }



    annule_add() {
        this.setState({ idArticle: '' });
        this.setState({ libArticle: '' });
        this.setState({ codeArticle: '' });
        this.setState({ cbArticle: '' });
        this.setState({ descArticle: '' });
        this.setState({ remiseArticle: 0 });
        this.setState({ finRemiseArticle: '' });
        this.setState({ debutRemiseArticle: '' });
        this.setState({ tvaArticle: 0 });
        this.setState({ prixRefVente: 0 });
        this.setState({ prixRefAchat: 0 });
        this.setState({ capturePiPers: 0 });
        this.setState({ constRefArticle: 1 });
        this.setState({ couvArticle: 'nocouv.png' });
        this.setState({ modif: false });
        this.setState({ visible_dlg_new: false });
        this.setState({ liste_inventaire: [] });
        this.forceUpdate();
    }

    add_article() {
        if (this.state.libArticle === null || this.state.libArticle === undefined || this.state.libArticle === '') {
            this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez choisir un article' });
            return;
        } else if (this.state.idStype === null || this.state.idStype === undefined || this.state.idStype === '') {
            this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez choisir un sous type' });
            return;
        }
        Array.prototype.insert = function (index) {
            this.splice.apply(this, [index, 0].concat(this.slice.call(arguments, 1)));
        };
        if (this.state.qte_inv === undefined || this.state.qte_inv === null || this.state.qte_inv === '') {
            this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez saisir la quantité ' });
        }
        else {
            this.setState({ num_ligne: this.state.num_ligne + 1 });
            let date = moment(this.state.date_exp, 'DD/MM/YYYY').format('DD/MM/YYYY');
            if (date === 'Invalid date') {
                date = null;
                if(this.state.userconnected.matrPers.idBoutiq.typeBoutiq === 'Pharmacie'){
                    this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez saisir la date de péremption' });
                    return;
                }
            }
            this.state.liste_inventaire.insert(this.state.num_ligne, { idArt: this.state.idArt, qte_invent: this.state.qte_inv, qte_stock: this.state.qte_stock, date_exp_article: date, idstype: this.state.idStype.value, libArt: this.state.libArticle, cbArt: this.state.cbArticle, prix_ref_achat: this.state.prixRefAchat, prix_ref_vente: this.state.prixRefVente, constRefArticle: this.state.constRefArticle, uniteArticle: this.state.uniteArticle.value, qte_stock_format: this.state.qte_stock, idBoutiq: this.state.idBoutiq, libsoustypearticle: this.state.idStype.label });
            this.enreg_inventaire();
        }
    }


    enreg_inventaire() {
        if (this.state.liste_inventaire === null || this.state.liste_inventaire === undefined) {
            this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez ajouter les articles' });
        } else if (this.state.liste_inventaire.length <= 0) {
            this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez ajouter les articles' });
        } else {
            this.xulaservice.enreg_inventaire(this.state.liste_inventaire)
                .then(data => {
                    if (data != undefined && data != null) {
                        this.resultat(data.code, data.contenu);
                    }
                });

        }
    }


    render() {
        let lsous_type = null;
        if (this.state.liste_sous_type_article !== null && this.state.liste_sous_type_article !== undefined) {
            lsous_type = this.state.liste_sous_type_article.map(x => {
                return { value: x.idStype, label: x.libStype }
            });
        }

        return (
            <div className="p-fluid">
                <Growl ref={(el) => this.growl = el} />
                <div className="p-grid">
                    <div className="p-col-12">
                        <div className="p-grid">
                            <div className="p-col-12">
                                <div className="card card-w-title" style={{ marginBottom: -8 }}>
                                    <div className="p-grid">
                                        <div className="p-col-12 p-md-3">
                                            <label htmlFor="libArticle">Article *</label>
                                        </div>
                                        <div className="p-col-12 p-md-9">
                                            <div className="p-inputgroup">
                                                <Dropdown options={this.state.lprod} placeholder="Articles" value={this.state.idArticle} panelStyle={{ width: '500px' }} optionLabel='label_rech' filter={true} filterBy='label_rech' itemTemplate={this.articleItemTemplate} filterMatchMode='contains'
                                                    onChange={(e) => {
                                                        this.setState({ idArticle: e.value }, () => {
                                                            if (this.state.idArticle.value === 'NEW') {
                                                                this.setState({ idArt: '0' });
                                                                this.setState({ qte_stock: '0' });
                                                                this.setState({ qte_inv: 0 });
                                                                this.setState({ libArticle: '' });
                                                                this.setState({ cbArticle: '' });
                                                                this.setState({ date_exp: '' });
                                                                this.setState({ prixRefVente: 0 });
                                                                this.setState({ prixRefAchat: 0 });
                                                                this.setState({ disable_modif: false });
                                                            } else {
                                                                this.setState({ idArt: this.state.idArticle.value });
                                                                this.setState({ qte_stock: this.state.idArticle.qte_stock });
                                                                this.setState({ qte_inv: this.state.idArticle.qte_stock });
                                                                this.setState({ libArticle: this.state.idArticle.label });
                                                                this.setState({ cbArticle: this.state.idArticle.cbArt });
                                                                this.setState({ date_exp: this.state.idArticle.date_exp_article });
                                                                this.setState({ prixRefVente: this.state.idArticle.prix_ref_vente });
                                                                this.setState({ prixRefAchat: this.state.idArticle.prix_ref_achat });
                                                                this.setState({ disable_modif: true });
                                                                this.setState({ idStype: { value: this.state.idArticle.idstype, label: this.state.idArticle.libsoustypearticle } });
                                                            }
                                                        })
                                                    }
                                                    } autoWidth={false} />
                                                <span className="p-inputgroup-addon" style={{ backgroundColor: '#f5f8f9', color: 'red', fontWeight: 'bold', width: 70 }}>{this.state.qte_stock}</span>
                                            </div>
                                        </div>
                                        <div className="p-col-12 p-md-3">
                                            <label htmlFor="libArticle">Désignation *</label>
                                        </div>
                                        <div className="p-col-12 p-md-9">
                                            <InputText id="libArticle" value={this.state.libArticle} onChange={(e) => this.setState({ libArticle: e.target.value, descArticle: e.target.value })} />
                                        </div>
                                        <div className="p-col-12 p-md-3">
                                            <label htmlFor="remiseArticle">Qauntité *</label>
                                        </div>
                                        <div className="p-col-6 p-md-9">
                                            <Cleave value={this.state.qte_inv} options={{ numeral: true, delimiter: ' ' }} onChange={(e) => this.setState({ qte_inv: e.target.rawValue })} className="p-inputtext" />
                                        </div>
                                        {this.state.userconnected.profilUser == 'PDG' ?
                                            <div className="p-col-12 p-md-3">
                                                <label htmlFor="prixRefAchat">Prix d'achat *</label>
                                            </div>
                                            :
                                            null}
                                        {this.state.userconnected.profilUser == 'PDG' ?
                                            <div className="p-col-12 p-md-9">
                                                <Cleave value={this.state.prixRefAchat} options={{ numeral: true, delimiter: ' ' }} onChange={(e) => this.setState({ prixRefAchat: e.target.rawValue }, () => {
                                                    let pv = Math.ceil(this.state.prixRefAchat * this.state.userconnected.idEtabl.commissionEtabl);
                                                    this.setState({ prixRefVente: pv });
                                                    this.setState({ prixRefRevient: this.state.prixRefAchat });
                                                })} className="p-inputtext" />
                                            </div>
                                            :
                                            null}
                                        <div className="p-col-12 p-md-3">
                                            <label htmlFor="prixRefAchat">Prix de vente *</label>
                                        </div>
                                        <div className="p-col-12 p-md-9">
                                            <Cleave value={this.state.prixRefVente} options={{ numeral: true, delimiter: ' ' }} onChange={(e) => this.setState({ prixRefVente: e.target.rawValue }, () => {
                                                if (this.state.userconnected.idEtabl.commissionEtabl !== 0) {
                                                    let prix = 0;
                                                    prix = Math.ceil(this.state.prixRefVente / this.state.userconnected.idEtabl.commissionEtabl);
                                                    this.setState({ prixRefAchat: prix });
                                                    this.setState({ prixRefRevient: prix });
                                                }
                                            })} className="p-inputtext" />
                                        </div>
                                        {this.state.userconnected.matrPers.idBoutiq.typeBoutiq !== 'Fonderie' ?
                                            <div className="p-col-12 p-md-3">
                                                <label htmlFor="cbArticle">Code barre </label>
                                            </div>
                                            :
                                            null}
                                        {this.state.userconnected.matrPers.idBoutiq.typeBoutiq !== 'Fonderie' ?
                                            <div className="p-col-12 p-md-9">
                                                <InputText id="cbArticle" value={this.state.cbArticle} onChange={(e) => this.setState({ cbArticle: e.target.value })} />
                                            </div>
                                            :
                                            null}

                                        <div className="p-col-12 p-md-3">
                                            <label htmlFor="idBoutiq">Sous type *</label>
                                        </div>
                                        <div className="p-col-12 p-md-9">
                                            <Dropdown value={this.state.idStype} placeholder="Sélectionner" options={lsous_type} optionLabel='label' filter={true} filterBy='label' onChange={(e) => {
                                                this.setState({ idStype: e.value }, () => {
                                                    if (this.state.idStype.label == 'METAL PRECIEUX') {
                                                        this.setState({ constRefArticle: 22 });
                                                    } else {
                                                        this.setState({ constRefArticle: 1 });
                                                    }
                                                });
                                            }} autoWidth={false} />
                                        </div>
                                        {this.state.userconnected.matrPers.idBoutiq.typeBoutiq !== 'Fonderie' ?
                                            <div className="p-col-12 p-md-3">
                                                <label >Date péremption </label>
                                            </div>
                                            :
                                            null}
                                        {this.state.userconnected.matrPers.idBoutiq.typeBoutiq !== 'Fonderie' ?
                                            <div className="p-col-12 p-md-9">
                                                <Calendar dateFormat="dd/mm/yy" value={this.state.date_exp} onChange={e => this.setState({ date_exp: e.value })}></Calendar>
                                            </div>
                                            :
                                            null}



                                    </div>
                                    <center>
                                        <div className="p-grid" style={{ width: 300 }}>
                                            <div className="p-col"><Button label="Annuler" className="p-button-info" icon="pi pi-ban" onClick={(e) => this.annule_add()} /></div>
                                            <div className="p-col"><Button label="Valider" className="p-button-info" icon="pi pi-check" onClick={(e) => this.add_article()} /></div>
                                        </div>
                                        {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : null}
                                    </center>
                                </div>



                            </div>
                        </div>

                    </div>

                </div>
            </div>
        );
    }
}