import React, { Component } from 'react';
import { Xulaservice } from '../service/Xulaservice';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import Numeral from 'numeral';
import { SplitButton } from 'primereact/splitbutton';
import './styles.css';
import { ProgressSpinner } from 'primereact/progressspinner';
import { FormEdition_vente } from './FormEdition_vente';
import { Dialog } from 'primereact/dialog';
import { Panel } from 'primereact/panel';
import { FormSituation_client } from './FormSituation_client';

export class FormEdition_Balance_client extends Component {
    constructor() {
        super();
        this.state = {
            liste_client: [],
            selectedClient: null,
            idCli: null,
            showindicator: false,
            visible_facture: false,
            total_solde: '0',
            total_balance: '0',
            total_non_livre: '0',
            color_solde: 'black',
            color_balance: 'black',
            items: [
                {
                    label: 'Factures en PDF',
                    icon: 'pi pi-print',
                    command: (e) => {
                        this.conf_print('PDF');
                    }
                },
                {
                    label: 'Factures en XLS',
                    icon: 'pi pi-print',
                    command: (e) => {
                        this.conf_print('XLS');
                    }
                }
            ],
            visible_situation_client: false ,
            idCli_situation: '',
            userconnected: JSON.parse(localStorage.getItem('userconnected')),
        };
        this.conf_print = this.conf_print.bind(this);
        this.show_situation_client_dlg = this.show_situation_client_dlg.bind(this);
        this.show_dlg_facture = this.show_dlg_facture.bind(this);
        this.xulaservice = new Xulaservice();
    }

    show_situation_client_dlg(rowData) {
        rowData.value=rowData.idCli;
        rowData.label=rowData.nomCli;
        this.setState({idCli_situation: rowData},()=>{
            this.setState({ visible_situation_client: true });
        })
    }


    show_dlg_facture(x) {
        let client = { value: x.idCli, label: x.nomCli, remiseCli: x.remiseCli, soldeCli_format: x.soldeCli_format, telCli: x.telCli, tvaCli: x.tvaCli, balanceCli: x.balanceCli, caventeCli: x.caventeCli, label_rech: x.nomCli + ' ' + x.idCli }
        this.setState({ idCli: client }, () => {
            this.setState({ visible_facture: true });
        });
    }

    conf_print(format) {
        this.xulaservice.generer_edition_balance_client(format);
    }

    componentDidMount() {
        this.get_liste_client();
    }

    get_liste_client() {
        this.setState({ showindicator: true });
        this.setState({ total_balance: '0' });
        this.setState({ total_non_livre: '0' });
        this.setState({ total_solde: '0' });
        this.xulaservice.getListe_client(this.state.userconnected.profilUser=='Recouvreur'?'Recouvrement':'Tous').then(data => {
            this.setState({ liste_client: data, showindicator: false }, () => {
                if (data != null && data != undefined) {
                    let initialValue = 0
                    let tt_solde = data.reduce(function (accumulator, currentValue) {
                        return accumulator + currentValue.soldeCli
                    }, initialValue);
                    let tt_balance = data.reduce(function (accumulator, currentValue) {
                        return accumulator + currentValue.balanceCli
                    }, initialValue);
                    let tt_nl = data.reduce(function (accumulator, currentValue) {
                        return accumulator + currentValue.valeurNonlivreCli
                    }, initialValue);

                    this.setState({ total_balance: this.formaterMontant(tt_balance) });
                    this.setState({ total_non_livre: this.formaterMontant(tt_nl) });
                    this.setState({ total_solde: this.formaterMontant(tt_solde) });
                    if (tt_balance < 0) {
                        this.setState({ color_balance: 'red' });
                    }
                    if (tt_solde < 0) {
                        this.setState({ color_solde: 'red' });
                    }
                }
            });
        });
    }
    formaterMontant(montant) {
        return Numeral(parseFloat(montant)).format('0,0');
    }


    actionTemplate(rowData, column) {
        return <div className="p-grid" style={{ width: 90 }}>
            <div className="p-col">
                {this.state.userconnected.profilUser == 'Recouvreur' ?
                    <Button onClick={(e) => this.show_situation_client_dlg(rowData)} className="p-button-info" style={{ width: 25, height: 25 }} icon="pi pi-search" />
                    :
                    <Button onClick={(e) => this.show_dlg_facture(rowData)} className="p-button-info" style={{ width: 25, height: 25 }} icon="pi pi-search" />
                }
            </div>
        </div>
    }



    render() {

        return (
            <div className="p-fluid">
                <Dialog header="Factures" visible={this.state.visible_facture} style={{ width: '95%' }} modal={true} onHide={(e) => this.setState({ visible_facture: false })}>
                    <FormEdition_vente client={this.state.idCli} />
                </Dialog>
                <Dialog header="Situation client" visible={this.state.visible_situation_client} style={{ width: '90%' }} modal={true} onHide={(e) => this.setState({ visible_situation_client: false })}>
                    <FormSituation_client client={this.state.idCli_situation} />
                </Dialog>

                <div className="p-grid">
                    <div className="p-col-12">
                        <div className="card card-w-title" style={{ minHeight: '800px' }}>
                            <Panel style={{ backgroundColor: '#f7f7f7' }}>
                                <div className="p-grid">
                                    <div className="p-col-12 p-md-2">
                                        <div className="p-grid" style={{ width: 300 }}>
                                            <div className="p-col">
                                                <SplitButton label="Rechercher" style={{ width: '140px' }} onClick={(e) => this.get_liste_client()} icon="pi pi-search" model={this.state.items} />
                                            </div>
                                            <div className="p-col">{this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : null}</div>
                                        </div>
                                    </div>
                                    <div className="p-col-12 p-md-1">
                                        <label style={{ fontSize: 16 }}>Total solde</label>
                                    </div>
                                    <div className="p-col-12 p-md-2">
                                        <label style={{ fontSize: 16, fontWeight: 'bold', color: this.state.color_solde }}> {this.state.total_solde} </label>
                                    </div>
                                    <div className="p-col-12 p-md-1">
                                        <label style={{ fontSize: 16 }}>Total balance</label>
                                    </div>
                                    <div className="p-col-12 p-md-3">
                                        <label style={{ fontSize: 16, fontWeight: 'bold', color: this.state.color_balance }}> {this.state.total_balance} </label>
                                    </div>
                                    <div className="p-col-12 p-md-1">
                                        <label style={{ fontSize: 16 }}>Non livré</label>
                                    </div>
                                    <div className="p-col-12 p-md-2">
                                        <label style={{ fontSize: 16, fontWeight: 'bold', color: 'red' }}> {this.state.total_non_livre} </label>
                                    </div>
                                </div>
                            </Panel>
                            <DataTable value={this.state.liste_client}
                                responsive={true} scrollable={true} scrollHeight="700px" selectionMode="single"
                                onRowClick={e => this.setState({ selectedClient: e.data })}
                                selection={this.state.selectedClient}>
                                <Column header="#" body={this.actionTemplate.bind(this)} style={{ width: '3%' }} />
                                <Column field="nomCli" header="Nom client" filter={true} filterMatchMode='contains' sortable={true} style={{ width: '20%' }} />
                                <Column field="telCli" header="Téléphone" filter={true} filterMatchMode='contains' sortable={true} style={{ width: '10%' }} />
                                <Column field="typeCli" header="Type" filter={true} filterMatchMode='contains' sortable={true} style={{ width: '15%' }} />
                                <Column field="caventeCli" header="CA" body={(rowData, column) => {
                                    return <div>
                                        <span >{this.formaterMontant(rowData.caventeCli)}</span>
                                    </div>
                                }} style={{ width: '12%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                <Column field="soldeCli" header="Solde" body={(rowData, column) => {
                                    return <div>
                                        <span >{this.formaterMontant(rowData.soldeCli)}</span>
                                    </div>
                                }} style={{ width: '10%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                <Column field="balanceCli" header="Balance" body={(rowData, column) => {
                                    let color = 'black';
                                    if (parseFloat(rowData.balanceCli) < 0) {
                                        color = 'red';
                                    }
                                    return <div>
                                        <span style={{ color: color, fontWeight: 'bold' }}>{this.formaterMontant(rowData.balanceCli)}</span>
                                    </div>
                                }} style={{ width: '10%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                <Column field="valeurNonlivreCli" header="Non livré" body={(rowData, column) => {
                                    return <div>
                                        <span >{this.formaterMontant(rowData.valeurNonlivreCli)}</span>
                                    </div>
                                }} style={{ width: '10%' }} filter={true} filterMatchMode='contains' sortable={true} />
                            </DataTable>

                        </div>
                    </div>
                </div>
            </div>
        );
    }
}