import React, { Component } from 'react';
import { Xulaservice } from '../service/Xulaservice';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import Numeral from 'numeral';
import { SplitButton } from 'primereact/splitbutton';
import './styles.css';
import { ProgressSpinner } from 'primereact/progressspinner';
import { FormEdition_Commande } from './FormEdition_Commande';
import { Dialog } from 'primereact/dialog';
import { Panel } from 'primereact/panel';

export class FormEdition_Balance_four extends Component {
    constructor() {
        super();
        this.state = {
            liste_four: [],
            selectedFour: null,
            idFour: null,
            showindicator: false,
            visible_facture: false,
            total_facture: '0',
            total_regle: '0',
            total_restant: '0',
            items: [
                {
                    label: 'Factures en PDF',
                    icon: 'pi pi-print',
                    command: (e) => {
                        this.conf_print('PDF');
                    }
                },
                {
                    label: 'Factures en XLS',
                    icon: 'pi pi-print',
                    command: (e) => {
                        this.conf_print('XLS');
                    }
                }
            ],
            userconnected: JSON.parse(localStorage.getItem('userconnected')),
        };
        this.conf_print = this.conf_print.bind(this);
        this.show_dlg_facture = this.show_dlg_facture.bind(this);
        this.xulaservice = new Xulaservice();
    }



    show_dlg_facture(x) {
        let four = { value: x.idFour.idFour, label: x.idFour.nomFour, typeFour: x.idFour.typeFour, tvaFour: x.idFour.tvaFour };
        this.setState({ idFour: four }, () => {
            this.setState({ visible_facture: true });
        });
    }

    conf_print(format) {
        this.xulaservice.generer_edition_balance_four(format);
    }

    componentDidMount() {
        this.get_liste_four();
    }

    get_liste_four() {
        this.setState({ showindicator: true });
        this.setState({ total_regle: '0' });
        this.setState({ total_restant: '0' });
        this.setState({ total_facture: '0' });
        this.xulaservice.get_balance_four().then(data => {
            this.setState({ liste_four: data, showindicator: false }, () => {
                if (data != null && data != undefined) {
                    let initialValue = 0
                    let tt_facture = data.reduce(function (accumulator, currentValue) {
                        return accumulator + currentValue.mtcmde
                    }, initialValue);
                    let tt_regle = data.reduce(function (accumulator, currentValue) {
                        return accumulator + currentValue.mtregl
                    }, initialValue);
                    let tt_restant = data.reduce(function (accumulator, currentValue) {
                        return accumulator + currentValue.mtrest
                    }, initialValue);

                    this.setState({ total_regle: this.formaterMontant(tt_regle) });
                    this.setState({ total_restant: this.formaterMontant(tt_restant) });
                    this.setState({ total_facture: this.formaterMontant(tt_facture) });
                    
                }
            });
        });
    }
    formaterMontant(montant) {
        return Numeral(parseFloat(montant)).format('0,0');
    }


    actionTemplate(rowData, column) {
        return <div className="p-grid" style={{ width: 90 }}>
            <div className="p-col"><Button onClick={(e) => this.show_dlg_facture(rowData)} className="p-button-info" style={{ width: 25, height: 25 }} icon="pi pi-search" /></div>
        </div>
    }



    render() {
      
        return (
            <div className="p-fluid">
                <Dialog header="Factures" visible={this.state.visible_facture} style={{ width: '95%' }} modal={true} onHide={(e) => this.setState({ visible_facture: false })}>
                    <FormEdition_Commande four={this.state.idFour} />
                </Dialog>

                <div className="p-grid">
                    <div className="p-col-12">
                        <div className="card card-w-title" style={{ minHeight: '800px' }}>
                            <Panel style={{ backgroundColor: '#f7f7f7' }}>
                                <div className="p-grid">
                                    <div className="p-col-12 p-md-2">
                                        <div className="p-grid" style={{ width: 300 }}>
                                            <div className="p-col">
                                                <SplitButton label="Rechercher" style={{ width: '140px' }} onClick={(e) => this.get_liste_four()} icon="pi pi-search" model={this.state.items} />
                                            </div>
                                            <div className="p-col">{this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : null}</div>
                                        </div>
                                    </div>
                                    <div className="p-col-12 p-md-1">
                                        <label style={{ fontSize: 16 }}>Total Facture</label>
                                    </div>
                                    <div className="p-col-12 p-md-2">
                                        <label style={{ fontSize: 16, fontWeight: 'bold', color: 'black'}}> {this.state.total_facture} </label>
                                    </div>
                                    <div className="p-col-12 p-md-1">
                                        <label style={{ fontSize: 16 }}>Total reglé</label>
                                    </div>
                                    <div className="p-col-12 p-md-3">
                                        <label style={{ fontSize: 16, fontWeight: 'bold', color:'black' }}> {this.state.total_regle} </label>
                                    </div>
                                    <div className="p-col-12 p-md-1">
                                        <label style={{ fontSize: 16 }}>Total restant</label>
                                    </div>
                                    <div className="p-col-12 p-md-2">
                                        <label style={{ fontSize: 16, fontWeight: 'bold', color: 'red' }}> {this.state.total_restant} </label>
                                    </div>
                                </div>
                            </Panel>
                            <DataTable value={this.state.liste_four} 
                                responsive={true} scrollable={true} scrollHeight="700px" selectionMode="single"
                                onRowClick={e => this.setState({ selectedFour: e.data })}
                                responsive={true} selection={this.state.selectedFour}>
                                <Column header="#" body={this.actionTemplate.bind(this)} style={{ width: '5%' }} />
                                <Column field="nomfour" header="Fournisseur" filter={true} filterMatchMode='contains' sortable={true} style={{ width: '20%' }} />
                                <Column field="telfour" header="Téléphone" filter={true} filterMatchMode='contains' sortable={true} style={{ width: '10%' }} />
                                <Column field="adrfour" header="Adresse" filter={true} filterMatchMode='contains' sortable={true} style={{ width: '20%' }} />
                                <Column field="mtcmde" header="Total facture" body={(rowData, column) => {
                                    return <div>
                                        <span >{this.formaterMontant(rowData.mtcmde)}</span>
                                    </div>
                                }} style={{ width: '15%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                <Column field="mtregl" header="Total reglé" body={(rowData, column) => {
                                    return <div>
                                        <span >{this.formaterMontant(rowData.mtregl)}</span>
                                    </div>
                                }} style={{ width: '15%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                <Column field="mtrest" header="Total restant" body={(rowData, column) => {
                                    return <div>
                                        <span style={{ color: 'red', fontWeight: 'bold' }}>{this.formaterMontant(rowData.mtrest)}</span>
                                    </div>
                                }} style={{ width: '15%' }} filter={true} filterMatchMode='contains' sortable={true} />
                               
                            </DataTable>

                        </div>
                    </div>
                </div>
            </div>
        );
    }
}