import React, { Component } from 'react';
import { Xulaservice } from '../service/Xulaservice';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dialog } from 'primereact/dialog';
import { Growl } from 'primereact/growl';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { ProgressSpinner } from 'primereact/progressspinner';
import Numeral from 'numeral';
import './styles.css';
import { InputText } from 'primereact/inputtext';
import 'moment';
import 'moment/locale/fr';
import moment from 'moment';
import { Panel } from 'primereact/panel';
import { Calendar } from 'primereact/calendar';
import { SplitButton } from 'primereact/splitbutton';

export class FormPaye extends Component {
    constructor() {
        super();
        this.state = {
            pd1: new Date(),
            pd2: new Date(),
            moisPaye: null,
            anneePaye: '',
            idBoutiq: null,
            ridBoutiq: null,
            rmatrPers: null,
            liste_paye: [],
            liste_paye_for_maj_selecteds: [],
            liste_paye_for_maj: [],
            total_salaire: '0',
            total_bon: '0',
            total_prime: '0',
            lboutique: [],
            lboutique_rech: [],
            liste_boutique: [],
            operation: 'Validation',
            liste_mois: [
                { label: 'Janvier', value: '01' },
                { label: 'Février', value: '02' },
                { label: 'Mars', value: '03' },
                { label: 'Avril', value: '04' },
                { label: 'Mai', value: '05' },
                { label: 'Juin', value: '06' },
                { label: 'Juillet', value: '07' },
                { label: 'Aout', value: '08' },
                { label: 'Septembre', value: '09' },
                { label: 'Octobre', value: '10' },
                { label: 'Novembre', value: '11' },
                { label: 'Décembre', value: '12' }
            ],
            itemsOp: [
                {
                    label: 'Validation',
                    icon: 'pi pi-check',
                    command: (e) => {
                        this.setState({ operation: 'Validation' }, () => {
                            this.show_dlg_paye();
                        })
                    }
                },
                {
                    label: 'Annulation',
                    icon: 'pi pi-ban',
                    command: (e) => {
                        this.setState({ operation: 'Annulation' }, () => {
                            this.show_dlg_paye();
                        })
                    }
                }
            ],
            items: [
                {
                    label: 'Etat de paye en PDF',
                    icon: 'pi pi-print',
                    command: (e) => {
                        this.generer_liste_paye('PDF');
                    }
                },
                {
                    label: 'Etat de paye en XLS',
                    icon: 'pi pi-print',
                    command: (e) => {
                        this.generer_liste_paye('XLS');
                    }
                },
                {
                    label: 'Bulletins de paye',
                    icon: 'pi pi-print',
                    command: (e) => {
                        this.conf_print_bulletin(null);
                    }
                }
            ],
            selectedPaye: null,
            msg: '',
            modif: false,
            visible: false,
            visible_dlg_paye: false,
            showindicator: false,
            userconnected: JSON.parse(localStorage.getItem('userconnected')),
        };

        this.conf_delete_paye = this.conf_delete_paye.bind(this);
        this.conf_print_bulletin = this.conf_print_bulletin.bind(this);
        this.generer_liste_paye = this.generer_liste_paye.bind(this);
        this.update_etat_data = this.update_etat_data.bind(this);
        this.show_dlg_paye = this.show_dlg_paye.bind(this);
        this.xulaservice = new Xulaservice();
    }



    formaterMontant(montant) {
        return Numeral(parseFloat(montant)).format('0,0');
    }

    formaterMois(mois) {
        let m = this.state.liste_mois.filter(function (el) {
            return el.value === mois;
        })[0];
        return m.label;
    }

    componentDidMount() {
        this.get_liste_paye('Tous');
        this.setState({ anneePaye: moment(new Date()).format('YYYY') });
        let mois = this.state.liste_mois.filter(function (el) {
            return el.value == moment(new Date()).format('MM');
        })[0];
        this.setState({ moisPaye: mois });
        this.get_liste_boutique();
    }

    update_etat_data(rowData) {
        rowData.statutPaye = 'Validé';
        rowData.iduser = this.state.userconnected.idUser;
        this.xulaservice.update_paye(rowData).then(data => this.resultat(data.code, data.contenu));
    }

    get_liste_boutique() {
        let lboutique = [{ value: '-1', label: 'Chaque boutique' }];
        let lboutique_rech = [{ value: 'Tous', label: 'Boutique' }];
        this.xulaservice.getListe_boutique().then(data => {
            if (data != null && data != undefined) {
                let liste = data.map(x => {
                    return { value: x.idBoutiq, label: x.nomBoutiq }
                });
                lboutique = lboutique.concat(liste);
                lboutique_rech = lboutique_rech.concat(liste);
                this.setState({ lboutique_rech: lboutique_rech })
            }
            this.setState({ lboutique: lboutique }, () => {
                this.setState({ idBoutiq: { value: this.state.userconnected.matrPers.idBoutiq.idBoutiq, label: this.state.userconnected.matrPers.idBoutiq.nomBoutiq } });
            })
        });
    }

    conf_print_bulletin(idPaye) {
        let ridPaye = 'Tous';
        if (idPaye !== undefined && idPaye !== null) {
            ridPaye = idPaye;
        }
        let rmatr_pers = 'Tous';
        let rid_boutique = 'Tous';
        let spd1 = moment(this.state.pd1, 'DD/MM/YYYY').format('DD/MM/YYYY');
        let spd2 = moment(this.state.pd2, 'DD/MM/YYYY').format('DD/MM/YYYY');
        if (this.state.ridBoutiq != undefined && this.state.ridBoutiq != null) {
            rid_boutique = this.state.ridBoutiq.value;
        }
        if (this.state.rmatrPers != undefined && this.state.rmatrPers != null && this.state.rmatrPers != '') {
            rmatr_pers = this.state.rmatrPers.value;
        }
        this.xulaservice.generer_edition_bulletin(spd1, spd2, rid_boutique, rmatr_pers, 'Tous', ridPaye);
    }

    generer_liste_paye(format) {
        let rmatr_pers = 'Tous';
        let rid_boutique = 'Tous';
        let spd1 = moment(this.state.pd1, 'DD/MM/YYYY').format('DD/MM/YYYY');
        let spd2 = moment(this.state.pd2, 'DD/MM/YYYY').format('DD/MM/YYYY');
        if (this.state.ridBoutiq != undefined && this.state.ridBoutiq != null) {
            rid_boutique = this.state.ridBoutiq.value;
        }
        if (this.state.rmatrPers != undefined && this.state.rmatrPers != null && this.state.rmatrPers != '') {
            rmatr_pers = this.state.rmatrPers.value;
        }
        this.xulaservice.generer_liste_paye(spd1, spd2, rid_boutique, rmatr_pers, 'Tous', format);
    }


    get_liste_paye(statutPaye) {

        let rmatr_pers = 'Tous';
        let rid_boutique = 'Tous';
        this.setState({ showindicator: true });
        let spd1 = moment(this.state.pd1, 'DD/MM/YYYY').format('DD/MM/YYYY');
        let spd2 = moment(this.state.pd2, 'DD/MM/YYYY').format('DD/MM/YYYY');
        if (this.state.ridBoutiq != undefined && this.state.ridBoutiq != null) {
            rid_boutique = this.state.ridBoutiq.value;
        }
        if (this.state.rmatrPers != undefined && this.state.rmatrPers != null && this.state.rmatrPers != '') {
            rmatr_pers = this.state.rmatrPers.value;
        }

        this.xulaservice.get_liste_paye(spd1, spd2, rid_boutique, rmatr_pers, statutPaye).then(data => {
            if (this.state.visible_dlg_paye === true) {
                this.setState({ liste_paye_for_maj: data, showindicator: false });
            } else {
                this.setState({ liste_paye: data, showindicator: false });
            }
            if (data != null && data != undefined) {
                let initialValue = 0
                let tt_salaire = data.reduce(function (accumulator, currentValue) {
                    return accumulator + currentValue.mtPaye
                }, initialValue);
                let tt_bon = data.reduce(function (accumulator, currentValue) {
                    return accumulator + currentValue.totalBon
                }, initialValue);
                let tt_prime = data.reduce(function (accumulator, currentValue) {
                    return accumulator + currentValue.totalPrime
                }, initialValue);
                this.setState({ total_salaire: this.formaterMontant(tt_salaire) });
                this.setState({ total_bon: this.formaterMontant(tt_bon) });
                this.setState({ total_prime: this.formaterMontant(tt_prime) });
            }
        });
    }


    resultat(code, contenu) {
        if (code == '200') {
            this.growl.show({ severity: 'success', summary: 'Success', detail: contenu });
            this.setState({ total_salaire: '0' });
            if (this.state.visible_dlg_paye === true) {
                if (this.state.operation == 'Validation') {
                    this.get_liste_paye('En attente');
                } else {
                    this.get_liste_paye('Tous');
                }
            } else {
                this.get_liste_paye('Tous');
            }

        } else {
            this.growl.show({ severity: 'error', summary: 'Error', detail: contenu });
        }
        this.setState({ showindicator: true });
    }

    generer_paye() {
        if (this.state.moisPaye === '' || this.state.moisPaye === undefined || this.state.anneePaye === '' || this.state.anneePaye === undefined || this.state.idBoutiq === undefined || this.state.idBoutiq === null) {
            this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez saisir tous les champs obligatoires' });
        } else if (parseFloat(this.state.anneePaye) < 2020) {
            this.growl.show({ severity: 'warn', summary: 'Attention', detail: "Veuillez vérifier l'année" });
        } else {
            this.setState({ showindicator: true });
            this.xulaservice.generer_paye(this.state.moisPaye.value, this.state.anneePaye, this.state.idBoutiq.value).then(data => this.resultat(data.code, data.contenu));
        }
    }

    actionTemplate(rowData, column) {
        let disable_val = true;
        let disable_supr = true;
        let disable_bulletin = true;
        if (rowData.statutPaye === 'En attente' && this.state.userconnected.profilUser === 'PDG') {
            disable_val = false;
            disable_supr = false;
        }

        if (rowData.statutPaye === 'Validé' && (this.state.userconnected.profilUser === 'PDG' || this.state.userconnected.profilUser === 'Coordinateur' || this.state.userconnected.profilUser === 'DRH')) {
            disable_bulletin = false;
        }

        if (rowData.statutPaye === 'Validé' && (this.state.userconnected.profilUser === 'PDG')) {
            disable_bulletin = false;
            disable_supr = false;
        }

        return <div className="p-grid" style={{ width: 120 }}>
            <div className="p-col"><Button disabled={disable_val} title="Valider" onClick={(e) => this.update_etat_data(rowData)} className="p-button-success" style={{ width: 25, height: 25 }} icon="pi pi-check" /></div>
            <div className="p-col"><Button disabled={disable_supr} title="Supprimer" onClick={(e) => this.conf_delete_paye(rowData)} className="p-button-danger" style={{ width: 25, height: 25, marginLeft: -8 }} icon="pi pi-times" /></div>
            <div className="p-col"><Button disabled={disable_bulletin} title="Bulletin" onClick={(e) => this.conf_print_bulletin(rowData.idPaye)} className="p-button-warning" style={{ width: 25, height: 25, marginLeft: -8 }} icon="pi pi-print" /></div>
        </div>
    }

    delete_data() {
        if (this.state.selectedPaye == null || this.state.selectedPaye == undefined) {
            this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez sélectionner la ligne à supprimer' });
        } else {
            this.setState({ showindicator: true });
            this.xulaservice.delete_paye(this.state.selectedPaye).then(data => this.resultat(data.code, data.contenu));
        }
    }

    annule_paye_batch() {
        if (this.state.liste_paye_for_maj_selecteds == null || this.state.liste_paye_for_maj_selecteds == undefined) {
            this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez sélectionner les lignes à supprimer' });
        } else {
            this.setState({ showindicator: true });
            this.xulaservice.delete_paye_batch(this.state.liste_paye_for_maj_selecteds).then(data => this.resultat(data.code, data.contenu));
        }
    }

    update_paye_batch() {
        if (this.state.liste_paye_for_maj_selecteds == null || this.state.liste_paye_for_maj_selecteds == undefined) {
            this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez sélectionner les lignes à supprimer' });
        } else {
            let liste = this.state.liste_paye_for_maj_selecteds;
            for (var i = liste.length; i--;) {
                liste[i].statutPaye = 'Validé';
                liste[i].iduser = this.state.userconnected.idUser;
            }
            this.setState({ showindicator: true });
            this.xulaservice.update_paye_batch(liste).then(data => this.resultat(data.code, data.contenu));
        }
    }

    conf_delete_paye(data) {
        if (data != null && data != undefined) {
            this.setState({ selectedPaye: data });
            this.setState({ msg: 'la ligne sélectionnée ' });
            this.setState({ visible: true });
        }
    }

    show_dlg_paye() {
        this.setState({ visible_dlg_paye: true }, () => {
            if (this.state.operation == 'Validation') {
                this.get_liste_paye('En attente');
            } else {
                this.get_liste_paye('Tous');
            }
        });
    }

    render() {
        let disable_btn_operation = true;
        if (this.state.userconnected.profilUser === 'PDG' || this.state.userconnected.profilUser === 'Coordinateur') {
            disable_btn_operation = false;
        }
        const dialogFooter = (
            <div>
                <Button icon="pi pi-check" onClick={() => {
                    this.setState({ visible: false });
                    this.delete_data();
                }} label="Oui" />
                <Button icon="pi pi-times" onClick={() => this.setState({ visible: false })} label="Annuler" className="p-button-secondary" />
            </div>
        );
        const dialogFooter_dlg = (
            <div>
                <Button icon="pi pi-times" onClick={() => this.setState({ visible_dlg_paye: false })} label="Annuler" className="p-button-secondary" />
            </div>
        );

        const footer_paye_for_maj = <label style={{ color: 'red', fontSize: '22px', fontWeight: 'bold' }} htmlFor="in">TOTAL = {this.state.total_salaire}</label>

        return (
            <div className="p-fluid">
                <Growl ref={(el) => this.growl = el} />
                <Dialog header="Confirmation" visible={this.state.visible} style={{ width: '250px' }} modal={true} footer={dialogFooter} onHide={(e) => this.setState({ visible: false })}>
                    <div>
                        <i className="fa fa-warning" style={{ color: '#FFD133', fontSize: 25 }}></i>
                        Voulez vous vraiment supprimer <b>{this.state.msg} </b>?
                    </div>
                </Dialog>

                <Dialog header={this.state.operation == 'Validation' ? "Validation" : "Annulation"} visible={this.state.visible_dlg_paye} style={{ width: '90%' }} modal={true} onHide={(e) => {
                    this.setState({ visible_dlg_paye: false }, () => {
                        this.get_liste_paye('Tous');
                    });
                }} >
                    <div className="p-grid">
                        <div className="p-col-12">
                            <div className="card card-w-title" style={{ height: 450 }}>
                                <DataTable value={this.state.liste_paye_for_maj} scrollable={true} scrollHeight="300px"
                                    onSelectionChange={e => {
                                        this.setState({ total_salaire: '0' });
                                        this.setState({ liste_paye_for_maj_selecteds: e.value }, () => {
                                            let total = 0;
                                            if (this.state.liste_paye_for_maj_selecteds != null && this.state.liste_paye_for_maj_selecteds != undefined) {
                                                if (this.state.liste_paye_for_maj_selecteds.length > 0) {
                                                    for (var i = this.state.liste_paye_for_maj_selecteds.length; i--;) {
                                                        total += this.state.liste_paye_for_maj_selecteds[i].mtPaye;
                                                    }
                                                }
                                            }
                                            this.setState({ total_salaire: this.formaterMontant(total) });
                                        });
                                    }
                                    } footer={footer_paye_for_maj}
                                    responsive={true} style={{ marginBottom: 12 }} selection={this.state.liste_paye_for_maj_selecteds}>
                                    <Column selectionMode="multiple" style={{ width: '5%' }} />
                                    <Column field="datePaye" header="Date" style={{ width: '9%' }} filter={true} filterMatchMode='contains' />
                                    <Column field="mois" header="Mois" style={{ width: '6%' }} filter={true} filterMatchMode='contains' />
                                    <Column body={(rowData, column) => {
                                        return <div>
                                            <span style={{ color: 'green', fontWeight: 'bold' }}>{this.formaterMontant(rowData.mtPaye)}</span>
                                        </div>
                                    }} header="Salaire net" style={{ width: '10%' }} filter={true} filterMatchMode='contains' />
                                    <Column field="nomPers" header="Personnel" style={{ width: '17%' }} filter={true} filterMatchMode='contains' />
                                    <Column field="nomBoutiqPers" header="Boutique" style={{ width: '13%' }} filter={true} filterMatchMode='contains' />
                                    <Column body={(rowData, column) => {
                                        return <div>
                                            <span style={{ color: '#000000', fontWeight: 'bold' }}>{this.formaterMontant(rowData.salaireBase)}</span>
                                        </div>
                                    }} header="Salaire base" style={{ width: '10%' }} filter={true} filterMatchMode='contains' />
                                    <Column body={(rowData, column) => {
                                        return <div>
                                            <span style={{ color: '#000000', fontWeight: 'bold' }}>{this.formaterMontant(rowData.salaireBase + rowData.totalPrime)}</span>
                                        </div>
                                    }} header="Salaire brut" style={{ width: '10%' }} filter={true} filterMatchMode='contains' />
                                    <Column body={(rowData, column) => {
                                        return <div>
                                            <span style={{ color: 'red', fontWeight: 'bold' }}>{this.formaterMontant(rowData.totalBon)}</span>
                                        </div>
                                    }} header="Total bon" style={{ width: '10%' }} filter={true} filterMatchMode='contains' />
                                </DataTable>
                            </div>
                            <center>
                                <div className="p-grid" style={{ width: 300 }}>
                                    {this.state.operation == 'Annulation' ?
                                        <div className="p-col"><Button label="Supprimer" className="p-button-danger" disabled={this.state.liste_paye_for_maj_selecteds.length === 0} icon="pi pi-ban" onClick={(e) => this.annule_paye_batch()} /></div>
                                        : null}
                                    {this.state.operation == 'Validation' ?
                                        <div className="p-col"><Button label="Valider" className="p-button-success" disabled={this.state.liste_paye_for_maj_selecteds.length === 0} icon="pi pi-check" onClick={(e) => this.update_paye_batch()} /></div>
                                        : null}
                                </div>
                                {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : null}
                            </center>
                        </div>
                    </div>
                </Dialog>




                <div className="p-grid">
                    <div className="p-col-12">
                        <div className="card card-w-title" style={{ minHeight: '700px' }}>
                            <Panel style={{ padding: 8, marginBottom: 5, backgroundColor: '#f5f8f9', border: '1px solid #C2C4C4', }}>
                                <div className="p-grid">
                                    <div className="p-col-12 p-md-1">
                                        <label htmlFor="nomCli">Mois *</label>
                                    </div>
                                    <div className="p-col-12 p-md-2">
                                        <Dropdown value={this.state.moisPaye} options={this.state.liste_mois} optionLabel='label' filter={true} filterBy='label' onChange={(e) => this.setState({ moisPaye: e.value })} autoWidth={false} style={{ width: '100%' }} />
                                    </div>
                                    <div className="p-col-12 p-md-1">
                                        <label htmlFor="adrCli">Année *</label>
                                    </div>
                                    <div className="p-col-12 p-md-2">
                                        <InputText keyfilter="pint" value={this.state.anneePaye} onChange={(e) => this.setState({ anneePaye: e.target.value })} />
                                    </div>
                                    <div className="p-col-12 p-md-2">
                                        <label >Boutique débiteur</label>
                                    </div>
                                    <div className="p-col-12 p-md-2">
                                        <Dropdown options={this.state.lboutique} value={this.state.idBoutiq} optionLabel='label' filter={true} filterBy='label' filterMatchMode='contains'
                                            onChange={(e) => {
                                                this.setState({ idBoutiq: e.value });
                                            }} autoWidth={false} style={{ width: '100%' }} />
                                    </div>
                                    <div className="p-col-12 p-md-1">
                                        <div className="p-inputgroup">
                                            <Button icon='pi pi-check' label='Générer' style={{ width: '150px' }} onClick={(e) => this.generer_paye()} />
                                            {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : null}
                                        </div>
                                    </div>
                                </div>
                            </Panel>
                            <i className="fa fa-fw fa-search"></i>Options de recherche
                            <hr />
                            <div className="p-grid">
                                <div className="p-col-12">
                                    <div className="p-grid">
                                        <div className="p-col-12 p-md-2">
                                            <Calendar value={this.state.pd1} dateFormat="dd/mm/yy" onChange={(e) => this.setState({ pd1: e.value })} />
                                        </div>
                                        <div className="p-col-12 p-md-2">
                                            <Calendar value={this.state.pd2} dateFormat="dd/mm/yy" onChange={(e) => this.setState({ pd2: e.value })} />
                                        </div>
                                        <div className="p-col-12 p-md-3">
                                            <Dropdown options={this.state.lboutique_rech} placeholder="Boutique" value={this.state.ridBoutiq} optionLabel='label' filter={true} filterBy='label' filterMatchMode='contains' onChange={(e) => this.setState({ ridBoutiq: e.value }, () => {
                                                this.get_liste_paye('Tous');
                                            })} autoWidth={false} />
                                        </div>
                                        <div className="p-col-12 p-md-2">
                                            <div className="p-inputgroup">
                                                <SplitButton label="Rechercher" style={{ width: '140px' }} onClick={(e) => this.get_liste_paye()} icon="pi pi-search" model={this.state.items}></SplitButton>
                                                {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : null}
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>

                            <Panel style={{ backgroundColor: '#f7f7f7' }}>
                                <div className="p-grid">

                                    {disable_btn_operation == false ?
                                        <div className="p-col-12 p-md-2">
                                            <div style={{ textAlign: 'left' }}>
                                                {disable_btn_operation == false ?
                                                    <SplitButton label="Opération" style={{ width: '160px' }} onClick={(e) => {
                                                        this.setState({ operation: 'Validation' }, () => {
                                                            this.show_dlg_paye();
                                                        })
                                                    }} icon="pi pi-list" className="p-button-warning" model={this.state.itemsOp} />
                                                    : null}
                                            </div>
                                        </div>
                                        : null}

                                    <div className="p-col-12 p-md-1">
                                        <label style={{ fontSize: 16 }}>Total bon</label>
                                    </div>
                                    <div className="p-col-12 p-md-2">
                                        <label style={{ fontSize: 16, fontWeight: 'bold', color: 'red' }}> {this.state.total_bon} </label>
                                    </div>
                                    <div className="p-col-12 p-md-1">
                                        <label style={{ fontSize: 16 }}>Total prime</label>
                                    </div>
                                    <div className="p-col-12 p-md-3">
                                        <label style={{ fontSize: 16, fontWeight: 'bold', color: 'black' }}> {this.state.total_prime} </label>
                                    </div>
                                    <div className="p-col-12 p-md-1">
                                        <label style={{ fontSize: 16 }}>Total salaire</label>
                                    </div>
                                    <div className="p-col-12 p-md-2">
                                        <label style={{ fontSize: 16, fontWeight: 'bold', color: 'black' }}> {this.state.total_salaire} </label>
                                    </div>
                                </div>
                            </Panel>
                            <DataTable value={this.state.liste_paye}
                                responsive={true} scrollable={true} scrollHeight="520px" selectionMode="single"
                                onRowClick={e => this.setState({ selectedPaye: e.data })}
                                selection={this.state.selectedPaye}>
                                <Column header="#" body={this.actionTemplate.bind(this)} style={{ width: '10%' }} />
                                <Column field="datePaye" header="Date" style={{ width: '8%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                <Column field="mois" header="Mois" style={{ width: '6%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                <Column field="mtPaye" body={(rowData, column) => {
                                    return <div>
                                        <span style={{ color: 'green', fontWeight: 'bold' }}>{this.formaterMontant(rowData.mtPaye)}</span>
                                    </div>
                                }} header="Salaire net" style={{ width: '10%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                <Column field="nomPers" header="Personnel" body={(rowData, column) => {
                                    return <div>
                                        <span style={{ color: '#000000', fontWeight: 'bold' }}>{rowData.nomPers}</span><br />
                                        <span style={{ color: '#F34C1F', fontWeight: 'bold' }}>{rowData.nomBoutiqPers}</span><br />
                                    </div>
                                }} style={{ width: '18%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                <Column field="nomBoutiqDebiteur" header="Boutique" style={{ width: '10%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                <Column field="salaireBase" body={(rowData, column) => {
                                    return <div>
                                        <span style={{ color: '#000000', fontWeight: 'bold' }}>{this.formaterMontant(rowData.salaireBase)}</span>
                                    </div>
                                }} header="Salaire base" style={{ width: '10%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                <Column field="salaireBase" body={(rowData, column) => {
                                    return <div>
                                        <span style={{ color: '#000000', fontWeight: 'bold' }}>{this.formaterMontant(rowData.salaireBase + rowData.totalPrime)}</span>
                                    </div>
                                }} header="Salaire brut" style={{ width: '10%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                <Column field="totalBon" body={(rowData, column) => {
                                    return <div>
                                        <span style={{ color: 'red', fontWeight: 'bold' }}>{this.formaterMontant(rowData.totalBon)}</span>
                                    </div>
                                }} header="Total bon" style={{ width: '10%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                <Column field="statutPaye" header="Etat" style={{ width: '8%' }} filter={true} filterMatchMode='contains' sortable={true} />
                            </DataTable>

                        </div>
                    </div>
                </div>
            </div>
        );
    }
}