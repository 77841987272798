import React, { Component } from 'react';
import { Xulaservice } from '../service/Xulaservice';
import { Calendar } from 'primereact/calendar';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Growl } from 'primereact/growl';
import './styles.css';
import { SplitButton } from 'primereact/splitbutton';
import 'moment';
import 'moment/locale/fr';
import moment from 'moment';
import { ProgressSpinner } from 'primereact/progressspinner';
import Numeral from 'numeral';
import { ScrollPanel } from 'primereact/scrollpanel';


export class FormEdition_situation_boutique extends Component {
    constructor() {
        super();
        this.state = {
            pd1: new Date(),
            pd2: new Date(),
            liste_data: [],
            items: [
                {
                    label: 'Exporter',
                    icon: 'pi pi-print',
                    command: (e) => {
                        this.dt.exportCSV();
                    }
                }
            ],
            userconnected: JSON.parse(localStorage.getItem('userconnected'))
        };
        this.myservice = new Xulaservice();
    }

    componentDidMount() {
        this.get_liste_data();
    }

    formaterMontant(montant) {
        return Numeral(parseFloat(montant)).format('0,0');
    }


    get_liste_data() {

        this.setState({ total: '0' });
        this.setState({ showindicator: true, liste_data: [] });
        let rboutique = 'Tous';
        let spd1 = moment(this.state.pd1).format('DD-MM-YYYY');
        let spd2 = moment(this.state.pd2).format('DD-MM-YYYY');
        if (this.state.ridBoutiq != undefined || this.state.ridBoutiq != null) {
            rboutique = this.state.ridBoutiq.value;
        }

        this.myservice.get_situation_boutique(spd1, spd2).then(data => this.setState({ liste_data: data, showindicator: false }, () => {
            let initialValue = 0
            if (data != null && data != undefined) {
                let tt = data.reduce(function (accumulator, currentValue) {
                    return accumulator + currentValue.dval
                }, initialValue);
                this.setState({ total: this.formaterMontant(tt) });
            }
        }));
    }



    render() {



        const footer = <div style={{ textAlign: 'left' }}>
            <label style={{ fontWeight: 'bold', fontSize: 18 }} >{this.state.total}</label>
        </div>;

        return (
            <div className="p-fluid">
                <Growl ref={(el) => this.growl = el} />
                <div className="p-grid">
                    <div className="p-col-12">
                        <div className="card card-w-title" style={{ height: 700 }}>
                            <i className="fa fa-fw fa-search"></i>Option de recherche
                            <hr />
                            <div className="p-grid">
                                <div className="p-col-12">
                                    <div className="p-grid">
                                        <div className="p-col-12 p-md-2">
                                            <Calendar value={this.state.pd1}  dateFormat="dd/mm/yy" onChange={(e) => this.setState({ pd1: e.value })} />
                                        </div>
                                        <div className="p-col-12 p-md-2">
                                            <Calendar value={this.state.pd2}  dateFormat="dd/mm/yy" onChange={(e) => this.setState({ pd2: e.value })} />
                                        </div>
                                        <div className="p-col-12 p-md-2">
                                            <div className="p-grid" style={{ width: 200 }}>
                                                <div className="p-col"><SplitButton label="Rechercher" style={{ width: '140px' }} onClick={(e) => this.get_liste_data()} icon="pi pi-search" model={this.state.items}></SplitButton></div>
                                                <div className="p-col">  {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : null}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <ScrollPanel style={{ width: '100%' }} className="custom">
                                <DataTable value={this.state.liste_data} scrollable={true} scrollHeight="400px"
                                    responsive={true} ref={(el) => { this.dt = el; }} style={{ width: '170%' }}>
                                    <Column field="nomBoutiq" header="Boutique" filter={true} filterMatchMode='contains' />
                                    <Column field="mt_vente" body={(rowData, column) => {
                                        return <div>
                                            <span style={{ color: '#000' }}>{this.formaterMontant(rowData.mt_vente)}</span>
                                        </div>
                                    }} header="Total vente" filter={true} filterMatchMode='contains' sortable={true} style={{ width: '6%' }} />
                                    <Column field="mt_vente_intermag" body={(rowData, column) => {
                                        return <div>
                                            <span style={{ color: '#000' }}>{this.formaterMontant(rowData.mt_vente_intermag)}</span>
                                        </div>
                                    }} header="Vente Inter" filter={true} filterMatchMode='contains' sortable={true} style={{ width: '6%' }} />
                                    <Column field="val_livr_intermag_jour" body={(rowData, column) => {
                                        return <div>
                                            <span style={{ color: '#000' }}>{this.formaterMontant(rowData.val_livr_intermag_jour)}</span>
                                        </div>
                                    }} header="Livr. Inter" filter={true} filterMatchMode='contains' sortable={true} style={{ width: '6%' }} />

                                    <Column field="mt_dep" body={(rowData, column) => {
                                        return <div>
                                            <span style={{ color: '#000' }}>{this.formaterMontant(rowData.mt_dep)}</span>
                                        </div>
                                    }} header="Dépenses" filter={true} filterMatchMode='contains' sortable={true} style={{ width: '6%' }} />
                                    <Column field="mt_rgl_dette" body={(rowData, column) => {
                                        return <div>
                                            <span style={{ color: '#000' }}>{this.formaterMontant(rowData.mt_rgl_dette)}</span>
                                        </div>
                                    }} header="Rgl. dette" filter={true} filterMatchMode='contains' sortable={true} style={{ width: '6%' }} />
                                    <Column field="mt_rgl_compte" body={(rowData, column) => {
                                        return <div>
                                            <span style={{ color: '#000' }}>{this.formaterMontant(rowData.mt_rgl_compte)}</span>
                                        </div>
                                    }} header="Rgl. compte" filter={true} filterMatchMode='contains' sortable={true} style={{ width: '6%' }} />
                                    <Column field="mt_rgl_jour" body={(rowData, column) => {
                                        return <div>
                                            <span style={{ color: '#000' }}>{this.formaterMontant(rowData.mt_rgl_jour)}</span>
                                        </div>
                                    }} header="Regl. jour" filter={true} filterMatchMode='contains' sortable={true} style={{ width: '6%' }} />
                                    <Column field="mt_rgl" body={(rowData, column) => {
                                        return <div>
                                            <span style={{ color: '#000' }}>{this.formaterMontant(rowData.mt_rgl)}</span>
                                        </div>
                                    }} header="Total regl." filter={true} filterMatchMode='contains' sortable={true} style={{ width: '6%' }} />

                                    <Column field="mt_fond_entrant" body={(rowData, column) => {
                                        return <div>
                                            <span style={{ color: '#000' }}>{this.formaterMontant(rowData.mt_fond_entrant)}</span>
                                        </div>
                                    }} header="Fd entrant" filter={true} filterMatchMode='contains' sortable={true} style={{ width: '6%' }} />
                                    <Column field="mt_fond_sortant" body={(rowData, column) => {
                                        return <div>
                                            <span style={{ color: '#000' }}>{this.formaterMontant(rowData.mt_fond_sortant)}</span>
                                        </div>
                                    }} header="Fd sortant" filter={true} filterMatchMode='contains' sortable={true} style={{ width: '6%' }} />
                                    <Column field="mt_depot" body={(rowData, column) => {
                                        return <div>
                                            <span style={{ color: '#000' }}>{this.formaterMontant(rowData.mt_depot)}</span>
                                        </div>
                                    }} header="Dépot" filter={true} filterMatchMode='contains' sortable={true} style={{ width: '6%' }} />
                                    <Column field="mt_retrait" body={(rowData, column) => {
                                        return <div>
                                            <span style={{ color: '#000' }}>{this.formaterMontant(rowData.mt_retrait)}</span>
                                        </div>
                                    }} header="Retrait" filter={true} filterMatchMode='contains' sortable={true} style={{ width: '6%' }} />

                                    <Column field="val_nl_jour" body={(rowData, column) => {
                                        return <div>
                                            <span style={{ color: '#000' }}>{this.formaterMontant(rowData.val_nl_jour)}</span>
                                        </div>
                                    }} header="Non livré" filter={true} filterMatchMode='contains' sortable={true} style={{ width: '6%' }} />
                                    <Column field="val_livr_du" body={(rowData, column) => {
                                        return <div>
                                            <span style={{ color: '#000' }}>{this.formaterMontant(rowData.val_livr_du)}</span>
                                        </div>
                                    }} header="Livraison dû" filter={true} filterMatchMode='contains' sortable={true} style={{ width: '6%' }} />
                                     <Column field="ttRetour" body={(rowData, column) => {
                                        return <div>
                                            <span style={{ color: '#000' }}>{this.formaterMontant(rowData.ttRetour)}</span>
                                        </div>
                                    }} header="Retour" filter={true} filterMatchMode='contains' sortable={true} style={{ width: '6%' }} />
                                 </DataTable>
                            </ScrollPanel>
                        </div>
                    </div>
                </div>
            </div>


        );
    }
}