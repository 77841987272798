import React, { Component } from 'react';
import { Xulaservice } from '../service/Xulaservice';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dialog } from 'primereact/dialog';
import { Growl } from 'primereact/growl';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import './styles.css';
import { Panel } from 'primereact/panel';
import { ProgressSpinner } from 'primereact/progressspinner';
import { SplitButton } from 'primereact/splitbutton';
import { InputText } from 'primereact/inputtext';
import Cleave from 'cleave.js/react';
import 'moment/locale/fr';
import moment from 'moment';
import { Calendar } from 'primereact/calendar';
import { OverlayPanel } from 'primereact/overlaypanel';

export class FormFidelisation extends Component {
    constructor() {
        super();
        this.state = {
            liste_fidelisation: [],
            liste_fidelisation_selected: [],
            liste_client: [],
            liste_categorie: [],
            selectedFid: null,
            idFid: '0',
            idCli: null,
            ridCli: null,
            idCatFid: null,
            actifFid: 'Oui',
            nomFid: '',
            msg: '',
            modif: false,
            visible: false,
            visible_print: false,
            rfiltreClient: '',
            pd1: new Date(),
            pd2: new Date(),
            items: [
                {
                    label: 'Edition les cartes',
                    icon: 'pi pi-print',
                    command: (e) => {
                        this.setState({ visible_print_ticket: false, titre_operation: 'EDITION DES CARTES' });
                    }
                },
                {
                    label: 'Edition ticket',
                    icon: 'pi pi-print',
                    command: (e) => {
                        this.get_liste_ticket();
                        this.setState({ visible_print_ticket: true, titre_operation: 'EDITION DES TICKETS' });
                    }
                }
            ],
            userconnected: JSON.parse(localStorage.getItem('userconnected')),
            liste_ligne_fid: [],
            liste_ligne_fid_adding: [],
            tauxLfid: 0,
            actifLfid: 'Oui',
            idArticle: null,
            debLfid: null,
            finLfid: null,
            modifLfid: true,
            visible_dlg_lfid: false,
            visible_dlg_ticket: false,
            selectedLfid: null,
            visible_print_ticket: false,
            liste_ticket_selected: [],
            liste_ticket: [],
            visible_ticket_genration: false,
            titre_operation: 'EDITION DES CARTES',
            showindicator: false,

        };
        this.edit = this.edit.bind(this);
        this.print_ticket = this.print_ticket.bind(this);
        this.export_carte = this.export_carte.bind(this);
        this.download_photos_clients = this.download_photos_clients.bind(this);
        this.print_carte = this.print_carte.bind(this);
        this.conf_delete = this.conf_delete.bind(this);
        this.toogle_print = this.toogle_print.bind(this);
        this.edit_lfid = this.edit_lfid.bind(this);

        this.inputTextEditor = this.inputTextEditor.bind(this);
        this.debLfidEditor = this.debLfidEditor.bind(this);
        this.finLfidEditor = this.finLfidEditor.bind(this);
        this.tauxLfidEditor = this.tauxLfidEditor.bind(this);
        this.show_ticket_generation = this.show_ticket_generation.bind(this);
        this.xulaservice = new Xulaservice();
    }


    inputTextEditor(props, field) {
        return <InputText value={props.rowData[field]} onChange={(e) => this.onEditorValueChange(props, e.target.value)} />;
    }


    onEditorValueChange(props, value) {
        let updateds = [...props.value];
        updateds[props.rowIndex][props.field] = value;
        this.setState({ liste_ligne_fid: updateds });
        this.forceUpdate();
    }

    debLfidEditor(props) {
        return this.inputTextEditor(props, 'debLfid');
    }

    finLfidEditor(props) {
        return this.inputTextEditor(props, 'finLfid');
    }

    tauxLfidEditor(props) {
        return this.inputTextEditor(props, 'tauxLfid');
    }

    print_carte() {
        if (this.state.liste_fidelisation_selected.length <= 0) {
            this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez sélectionner les lignes à imprimer' });
        } else {
            let ids = this.state.liste_fidelisation_selected.map(x => {
                return x.idFid
            }).join("__");
            this.xulaservice.generer_carte_fidelite(ids);
        }
    }

    export_carte() {
        if (this.state.liste_fidelisation_selected.length <= 0) {
            this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez sélectionner les lignes à imprimer' });
        } else {
            let ids = this.state.liste_fidelisation_selected.map(x => {
                return x.idFid
            }).join("__");
            this.xulaservice.export_carte_info(ids);
        }
    }

    download_photos_clients() {
        if (this.state.liste_fidelisation_selected.length <= 0) {
            this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez sélectionner les lignes à imprimer' });
        } else {
            let ids = this.state.liste_fidelisation_selected.map(x => {
                return x.idFid
            }).join("__");
            this.xulaservice.download_photos_clients(ids);
        }
    }


    print_ticket() {
        if (this.state.liste_ticket_selected.length <= 0) {
            this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez sélectionner les lignes à imprimer' });
        } else {
            let ids = this.state.liste_ticket_selected.map(x => {
                return x.idTicket
            }).join("__");
            this.xulaservice.editer_ticket_fidelite(ids);
        }
    }

    onIndexTemplate(data, props) {
        return props.rowIndex + 1;
    }

    generate_ticket() {
        if (this.state.liste_ligne_fid_adding.length <= 0) {
            this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez sélectionner les lignes à imprimer' });
        } else {
            this.setState({ showindicator: true });
            this.xulaservice.generer_ticket_fidelite(this.state.liste_ligne_fid_adding).then(data => {
                this.setState({ showindicator: false });
                this.resultat(data.code, data.contenu);
            });
        }
    }

    show_ticket_generation() {
        this.setState({ visible_ticket_genration: true, titre_operation: 'GENERATION DES TICKETS' }, () => {
            this.get_all_lignes_fidelisations();
        });
    }

    toogle_print() {
        this.setState({ visible_print: !this.visible_print });
    }

    toogle_dlg_lfid(rowData) {
        this.setState({ visible_dlg_lfid: !this.visible_dlg_lfid, modifLfid: true }, () => {
            this.get_lignes_fidelisations();
        });
    }

    componentDidMount() {
        this.get_liste_fidelisation();
        this.getliste_categorie();
    }

    get_liste_fidelisation() {
        this.setState({ showindicator: true });
        this.xulaservice.get_liste_fidelisation().then(data => this.setState({ liste_fidelisation: data, showindicator: false }));
    }
    get_liste_ticket() {
        let rid_cli = 'Tous';
        this.setState({ showindicator: true });
        let spd1 = moment(this.state.pd1, 'DD/MM/YYYY').format('DD/MM/YYYY');
        let spd2 = moment(this.state.pd2, 'DD/MM/YYYY').format('DD/MM/YYYY');
        if (this.state.ridCli != undefined && this.state.ridCli != null) {
            rid_cli = this.state.ridCli.value;
        }
        this.xulaservice.get_liste_tickets(spd1, spd2, rid_cli).then(data => this.setState({ liste_ticket: data, showindicator: false }));
    }

    getliste_categorie(idFid) {
        let liste = null;
        this.xulaservice.get_liste_catfidelisation(idFid).then(data => {
            if (data != null && data != undefined) {
                liste = data.map(x => {
                    return { value: x.idCatFid, label: x.libCatFid }
                });
            }
            this.setState({ liste_categorie: liste });
        });
    }

    get_liste_client() {
        let liste = null;
        this.xulaservice.getliste_client_light(this.state.rfiltreClient).then(data => {
            if (data != null && data != undefined) {
                liste = data.map(x => {
                    return { value: x.idCli, label: x.nomCli, label_rech: x.nomCli + ' ' + x.idCli }
                });
            }
            this.setState({ liste_client: liste });
        });
    }


    resultat(code, contenu) {
        if (code == '200') {
            this.growl.show({ severity: 'success', summary: 'Success', detail: contenu });
            if (this.state.visible_dlg_lfid == true) {
                if (this.state.modifLfid == false) {
                    this.setState({ modifLfid: true }, () => {
                        this.get_lignes_fidelisations();
                    })
                } else {
                    if (this.state.visible_ticket_genration == true) {
                        this.setState({ visible_ticket_genration: false });
                        this.get_liste_ticket();
                    } else {
                        this.setState({ visible_ticket_genration: false });
                        this.get_liste_ticket();
                    }
                }

            } else {
                this.annule();
                if (this.state.visible_ticket_genration == true) {
                    this.setState({ visible_ticket_genration: false, titre_operation: 'EDITION DES TICKETS' });
                    this.get_liste_ticket();
                } else {
                    this.setState({ visible_ticket_genration: false });
                    this.get_all_lignes_fidelisations();
                    this.get_liste_fidelisation();
                }
            }
        } else {
            this.growl.show({ severity: 'error', summary: 'Error', detail: contenu });
        }
    }


    update_lfid() {
        if (this.state.selectedLfid === undefined || this.state.tauxLfid === '' || this.state.debLfid === null || this.state.finLfid === null) {
            this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez saisir tous les champs obligatoires' });
        } else {
            this.setState({ showindicator: true });
            let data = this.state.selectedLfid;
            data.actifLfid = this.state.actifLfid;
            data.tauxLfid = this.state.tauxLfid;
            data.debLfid = moment(this.state.debLfid, 'DD/MM/YYYY').format('DD/MM/YYYY');
            data.finLfid = moment(this.state.finLfid, 'DD/MM/YYYY').format('DD/MM/YYYY');
            this.xulaservice.update_lfid(data).then(data => {
                this.setState({ showindicator: false });
                this.resultat(data.code, data.contenu);
            });
        }
    }

    add_lfid_batch() {
        if (this.state.liste_ligne_fid_adding.length <= 0) {
            this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez sélectionner les lignes à ajouter' });
        } else {
            this.setState({ showindicator: true });
            this.xulaservice.add_lfid_batch(this.state.liste_ligne_fid_adding).then(data => {
                this.setState({ showindicator: false });
                this.resultat(data.code, data.contenu);
            });
        }
    }

    set_value() {
        for (var i = this.state.liste_ligne_fid.length; i--;) {
            this.state.liste_ligne_fid[i].tauxLfid = this.state.tauxLfid;
            this.state.liste_ligne_fid[i].debLfid = moment(this.state.debLfid, 'DD/MM/YYYY').format('DD/MM/YYYY');
            this.state.liste_ligne_fid[i].finLfid = moment(this.state.finLfid, 'DD/MM/YYYY').format('DD/MM/YYYY');
            this.state.liste_ligne_fid[i].iduser = this.state.userconnected.iduser;
            this.state.liste_ligne_fid[i].idEtabl = this.state.userconnected.idEtabl.idEtabl;
        }
    }

    edit_lfid(data) {
        if (data != null && data != undefined) {
            this.setState({ tauxLfid: data.tauxLfid });
            this.setState({ debLfid: data.debLfid });
            this.setState({ finLfid: data.finLfid });
            this.setState({ selectedLfid: data });
            window.scrollTo(0, 0);
        }
    }

    annule_lfid() {
        this.setState({ modifLfid: true });
        this.forceUpdate();
    }


    edit(data) {
        if (data != null && data != undefined) {
            this.setState({ idFid: data.idFid });
            let liste = null;
            this.xulaservice.getliste_client_light(data.idCli.nomCli).then(res => {
                if (res != null && res != undefined) {
                    liste = res.map(x => {
                        return { value: x.idCli, label: x.nomCli, label_rech: x.nomCli + ' ' + x.idCli }
                    });
                }
                this.setState({ liste_client: liste }, () => {
                    this.setState({ idCli: data.idCli.idCli });
                });
            });
            this.setState({ actifFid: data.actifFid });
            this.setState({ nomFid: data.nomFid });
            this.setState({ idCatFid: data.idCatFid.idCatFid });
            this.setState({ modif: true });
            this.setState({ selectedFid: data });
            window.scrollTo(0, 0);
        }
    }


    annule() {
        this.setState({ idCli: null });
        this.setState({ idCatFid: null, nomFid: '' });
        this.setState({ modif: false });
        this.forceUpdate();
    }




    enregistrer() {
        if (this.state.idCli === undefined || this.state.idCli === null || this.state.idCatFid === undefined || this.state.idCatFid === null) {
            this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez saisir tous les champs obligatoires' });
        } else {
            if (this.state.modif == false) {
                let data = { idFid: '0', nomFid: this.state.nomFid, idCli: { idCli: this.state.idCli.value }, actifFid: this.state.actifFid, idCatFid: { idCatFid: this.state.idCatFid } };
                this.xulaservice.enreg_fidelisation(data).then(data => this.resultat(data.code, data.contenu));
            } else {
                let data = this.state.selectedFid;
                data.actifFid = this.state.actifFid;
                data.nomFid = this.state.nomFid;
                this.xulaservice.enreg_fidelisation(data).then(data => this.resultat(data.code, data.contenu));
            }
        }
    }


    actionTemplate(rowData, column) {
        let items = [
            {
                label: 'Modifier',
                icon: 'pi pi-pencil',
                command: (e) => {
                    this.edit(rowData);
                }
            },
            {
                label: 'Détails',
                icon: 'pi pi-sitemap',
                command: (e) => {
                    this.toogle_dlg_lfid(rowData);
                }
            }
        ];
        return <SplitButton label="" onClick={(e) => this.edit(rowData)} icon="pi pi-pencil" model={items} />
    }


    delete() {
        if (this.state.selectedFid == null || this.state.selectedFid == undefined) {
            this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez sélectionner la ligne à supprimer' });
        } else {
            this.xulaservice.delete_fidelisation(this.state.selectedFid).then(data => this.resultat(data.code, data.contenu));
        }
    }


    conf_delete(data) {
        if (data != null && data != undefined) {
            this.setState({ selectedFid: data });
            this.setState({ msg: data.idCli.nomCli });
            this.setState({ visible: true });
        }
    }

    get_lignes_fidelisations() {
        this.setState({ showindicator: true });
        if (this.state.modifLfid == false) {
            this.xulaservice.get_lignes_fidelisations_for_adding(this.state.selectedFid.idFid).then(data => this.setState({ liste_ligne_fid: data, showindicator: false }));
        } else {
            this.xulaservice.get_lignes_fidelisations(this.state.selectedFid.idFid).then(data => this.setState({ liste_ligne_fid: data, showindicator: false }));
        }
    }

    get_all_lignes_fidelisations() {
        this.setState({ showindicator: true });
        this.xulaservice.get_all_lignes_fidelisations().then(data => this.setState({ liste_ligne_fid: data, showindicator: false }));
    }

    actionTemplateLfid(rowData, column) {
        return <Button onClick={(e) => this.edit_lfid(rowData)} className="p-button-info" style={{ width: 25, height: 25 }} icon="pi pi-pencil" />;
    }

    render() {

        const dialogFooter = (
            <div>
                <Button icon="pi pi-check" onClick={() => {
                    this.setState({ visible: false });
                    this.delete();
                }} label="Oui" />
                <Button icon="pi pi-times" onClick={() => this.setState({ visible: false })} label="Annuler" className="p-button-secondary" />

            </div>
        );

        return (
            <div className="p-fluid ">
                <Growl ref={(el) => this.growl = el} />
                <Dialog header="Confirmation" visible={this.state.visible} style={{ width: '250px' }} modal={true} footer={dialogFooter} onHide={(e) => this.setState({ visible: false })}>
                    <div>
                        <i className="fa fa-warning" style={{ color: '#FFD133', fontSize: 25 }}></i>
                        Voulez vous vraiment supprimer <b>{this.state.msg} </b>?
                    </div>
                </Dialog>

                <Dialog header="Détails fidélisation" visible={this.state.visible_dlg_lfid} style={{ width: '80%' }} modal={true} onHide={(e) => {
                    this.setState({ visible_dlg_lfid: false });
                }} >
                    {this.state.modifLfid == true ?
                        <>

                            <div className="p-grid">
                                <div className="p-col-12 p-md-2">
                                    <span >
                                        <label style={{ fontSize: '13px', color: '#9A9C9C' }} htmlFor="inPu">Date début *</label>
                                    </span>
                                    <Calendar value={this.state.debLfid} dateFormat="dd/mm/yy" onChange={(e) => this.setState({ debLfid: e.value })} />
                                </div>
                                <div className="p-col-12 p-md-2">
                                    <span >
                                        <label style={{ fontSize: '13px', color: '#9A9C9C' }} htmlFor="inPu">Date fin *</label>
                                    </span>
                                    <Calendar value={this.state.finLfid} dateFormat="dd/mm/yy" onChange={(e) => this.setState({ finLfid: e.value })} />
                                </div>
                                <div className="p-col-12 p-md-2">
                                    <span >
                                        <label style={{ fontSize: '13px', color: '#9A9C9C' }} htmlFor="inPu">Taux *</label>
                                    </span>
                                    <Cleave value={this.state.tauxLfid} options={{ numeral: true, delimiter: ' ' }} onChange={(e) => this.setState({ tauxLfid: e.target.rawValue })} className="p-inputtext" />
                                </div>

                                <div className="p-col-12 p-md-4">
                                    <span >
                                        <label style={{ fontSize: '13px', color: '#9A9C9C' }} htmlFor="inPu"> *</label>
                                    </span>
                                    <div className="p-grid" >
                                        <div className="p-col"><Button label="Valider" icon="pi pi-check" onClick={(e) => this.update_lfid()} /></div>
                                        <div className="p-col"><Button label="Annuler" icon="pi pi-ban" onClick={(e) => { this.setState({ visible_dlg_lfid: false }) }} className="p-button-danger" /></div>
                                    </div>
                                </div>
                                <div className="p-col-12 p-md-2">
                                    {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : null}
                                </div>
                            </div>

                            <DataTable value={this.state.liste_ligne_fid}
                                scrollable={true} paginator={true} rows={8} paginatorPosition='bottom' alwaysShowPaginator={false}
                                onRowClick={e => this.setState({ selectedLfid: e.data })} selectionMode="single"
                                selection={this.state.selectedLfid}
                                header={<Button label="Ajouter" style={{ width: 150 }} icon="pi pi-plus" onClick={(e) => {
                                    this.setState({ modifLfid: false, liste_ligne_fid: [] }, () => {
                                        this.get_lignes_fidelisations();
                                    })
                                }} className="p-button-warning" />}
                            >
                                <Column header="#" body={this.actionTemplateLfid.bind(this)} style={{ width: '8%' }} />
                                <Column field="idArticle.libArticle" header="Article" sortable={true} filter={true} filterMatchMode='contains' />
                                <Column field="tauxLfid" header="Taux" sortable={true} style={{ width: '10%' }} filter={true} filterMatchMode='contains' />
                                <Column field="debLfid" header="Date début" sortable={true} style={{ width: '15%' }} filter={true} filterMatchMode='contains' />
                                <Column field="finLfid" header="Date fin" style={{ width: '15%' }} sortable={true} filter={true} filterMatchMode='contains' />
                                <Column field="actifLfid" header="Actif" sortable={true} style={{ width: '10%' }} filter={true} filterMatchMode='contains' />
                            </DataTable>
                        </>
                        :
                        <>
                            <div className="p-grid">
                                <div className="p-col-12 p-md-3">
                                    <span >
                                        <label style={{ fontSize: '13px', color: '#9A9C9C' }} htmlFor="inPu">Date début *</label>
                                    </span>
                                    <Calendar value={this.state.debLfid} dateFormat="dd/mm/yy" onChange={(e) => this.setState({ debLfid: e.value }, () => {
                                        this.set_value();
                                    })} />
                                </div>
                                <div className="p-col-12 p-md-3">
                                    <span >
                                        <label style={{ fontSize: '13px', color: '#9A9C9C' }} htmlFor="inPu">Date fin *</label>
                                    </span>
                                    <Calendar value={this.state.finLfid} dateFormat="dd/mm/yy" onChange={(e) => this.setState({ finLfid: e.value }, () => {
                                        this.set_value();
                                    })} />
                                </div>
                                <div className="p-col-12 p-md-2">
                                    <span >
                                        <label style={{ fontSize: '13px', color: '#9A9C9C' }} htmlFor="inPu">Taux *</label>
                                    </span>
                                    <Cleave value={this.state.tauxLfid} options={{ numeral: true, delimiter: ' ' }} onChange={(e) => this.setState({ tauxLfid: e.target.rawValue }, () => {
                                        this.set_value();
                                    })} className="p-inputtext" />
                                </div>
                                <div className="p-col-12 p-md-2">
                                    {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : null}
                                </div>
                            </div>
                            <DataTable value={this.state.liste_ligne_fid}
                                paginator={true} rows={8} paginatorPosition='bottom' alwaysShowPaginator={false}
                                onSelectionChange={e => this.setState({ liste_ligne_fid_adding: e.value })}
                                selection={this.state.liste_ligne_fid_adding}
                                footer={
                                    <center>
                                        <div className="p-grid" style={{ width: 300 }}>
                                            <div className="p-col"><Button label="Annuler" icon="pi pi-ban" onClick={(e) => {
                                                this.setState({ modifLfid: true, liste_ligne_fid: [] }, () => {
                                                    this.get_lignes_fidelisations();
                                                });
                                            }} /></div>
                                            <div className="p-col"><Button label="Valider" icon="pi pi-check" onClick={(e) => this.add_lfid_batch()} /></div>
                                        </div>
                                        {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : null}

                                    </center>
                                }
                            >
                                <Column selectionMode="multiple" style={{ width: '6%' }} />
                                <Column field="idArticle.libArticle" header="Article" sortable={true} filter={true} filterMatchMode='contains' />
                                <Column field="tauxLfid" header="Taux" editor={this.tauxLfidEditor} sortable={true} style={{ width: '10%' }} filter={true} filterMatchMode='contains' />
                                <Column field="debLfid" header="Date début" editor={this.debLfidEditor} sortable={true} style={{ width: '15%' }} filter={true} filterMatchMode='contains' />
                                <Column field="finLfid" header="Date fin" editor={this.finLfidEditor} sortable={true} style={{ width: '15%' }} filter={true} filterMatchMode='contains' />
                                <Column field="actifLfid" header="Actif" sortable={true} style={{ width: '10%' }} filter={true} filterMatchMode='contains' />
                            </DataTable>
                        </>
                    }
                </Dialog>

                <Dialog header="Edition des cartes et tickets" visible={this.state.visible_print} style={{ width: '80%' }} modal={true} onHide={(e) => {
                    this.setState({ visible_print: false });
                }} >
                    <div className="p-grid" >
                        <div className="p-col">
                            <SplitButton label="Edition des tickets" style={{ width: '200px' }} onClick={(e) => {
                                this.get_liste_ticket();
                                this.setState({ visible_print_ticket: true, titre_operation: 'EDITION DES TICKETS' });
                            }} icon="pi pi-search" model={this.state.items}></SplitButton>
                        </div>
                        <div className="p-col">
                            {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : null}
                        </div>
                        <div className="p-col card card-w-title" style={{ backgroundColor: '#f7f7f7' }}>
                            <label style={{ fontSize: 22, color: '#F54819', fontWeight: 'bold' }}>{this.state.titre_operation}</label>
                        </div>
                    </div>

                    {this.state.visible_print_ticket == false ?
                        <DataTable value={this.state.liste_fidelisation}
                            paginator={true} rows={8} paginatorPosition='bottom' alwaysShowPaginator={false}
                            onSelectionChange={e => this.setState({ liste_fidelisation_selected: e.value })}
                            selection={this.state.liste_fidelisation_selected}
                            footer={
                                <>
                                    <Button onClick={(e) => this.export_carte()} label="Exporter les cartes" style={{ width: 155 }} icon="pi pi-print" className="p-button-info p-button-rounded" />
                                    <Button onClick={(e) => this.download_photos_clients()} label="Exporter les photos" style={{ width: 155 }} icon="pi pi-print" className="p-button-info p-button-rounded" />
                                    <Button onClick={(e) => this.print_carte()} label="Editer les cartes" style={{ width: 155 }} icon="pi pi-print" className="p-button-warning p-button-rounded" />
                                </>
                            }  >
                            <Column selectionMode="multiple" style={{ width: '6%' }} />
                            <Column header="No" body={this.onIndexTemplate.bind(this)} style={{ width: '5%' }} />
                            <Column field="dateFid" header="Date" sortable={true} style={{ width: '10%' }} filter={true} filterMatchMode='contains' />
                            <Column field="nomFid" header="Nom client" sortable={true} filter={true} filterMatchMode='contains' />
                            <Column field="idCatFid.libCatFid" header="Catégorie" sortable={true} style={{ width: '20%' }} filter={true} filterMatchMode='contains' />
                            <Column field="idCatFid.prcCatFid" header="Pourcentage" sortable={true} style={{ width: '15%' }} filter={true} filterMatchMode='contains' />
                            <Column field="actifFid" header="Actif" sortable={true} style={{ width: '10%' }} filter={true} filterMatchMode='contains' />
                        </DataTable> :
                        <>
                            {this.state.visible_ticket_genration == true ?
                                <DataTable value={this.state.liste_ligne_fid}
                                    paginator={true} rows={8} paginatorPosition='bottom' alwaysShowPaginator={false}
                                    onSelectionChange={e => this.setState({ liste_ligne_fid_adding: e.value })}
                                    selection={this.state.liste_ligne_fid_adding}
                                    footer={
                                        <center>
                                            <div className="p-grid" style={{ width: 400 }}>
                                                <div className="p-col"><Button label="Fermer" icon="pi pi-ban" onClick={(e) => {
                                                    this.setState({ visible_ticket_genration: false, liste_ligne_fid: [], titre_operation: 'EDITION DES TICKETS' });
                                                }} className="p-button-danger" /></div>
                                                <div className="p-col"><Button label="Générer les tickets" icon="pi pi-refresh" onClick={(e) => this.generate_ticket()} className="p-button-warning p-button-rounded" /></div>
                                            </div>
                                        </center>
                                    }
                                >
                                    <Column selectionMode="multiple" style={{ width: '6%' }} />
                                    <Column header="No" body={this.onIndexTemplate.bind(this)} style={{ width: '5%' }} />
                                    <Column field="idFid.nomFid" header="Client" sortable={true} filter={true} filterMatchMode='contains' />
                                    <Column field="idArticle.libArticle" header="Article" sortable={true} filter={true} filterMatchMode='contains' />
                                    <Column field="tauxLfid" header="Taux" editor={this.tauxLfidEditor} sortable={true} style={{ width: '8%' }} filter={true} filterMatchMode='contains' />
                                    <Column field="debLfid" header="Date début" editor={this.debLfidEditor} sortable={true} style={{ width: '13%' }} filter={true} filterMatchMode='contains' />
                                    <Column field="finLfid" header="Date fin" editor={this.finLfidEditor} sortable={true} style={{ width: '13%' }} filter={true} filterMatchMode='contains' />
                                    <Column field="actifLfid" header="Actif" sortable={true} style={{ width: '10%' }} filter={true} filterMatchMode='contains' />
                                </DataTable>
                                :
                                <>
                                    <div className="p-grid">
                                        <div className="p-col-12 p-md-2">
                                            <Calendar value={this.state.pd1} dateFormat="dd/mm/yy" onChange={(e) => this.setState({ pd1: e.value })} />
                                        </div>
                                        <div className="p-col-12 p-md-2">
                                            <Calendar value={this.state.pd2} dateFormat="dd/mm/yy" onChange={(e) => this.setState({ pd2: e.value })} />
                                        </div>
                                        <div className="p-col-12 p-md-5">
                                            <div className="p-grid">
                                                <div className="p-col-10">
                                                    <Dropdown id="new_dp_client_rech"
                                                        options={[{ value: 'Tous', label: 'Client', label_rech: 'Client' }].concat(this.state.liste_client)}
                                                        value={this.state.ridCli}
                                                        placeholder="Client"
                                                        onChange={(e) => {
                                                            this.setState({ ridCli: e.value })
                                                        }
                                                        }
                                                        style={{ width: '100%' }}
                                                        filter={true} filterBy='label_rech' filterMatchMode='contains'
                                                        optionLabel='label_rech'
                                                        itemTemplate={(option) => {
                                                            if (!option.value) {
                                                                return option.label;
                                                            }
                                                            else {
                                                                return (
                                                                    <div className="p-clearfix">
                                                                        <div className="p-grid">
                                                                            <div className="p-col-12 p-md-12">
                                                                                <label>{option.label}</label>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                );
                                                            }
                                                        }}
                                                    />
                                                </div>
                                                <div className="p-col-2">
                                                    <Button type="button" icon="pi pi-search" className="p-button-warning" onClick={(e) => {
                                                        this.op_rech.toggle(e);
                                                        document.getElementById("id_ovp_rech").focus();
                                                    }} style={{ marginLeft: -15, width: '35px' }} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="p-col-12 p-md-2">
                                            <Button type="button" icon="pi pi-search" onClick={(e) => { this.get_liste_ticket() }} label="Rechercher" />
                                        </div>
                                    </div>

                                    <DataTable value={this.state.liste_ticket}
                                        paginator={true} rows={8} paginatorPosition='bottom' alwaysShowPaginator={false}
                                        onSelectionChange={e => this.setState({ liste_ticket_selected: e.value })}
                                        selection={this.state.liste_ticket_selected}
                                        footer={
                                            <>
                                                <Button onClick={(e) => this.show_ticket_generation()} label="Générer des tickets" style={{ width: 200 }} icon="pi pi-print" className="p-button-info p-button-rounded" />
                                                <Button onClick={(e) => this.print_ticket()} label="Editer des tickets" style={{ width: 200 }} icon="pi pi-print" className="p-button-warning p-button-rounded" />
                                            </>
                                        }  >
                                        <Column selectionMode="multiple" style={{ width: '6%' }} />
                                        <Column header="No" body={this.onIndexTemplate.bind(this)} style={{ width: '5%' }} />
                                        <Column field="dateTicket" header="Date" sortable={true} style={{ width: '10%' }} filter={true} filterMatchMode='contains' />
                                        <Column field="idTicket" header="N° ticket" sortable={true} filter={true} filterMatchMode='contains' style={{ width: '15%' }} />
                                        <Column field="idLfid.idArticle.libArticle" header="Article" sortable={true} filter={true} filterMatchMode='contains' />
                                        <Column field="idLfid.idFid.nomFid" header="Client" sortable={true} style={{ width: '25%' }} filter={true} filterMatchMode='contains' />
                                        <Column field="idLfid.idFid.idCatFid.libCatFid" header="Catégorie" sortable={true} style={{ width: '15%' }} filter={true} filterMatchMode='contains' />
                                    </DataTable>
                                </>
                            }
                        </>
                    }

                </Dialog>

                <OverlayPanel ref={(el) => { this.op = el; }} style={{ width: '300px' }} >
                    <div className="p-grid">
                        <div className="p-col-10">
                            <InputText value={this.state.rfiltreClient} id="id_ovp" onKeyDown={(e) => {
                                if (e.keyCode === 13) {
                                    this.op.toggle(e);
                                    document.getElementById("new_dp_client").click();
                                    this.get_liste_client();
                                }
                            }} onChange={(e) => this.setState({ rfiltreClient: e.target.value })} autoFocus={true} />
                        </div>
                        <div className="p-col-2">
                            <Button type="button" icon="pi pi-search" className="p-button-info" onClick={(e) => {
                                this.op.toggle(e);
                                document.getElementById("new_dp_client").click();
                                this.get_liste_client();
                            }} style={{ width: '40px' }} />
                        </div>

                    </div>
                </OverlayPanel>

                <OverlayPanel ref={(el) => { this.op_rech = el; }} style={{ width: '300px' }} >
                    <div className="p-grid">
                        <div className="p-col-10">
                            <InputText value={this.state.rfiltreClient} id="id_ovp_rech" onKeyDown={(e) => {
                                if (e.keyCode === 13) {
                                    this.op.toggle(e);
                                    document.getElementById("new_dp_client_rech").click();
                                    this.get_liste_client();
                                }
                            }} onChange={(e) => this.setState({ rfiltreClient: e.target.value })} autoFocus={true} />
                        </div>
                        <div className="p-col-2">
                            <Button type="button" icon="pi pi-search" className="p-button-info" onClick={(e) => {
                                this.op.toggle(e);
                                document.getElementById("new_dp_client_rech").click();
                                this.get_liste_client();
                            }} style={{ width: '40px' }} />
                        </div>

                    </div>
                </OverlayPanel>


                <div className="p-grid">


                    <div className="p-grid">
                        <div className="p-col-12">
                            <div className="card card-w-title" style={{ height: 550 }}>

                                <div className="p-grid">

                                    <div className="p-col-12 p-md-3">
                                        <span >
                                            <label style={{ fontSize: '13px', color: '#9A9C9C' }} htmlFor="inPu">Client *</label>
                                        </span>
                                        <div className="p-grid">
                                            <div className="p-col-10">
                                                <Dropdown id="new_dp_client"
                                                    options={this.state.liste_client}
                                                    value={this.state.idCli}
                                                    placeholder="Client"
                                                    onChange={(e) => {
                                                        this.setState({ idCli: e.value })
                                                    }
                                                    }
                                                    style={{ width: '100%' }}
                                                    filter={true} filterBy='label_rech' filterMatchMode='contains'
                                                    optionLabel='label_rech'
                                                    itemTemplate={(option) => {
                                                        if (!option.value) {
                                                            return option.label;
                                                        }
                                                        else {
                                                            return (
                                                                <div className="p-clearfix">
                                                                    <div className="p-grid">
                                                                        <div className="p-col-12 p-md-12">
                                                                            <label>{option.label}</label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            );
                                                        }
                                                    }}
                                                />
                                            </div>
                                            <div className="p-col-2">
                                                {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> :
                                                    <Button type="button" icon="pi pi-search" className="p-button-warning" onClick={(e) => {
                                                        this.op.toggle(e);
                                                        document.getElementById("id_ovp").focus();
                                                    }} style={{ marginLeft: -15, width: '35px' }} />
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <div className="p-col-12 p-md-3">
                                        <span >
                                            <label style={{ fontSize: '13px', color: '#9A9C9C' }} htmlFor="inPu">Nom *</label>
                                        </span>
                                        <InputText value={this.state.nomFid} onChange={(e) => this.setState({ nomFid: e.target.value })} />
                                    </div>
                                    <div className="p-col-12 p-md-3">
                                        <span >
                                            <label style={{ fontSize: '13px', color: '#9A9C9C' }} htmlFor="inPu">Catégorie *</label>
                                        </span>
                                        <Dropdown autoWidth={false} options={this.state.liste_categorie} value={this.state.idCatFid} onChange={(e) => this.setState({ idCatFid: e.value })} />
                                    </div>

                                    <div className="p-col-12 p-md-3">
                                        <span >
                                            <label style={{ fontSize: '13px', color: '#9A9C9C' }} htmlFor="inPu">Actif *</label>
                                        </span>
                                        <Dropdown autoWidth={false} options={[{ label: 'Oui', value: 'Oui' }, { label: 'Non', value: 'Non' }]} value={this.state.actifFid} onChange={(e) => this.setState({ actifFid: e.value })} />
                                    </div>

                                </div>

                                <center>
                                    <div className="p-grid" style={{ width: 300 }}>
                                        <div className="p-col"><Button label="Annuler" icon="pi pi-ban" onClick={(e) => this.annule()} /></div>
                                        <div className="p-col"><Button label="Valider" icon="pi pi-check" onClick={(e) => this.enregistrer()} /></div>
                                    </div>
                                    {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : null}
                                </center>

                                <Panel style={{ minHeight: '350px', marginBottom: 8 }}>
                                    <DataTable value={this.state.liste_fidelisation}
                                        header={<Button onClick={(e) => this.toogle_print()} label="Editions" style={{ width: 200 }} icon="pi pi-print" className="p-button-warning p-button-rounded" />}
                                        selectionMode="single" paginator={true} rows={10} paginatorPosition='bottom' alwaysShowPaginator={false}
                                        onRowClick={e => this.setState({ selectedFid: e.data })}
                                        selection={this.state.selectedFid}
                                    >
                                        <Column header="#" body={this.actionTemplate.bind(this)} style={{ width: '7%' }} />
                                        <Column header="No" body={this.onIndexTemplate.bind(this)} style={{ width: '4%' }} />
                                        <Column field="dateFid" header="Date" sortable={true} style={{ width: '10%' }} filter={true} filterMatchMode='contains' />
                                        <Column field="nomFid" header="Client" sortable={true} filter={true} filterMatchMode='contains' />
                                        <Column field="idCatFid.libCatFid" header="Catégorie" sortable={true} style={{ width: '20%' }} filter={true} filterMatchMode='contains' />
                                        <Column field="idCatFid.prcCatFid" header="Pourcentage" sortable={true} style={{ width: '12%' }} filter={true} filterMatchMode='contains' />
                                        <Column field="actifFid" header="Actif" sortable={true} style={{ width: '10%' }} filter={true} filterMatchMode='contains' />
                                    </DataTable>
                                </Panel>




                            </div>
                        </div>
                    </div>
                </div>




            </div>
        );
    }
}