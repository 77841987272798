import React, { Component } from 'react';
import { Xulaservice } from '../service/Xulaservice';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Growl } from 'primereact/growl';
import { Dropdown } from 'primereact/dropdown';
import { SplitButton } from 'primereact/splitbutton';
import { Calendar } from 'primereact/calendar';
import { ProgressSpinner } from 'primereact/progressspinner';
import Numeral from 'numeral'; import 'moment';
import 'moment/locale/fr';
import moment from 'moment';
import './styles.css';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';

export class FormEdition_Solde extends Component {
    constructor() {
        super();
        this.state = {
            pd1: new Date(),
            pd2: new Date(),
            date_solde: new Date(),
            ridBoutiq: null,
            visible_dgl_refresh: false,
            liste_data: [],
            liste_boutique: [],
            items: [
                {
                    label: 'Format PDF',
                    icon: 'pi pi-print',
                    command: (e) => {
                        this.conf_print('PDF');
                    }
                },
                {
                    label: 'Format XLS',
                    icon: 'pi pi-print',
                    command: (e) => {
                        this.conf_print('XLS');
                    }
                },
                {
                    label: 'Exporter en CSV',
                    icon: 'pi pi-print',
                    command: (e) => {
                        this.dt.exportCSV();
                    }
                }
            ],
            userconnected: JSON.parse(localStorage.getItem('userconnected')),
        };
        this.conf_print = this.conf_print.bind(this);
        this.xulaservice = new Xulaservice();
        this.show_refresh_dlg = this.show_refresh_dlg.bind(this);
    }

    conf_print(format) {
        let rid_boutique = 'Tous';
        let spd1 = moment(this.state.pd1, 'DD/MM/YYYY').format('DD/MM/YYYY');
        let spd2 = moment(this.state.pd2, 'DD/MM/YYYY').format('DD/MM/YYYY');
        if (this.state.ridBoutiq != undefined && this.state.ridBoutiq != null) {
            rid_boutique = this.state.ridBoutiq.value;
        }
        this.xulaservice.generer_edition_solde(spd1, spd2, rid_boutique, format);
    }

    formaterMontant(montant) {
        return Numeral(parseFloat(montant)).format('0,0');
    }


    get_liste_boutique() {
        let lboutique = null;
        let lboutique_rech = [{ value: 'Tous', label: 'Boutique' }];
        
        this.xulaservice.getListe_boutique().then(data =>{
            if (data != null && data != undefined) {
                lboutique = data.map(x => {
                    return { value: x.idBoutiq, label: x.nomBoutiq }
                });
            }
            if (this.state.userconnected.profilUser === 'PDG' || this.state.userconnected.profilUser === 'Coordinateur') {
                lboutique_rech = lboutique_rech.concat(lboutique);
                this.setState({ liste_boutique: lboutique_rech });
            } else {
                let id = this.state.userconnected.matrPers.idBoutiq.idBoutiq;
                if (lboutique.length > 0) {
                    let liste = lboutique.filter(function (el) {
                        return el.value === id;
                    });
                    lboutique_rech = lboutique_rech.concat(liste);
                    this.setState({ liste_boutique: lboutique_rech },()=>{
                        if (this.state.userconnected != null && this.state.userconnected != undefined) {
                            this.setState({ ridBoutiq: { value: this.state.userconnected.matrPers.idBoutiq.idBoutiq, label: this.state.userconnected.matrPers.idBoutiq.nomBoutiq } },()=>{
                                this.refresh_solde();
                            });
                        }
                    });
                }
            }
        });
    }

    componentDidMount() {
        this.get_liste_boutique();
    }



    get_liste_data() {
        let rid_boutique = 'Tous';
        this.setState({ showindicator: true });
        let spd1 = moment(this.state.pd1, 'DD/MM/YYYY').format('DD/MM/YYYY');
        let spd2 = moment(this.state.pd2, 'DD/MM/YYYY').format('DD/MM/YYYY');
        if (this.state.ridBoutiq != undefined && this.state.ridBoutiq != null) {
            rid_boutique = this.state.ridBoutiq.value;
        }
        this.xulaservice.get_journal_solde(spd1, spd2, rid_boutique).then(data => this.setState({ liste_data: data, showindicator: false }));
    }

 
    resultat(code, contenu) {
        if (code == '200') {
            this.growl.show({ severity: 'success', summary: 'Success', detail: contenu });
            this.setState({ visible_dgl_refresh: false });
            this.get_liste_data();
        } else {
            this.growl.show({ severity: 'error', summary: 'Error', detail: contenu });
        }
    }

    refresh_solde() {
        this.setState({ showindicator: true });
        this.xulaservice.refresh_solde(moment(this.state.date_solde,'DD/MM/YYYY').format('DD/MM/YYYY')).then(data => {
            this.resultat(data.code, data.contenu);
            this.get_liste_data();
        });
    }

    show_refresh_dlg() {
        this.setState({ visible_dgl_refresh: true });
    }

    render() {
        const header_refresh = <div style={{ textAlign: 'left' }}>
        <Button type="button" icon="pi pi-refresh" className="p-button-success" iconPos="left" label="Rafraichir" style={{ width: 150 }} onClick={this.show_refresh_dlg}></Button>
    </div>;
        return (
            <div className="p-fluid">
                <Growl ref={(el) => this.growl = el} />
                <Dialog header="Rafraichissement de solde" visible={this.state.visible_dgl_refresh} style={{ width: '30%' }} modal={true} onHide={(e) => this.setState({ visible_dgl_refresh: false })}>
                    <div className="p-grid" >
                        <div className="p-col-12">
                            <div className="card card-w-title" style={{ marginBottom: '-7px' }}>
                                    <div className="p-grid">
                                        <div className="p-col-12">
                                            <span >
                                                <label style={{ fontSize: '13px', color: '#9A9C9C' }} htmlFor="inPu">Date début *</label>
                                            </span>
                                            <Calendar value={this.state.date_solde} dateFormat="dd/mm/yy" onChange={(e) => this.setState({ date_solde: e.value })} />
                                        </div>
                                    </div>
                                <center>
                                    <div className="p-grid" style={{ width: 300 }}>
                                        <div className="p-col"><Button label="Annuler" icon="pi pi-ban" onClick={(e) => {
                                            this.setState({ visible_dgl_refresh: false });
                                        }} /></div>
                                        <div className="p-col"><Button label="Rafraichir" icon="pi pi-check" onClick={(e) => this.refresh_solde()} /></div>
                                        <div className="p-col">{this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : null}</div>
                                    </div>
                                </center>
                            </div>
                        </div>
                    </div>
                </Dialog>

                <div className="p-grid">
                    <div className="p-col-12">
                        <div className="card card-w-title" style={{ minHeight: '700px' }}>
                            <i className="fa fa-fw fa-search"></i>Option de recherche
                         <hr />
                            <div className="p-grid">
                                <div className="p-col-12">
                                    <div className="p-grid">
                                        <div className="p-col-12 p-md-3">
                                            <Calendar value={this.state.pd1} dateFormat="dd/mm/yy" onChange={(e) => this.setState({ pd1: e.value })} />
                                        </div>
                                        <div className="p-col-12 p-md-3">
                                            <Calendar value={this.state.pd2} dateFormat="dd/mm/yy" onChange={(e) => this.setState({ pd2: e.value })} />
                                        </div>
                                        <div className="p-col-12 p-md-3">
                                            <Dropdown options={this.state.liste_boutique} placeholder="Boutique" value={this.state.ridBoutiq} optionLabel='label' filter={true} filterBy='label' filterMatchMode='contains' onChange={(e) => this.setState({ ridBoutiq: e.value })} autoWidth={false} />
                                        </div>
                                        <div className="p-col-12 p-md-3">
                                            <div className="p-inputgroup">
                                                <SplitButton label="Rechercher" style={{ width: '140px' }} onClick={(e) => this.get_liste_data()} icon="pi pi-search" model={this.state.items}></SplitButton>
                                                {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : null}
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
 
                            <DataTable value={this.state.liste_data} header={this.state.userconnected.profilUser=='PDG' || this.state.userconnected.profilUser=='Coordinateur' ?  header_refresh:null}
                                responsive={true} scrollable={true} scrollHeight="620px" csvSeparator=";" ref={(el) => { this.dt = el; }}>
                                <Column field="dateFormat" header="Date" style={{ width: '8%' }}  filter={true} filterMatchMode='contains' sortable={true}  />
                                <Column field="soldeAnt_format" header="Solde Ant." style={{ width: '10%' }}  filter={true} filterMatchMode='contains' sortable={true}  />
                                <Column field="fdDep_format" header="Dépenes" style={{ width: '8%' }}  filter={true} filterMatchMode='contains' sortable={true} />
                                <Column field="fdEntrant_format" header="Fond Entrant" style={{ width: '9%' }}  filter={true} filterMatchMode='contains' sortable={true} />
                                <Column field="fdSortant_format" header="Fond sortant" style={{ width: '9%' }}  filter={true} filterMatchMode='contains' sortable={true} />
                                <Column field="fdVente_format" header="Ventes" style={{ width: '10%' }}  filter={true} filterMatchMode='contains' sortable={true} />
                                <Column field="fdRgl_format" header="Commande" style={{ width: '10%' }}  filter={true} filterMatchMode='contains' sortable={true} />
                                <Column field="soldeJrn" header="Solde J" body={(rowData, column) => {
                                    let color = 'black';
                                    if (parseFloat(rowData.soldeJrn) < 0) {
                                        color = 'red';
                                    } else if (parseFloat(rowData.soldeJrn) > 0) {
                                        color = 'green';
                                    }
                                    return <div>
                                        <span style={{ color: color }}>{rowData.soldeJrn_format}</span>
                                    </div>
                                }} style={{ width: '10%' }}  filter={true} filterMatchMode='contains' sortable={true} />
                                <Column field="soldeGnle" body={(rowData, column) => {
                                    let color = 'black';
                                    if (parseFloat(rowData.soldeGnle) < 0) {
                                        color = 'red';
                                    } else if (parseFloat(rowData.soldeGnle) > 0) {
                                        color = 'green';
                                    }
                                    return <div>
                                        <span style={{ color: color, fontWeight: 'bold' }}>{rowData.soldeGnl_format}</span>
                                    </div>
                                }} header="Solde G" style={{ width: '10%' }}  filter={true} filterMatchMode='contains' sortable={true} />
                                <Column field="valNonLivre_format" header="Non livré" style={{ width: '8%' }}  filter={true} filterMatchMode='contains' sortable={true} />
                                <Column field="codeBoutiq" header="Boutique" style={{ width: '8%' }}  filter={true} filterMatchMode='contains' sortable={true} />
                            </DataTable>

                        </div>
                    </div>
                </div>
            </div>
        );
    }
}