import React, { Component } from 'react';
import { Xulaservice } from '../service/Xulaservice';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { SplitButton } from 'primereact/splitbutton';
import './styles.css';
import { ProgressSpinner } from 'primereact/progressspinner';
import Numeral from 'numeral';
import 'moment';
import 'moment/locale/fr';
import moment from 'moment';
import { Calendar } from 'primereact/calendar';

export class FormEdition_Journal_trs extends Component {
    constructor() {
        super();
        this.state = {
            pd1: new Date(),
            pd2: new Date(),
            liste_article: [],
            lboutique_rech: [],
            ridBoutiq_scre: { value: 'Tous', label: 'Boutique' },
            ridBoutiq_dest: { value: 'Tous', label: 'Boutique' },
            rcb_article: '',
            rlib_article: '',
            items: [
                {
                    label: 'Format CSV',
                    icon: 'pi pi-print',
                    command: (e) => {
                        this.dt.exportCSV();
                    }
                }
            ],
            userconnected: JSON.parse(localStorage.getItem('userconnected')),
        };

        this.state.pd1.setDate(this.state.pd1.getDate() - 2);
        this.xulaservice = new Xulaservice();
    }



    formaterMontant(montant) {
        return Numeral(parseFloat(montant)).format('0,0');
    }


    componentDidMount() {
        this.get_liste_data();
        this.get_liste_boutique();
    }

    get_liste_data() {
        let rid_boutique_srce = 'Tous';
        let rid_boutique_dest = 'Tous';
        if (this.state.ridBoutiq_scre != undefined || this.state.ridBoutiq_scre != null) {
            rid_boutique_srce = this.state.ridBoutiq_scre.value;
        }
        if (this.state.ridBoutiq_dest != undefined || this.state.ridBoutiq_dest != null) {
            rid_boutique_dest = this.state.ridBoutiq_dest.value;
        }
        let spd1 = moment(this.state.pd1).format('DD-MM-YYYY');
        let spd2 = moment(this.state.pd2).format('DD-MM-YYYY');
        this.setState({ showindicator: true });
        this.xulaservice.get_journal_trs(spd1, spd2, rid_boutique_srce, rid_boutique_dest, this.state.rlib_article).then(data => this.setState({ liste_article: data, showindicator: false }));
    }


    get_liste_boutique() {
        let lboutique_rech = [{ value: 'Tous', label: 'Boutique' }];
        let lboutique = null;
        this.xulaservice.getListe_boutique().then(data => this.setState({ liste_boutique: data }, () => {
            if (this.state.liste_boutique != null && this.state.liste_boutique != undefined) {
                lboutique = this.state.liste_boutique.map(x => {
                    return { value: x.idBoutiq, label: x.nomBoutiq }
                });
            }

            if (this.state.userconnected.profilUser === 'PDG' || this.state.userconnected.profilUser === 'Coordinateur') {
                this.setState({ lboutique: lboutique });
                lboutique_rech = lboutique_rech.concat(lboutique);
            } else {
                let id = this.state.userconnected.matrPers.idBoutiq.idBoutiq;
                if (lboutique.length > 0) {
                    let liste = lboutique.filter(function (el) {
                        return el.value === id;
                    });
                    this.setState({ lboutique: liste });
                    lboutique_rech = liste;
                }
            }
            this.setState({ lboutique_rech: lboutique_rech })
        }));
    }

    render() {
        return (
            <div className="p-fluid">

                <div className="p-grid">
                    <div className="p-col-12">
                        <div className="card card-w-title" style={{ minHeight: '900px' }}>
                            <i className="fa fa-fw fa-search"></i>Option de recherche
                         <hr />
                            <div className="p-grid">
                                <div className="p-col-12 p-md-2">
                                    <Calendar value={this.state.pd1} dateFormat="dd/mm/yy" onChange={(e) => this.setState({ pd1: e.value })} />
                                </div>
                                <div className="p-col-12 p-md-2">
                                    <Calendar value={this.state.pd2} dateFormat="dd/mm/yy" onChange={(e) => this.setState({ pd2: e.value })} />
                                </div>
                                <div className="p-col-12 p-md-2">
                                    <InputText value={this.state.rlib_article} placeholder="Désignation" onChange={(e) => this.setState({ rlib_article: e.target.value })} />
                                </div>
                                <div className="p-col-12 p-md-2">
                                    <Dropdown value={this.state.ridBoutiq} options={this.state.lboutique_rech} optionLabel='label' filter={true} filterBy='label' onChange={(e) => this.setState({ ridBoutiq: e.value }, () => {
                                        this.get_liste_data();
                                    })} autoWidth={false} />
                                </div>
                                <div className="p-col-12 p-md-2">
                                    <div className="p-inputgroup">
                                        <SplitButton label="Rechercher" style={{ width: '140px' }} onClick={(e) => this.get_liste_data()} icon="pi pi-search" model={this.state.items}></SplitButton>
                                        {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : null}
                                    </div>
                                </div>

                            </div> 

                            <DataTable value={this.state.liste_article} paginatorPosition="top" paginator={true} rows={20}
                                onRowClick={e => this.setState({ selectedData: e.data })} selectionMode="single" rowsPerPageOptions={[10, 20, 50, 100, 200]}
                                responsive={true} selection={this.state.selectedData} ref={(el) => { this.dt = el; }}>
                                <Column field="dateTrs" header="Date" style={{ width: '8%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                <Column field="idTrs" header="Num BS" style={{ width: '11%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                <Column field="libArticle" header="Désignation" style={{ width: '16%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                <Column field="qteLtrs" header="Qté" style={{ width: '6%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                <Column field="puLtrs" header="Prix" body={(rowData, column) => {
                                    return <div>
                                        <span >{this.formaterMontant(parseFloat(rowData.puLtrs))}</span>
                                    </div>
                                }} style={{ width: '7%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                <Column field="montantLtrs" body={(rowData, column) => {
                                    return <div>
                                        <span >{this.formaterMontant(parseFloat(rowData.montantLtrs))}</span>
                                    </div>
                                }} header="Montant" style={{ width: '9%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                <Column field="nomBoutique_srce" header="Initiateur" style={{ width: '10%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                <Column field="nomBoutique_dest" header="Bénéficiaire" style={{ width: '10%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                <Column field="approbTrs" header="Apprv" style={{ width: '7%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                <Column field="typeTrs" header="Type" style={{ width: '7%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                <Column field="statutLivrTrs" header="Statut" style={{ width: '7%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                <Column field="qterestTrs" header="Q. rest" style={{ width: '7%' }} filter={true} filterMatchMode='contains' sortable={true} />
                            </DataTable>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}