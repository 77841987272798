import React, { Component } from 'react';

export class AppFooter extends Component {

    render() {
        return <div className="layout-footer">
            <div className="clearfix">
                <span className="footer-text-left">OPTION PLUS TECHNOLOGIE</span>
                <span className="footer-text-right">All Rights Reserved</span>
            </div>
        </div>
    }
}